import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Pagination, Input, Select ,Button, Popover, Space , message ,QRCode ,Carousel , Image, Spin, Tooltip } from 'antd';
import { CaretDownOutlined, CaretUpOutlined,ShareAltOutlined,PhoneFilled,MailFilled,EnvironmentFilled,StopFilled } from '@ant-design/icons';
import "./styles/market.css";
import './styles/theme.css';

  import lineIcon from '../../../assets/lineLogo1.png';
  import facebookIcon from '../../../assets/facebookLogo2.png';
  import instagramIcon from '../../../assets/igLogo2.png';
  import tiktokIcon from '../../../assets/Tiktok_logo2.png';
  import youtubeIcon from '../../../assets/youtubeLogo2.png';
  import xIcon from '../../../assets/XLogo.jpeg';
  import telegramIcon from '../../../assets/telegramLogo.png';
  import wechatIcon from '../../../assets/WechatLogo.png';
  import shopeeIcon from '../../../assets/ShopeeLogo.png';
  import lazadaIcon from '../../../assets/LazadaLogo.png';
  import whatsappIcon from '../../../assets/whatsappLogo.png';
  import snapchatIcon from '../../../assets/SnapchatLogo.png';
  import phoneIcon from '../../../assets/phoneLogo.png';
  import mailIcon from '../../../assets/MailLogo.png';

  import logoUndefined from '../../../assets/marketundefined.svg';

const { Option } = Select;
const { Search } = Input;

function Market() {
  const { userId, market: urlMarket } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [market, setMarket] = useState(urlMarket || '');
  const [theme, setTheme] = useState('default-theme');
  const [data, setData] = useState(true);
  const [markets, setMarkets] = useState({ 
    logoUrl: [], 
    name: '', 
    description: '',  
  });  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [initialPosition, setInitialPosition] = useState({ lat: 13.7248785, lng: 100.4683055 });
  const [locationButton, setLocationButton] = useState(false);
  const [locationStatus, setLocationStatus] = useState('ตำแหน่ง');
  const [sortOrder, setSortOrder] = useState('default');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      if (userId) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/product/from_id_market/`, {
            params: { userId, market }
          });

          if(response.data.length === 0){
            setData(false)
          }

          if (Array.isArray(response.data)) {
            const sortedProducts = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            setProducts(sortedProducts);
            setFilteredProducts(sortedProducts);
          } else {
            console.error('Fetched products are not an array:', response.data);
          }
        } catch (err) {
          console.error('Error fetching products:', err);
        }
      }
    };

    const fetchMarkets = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market/from_id_market/${market}?populate=location`);

        if (response.data.status === 'closed') {
          navigate('/market_not_found');
          return;
        }

        setMarkets(response.data);
        setTheme(response.data.theme);

        if (response.data.location && response.data.location.lat && response.data.location.lng) {
          const lat = response.data.location.lat;
          const lng = response.data.location.lng;
    
          setSelectedPosition({ lat, lng });
          setInitialPosition({ lat, lng });
        }

      } catch (err) {
        console.error('Error fetching markets:', err);
      }
    };

    fetchProducts();
    fetchMarkets();
  }, [userId, market]);
  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }
  function getImageProductUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product`;
    return `${basePath}/${filename}`;
  }

  const contactIcons = {
    line: lineIcon,
    facebook: facebookIcon,
    instagram: instagramIcon,
    tiktok: tiktokIcon,
    youtube: youtubeIcon,
    x: xIcon,
    telegram: telegramIcon,
    wechat: wechatIcon,
    shopee: shopeeIcon,
    lazada: lazadaIcon,
    whatsapp: whatsappIcon,
    snapchat: snapchatIcon,
    phone: phoneIcon ,
    mail: mailIcon ,
  };
  
  useEffect(() => {
    // ตั้งค่า theme ให้กับ root element ของหน้านี้
    const bg1 = document.querySelector('.bg-800');
    if (bg1) {  // ตรวจสอบว่า pageContent มีอยู่
      bg1.classList.remove('default-theme', 'dark-theme', 'beige-theme', 'blue-theme');
      bg1.classList.add(`${theme}-theme`);
    }

  }, [theme]);

  const handleDetail = (productId) => {
    navigate(`/product/${productId}?previous=${encodeURIComponent(window.location.href)}`);
  };

  const handleItemsPerPageChange = (current, size) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    filterProducts(value, market);
  };

  const handleSortByPrice = () => {
    let newSortOrder;
    if (sortOrder === 'default') newSortOrder = 'ascp';
    else if (sortOrder === 'ascp') newSortOrder = 'descp';
    else newSortOrder = 'default';
    setSortOrder(newSortOrder);

    const sortedProducts = [...filteredProducts];
    if (newSortOrder === 'ascp') {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (newSortOrder === 'descp') {
      sortedProducts.sort((a, b) => b.price - a.price);
    } else {
      sortedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    setFilteredProducts(sortedProducts);
  };

  const filterProducts = (search, market) => {
    let filtered = products;

    if (search) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(search.toLowerCase()) ||
        product.sku.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (market) {
      filtered = filtered.filter(product => product.market._id === market);
    }

    setFilteredProducts(filtered);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedProductsPage = filteredProducts.slice(startIndex, startIndex + itemsPerPage);

  // กำหนดขนาดแผนที่
  const mapContainerStyle = {
    height: '400px',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  };
  // การตรวจสอบว่ามีการโหลด Script อยู่แล้วหรือไม่
  const shouldLoadMap = markets && locationButton && data;

  // ใช้ useMemo เพื่อหลีกเลี่ยงการเรนเดอร์ซ้ำ
  const LoadMap = useMemo(() => (
    shouldLoadMap && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={selectedPosition || initialPosition}
          zoom={12}
        >
          {selectedPosition && <MarkerF position={selectedPosition} />}
        </GoogleMap>
    )
  ), [initialPosition, selectedPosition, shouldLoadMap]);

  const locationClick = () => {
    if (locationButton === false) {
      setLocationButton(true);
      setLocationStatus('ซ่อน');
    }
    if (locationButton === true) {
      setLocationButton(false);
      setLocationStatus('ตำแหน่ง');
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => message.success("คัดลอกลิงค์สำเร็จ"))
      .catch(err => console.error('เกิดข้อผิดพลาดในการคัดลอกลิงค์:', err));
  };

  const content = (
    <div className='flex border rounded-md bg-700'>
      <div className='flex flex-col bg-700'>
        <div className='flex justify-center'>
          <QRCode value={window.location.href || '-'} />
        </div>
        <div className='p-1 rounded-md max-w-80 overflow-hidden overflow-x-auto bg-700'>
          <p>{window.location.href}</p>
        </div>
      </div>
      <div className='content-center bg-700'>
        <button className='object-cover px-2 border-l-2 bg-700' onClick={() => copyLink(window.location.href)}> <div className='border p-1 rounded-lg button-500'>คัดลอกลิงค์</div></button>
      </div>
    </div>
  );

  if (!markets.name && data) {
    return (
      <>
      <div className='flex justify-between'>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
    );
  }

  return (
    <>
    <div className='bg-800'>
    <div className='flex justify-center bg-700-900 min-h-svh'>
      <div className='' style={{ width:'924px',maxWidth: '924px' }}>
        {markets.status === 'vacation' ?
        <div className='sticky top-0 mb-3 md:block bg-white rounded-b-lg p-6 bg-vacation text-2xl select-none text-center z-50'>
            <StopFilled  className='mr-2' />ตอนนี้ร้านกำลังปิดพักร้อนอยู่
        </div> :''
        }

        <div className='grid grid-cols-6 mb-3'>
          <div className='col-start-1 col-end-7 rounded-b-lg overflow-auto'>
            <Carousel arrows autoplay>
              {markets?.imageUrls?.map((imageUrl, index) => (  // Use optional chaining here
                <div className='bg-gray-400 object-contain aspect-video'>
                  <img key={index} src={getImageMarketUrl(imageUrl)} alt={markets.name} 
                    className='object-contain  m-auto h-full' />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        
        <div className='mb-3 md:block bg-white rounded-lg md:p-6 p-2 bg-600'>
          <div className='flex justify-between mb-4'>
            <div className='flex md:pl-4 pl-2 '>
              <Search
                placeholder="ค้นหาสินค้า"
                allowClear
                style={{ marginRight: 10 }}
                className='w-72 md:w-96'
                onSearch={handleSearch}
                value={searchQuery}
                onChange={e => handleSearch(e.target.value)}
                classNames={'bg-500'}
              />
            </div>
          </div>
          <div className="gap-2">
            <div className=''>
              <div>
                <div className='flex flex-row ml-4 text-sm header-300'>
                  <div className='min-w-28 cursor-pointer flex' onClick={handleSortByPrice}>ราคา
                    {sortOrder === 'ascp' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  
                      : sortOrder === 'descp' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 
                        : <div className='flex flex-col pl-1 opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                  </div>
                </div>
              </div>
              {products.length === 0 ? (
                <div className='bg-white p-4 mt-4 rounded-md text-center text-gray-400'>
                  <label className='text-center'>ไม่พบข้อมูล</label>
                </div>) : ''}

              <div className='text-sm grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-2'>
                {selectedProductsPage
                .filter(product => product.status === true)
                .map(product => (
                  <div key={product._id} className='max-w-lg rounded-md border overflow-hidden hover:shadow-xl cursor-pointer bg-500' onClick={() => handleDetail(product._id)}>
                    <div className=''>
                      <a className=''>
                        {product.imageUrls[0] ? (
                          <img 
                            className="object-cover h-full w-auto aspect-square" src={getImageProductUrl(product.imageUrls[0])} alt={product.name} 
                            onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                          />
                        ) : (
                          <img className="object-cover h-full w-auto aspect-square" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.jpg`} />
                        )}
                      </a>
                    </div>
                    <div className='p-3 px-3 pb-2'>
                      <div className='max-h-20 max-w-44 break-words truncate-2-lines text-lg'>{product.name}</div>
                      <div className='pt-1 truncate text-xl header-1'>฿{product.price}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-center md:justify-end my-6">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={filteredProducts.length}
                onChange={handlePageChange}
                onShowSizeChange={handleItemsPerPageChange}
                showSizeChanger
                pageSizeOptions={['10', '20', '48', '100']}
              />
            </div>
          </div>
        </div>
        
        <div className='flex md:flex-row flex-col md:flex-wrap justify-center md:mt-3 md:mb-3 mb-0 h-xl md:rounded-lg rounded-t-lg shadow-lg bg-white bg-600 relative'>
          <div className='flex my-2 mx-2 absolute top-0 right-0 ...'>
            <div>
              <Tooltip placement="bottomRight" title='แชร์ร้านค้า'>
                <Popover content={content} trigger="click">
                  <button 
                    className='flex items-center justify-center py-2 px-2 rounded-full bg-white text-sm button-500 w-10 h-10 ease-in-out select-none duration-300' 
                    type="button" 
                  > 
                    <ShareAltOutlined className='scale-150'/>
                  </button>
                </Popover>
              </Tooltip>
            </div>
          </div>
          
          <div className="p-3 md:p-6 content-center">
            {Array.isArray(markets.logoUrl) && markets.logoUrl.some(logoUrl => logoUrl.trim() !== '') ? (
              markets.logoUrl.map((logoUrl, index) => (
                logoUrl.trim() !== '' ? (
                  <div className='object-contain rounded-lg ' key={index}>
                    <img src={getImageMarketUrl(logoUrl)} alt={markets.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60 bg-500' />
                  </div>
                ) : ''
              ))
            ) : (
              <img src={logoUndefined} alt={markets.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60' />
            )}
          </div>

          <div className=" grow md:p-6 md: p-0 md:py-6 py-2 md:max-w-xl md:px-0 px-4">
            <div className="max-w-xl min-h-10 overflow-hidden ">
                <h3 className="break-words md:text-3xl text-xl text-center">{markets.name}</h3>
            </div>

            <div className="max-w-xl min-h-10 overflow-hidden  mt-3">
                <h3 className="break-words text">{markets.description}</h3>
            </div>

            <div className="md:p-4 pt-4 md:pb-0 pb-2 p-0">
                {markets.contactlink && markets.contactlink.map((contact, index) => (
                  <div key={index} className='flex'> 
                    <div className='min-w-16'>
                      <img 
                        src={contactIcons[contact.source]} 
                        className='md:w-16 w-14 p-2' 
                        alt={`${contact.source} Icon`} 
                      /> 
                    </div> 
                    <div className="md:min-w-96 w-60 p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-wrap bg-550"> 
                      <p>{contact.word || '-'}</p> 
                    </div> 
                  </div>
                ))}
                {(markets.phone || markets.email) ?
                  <div className='flex flex-col md:flex-row w-full md:gap-2'>
                    {markets.email  && (
                      <div className="flex p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-550 break-all">
                        <MailFilled className="mr-2 p-1 rounded-full" /> 
                        <p>{markets.email || '-'}</p>
                      </div>
                    )}
                    {markets.phone  && (
                      <div className="flex p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-550 break-all">
                        <PhoneFilled className="mr-2 p-1 rounded-full" /> 
                        <p>{markets.phone || '-'}</p>
                      </div>
                    )}
                  </div>
                  :''
                }
                {markets.location && markets.location.status === true ? (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <div className='max-w-28 button-500 hover:cursor-pointer p-1 px-3 rounded-full ease-in-out select-none duration-300' onClick={locationClick}>
                      <EnvironmentFilled className='text-red-600'/> {locationStatus}
                    </div>
                    <div className='mt-2 w-full'>
                    {LoadMap}
                    </div>
                  </div>
                ): ''}
            </div>

          </div>

        </div>

      </div>
    </div>
    </div>
    </>
  );
}

export default Market;