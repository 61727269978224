import React , { useEffect, useState  } from 'react';
import { Nav,NavNoUser,Sidebar,BottomNav} from '../components/Nav';
import { useUserAuth } from '../context/UserContext.jsx';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CardLineChart from "../components/Charts/CardLineChart.jsx";
import CardPageVisits from "../components/Charts/CardPageVisits.jsx";
import { LoginAlert , AboutToExpire , ExpireAlerts } from '../components/Alert.jsx';
import CookieBar from '../components/Cookie.jsx';

import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import { ShoppingFilled , EditFilled , QuestionCircleOutlined , DeleteFilled, EditOutlined , QuestionOutlined } from '@ant-design/icons'
import { Popconfirm , Modal , Collapse , Spin , Skeleton, Tooltip } from 'antd';
import "./styles/HomePage.css"
import { TagIcon , UsersIcon , DocumentTextIcon } from '@heroicons/react/20/solid'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import StoreIcon from '@mui/icons-material/Store';
import CalculateIcon from '@mui/icons-material/Calculate';

function HomePage() {
  const { user } = useUserAuth();

  const [selectedMenuItem, setSelectedMenuItem] = useState('1'); // เพิ่ม state เพื่อเก็บ key ของรายการที่ถูกเลือก
  
  const handleMenuItemClick = (key) => {
    setSelectedMenuItem(key); // เมื่อคลิกที่รายการเมนู ให้เปลี่ยน selectedMenuItem เป็น key ของรายการที่ถูกคลิก
  };

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}
        <div className='flex justify-between'>
          <div id='sidebar' className=''>
            <Sidebar onMenuItemClick={handleMenuItemClick}/>
            <BottomNav />
          </div>
          <div className='grow overflow-auto pb-4'>

            <div className='flex justify-center'>
              
              <div className='' style={{width:'1190px'}}>
                <LoginAlert />
                <AboutToExpire />
                <ExpireAlerts />
              </div>
              
            </div>
            
            <HomeComponent />
            
          </div>

          <div></div>

        </div>


    </>
  );
}

export default HomePage

function HomeComponent(){
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const userId = user ? user._id : null;
  const [markets, setMarkets] = useState([]);
  const [countmarkets, setCountMarkets] = useState([]);
  const [countproducts, setCountProducts] = useState([]);
  const [countcustomers, setCountCustomers] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(false); // สถานะการโหลด

  const fetchMarkets = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, {
          params: { userId: user._id }
        });

        if(response.data.length === 0){
          setData(false)
        }

        if (Array.isArray(response.data)) {
          const numberOfdata = response.data.length; // นับจำนวนร้านค้า
          setCountMarkets(numberOfdata);
          setMarkets(response.data);
        } else {
          console.error('Fetched markets are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching markets:', err);
      }
  };
  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }

  const fetchProducts = async () => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/product`, {
                params: { userId: user._id }
            });

            if(response.data.length === 0){
              setData(false)
            }

            if (Array.isArray(response.data)) {
              const numberOfdata = response.data.length;
              setCountProducts(numberOfdata);
            } else {
              console.error('Fetched products are not an array:', response.data);
            }
        } catch (err) {
            console.error('Error fetching products:', err);
        }
  };

  const fetchCustomers = async () => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/customer`, {
                params: { userId: user._id }
            });

            if(response.data.length === 0){
              setData(false)
            }

            if (Array.isArray(response.data)) {
              const numberOfdata = response.data.length;
              setCountCustomers(numberOfdata);
            } else {
              console.error('Fetched customers are not an array:', response.data);
            }
        } catch (err) {
            console.error('Error fetching customers:', err);
        }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/category`, {
        params: { userId: user._id }
      });
      if (Array.isArray(response.data)) {
        setCategories(response.data);
      } else {
        console.error('Fetched categories are not an array:', response.data);
      }
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  useEffect(() => {
    if (user && user._id) {
      const fetchData = async () => {
        await fetchMarkets();
        await fetchProducts();
        await fetchCustomers();
        await fetchCategories();
        setLoading(false); // ปิดสถานะโหลดเมื่อข้อมูลพร้อม
      };
      fetchData();
    }
  }, [user]);

  const showModalCategory = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/category/${id}`,
        {data: { imageUrls }},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      message.success("ลบประเภทสินค้าสำเร็จ")
      navigate(0)
    } catch (err) {
      console.error('Error deleting category:', err);
    }
  };


  const itemsetting = [
    {
      key: '3',
      label: (
        <>
        <SettingsRoundedIcon  className='mr-1.5 opacity-90' />
        <label className='h-full cursor-pointer'>
            ตั้งค่า
        </label>
        </>
      ),
      children: 
      <>
        <Link className='w-full' onClick={showModalCategory}>
          <div className='flex'>
            <label 
              className='py-1.5 px-3 border rounded-lg cursor-pointer w-full
              bg-white hover:bg-gray-100 active:bg-gray-200 hover:text-black duration-300 active:duration-0	'
            >
              <div className='flex'>
                <div>
                  <TagIcon  className=' opacity-90 w-6' />
                </div>
                <div className='h-full content-end translate-y-0.5'>
                  <label className='cursor-pointer ml-2 '>ประเภทสินค้า</label>
                </div>
              </div>
            </label>
          </div>
        </Link>
        <Link to="/manage-carrier" className='w-full'>
          <div className='flex mt-3'>
            <label 
              className='py-1.5 px-3 border rounded-lg cursor-pointer w-full
              bg-white hover:bg-gray-100 active:bg-gray-200 hover:text-black duration-300 active:duration-0	'>
              <LocalShippingRoundedIcon  className=' opacity-90' />
              <label className='cursor-pointer ml-2'>ตัวเลือกการจัดส่งสินค้า</label>
            </label>
          </div>
        </Link>
      </>
      ,
    },
    
  ];

  // หากข้อมูลยังโหลดไม่เสร็จ ให้แสดง Skeleton
  if (loading) {
    return (
      <div className="p-4">
        {/* Desktop skeleton */}
        <div className="hidden md:block mt-4">
          <div className="flex justify-center ml-2" style={{ minWidth: "900px" }}>
            <Skeleton active paragraph={{ rows: 5 }} />
          </div>
        </div>


        {/* Mobile skeleton */}
        <div className="md:hidden mt-4 mx-2">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      </div>
    );
  }

  return(
    <div>
      <div className='hidden md:block mt-4'>
        <div className='flex justify-center ml-2' style={{minWidth:'900px'}}>
          
          <div className='mr-4' style={{width:'900px'}}>
          
            <div className='bg-white p-4 rounded-md shadow mb-4'>
              <div className='flex'>
                <div className='mb-2 text-xl font-bold	text-gray-800 w-full'>ดูหน้าร้านค้า</div>
                <Link to="/manage-market" className='text-nowrap'><div className='text-blue-700 hover:text-blue-800 '>จัดการร้านค้า <EditOutlined /></div></Link>
              </div>

              <div className='grid grid-cols-3 gap-2'>
                {markets.length === 0? (
                  <Tooltip title={!user ? 'กรุณาเข้าสู่ระบบก่อน' : ''}>
                    <div className="w-full">
                      <Link 
                        to={user ? "/add-market" : "#"} 
                        className={`w-full ${!user ? "pointer-events-none opacity-50" : ""}`}
                        onClick={(e) => {
                          if (!user) e.preventDefault(); // ป้องกันการนำทางถ้าไม่มีผู้ใช้
                        }}
                      >
                        <div className="text-center w-full p-2 px-2 gap-3 bg-white rounded-md border hover:cursor-pointer hover:shadow hover:bg-gray-50 active:bg-gray-100 select-none">
                          <label className="cursor-pointer">+ เพิ่มร้านค้าแรก</label>
                        </div>
                      </Link>
                    </div>
                  </Tooltip>
                  )
                    : ''}
                {markets.map(market => (
                <a href={`/${market.id_text}`} className='cursor-pointer' key={market._id} target="_blank" rel="noopener noreferrer">
                <div key={market._id} className='flex w-full p-2 px-2 gap-3 bg-white rounded-md border hover:cursor-pointer hover:shadow hover:bg-gray-50 active:bg-gray-100'>
                  <div className='min-w-16 max-w-16'>
                    <a className='cursor-pointer'>
                      {market.logoUrl[0] ? (
                      <img
                        className="rounded-lg object-cover"
                        src={getImageMarketUrl(market.logoUrl[0])}
                        alt="Logo"
                        width="60"
                      />
                      ) : (
                      <img
                        className="rounded-lg object-cover"
                        src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`}
                        alt="Image not found"
                        width="60"
                      />
                      )}
                    </a>
                  </div>
                  <div className='grow pt-2'>
                    {market.name}
                  </div>
                </div>
                </a>
                ))}

              </div>
            </div>

            <div className="flex flex-row gap-2 min-w-0 hidden">
              <div className="w-[350px] min-w-0 grow mb-4">
                <CardLineChart />
              </div>
              <div className="w-[296px] min-w-0 mb-4">
                <CardPageVisits />
              </div>
            </div>

            <div className='flex mb-4 '>
              <div className='grid grid-cols-4 gap-2 grow'>
                <Link to="/manage-product" className='w-full'>
                <div className='flex flex-col place-content-center items-center text-center bg-white rounded-md w-full py-4 shadow hover:bg-gray-50 hover:ring ring-slate-400	 hover:cursor-pointer select-none'>
                  <ShoppingFilled className='flex justify-center text-4xl	text-sky-600 bg-sky-100	rounded-full p-4 mb-3'/>
                  จัดการสินค้า
                  <div className='text-xs text-gray-600'>{countproducts} ผลิตภัณฑ์</div>
                </div></Link>

                <Link to="/manage-market" className='w-full'>
                <div className='flex flex-col place-content-center items-center text-center bg-white rounded-md w-full py-4 shadow hover:bg-gray-50 hover:ring ring-slate-400	 hover:cursor-pointer select-none'>
                  <div className='bg-sky-100 rounded-full p-4 mb-3 aspect-square ' style={{width:'68px'}}><StoreIcon  className='w-10 text-sky-600 mt-2' style={{transform:"scale(1.8)"}}/></div>
                  จัดการร้านค้า
                  <div className='text-xs text-gray-600'>{countmarkets} ร้านค้า</div>
                </div></Link>

                <Link to="/manage-customer" className='w-full'>
                <div className='flex flex-col place-content-center items-center text-center bg-white rounded-md w-full py-4 shadow hover:bg-gray-50 hover:ring ring-slate-400	 hover:cursor-pointer select-none'>
                  <div className=' ' style={{width:'68px'}}><UsersIcon className='flex justify-center	text-sky-600 bg-sky-100	rounded-full p-4 mb-3'/></div>
                  จัดการรายชื่อลูกค้า
                  <div className='text-xs text-gray-600'>{countcustomers} รายชื่อ</div>
                </div></Link>

              </div>
            </div>
            
            <div className='bg-white p-4 rounded-md shadow mb-4 '>
              <div className='mb-2 ml-2 text-xl font-bold	text-gray-800'>เครื่องมือ</div>
              <div className='grid grid-cols-4 gap-2 grow'>              <Link to="/checkfee/tiktok" className='w-full'>
                <div className='flex flex-col items-center text-center bg-white rounded-md w-full py-4 px-6 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                  <div className='bg-teal-100 rounded-full p-4 mb-3 aspect-square ' style={{width:'68px'}}><CalculateIcon  className='w-10 text-teal-600 mt-2' style={{transform:"scale(1.8)"}}/></div>
                  คำนวณกำไร                 <div className='text-xs text-gray-600'>คำนวณหาค่าธรรมเนียมการขาย เพื่อคาดการรายได้และกำไรจากการขายสินค้าในแพลตฟอร์มต่างๆ</div>
                </div></Link>

                <Link to="/manage-receipt" className='w-full'>
                <div className='flex flex-col items-center text-center bg-white rounded-md w-full h-full py-4 px-6 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                  <div className=' ' style={{width:'68px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-4 mb-3'/></div>
                  ใบเสร็จรับเงิน
                  <div className='text-xs text-gray-600'>รายการเอกสารใบเสร็จรับเงิน/ใบกำกับภาษี</div>
                </div></Link>

                <Link to="/manage-bill" className='w-full'>
                <div className='flex flex-col items-center text-center bg-white rounded-md w-full h-full py-4 px-6 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                  <div className=' ' style={{width:'68px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-4 mb-3'/></div>
                  ใบวางบิล
                  <div className='text-xs text-gray-600'>รายการเอกสารใบวางบิล</div>
                </div></Link>

                <Link to="/manage-invoice" className='w-full'>
                <div className='flex flex-col items-center text-center bg-white rounded-md w-full h-full py-4 px-6 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                  <div className=' ' style={{width:'68px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-4 mb-3'/></div>
                  ใบแจ้งหนี้
                  <div className='text-xs text-gray-600'>รายการเอกสารใบแจ้งหนี้</div>
                </div></Link>

                <Link to="/manage-quotation" className='w-full'>
                <div className='flex flex-col items-center text-center bg-white rounded-md w-full h-full py-4 px-6 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                  <div className=' ' style={{width:'68px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-4 mb-3'/></div>
                  ใบเสนอราคา
                  <div className='text-xs text-gray-600'>รายการเอกสารใบเสนอราคา</div>
                </div></Link>

              </div>
            </div>
          </div>
          
          <div className='w-64 mr-2'>
            <div className='bg-white border-0 rounded-lg p-4 shadow text-nowrap mb-4 hidden'>
              ทดสอบๆๆ
            </div>

            <Collapse 
              items={itemsetting} 
              defaultActiveKey={['3']} 
              expandIconPosition={'end'}
              className='bg-white border-0 shadow text-nowrap'  
            />

            <Modal 
              open={isModalOpen} 
              onCancel={handleCancel}
              okButtonProps={{
                hidden: true,
              }}
              cancelText="ยกเลิก"
            >
              <label className='text-lg'>ประเภทสินค้า</label>
              <div className='mt-2'>
                {categories.map(category => (
                <div key={category._id} className='flex justify-between mt-1 p-2 border truncate hover:shadow-md rounded-md'>
                  <div>{category.name}</div> 
                  <div className='flex'>
                    <div className='mr-1'>
                      <a href={`/edit-category/${category._id}`} className='cursor-pointer' key={category._id} target="_blank" rel="noopener noreferrer">
                        <EditFilled  className='hover:bg-orange-400 rounded p-0.5 opacity-50 scale-110' />
                      </a>
                    </div>
                    <Popconfirm
                            title="ยืนยันการลบประเภทสินค้า"
                            description="คุณต้องการลบประเภทสินค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => handleDelete(category._id)}
                            okText="ยืนยัน"
                            cancelText="ยกเลิก"
                          >
                      <div className='mr-1'><DeleteFilled className='hover:bg-red-600 rounded p-0.5 opacity-50 scale-110 cursor-pointer'/></div>
                    </Popconfirm>
                  </div>
                </div>
                ))}
              </div>
                <a href="/add-category" className='cursor-pointer ' target="_blank" rel="noopener noreferrer">
                  <div className='p-2 border truncate hover:shadow-md rounded-md mt-1 text-center'>
                    + เพิ่มประเภทสินค้า
                  </div>
                </a>
            </Modal>
          </div>
        </div>
      </div>


      {/* mini */}
      <div className='md:hidden mt-4 mx-2'>
        <div className='bg-white p-3 rounded-md mb-2'>
              <div className='mb-2 text-md font-bold	text-gray-800'>ดูหน้าร้านค้า</div>
              <div className='grid grid-cols-2 gap-2 text-sm'>
                {markets.length === 0? (
                    <Link to="/add-market" className='w-full'>
                    <div className='text-center w-full p-2 px-2 gap-3 bg-white rounded-md border hover:cursor-pointer hover:shadow hover:bg-gray-50 active:bg-gray-100 '>
                      <label className='cursor-pointer'>+ เพิ่มร้านค้าแรก</label>
                    </div></Link>)
                    : ''}
                {markets.map(market => (
                <a href={`/${market.id_text}`} className='cursor-pointer' key={market._id} target="_blank" rel="noopener noreferrer">
                <div key={market._id} className='flex w-full p-2 px-2 gap-3 bg-white rounded-md border hover:cursor-pointer hover:shadow hover:bg-gray-50 active:bg-gray-100'>
                  <div className='min-w-16 max-w-16'>
                    <a className='cursor-pointer'>
                      {market.logoUrl && market.logoUrl[0] ? (
                      <img
                        className="rounded-lg object-cover"
                        src={getImageMarketUrl(market.logoUrl[0])}
                        alt="Logo"
                        width="60"
                      />
                      ) : (
                      <img
                        className="rounded-lg object-cover"
                        src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`}
                        alt="Image not found"
                        width="60"
                      />
                      )}
                    </a>
                  </div>
                  <div className='grow pt-2'>
                    {market.name}
                  </div>
                </div>
                </a>
                ))}

          </div>
        </div>

        <div className='flex mb-3'>
          <div className='grid grid-cols-3 gap-2 grow'>
                <Link to="/manage-product" className='w-full'>
                <div className='flex flex-col text-xs place-content-center items-center text-center bg-white rounded-md w-full py-2 hover:bg-gray-50 hover:ring ring-slate-400	 hover:cursor-pointer select-none'>
                  <ShoppingFilled className='flex justify-center text-2xl	text-sky-600 bg-sky-100	rounded-full p-2 mb-2'/>
                  สินค้า
                  <div className='text-[10px] text-gray-600'>{countproducts} ผลิตภัณฑ์</div>
                </div></Link>

                <Link to="/manage-market" className='w-full'>
                <div className='flex flex-col text-xs place-content-center items-center text-center bg-white rounded-md w-full py-2 hover:bg-gray-50 hover:ring ring-slate-400	 hover:cursor-pointer select-none'>
                  <div className='bg-sky-100 rounded-full p-2 mb-2 aspect-square ' style={{width:'40px'}}><StoreIcon  className='w-10 text-sky-600 mt-0 ' style={{transform:"scale(1.2)"}}/></div>
                  ร้านค้า
                  <div className='text-[10px] text-gray-600'>{countmarkets} ร้านค้า</div>
                </div></Link>

                <Link to="/manage-customer" className='w-full'>
                <div className='flex flex-col text-xs place-content-center items-center text-center bg-white rounded-md w-full py-2 hover:bg-gray-50 hover:ring ring-slate-400	 hover:cursor-pointer select-none'>
                  <div className=' ' style={{width:'40px'}}><UsersIcon className='flex justify-center	text-sky-600 bg-sky-100	rounded-full p-2 mb-2'/></div>
                  รายชื่อลูกค้า
                  <div className='text-[10px] text-gray-600'>{countcustomers} รายชื่อ</div>
                </div></Link>

          </div>
        </div>

        <div className='bg-white p-1 rounded-md '>
          <div className='mb-2 ml-2 pt-2 text-md font-bold	text-gray-800'>เครื่องมือ</div>
          <div className='grid grid-cols-3 gap-2 grow'>
              <Link to="/checkfee/tiktok" className='w-full'>
              <div className='flex flex-col text-xs items-center text-center bg-white rounded-md w-full py-2 px-1 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                <div className='bg-teal-100 rounded-full p-2 mb-2 aspect-square ' style={{width:'40px'}}><CalculateIcon  className='w-10 text-teal-600 mt-0' style={{transform:"scale(1.1)"}}/></div>
                คำนวณกำไร
              </div></Link>

              <Link to="/manage-receipt" className='w-full'>
              <div className='flex flex-col text-xs items-center text-center bg-white rounded-md w-full h-full py-2 px-1 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                <div className=' ' style={{width:'40px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-2 mb-2'/></div>
                ใบเสร็จรับเงิน
              </div></Link>

              <Link to="/manage-bill" className='w-full'>
              <div className='flex flex-col text-xs items-center text-center bg-white rounded-md w-full h-full py-2 px-1 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                <div className=' ' style={{width:'40px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-2 mb-2'/></div>
                ใบวางบิล
              </div></Link>

              <Link to="/manage-invoice" className='w-full'>
              <div className='flex flex-col text-xs items-center text-center bg-white rounded-md w-full h-full py-2 px-1 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                <div className=' ' style={{width:'40px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-2 mb-2'/></div>
                ใบแจ้งหนี้
              </div></Link>

              <Link to="/manage-quotation" className='w-full'>
              <div className='flex flex-col text-xs items-center text-center bg-white rounded-md w-full h-full py-2 px-1 hover:bg-gray-100	 hover:cursor-pointer select-none'>
                <div className=' ' style={{width:'40px'}}><DocumentTextIcon className='flex justify-center	text-teal-600 bg-teal-100	rounded-full p-2 mb-2'/></div>
                ใบเสนอราคา
              </div></Link>
            </div>
            
        </div>

        <Collapse 
          items={itemsetting} 
          defaultActiveKey={['3']} 
          expandIconPosition={'end'}
          className='bg-white mt-2 border-0'  
        />
        <Link to="/Help" className='w-full'>
        <div className='bg-white p-3 mt-2 rounded-md mb-16 text-sm active:bg-gray-200 duration-300'>
          <QuestionOutlined className='mr-2 scale-125' />ศูนย์ช่วยเหลือ
        </div></Link>
      </div>

    </div>
  )
}
