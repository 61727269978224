import React from 'react';

import { useUserAuth } from '../context/UserContext';
import { Nav,NavNoUser } from '../components/Nav';
import { FloatButton } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

function TermOfUse() {
  const{user} =  useUserAuth();

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}
    <div className="flex justify-center items-center ">
      <div className='p-4 md:m-4 m-2 bg-white border rounded-lg max-w-7xl'>
      <h2 className='font-bold text-2xl mx-6 mt-4'> เงื่อนไขการใช้งาน <br />( Term of Use) </h2>
          <hr/>
          <div className="md:px-14 px-2 pt-4 pb-10"> 
            <div className=" whitespace-pre-line">
            
<div className='indent-8'>เราคือระบบการวางแสดงสินค้าผ่านทางออนไลน์ สำหรับพ่อค้าแม่ค้าที่ต้องการหน้าเว็บไซต์สำหรับแสดงสินค้าโดยไม่ผ่านตัวกลาง และรองรับการสร้างหลายหน้าร้านค้า สามารถปรับรูปลักษณ์ของร้านได้ตามต้องการ
  และมีฟังก์ชันเสริมหลายตัว เช่น ฟังก์ชันสำหรับผู้ที่ขายสินค้าบน Lazada, Shopee และ TiktokShop วิเคราะห์ได้ทั้ง รายได้/กำไร/ค่าส่งสินค้า และ ค่าธรรมเนียมการลงขาย  
  มีฟังก์ชันสำหรับการบันทึกข้อมูลลูกค้า การออกใบเสนอราคา และใบเสร็จรับเงิน/ใบกำกับภาษี ระบบเหล่านี้จะช่วยงานในการขายและลดข้อผิดพลาดในการกำหนดราคาสินค้าให้ผู้ใช้บริการได้อย่างดี <a href='/about' className='hover:text-blue-800'>ดูเพิ่มเติมเกี่ยวกับเรา</a>
</div>
<br />
<div className='indent-8'>การตกลงสมัครเข้าใช้บริการถือว่าผู้ใช้บริการยอมรับว่าได้อ่านและทำความเข้าใจเงื่อนไขการใช้บริการทุกประการ รวมถึง<a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a>โดยละเอียดถี่ถ้วนแล้ว</div>
<br />
เงื่อนไขการใช้เว็บไซต์ ฉบับนี้มีวัตถุประสงค์เพื่อแจ้งให้ทราบถึงสิทธิและหน้าที่ตามกฎหมายอันพึ่งต้องปฏิบัติตาม<br />
คำจำกัดความ<br />
- “ผู้ให้บริการ” หมายถึง “เว็บไซต์ Aseller” หรือชื่ออื่นใดอันหมายถึงบริการนี้ และ ให้หมายรวมถึงการใช้งานส่วนใดส่วนหนึ่งหรือทั้งหมด ทั้ง โดยตรงผ่านเว็บไซต์และหรือผ่านตัวแทนในรูปแบบต่างๆ<br />
- “ผู้ใช้บริการ” หมายถึง บุคคลใดก็ตามที่เข้าถึง เข้า ใช้งานไม่ว่าจะเป็น ผลิตภัณฑ์ ซอฟต์แวร์ บริการ หรือเว็บไซต์ ของผู้ให้บริการ<br />
- “เงื่อนไขการใช้บริการ” หมายถึง ข้อตกลง ข้อกำหนด ข้อสัญญา หรือชื่ออื่นใดที่มีลักษณะแบบเดียวกัน เพื่อแจ้งให้ทราบถึงสิทธิและหน้าที่ตามกฎหมาย<br />
- “การให้บริการ” หมายถึง การให้บริการวิเคราะห์ยอดขาย/ค่าขนส่ง/รับได้ต่างๆนาๆ<br />
- “ข้อมูล” หมายถึง ข้อมูลใดๆที่ผู้ใช้บริการอัพโหลดลงสู่เว็บไซต์นี้<br />
- “ข้อมูลลับ” หมายถึง ข้อมูลยอดขายหรือข้อมูลลูกค้า ที่ผู้ใช้บริการอัพโหลดลงสู่เว็บไซต์นี้ แต่ไม่รวมถึงข้อมูลใดๆที่ได้ถูกเผยแพร่สู่สาธารณะชนเป็นที่เรียบร้อยแล้ว<br />
- “ค่าบริการ” หมายถึง ค่าบริการแพ็คเกจรายเดือน<br />
การใช้บริการของผู้ให้บริการนั้น ผู้ใช้บริการต้องเข้าใจและยอมรับข้อตกลง เงื่อนไขการใช้บริการ และ<a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a>ต่างๆของผู้ให้บริการทั้งหมดก่อน<br />
การสมัครเข้าใช้บริการ การยอมรับข้อตกลง เงื่อนไขการใช้บริการและ<a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a>ต่างๆ ถือว่าผู้ใช้บริการยอมรับได้ว่าอ่านและเข้าใจพร้อมทั้งตกลงยินยอมตามข้อตกลง เงื่อนไขการใช้บริการ <a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a>และหรือข้อบังคับอื่นใดแล้วแต่กรณี<br />
ผู้ให้บริการไม่อนุญาติให้นำเข้าและหรือส่งออกซึ่งข้อมูลใดๆ และ หรือเอกสารใดๆเข้ามาในเวบไซด์โดยประการที่อาจทำให้เกิดความเสียหายแก่อุปกรณ์ ฮาร์ดแวร์ หรือ ซอฟท์แวร์ อันเป็นความผิดตามกฎหมาย<br />
ผู้ใช้บริการต้องไม่พยายามหรือกระทำการใดๆที่เป็นอันตรายต่อความปลอดภัยของระบบคอมพิวเตอร์หรือระบบเน็ตเวิร์ค ซึ่งหมายรวมถึงการกระทำผิดใดๆตามที่กฎหมายกำหนด<br />
ผู้ให้บริการไม่อนุญาติให้ผู้ใช้บริการโอนสิทธิและหน้าที่ภายใต้ข้อตกลง เงื่อนไขการใช้บริการ และ<a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a>แก่บุคคลและหรือนิติบุคคลอื่น โดยเด็ดขาด<br />
ข้อมูลส่วนบุคคลของผู้ใช้บริการ จะไม่เปิดเผยความลับหรือข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกหรือองค์กรอื่นใดไม่ว่าเพื่อวัตถุประสงค์ใดๆ เว้นแต่<br />
- ได้รับความยินยอมจากผู้ใช้บริการ<br />
- กระทำการตามที่กฎหมายบังคับ และหรือตามคำสั่งหรือหมายของศาล เป็นต้น<br />
- เป็นไปตาม <a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a><br />
ผู้ให้บริการ มีซึ่งสิทธิ ลิขสิทธิ์ และ ทรัพย์สินทางปัญญาในข้อมูลที่แสดง รูปภาพ และ รูปแบบการแสดงผล ตามที่ปรากฏในเว็บไซต์ทั้งหมด ยกเว้นจะมีการระบุอย่างชัดเจนเป็นอื่น ห้ามมิให้ผู้ใดทำการคัดลอก ทำซ้ำ มีสำเนา สำรองไว้ ทำเลียนแบบ ทำเหมือน ดัดแปลง ทำเพิ่ม เพื่อนำไปเผยแพร่ด้วยวัตถุประสงค์อื่นใด โดยปราศจากความยินยอมเป็นหนังสือจากผู้ให้บริการ ทั้งนี้เว้นแต่จะได้มีการระบุกำหนดไว้ในข้อตกลง เงื่อนไขการใช้บริการ และ<a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a><br />
ผู้ให้บริการ ขอสงวนสิทธิ์ในการคืนค่าบริการในกรณีที่ผู้ใช้ต้องการยกเลิกใช้บริการ หรือปรับลดบริการลง และผู้ใช้บริการไม่มีสิทธิ์เรียกค่าบริการคืน ไม่ว่าบางส่วน หรือทั้งหมดได้ เว้นแต่ทางผู้ให้บริการจะเป็นผู้ยกเลิกบริการเอง โดยจะคืนค่าบริการตามสมควรให้เท่านั้น หากมีการละเมิดข้อตกลงดังกล่าวผู้ให้บริการจะแจ้งเตือน และจะหยุดให้บริการหากผู้ใช้บริการไม่ทำการแก้ไขให้ถูกต้อง และผู้ใช้บริการจะไม่สามารถเรียกร้องสิทธิ์ในการคืนเงินได้ และหากเป็นการกระทำที่ส่งผลกระทบต่อ ระบบ เครือข่าย และชื่อเสียงของผู้ให้บริการ ผู้ใช้บริการจะต้องรับผิดชอบความเสียหายที่เกิดขึ้นทั้งหมด<br />
ผู้ให้บริการ สามารถระงับการใช้บริการแก่ผู้ใช้บริการใดๆ ตามเห็นสมควรได้ ถึงแม้ว่าผู้ใช้บริการจะไม่ได้ละเมิดข้อตกลง เงื่อนไขการใช้การบริการ และ<a href='/PrivacyPolicy' className='hover:text-blue-800'>นโยบายความเป็นส่วนตัว</a>ใดๆก็ตาม โดยผู้ให้บริการจะคืนค่าบริการตามจริงเฉพาะที่ยังไม่ได้ใช้งานเท่านั้น ทั้งนี้งให้เป็นไปตามเงื่อนไขของค่าบริการที่ผู้ให้บริการกำหนดไว้ และผู้ใช้บริการไม่สามารถเรียกร้องค่าเสียหายอื่น ๆ นอกเหนือจากค่าบริการที่ผู้ใช้บริการได้ชำระกับผู้ให้บริการแล้วเท่านั้น<br />
ผู้ให้บริการ สามารถระงับการใช้บริการแก่ผู้ใช้บริการในกรณีที่ผู้ใช้บริการไม่เข้าสู่ระบบเป็นเวลานาน ซึ่งผู้ให้บริการขอสงวนสิทธิ์การระงับบริการโดยมิต้องแจ้งให้ทราบล่วงหน้า<br />
ผู้ให้บริการ อาจระงับการให้บริการเป็นการชั่วคราว ด้วยเหตุจำเป็นบางประการ และในกรณีที่มีการระงับบริการประเภทที่มีค่าบริการ ขอสงวนสิทธิในการไม่คืนค่าบริการสำหรับระยะเวลาที่ระงับบริการดังกล่าว และถือว่า ผู้ให้บริการไม่มีความรับผิดในการระงับบริการดังกล่าวไม่ว่าประการใด ซึ่งรวมถึงข้อบกพร่องที่ไม่สามารถคาดการณ์ได้ล่วงหน้า อันอาจจะเกิดข้อบกพร่องของอุปกรณ์ ซอฟต์แวร์ หรือสิ่งอื่นใดที่มีผลกระทบต่อการใช้บริการและให้บริการ<br />
ผู้ให้บริการ ข้อแจ้งให้ทราบและเข้าใจ ดังนี้<br />
- ไม่รับผิดชอบกรณีข้อมูลไม่ตรง อาทิ ยอดขาย รายได้ และอื่นๆ<br />
และผู้ให้บริการไม่มีความเกี่ยวข้องใดๆกับแพลตฟอร์ม Lazada , Shopee และ TiktokShop ทุกประการ<br />
ผู้ใช้บริการต้องเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่างๆของผู้ให้บริการที่ได้มีการเปลี่ยนแปลงแล้ว หากผู้ใช้บริการเข้าใช้บริการหลังมีการเปลี่ยนแปลง
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermOfUse

