import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined } from '@ant-design/icons';
import shopeeIcon from '../../assets/shopee_icon1.png';
import lazadaIcon from '../../assets/lazada_icon2.png';
import {EditOutlined,EyeOutlined ,TikTokOutlined  ,WechatOutlined } from '@ant-design/icons';
import { Spin, FloatButton ,Image } from 'antd';

import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";



function DetailProduct() {
    const { id } = useParams();
    const { user } = useUserAuth();
    const navigate = useNavigate();
    const [markets, setMarkets] = useState([]);
    const [product, setProduct] = useState(null);



  useEffect(() => {
    if(!user){
      navigate('../manage-product');
    }

    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/product/from_id_product/${id}`);
        const productData = response.data;
        setProduct(productData);
      } catch (err) {
        console.error('Error fetching product:', err);
      }
    };


    fetchProduct();
  }, [id, navigate, user]);

  const handleEdit = (id) => {navigate(`/edit-product/${id}`);};
  const handleCalcuTiktok = (id) => {navigate(`/calcutiktok/${id}`);};
  const handleCalcuShopee = (id) => {navigate(`/calcushopee/${id}`);};
  const handleCalcuLazada = (id) => {navigate(`/calculazada/${id}`);};
  const handleCalcuB2C = (id) => {navigate(`/calcub2c/${id}`);};

  if (!product) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }

  
  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}

      <div className='flex justify-between'>
        <div id='sidebar' className=''>
          <Sidebar />
        </div>

        <div className='shrink hidden md:block overflow-x-hidden '>
            <div className='ml-2 overflow-hidden overflow-x-auto custom-scrollbar'>
                <div className='mt-3 ml-4 max-w-72 overflow-hidden break-all whitespace-normal '>
                    <Link to="../manage-product" className='text-sm text-gray-600'>
                    <LeftOutlined /> จัดการผลิตภัณฑ์
                    </Link>
                    
                    <div className='mb-4 mt-2'>
                    {product.market ? (
                        <div>
                          <label>ร้าน {product.market.name}</label>
                        </div>
                      )
                    
                    : 'N/A'}
                    </div>
                </div>

                <div className='flex '>
                  <div className='bg-white p-6 ml-2 mb-4 flex-none max-w-6xl rounded-2xl break-all whitespace-normal drop-shadow-md '>
                    <div className='flex flex-row '>
                        {product.imageUrls && product.imageUrls.length > 0 ? (
                          <Image.PreviewGroup
                          preview={{
                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                          }}
                          >
                          <div className='mb-4 max-w-lg'>
                            <div className='mb-2'>
                              <Image src={product.imageUrls[0]} alt={product.name} className='h-lg max-w-lg' />
                            </div>
                            <div className='flex overflow-hidden overflow-x-auto custom-scrollbar'>
                              {product.imageUrls.slice(1).map((imageUrl, index) => (
                              <Image key={index} src={imageUrl} alt={product.name} className='min-w-24 min-h-24 w-24 h-24  max-w-44' />
                              ))}
                            </div>
                          </div>
                        </Image.PreviewGroup>
                        ):
                        
                          <div className='mb-4 max-w-lg'>
                            <div className='mb-2'>
                              <Image src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.jpg`} alt='Image not found' className='h-lg max-w-lg' />
                            </div>
                          </div>
                        }

                      <div className='flex flex-col pl-10 pr-4'> 
                        <div className='max-w-fit'>
                          <h2 className='text-xl pb-2'>{product.name}</h2>
                        </div>

                        <div className='mb-4 max-w-fit'>
                            <label>SKU : </label>{product.sku}
                        </div>

                        <div className='flex justify-between bg-gray-50 p-4 text-3xl'>
                          <div className='text-amber-500 max-w-fit'>
                              <label>ต้นทุน : </label>฿{product.cost}
                          </div>
                          <div className='text-emerald-500 pl-6	max-w-fit'>
                              <label>ราคาขาย : </label>฿{product.price}
                          </div>
                        </div>

                        <div className='pt-4 text-lg'>รายละเอียด</div>
                        <div className='mt-2 mb-6 mx-4 w-full  bg-gray-50 p-2 rounded-md whitespace-pre-wrap'>
                            {product.description}
                        </div>

                        
                      </div>
                    </div>
                      <div className='flex w-full '>
                        <div className='flex-1 py-4	bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 text-center w-max' onClick={() => handleEdit(product._id)}>
                          <button ><EditOutlined className='opacity-75 scale-150 pr-3'/>แก้ไขข้อมูลสินค้า</button>
                        </div>
                        <a 
                          href={`/product/${product._id}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className='flex-1 py-4	ml-4 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 text-center  w-max'
                        >
                          <button ><EyeOutlined className='opacity-75 scale-150 pr-3'/>แสดงผลลัพธ์</button>
                        </a>
                      </div>
                  </div>

                <div className='ml-6 mb-6 flex-initial mr-2'>
                      <div className='text-center'>
                        คำนวณกำไรจากการขาย
                      </div>
                      <div className='bg-gradient-to-r from-neutral-900 to-zinc-700 hover:drop-shadow-xl mt-2
                        p-3 rounded-lg w-48 overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2  hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuTiktok(product._id)}
                      >
                        <div className='flex flex-row justify-between'>
                          <div>
                            <button>Tiktok</button>
                          </div>
                          <div>
                            <TikTokOutlined />
                          </div>
                        </div>
                          
                      </div>

                      <div className='bg-gradient-to-r from-orange-500 to-amber-500 p-3 rounded-lg hover:drop-shadow-xl  w-full mt-2  
                        overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2  hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuShopee(product._id)}
                      >
                         <div className='flex flex-row justify-between' >
                          <div>
                            <button>Shopee</button>
                          </div>
                          <div>
                            <img src={shopeeIcon} className='w-4 invert mt-0.5' alt="Shopee Icon" />
                          </div>
                        </div>
                      </div>

                      <div className='bg-gradient-to-r from-blue-800 to-sky-600 p-3 rounded-lg hover:drop-shadow-xl w-full mt-2  
                        overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2  hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuLazada(product._id)}
                      >
                        <div className='flex flex-row justify-between' >
                          <div>
                            <button>Lazada</button>
                          </div>
                          <div>
                            <img src={lazadaIcon} className='w-4 invert mt-1' alt="Lazada Icon" />
                          </div>
                        </div>
                      </div>

                      <div className='bg-gradient-to-r from-blue-500 to-pink-900 p-3 rounded-lg hover:drop-shadow-xl w-full mt-2  
                        overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2 hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuB2C(product._id)}
                      >
                        <div className='flex flex-row justify-between'>
                          <div>
                            <button>สั่งซื้อโดยตรง</button>
                          </div>
                          <div>
                            <WechatOutlined />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>

        {/* mini */}
        <div className='shrink md:hidden mb-24 '>
            <div className=' '>
                <div className='flex flex-wrap'>
                  <div className='bg-white  flex-grow max-w-screen break-all whitespace-normal'>
                    <div className='ml-1 mt-3 max-w-64 break-all whitespace-normal '>
                      <Link to="../manage-product" className='ml-1 text-sm text-gray-600'>
                        <LeftOutlined /> จัดการผลิตภัณฑ์
                      </Link>
                      
                      <div className='pl-2 pb-2 pt-2'>
                        {product.market ? product.market.name : 'N/A'}
                      </div>
                    </div>
                        <div className='w-fit '>
                          {product.imageUrls && product.imageUrls.length > 0 ? (
                            <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                            }}
                            >
                            <div className='mb-4 w-fit'>
                              <div className='w-full'>
                                <Image src={product.imageUrls[0]} alt={product.name} className='w-fit' />
                              </div>
                              
                              <div className='flex flex-nowrap  overflow-x-hidden max-w-fit '>
                                {product.imageUrls.slice(1).map((imageUrl, index) => (
                                  <Image key={index} src={imageUrl} alt={product.name} className='max-w-20' />
                                ))}
                              </div>
                            </div>
                          </Image.PreviewGroup>
                          ):
                        
                          <div className='mb-4 max-w-lg'>
                            <div className='mb-2'>
                              <Image src='http://localhost:5001/public/uploads/server/image-notfound.jpg' alt='Image not found' className='h-lg max-w-lg' />
                            </div>
                          </div>
                        }
                        </div>

                        <div className='px-2 w-full'>
                          <h2 className='text-xl pb-2'>{product.name}</h2>
                        </div>

                        <div className='pb-4 pl-4 w-full'>
                            <label>SKU : </label>{product.sku}
                        </div>

                        <div className='flex justify-between bg-gray-50 p-3 text-xl'>
                          <div className='text-amber-500 w-full'>
                              <label>ต้นทุน : </label>฿{product.cost}
                          </div>
                          <div className='text-emerald-500 pl-3	w-full'>
                              <label>ราคาขาย : </label>฿{product.price}
                          </div>
                        </div>

                        <div className='pl-4 mt-6 text-lg'><label>รายละเอียด</label></div>
                        <div className='mt-2 mb-6 mx-4  bg-gray-50 p-2 rounded-md whitespace-pre-wrap'>
                            {product.description}
                        </div>

                        
                      <div className='flex w-full '>
                        <div className='border border-gray-200 flex-1 py-4 bg-gray-100 cursor-pointer hover:bg-gray-200 text-center w-max' onClick={() => handleEdit(product._id)}>
                          <button ><EditOutlined className='opacity-75 scale-110 pr-3'/>แก้ไขข้อมูลสินค้า</button>
                        </div>
                        <a 
                          href={`/product/${product._id}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className='border border-gray-200 flex-1 py-4 bg-gray-100 cursor-pointer hover:bg-gray-200 text-center w-max'
                        >
                          <button ><EyeOutlined className='opacity-75 scale-110 pr-3'/>แสดงผลลัพธ์</button>
                        </a>
                      </div>

                      <div className='mx-10 my-6 flex-initial '>
                      <div className='text-center text-lg'>
                        คำนวณกำไรจากการขาย
                      </div>
                      <div className='bg-gradient-to-r from-neutral-900 to-zinc-700 hover:drop-shadow-xl mt-2 p-3 rounded-lg w-full 
                         overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2  hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuTiktok(product._id)}
                      >
                        <div className='flex flex-row justify-between'>
                          <div>
                            <button>Tiktok</button>
                          </div>
                          <div>
                            <TikTokOutlined />
                          </div>
                        </div>
                          
                      </div>

                      <div className='bg-gradient-to-r from-orange-500 to-amber-500 p-3 rounded-lg hover:drop-shadow-xl   mt-2  
                        overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2  hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuShopee(product._id)}
                      >
                         <div className='flex flex-row justify-between' >
                          <div>
                            <button>Shopee</button>
                          </div>
                          <div>
                            <img src={shopeeIcon} className='w-4 invert mt-0.5' alt="Shopee Icon" />
                          </div>
                        </div>
                      </div>

                      <div className='bg-gradient-to-r from-blue-800 to-sky-600 p-3 rounded-lg hover:drop-shadow-xl w-full mt-2  
                        overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2  hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuLazada(product._id)}
                      >
                        <div className='flex flex-row justify-between' >
                          <div>
                            <button>Lazada</button>
                          </div>
                          <div>
                            <img src={lazadaIcon} className='w-4 invert mt-1' alt="Lazada Icon" />
                          </div>
                        </div>
                      </div>

                      <div className='bg-gradient-to-r from-blue-500 to-pink-950 p-3 rounded-lg hover:drop-shadow-xl w-full mt-2  
                        overflow-hidden break-all whitespace-normal text-white cursor-pointer hover:-skew-y-2 hover:-translate-x-2 hover:scale-110 ease-out duration-100' 
                        onClick={() => handleCalcuB2C(product._id)}
                      >
                        <div className='flex flex-row justify-between'>
                          <div>
                            <button>สั่งซื้อโดยตรง</button>
                          </div>
                          <div>
                            <WechatOutlined />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                  </div>

                
            </div>
        </div>

        <div></div>
      </div>
    </>
  );
}

export default DetailProduct;
