import moment from 'moment';
import 'moment/locale/th';
moment.locale('th'); // ตั้งค่า locale เป็นไทย

const thaiMonths = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
];

// ฟังก์ชันแปลงวันที่
export function calculateRemainingUsage(userData) {
    const expirationDate = moment(userData.membershipExpirationDate);
    const now = moment();

    // คำนวณอายุการใช้งานคงเหลือ
    let remainingDuration = moment.duration(expirationDate.diff(now));
    let remainingDays = Math.max(Math.floor(remainingDuration.asDays()), 0);
    let remainingHours = Math.max(remainingDuration.hours(), 0);
    let remainingMinutes = Math.max(remainingDuration.minutes(), 0);

    // แปลงวันที่เป็น พ.ศ. และใช้ชื่อเดือนภาษาไทย
    const day = expirationDate.format('DD');
    const month = thaiMonths[expirationDate.month()];
    const year = parseInt(expirationDate.format('YYYY')) + 543;
    const formattedExpirationDate = `${day} ${month} ${year}`;

    return {
        remainingDays,
        remainingHours,
        remainingMinutes,
        formattedExpirationDate
    };
}

// ฟังก์ชันแปลงวันที่หมดอายุเป็นภาษาไทย
export function calculateExpirationDate(oldMember, newMember, currentExpirationDate, planDays) {
    if (planDays === '') return calculateExpirationDateNoselected(currentExpirationDate); // ถ้ายังไม่เลือก ให้แสดงเป็น "-"

    const now = new Date();

    let expirationDate;

    const pricePerDay = {
        "silver": {
            "30d": 3.3,
            "365d": 2.74
        },
        "gold": {
            "30d": 6.63,
            "365d": 5.48
        },
        "platinum": {
            "30d": 23.3,
            "365d": 19.178
        }
    };

    if (new Date(currentExpirationDate) < now) {
        // กรณีวันหมดอายุน้อยกว่าวันปัจจุบัน → เริ่มนับจากวันนี้
        expirationDate = new Date(now);
        expirationDate.setDate(expirationDate.getDate() + parseInt(planDays));
    } 
    else if (oldMember !== newMember && new Date(currentExpirationDate) > now) {
        // กรณีเปลี่ยนประเภทสมาชิก และวันหมดอายุยังไม่หมด
        const remainingDays = Math.ceil((new Date(currentExpirationDate) - now) / (1000 * 60 * 60 * 24));
        const planType = planDays === "30" || planDays === 30 ? "30d" : "365d";
        const remainingValue = remainingDays * pricePerDay[oldMember]["30d"];
        const additionalDays = Math.floor(remainingValue / pricePerDay[newMember][planType]);
        const totalDays = parseInt(planDays) + additionalDays;
        expirationDate = new Date(now);
        expirationDate.setDate(expirationDate.getDate() + totalDays);
    } 
    else {
        // กรณีต่ออายุสมาชิกแบบเดิม และยังไม่หมดอายุ
        expirationDate = new Date(currentExpirationDate);
        expirationDate.setDate(expirationDate.getDate() + parseInt(planDays));
    }

    // แปลงวันที่เป็นภาษาไทย
    const newDate = moment(expirationDate);
    const monthIndex = newDate.month();
    return `${newDate.format("DD")} ${thaiMonths[monthIndex]} ${newDate.year() + 543}`;
}

// ฟังก์ชันแปลงวันที่หมดอายุเป็นภาษาไทย ถ้ายังไม่ได้เลือก
export function calculateExpirationDateNoselected(currentExpirationDate) {
    const expirationDate = moment(currentExpirationDate);

    const day = expirationDate.format('DD');
    const month = thaiMonths[expirationDate.month()];
    const year = parseInt(expirationDate.format('YYYY')) + 543;
    const formattedExpirationDate = `${day} ${month} ${year}`;

    return formattedExpirationDate;
}

// ฟังก์ชันสำหรับคำนวณวันที่หมดอายุใหม่
export function calculateNewExpirationDate(oldMember, newMember, currentExpirationDate, planDays) {
    const now = new Date();
    let expirationDate;
    
    // ราคาต่อวันของแต่ละประเภทสมาชิก
    const pricePerDay = {
        'silver': {
            '30d': 3.3,    // 99บาท / 30วัน
            '365d': 2.74   // 1000บาท / 365วัน
        },
        'gold': {
            '30d': 6.6,   // 199บาท / 30วัน
            '365d': 5.4   // 2000บาท / 365วัน
        },
        'platinum': {
            '30d': 23,   // 699บาท / 30วัน
            '365d': 19.17 // 7000บาท / 365วัน
        }
    };
    
    // กรณีวันหมดอายุน้อยกว่าวันปัจจุบัน
    if (new Date(currentExpirationDate) < now) {
        // เริ่มต้นจากวันปัจจุบัน + จำนวนวันตามแพ็กเกจ
        expirationDate = new Date(now);
        expirationDate.setDate(expirationDate.getDate() + parseInt(planDays));
    } 
    // กรณีมีการเปลี่ยนประเภทสมาชิกและวันหมดอายุยังไม่หมด
    else if(oldMember !== newMember && new Date(currentExpirationDate) > now) {
        // คำนวณจำนวนวันที่เหลือของสมาชิกเดิม
        const remainingDays = Math.ceil((new Date(currentExpirationDate) - now) / (1000 * 60 * 60 * 24));
        
        // กำหนดประเภทแพ็กเกจตามจำนวนวันที่เลือก
        const planType = planDays === '30' || planDays === 30 ? '30d' : '365d';
        
        // คำนวณมูลค่าที่เหลืออยู่ (วันที่เหลือ * ราคาต่อวันของประเภทเดิม)
        // ใช้ราคาแบบรายเดือนสำหรับการคำนวณมูลค่าที่เหลือ (ตามธรรมเนียมทั่วไป)
        const remainingValue = remainingDays * pricePerDay[oldMember]['30d'];
        
        // คำนวณจำนวนวันที่จะได้เพิ่มจากประเภทสมาชิกใหม่ โดยใช้ราคาตามแพ็กเกจที่ซื้อ
        const additionalDays = Math.floor(remainingValue / pricePerDay[newMember][planType]);
                
        // คำนวณจำนวนวันทั้งหมดที่จะได้ (วันตามแพ็กเกจที่ซื้อ + วันที่แปลงมาจากมูลค่าที่เหลือ)
        const totalDays = parseInt(planDays) + additionalDays;
        
        // สร้างวันหมดอายุใหม่เริ่มจากวันปัจจุบัน
        expirationDate = new Date(now);
        expirationDate.setDate(expirationDate.getDate() + totalDays);

    }
    // กรณีไม่มีการเปลี่ยนประเภทสมาชิกและวันหมดอายุยังไม่หมด
    else {
        // ใช้วันหมดอายุเดิม + จำนวนวันตามแพ็กเกจ
        expirationDate = new Date(currentExpirationDate);
        expirationDate.setDate(expirationDate.getDate() + parseInt(planDays));

    }
    
    return expirationDate.toISOString();
}

// ฟังก์ชันสำหรับการต่ออายุสมาชิก (แนะนำให้ใช้ในฟังก์ชัน handleSubmit)
export function handleMembershipExtension(selectedPlan, userData, newMembershipType) {
    // แปลงแพ็กเกจที่เลือกเป็นจำนวนวัน
    const planDays = selectedPlan === "30d" ? 30 : 365;
    
    // คำนวณวันหมดอายุใหม่
    const newDate = calculateNewExpirationDate(
        userData.membershipType,  // ประเภทสมาชิกเดิม
        newMembershipType,        // ประเภทสมาชิกใหม่
        userData.expirationDate,  // วันหมดอายุปัจจุบัน
        planDays                  // จำนวนวันที่ต่ออายุ
    );
    
    return {
        userId: userData._id,
        membershipType: newMembershipType,
        newDate: newDate
    };
}