import React, { useState, useEffect } from 'react'
import Cookies from "js-cookie";
import axios from 'axios';
import './Nav.css';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserContext.jsx';
import shopchumlogo from '../assets/logowhite.png';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import {LogoutOutlined , ClusterOutlined , EditFilled,DeleteFilled, LoginOutlined ,QuestionCircleOutlined,SettingOutlined ,HomeOutlined, ShoppingOutlined , CalculatorOutlined ,UserOutlined , QuestionOutlined , TikTokOutlined,WechatOutlined,FileTextOutlined,CaretLeftOutlined ,CaretRightOutlined } from '@ant-design/icons'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Menu,Popconfirm , Tooltip , Modal , Button} from 'antd'
import { UserCircleIcon, UsersIcon } from '@heroicons/react/24/outline'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { TagIcon , DocumentTextIcon} from '@heroicons/react/20/solid'
import shopeeIcon from '../assets/shopee_icon1.ico';
import lazadaIcon from '../assets/lazada_icon2.ico';
import lineshopIcon from '../assets/lineshopLogo3.png';
import nocnocIcon from '../assets/nocnocLogo3.png';

//เป็นสมาชิค
export function Nav() {
    const { user } = useUserAuth();

    //ระบบlogout
    const {logout} = useUserAuth();
    const navigate = useNavigate();

    const handleLogout = async () =>{
      logout();
      navigate(0)
    }

    const[isMenuOpen, setIsMenuOpen]=useState(false);

    //ปุ่มberger menu
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen)
    }

  return (
    <>
      {/*menu เมื่อlogin แล้ว*/}

      <nav className='bg-gradient-to-r from-blue-950 to-gray-900 nav'>
        <div className='flex items-center justify-between'>
          <div className='text-white text-2xl font-bold md:ml-8 ml-0'>
            <Link to='../homepage' className='flex flex-wrap'>
              <img src={shopchumlogo} className='h-[42px]' /> 
              <div className='flex flex-row'>
                <label className='md:ml-4 ml-3 mt-1.5 md:text-[23px] text-[16px] cursor-pointer'>
                  SHOPCHUM
                </label>
                <label className='md:mx-[10px] mx-[3px] mt-1.5 md:text-[20px] text-[14px] cursor-pointer'>
                  |
                </label>
                <label className='mt-[7px] md:text-[20px] text-[14px] cursor-pointer'>
                  Seller Center
                </label>
              </div>
            </Link>
          </div>


          <div className='md:hidden'>
            <button id="menu-toggle" className='text-white hover:text-blue-200 active:text-blue-400' onClick={toggleMenu}>
                <svg 
                  fill='none' 
                  stroke='currentColor' 
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox="0 0 24 20"
                  className='w-6 h-6'
                >
                  <path d='M4 6h16M4 12h16M4 18h16'></path>
                </svg>
            </button>
          </div>

          {/* Normal menu */}
          <div className='hidden md:flex'>
          {user.admin ? 
            <Tooltip title="แผงควบคุม">
              <Link to='../../admin/manageuserPage' className='text-white hover:text-blue-200 active:text-blue-400'><div className='w-12  text-center'><ClusterOutlined className='scale-[1.8]' /></div></Link>
            </Tooltip> : ''
          }
          <Tooltip title="หน้าหลัก">
            <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400'><div className='w-12 text-center'><HomeOutlined className='scale-[1.63]' /></div></Link>
          </Tooltip>
          <Tooltip title="บัญชีของฉัน">
            <Link to='../account' className='text-white hover:text-blue-200 active:text-blue-400'><div className='w-12  text-center justify-center'><AccountCircleOutlinedIcon className='scale-[1.3] mb-[3px]' /></div></Link>
          </Tooltip>
          <Tooltip title="ออกจากระบบ">
            <Popconfirm
                placement="bottomRight"
                title={'ออกจากระบบ'}
                description={'ยืนยันการออกจากระบบ'}
                onConfirm={handleLogout}
                okText="ออกจากระบบ"
                cancelText="ยกเลิก"
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: 'red',
                    }}
                  />
                }
              >
              <Link className='text-white hover:text-blue-200 active:text-blue-400 pr-2'><div className='w-12  text-center'><LogoutOutlined className='scale-[1.62]' /></div></Link>
            </Popconfirm>
          </Tooltip>
          </div>

          </div>

          <div className='flex justify-end'>
          {/* Mobile menu */}
          {isMenuOpen ? (
          <ul className='flex-col justify-end md:hidden'>
            {user.admin ? 
              <Link to='../../admin/manageuserPage' className='text-white hover:text-blue-200 active:text-blue-400'><li className='py-2 pt-4'><ClusterOutlined /><label className='cursor-pointer ml-1'>แผงควบคุม</label></li></Link>
            : ''}
            <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400'><li className='py-2'><HomeOutlined /><label className='cursor-pointer ml-1'>หน้าหลัก</label></li></Link>
            <Link to='../account' className='text-white hover:text-blue-200 active:text-blue-400 flex flex-row'><li className='py-2'><AccountCircleOutlinedIcon className='scale-[0.8] -ml-1' /><label className='cursor-pointer'>บัญชีของฉัน</label></li></Link>
            <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400' onClick={handleLogout}><li className='py-2'><LogoutOutlined /><label className='cursor-pointer ml-1'>ออกจากระบบ</label></li></Link>
          </ul>
          ) : null
          }
      
        </div>
      </nav>
  </>
  )
}

//แสดงเฉพาะหน้าLogin
export function NavLoginPage() {

  const[isMenuOpen, setIsMenuOpen]=useState(false);

  //ปุ่มberger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

return (

  <nav className='bg-gradient-to-r from-blue-950 to-gray-900 nav'>
    <div className='flex items-center justify-between'>
      <div className='text-white text-2xl font-bold md:ml-8 ml-0'>
        <Link to='../homepage' className='flex flex-wrap'>
          <img src={shopchumlogo} className='h-[42px]' /> 
          <div className='flex flex-row'>
            <label className='md:ml-4 ml-3 mt-1.5 md:text-[23px] text-[16px] cursor-pointer'>
              SHOPCHUM
            </label>
            <label className='md:mx-[10px] mx-[3px] mt-1.5 md:text-[20px] text-[14px] cursor-pointer'>
              |
            </label>
            <label className='mt-[7px] md:text-[20px] text-[14px] cursor-pointer'>
              Seller Center
            </label>
          </div>
        </Link>
      </div>

      <div className='md:hidden'>
        <button id="menu-toggle" className='text-white hover:text-blue-200 active:text-blue-400' onClick={toggleMenu}>
            <svg 
              fill='none' 
              stroke='currentColor' 
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              viewBox="0 0 24 20"
              className='w-6 h-6'
            >
              <path d='M4 6h16M4 12h16M4 18h16'></path>
            </svg>
        </button>
      </div>

      {/* Normal menu */}
      <div className='hidden md:flex mr-4'>
        <Tooltip title="หน้าหลัก">
          <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400'><div className='w-10 p-2 px-4 pr-8 text-center'><HomeOutlined className='scale-[1.63]' /></div></Link>
        </Tooltip>

        <Tooltip title="เกี่ยวกับเรา">
          <Link to='../about' className='text-white hover:text-blue-200 active:text-blue-400'><div className='w-full p-2 px-4 text-center justify-center text-nowrap'>เกี่ยวกับเรา</div></Link>
        </Tooltip>
      </div>

    </div>

    <div className='flex justify-end'>
      {/* Mobile menu */}
      {isMenuOpen ? (
      <ul className='flex-col justify-end md:hidden'>
          <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400'><li className='py-2 pt-4'><HomeOutlined /><label className='cursor-pointer ml-1'>หน้าหลัก</label></li></Link>
          <Link to='../about' className='text-white hover:text-blue-200 active:text-blue-400'><li className='py-2'>เกี่ยวกับเรา</li></Link>
      </ul>
      ) : null
      }
    </div>
  </nav>
)
}

//ไม่ได้เป็นผู้ใช้
export function NavNoUser() {

  const[isMenuOpen, setIsMenuOpen]=useState(false);

  //ปุ่มberger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

return (

  <nav className='bg-gradient-to-r from-blue-950 to-gray-900 nav'>
    <div className='flex items-center justify-between'>
      <div className='text-white text-2xl font-bold md:ml-8 ml-0'>
        <Link to='../homepage' className='flex flex-wrap'>
          <img src={shopchumlogo} className='h-[42px]' /> 
          <div className='flex flex-row'>
            <label className='md:ml-4 ml-3 mt-1.5 md:text-[23px] text-[16px] cursor-pointer'>
              SHOPCHUM
            </label>
            <label className='md:mx-[10px] mx-[3px] mt-1.5 md:text-[20px] text-[14px] cursor-pointer'>
              |
            </label>
            <label className='mt-[7px] md:text-[20px] text-[14px] cursor-pointer'>
              Seller Center
            </label>
          </div>
        </Link>
      </div>

      <div className='md:hidden'>
        <button id="menu-toggle" className='text-white hover:text-blue-200 active:text-blue-400' onClick={toggleMenu}>
            <svg 
              fill='none' 
              stroke='currentColor' 
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              viewBox="0 0 24 20"
              className='w-6 h-6'
            >
              <path d='M4 6h16M4 12h16M4 18h16'></path>
            </svg>
        </button>
      </div>

      {/* Normal menu */}
      <div className='hidden md:flex'>
        <Tooltip title="หน้าหลัก">
          <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400 block w-full'><HomeOutlined className='scale-[1.63] p-2 px-4'/></Link>
        </Tooltip>
        <Tooltip title="เกี่ยวกับเรา">
          <Link to='../about' className='text-white hover:text-blue-200 active:text-blue-400 block w-full text-nowrap p-2 px-4 pr-8'>เกี่ยวกับเรา</Link>
        </Tooltip>
        <Tooltip title="เข้าสู่ระบบ">
          <Link to='../loginpage' className='p-2 px-4 rounded-lg text-nowrap
            bg-gradient-to-r from-blue-800 to-blue-600 hover:from-blue-900 hover:to-blue-700 active:from-blue-950 active:to-blue-800 transition text-white block w-full'>
            <LoginOutlined className='mr-1 scale-125' /> ล็อกอิน/สมัครสมาชิก
          </Link>
        </Tooltip>
      </div>

    </div>

    <div className='flex justify-end'>
      {/* Mobile menu */}
      <ul className={`md:hidden ${isMenuOpen ? '' : 'hidden'}`}>
          <Link to='../homepage' className='text-white hover:text-blue-200 active:text-blue-400 block w-full'><li className='py-2 pt-4'><HomeOutlined className='scale-125 mr-1'/><label className='cursor-pointer ml-1'>หน้าหลัก</label></li></Link>
          <Link to='../about' className='text-white hover:text-blue-200 active:text-blue-400 block w-full'><li className='py-2'>เกี่ยวกับเรา</li></Link>
          <li><Link to='../loginpage' className='p-2 px-4 rounded-lg 
            bg-gradient-to-r from-blue-800 to-blue-600 hover:from-blue-900 hover:to-blue-700 active:from-blue-950 active:to-blue-800  text-white block w-full'>
              <LoginOutlined className='mr-1 scale-125'/> ล็อกอิน/สมัครสมาชิก
              </Link>
          </li>
      </ul>
    </div>
  </nav>
)
}

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StorefrontSharpIcon from '@mui/icons-material/StorefrontSharp';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

//side bar จะไม่แสดงเมื่ออยู่หน้าหลัก หน้าlogin และ หน้าregister
export function Sidebar({onMenuItemClick = () => {} }) {

  const { user } = useUserAuth();
  const token = localStorage.getItem('token');

  const [selectedKey, setSelectedKey] = useState(''); // สร้าง state เพื่อเก็บ key ของรายการที่ถูกเลือก
  const [value, setValue] = useState(''); // State for BottomNavigation value
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState(true);

  const [openKeys, setOpenKeys] = useState([]);

  const [collapsed, setCollapsed] = useState(Cookies.get("collapsed") === "true"); // อ่านค่า collapsed จากคุกกี้

  const toggleCollapsed = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    Cookies.set("collapsed", newCollapsedState); // บันทึกค่าลงคุกกี้
  };

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  

  //ตั้งค่าley ที่เลือกเป็นลิ้งค์ที่อยู่
  useEffect(() => {
    setSelectedKey(location.pathname); // อัปเดต selectedKey เมื่อ URL เปลี่ยน
    setValue(location.pathname);
  }, [location.pathname]);
  
  //update ข้อมูลตามที่ถูกเลือก
  const handleClick = (e) => {
    setSelectedKey(e.key); // เมื่อคลิกที่รายการเมนู ให้เปลี่ยน selectedKey เป็น key ของรายการที่ถูกคลิก
    onMenuItemClick(e.key); // เรียกใช้งานฟังก์ชันที่ถูกส่งผ่าน props เพื่ออัปเดตค่า selectedMenuItem ใน component อื่นๆ
    navigate(e.key); // ใช้ navigate เพื่อเปลี่ยนเส้นทาง
  };

  const showModalCategory = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/category`, {
        params: { userId: user._id }
      });

      if(response.data.length === 0){
        setData(false)
      }

      if (Array.isArray(response.data)) {
        setCategories(response.data);
      } else {
        console.error('Fetched categories are not an array:', response.data);
      }
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/category/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },data: { imageUrls }
        }
      );

      message.success("ลบประเภทสินค้าสำเร็จ")
      navigate(0)
    } catch (err) {
      console.error('Error deleting category:', err);
    }
  };

  useEffect(() => {
    if (user && categories.length === 0 && data) {
      fetchCategories();
    }
  }, [user]);

  const items = [
    { key: '/homepage', icon: <HomeOutlined className="scale-110" />, label: <Link to="/homepage">หน้าหลัก</Link> },
    {
      key: '2',
      label: 'สินค้า',
      icon: <ShoppingBagOutlinedIcon className="scale-125" />,
      children: [
        { key: '/manage-product', label: <Link to="/manage-product">จัดการสินค้า</Link> },
        { key: '/add-product', label: <Link to="/add-product">เพิ่มสินค้าใหม่</Link> },
        { key: '/promotion', label: <Link to="/promotion">โปรโมชั่น</Link> },
      ],
    },
    { key: '/manage-market', label: <Link to="/manage-market">ร้านค้า</Link>, icon: <StorefrontIcon className='scale-125' /> },
    {
      key: '4',
      label: 'คำนวณกำไรสินค้า',
      icon: <CalculateOutlinedIcon className="scale-125" />,
      children: [
        { key: '/checkfee/tiktok', label: <Link to="/checkfee/tiktok">Tiktok Shop</Link>, icon: <TikTokOutlined /> },
        { key: '/checkfee/shopee', label: <Link to="/checkfee/shopee">Shopee</Link>, icon: <img src={shopeeIcon} className="w-4" alt="Shopee Icon" /> },
        { key: '/checkfee/lazada', label: <Link to="/checkfee/lazada">Lazada</Link>, icon: <img src={lazadaIcon} className="w-4" alt="Lazada Icon" /> },
        { key: '/checkfee/lineshop', label: <Link to="/checkfee/lineshop">Line Shopping</Link>, icon: <img src={lineshopIcon} className="w-4" alt="Lazada Icon" /> },
        { key: '/checkfee/nocnoc', label: <Link to="/checkfee/nocnoc">Nocnoc</Link>, icon: <img src={nocnocIcon} className="w-4" alt="Lazada Icon" /> },
        { key: '/checkfee/b2c', label: <Link to="/checkfee/b2c">สั่งซื้อโดยตรง</Link>, icon: <WechatOutlined /> },
      ],
    },
    { key: '/manage-customer', icon: <UsersIcon className="h-4 text-blue-500 scale-110" />, label: <Link to="/manage-customer">ลูกค้า</Link> },
    ,
    {
      key: '5',
      label: 'เอกสาร',
      icon: <FileTextOutlined className="h-4 text-blue-500 scale-110" />,
      children: [
        { key: '/manage-receipt', label: <Link to="/manage-receipt">ใบเสร็จรับเงิน</Link> },
        { key: '/manage-bill', label: <Link to="/manage-bill">ใบวางบิล</Link> },
        { key: '/manage-invoice', label: <Link to="/manage-invoice">ใบแจ้งหนี้</Link> },
        { key: '/manage-quotation', label: <Link to="/manage-invoice">ใบเสนอราคา</Link> },
      ],
    },
    {
      key: '6',
      label: 'การตั้งค่า',
      icon: <SettingOutlined className="h-4 text-blue-500 scale-110" />,
      children: [
        { key: '', label: 'ประเภทสินค้า', icon: <TagIcon className='w-4 -ml-0.5'/> , onClick: showModalCategory,},
        { key: '/manage-carrier', label: 'ตัวเลือกการจัดส่ง', icon: <LocalShippingRoundedIcon className='scale-125 -mr-'/> },
      ],
    },
    { type: 'divider' },
    { key: '/help', icon: <QuestionOutlined className="scale-110" />, label: <Link to="/help">ศูนย์ช่วยเหลือ</Link> },
  ];

  return (
    <>
    <div id='sidebar' className={`mr-1.5 hidden md:block ${collapsed ? 'w-[73px]' : 'w-[216px]'}`}>
      <div className={`bg-white sidebar h-dvh ${collapsed ? 'w-[79px]' : 'w-[222px]'}`}>
        <div className={`mr-4 sidebar ${collapsed ? 'w-[79px]' : 'w-[222px]'}`}>

          <Menu
            mode="inline"
            items={items}
            inlineCollapsed={collapsed}
            selectedKeys={[selectedKey]}
            openKeys={openKeys} // กำหนด state ที่ควบคุมการเปิดเมนู
            onOpenChange={handleOpenChange} // อัปเดตให้เปิดแค่เมนูเดียว
            onClick={handleClick}
            className='select-none pt-[16px]'
          />


            <Modal 
              open={isModalOpen} 
              onCancel={handleCancel}
              okButtonProps={{
                hidden: true,
              }}
              cancelText="ยกเลิก"
            >
              <label className='text-lg'>ประเภทสินค้า</label>
                <div className='mt-2'>
                {categories.map(category => (
                <div key={category._id} className='flex justify-between mt-1 p-2 border truncate hover:shadow-md rounded-md'>
                  <div>{category.name}</div> 
                  <div className='flex'>
                    <div className='mr-1'>
                      <a href={`/edit-category/${category._id}`} className='cursor-pointer' key={category._id} target="_blank" rel="noopener noreferrer">
                        <EditFilled  className='hover:bg-orange-400 rounded p-0.5 opacity-50 scale-110' />
                      </a>
                    </div>
                    <Popconfirm
                            title="ยืนยันการลบประเภทสินค้า"
                            description="คุณต้องการลบประเภทสินค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => handleDelete(category._id)}
                            okText="ยืนยัน"
                            cancelText="ยกเลิก"
                          >
                      <div className='mr-1'><DeleteFilled className='hover:bg-red-600 rounded p-0.5 opacity-50 scale-110 cursor-pointer'/></div>
                    </Popconfirm>
                  </div>
                </div>
                ))}
                </div>
                <a href="/add-category" className='cursor-pointer ' target="_blank" rel="noopener noreferrer">
                  <div className='p-2 border truncate hover:shadow-md rounded-md mt-1 text-center'>
                    + เพิ่มประเภทสินค้า
                  </div>
                </a>
            </Modal>

            <div className={`fixed bottom-0 left-0  ${collapsed ? 'w-[79px]' : 'w-[222px]'}`}>
              <button
                type="primary"
                onClick={toggleCollapsed}
                className="w-full h-10 bg-white border-t border-gray-300 text-black hover:bg-gray-100 transition"
              >
                {collapsed ? (
                  <ArrowRightRoundedIcon className="scale-[2] mr-1" />
                ) : (
                  <div className="flex items-center justify-center">
                    <ArrowLeftRoundedIcon className="scale-[2] mr-1" />ซ่อน
                  </div>
                )}
              </button>
            </div>

        </div>
      </div>
    </div>
  </>
  );
}

//Bottom Nav
export function BottomNav() {
  const [selectedKey, setSelectedKey] = useState(''); // สร้าง state เพื่อเก็บ key ของรายการที่ถูกเลือก
  const [value, setValue] = useState(''); // State for BottomNavigation value
  const navigate = useNavigate();
  const location = useLocation();

  //ตั้งค่าley ที่เลือกเป็นลิ้งค์ที่อยู่
  useEffect(() => {
    setSelectedKey(location.pathname); // อัปเดต selectedKey เมื่อ URL เปลี่ยน
    setValue(location.pathname);
  }, [location.pathname]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedKey(newValue);
    navigate(newValue);
  };
  


  return (
    <>
      <div id='buttomnav' className='z-50 md:hidden'>
        <Box sx={{ pb: 0 }}>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: '70px', zIndex: 2000 }} elevation={3}>
            <BottomNavigation showLabels value={value} onChange={handleChange}>
              <BottomNavigationAction label="หน้าหลัก" value="/homepage" icon={<HomeRoundedIcon className='scale-125 mb-0.5'/>} />
              <BottomNavigationAction label="สินค้า" value="/manage-product" icon={<LocalMallRoundedIcon className='scale-110 mb-0.5'/>} />
              <BottomNavigationAction label="ร้านค้า" value="/manage-market" icon={<StorefrontRoundedIcon className='scale-110 mb-0.5'/>} />
              <BottomNavigationAction label="ลูกค้า" value="/manage-customer" icon={<PeopleRoundedIcon className='scale-110 mb-0.5'/>} />
              <BottomNavigationAction label="เอกสาร" value="/manage-receipt" icon={<DocumentTextIcon className='w-7 '/>} />
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
  </>
  );
}