import { Menu } from 'antd';
import React, { useState, useEffect  , useRef  } from 'react'
import './navadmin.css';
import { useNavigate } from 'react-router-dom';
import { SettingOutlined , CalculatorOutlined ,UserOutlined , QuestionOutlined  } from '@ant-design/icons';


export function Sidebar({ onMenuItemClick = () => {} }) {
  const [divWidth, setDivWidth] = useState(222);
  const [selectedKey, setSelectedKey] = useState('');
  const navigate = useNavigate();
  const sidebarRef = useRef(null);

  useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 767px)');
      const handleResize = (e) => {
          setDivWidth(e.matches ? 82 : 222);
      };

      handleResize(mediaQuery);

      mediaQuery.addEventListener('change', handleResize);

      return () => {
          mediaQuery.removeEventListener('change', handleResize);
      };
  }, []);

  useEffect(() => {
      setSelectedKey(location.pathname);
  }, [location.pathname]);

  const handleClick = (e) => {
      setSelectedKey(e.key);
      onMenuItemClick(e.key);
      navigate(e.key);
  };
  
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth < 766) {
            sidebarRef.current.classList.remove('w-52');
            sidebarRef.current.classList.add('w-16');
        } else {
            sidebarRef.current.classList.remove('w-16');
            sidebarRef.current.classList.add('w-52');
        }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
}, []);


  const items = [
      { key: '/admin/manageuserpage', icon: <UserOutlined className="scale-110" />, label: 'จัดการผู้ใช้' },
      {
        key: '/admin/settingpage',
        label: 'ตั้งค่าระบบ',
        icon: <SettingOutlined className="scale-110" />,
        children: [
            { key: '/admin/settingcalcupage', label: 'คิดค่าธรรมเนียม', icon: <CalculatorOutlined className="scale-110" /> },
            { key: '', label: '', icon: '' },
        ],
      },
  ];

  return (
      <div id="sidebar" ref={sidebarRef} className="flex-none w-52">
          <div className="bg-white sidebar" style={{ height: '100dvh', width: divWidth }}>
              <div className="mr-4 sidebar" style={{ width: divWidth }}>
                  <Menu
                      mode="inline"
                      inlineCollapsed={divWidth === 82}
                      items={items}
                      selectedKeys={[selectedKey]}
                      onClick={handleClick}
                  />
              </div>
          </div>
      </div>
  );
}
