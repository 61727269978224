import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BottomNav, Nav, NavNoUser ,Sidebar } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Link, useNavigate } from 'react-router-dom';
import {LoginAlert} from '../../components/Alert.jsx';

import { Pagination, message, Popconfirm, Input, Select , Dropdown,Image, Skeleton } from 'antd';
import { QuestionCircleOutlined , CaretDownOutlined , DownOutlined ,CaretUpOutlined , EyeOutlined  } from '@ant-design/icons';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {  TagIcon,UsersIcon , DocumentTextIcon } from '@heroicons/react/20/solid'
import { Spin , FloatButton } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

const { Option } = Select;
const { Search } = Input;

function ManageCustomer() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const userId = user ? user._id : null;
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [market, setMarket] = useState('');
  const [markets, setMarkets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('default');
  const navigate = useNavigate();
  const [data, setData] = useState(true);

  const [loading, setLoading] = useState(true); // สถานะการโหลด

  const fetchCustomers = async () => {
    if (user && user._id) {
        setLoading(true); // เริ่มโหลด
        try {
            const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/customer`, {
                params: { userId: user._id }
            });

            if(response.data.length === 0){
              setData(false)
            }

            if (Array.isArray(response.data)) {
                const sortedCustomers = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                setCustomers(sortedCustomers);
                setFilteredCustomers(sortedCustomers);
            } else {
                console.error('Fetched customers are not an array:', response.data);
            }
        } catch (err) {
            console.error('Error fetching customers:', err);
        } finally {
          setLoading(false); // โหลดเสร็จ
        }
    }
  };
  function getImageCustomerUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_CUSTOMER_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/customer`;
    return `${basePath}/${filename}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchCustomers();
      await fetchMarkets();
      setLoading(false); // ปิดสถานะโหลดเมื่อข้อมูลพร้อม
    };
    fetchData();
  }, [user]);

    const fetchMarkets = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`);
        if (Array.isArray(response.data)) {
          setMarkets(response.data);
        } else {
          console.error('Fetched markets are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching markets:', err);
      }
    };

  const handleBulkDelete = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/customer/bulk-delete`,
        {customerIds: selectedCustomers},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      setCustomers(customers.filter(customer => !selectedCustomers.includes(customer._id)));
      setSelectedCustomers([]);
      navigate(0);
      message.success("ลบลูกค้าสำเร็จ");
    } catch (err) {
      console.error('Error deleting customers:', err);
    }
};

  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/customer/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },
            data: { imageUrls }
        }
      );

      setCustomers(customers.filter(customer => customer._id !== id));
      navigate(0)
      message.success("ลบลูกค้าสำเร็จ")
    } catch (err) {
      console.error('Error deleting customer:', err);
    }
  };

  const handleDetail = (id) => {
    navigate(`/detail-customer/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/edit-customer/${id}`);
  };

  const handleSelectCustomer = (id) => {
    setSelectedCustomers(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(customerId => customerId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSelectAll = () => {
    if (selectedCustomers.length === customers.length) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(customers.map(customer => customer._id));
    }
  };

  const handleItemsPerPageChange = (current, size) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    filterCustomers(value, market);
  };
  const handleMarketChange = (value) => {
    setMarket(value);
    filterCustomers(searchQuery, value);
  };

  const handleSortByTime = () => {
    let newSortOrder;
    if (sortOrder === 'default') newSortOrder = 'asct';
    else if (sortOrder === 'asct') newSortOrder = 'desct';
    else newSortOrder = 'default';
    setSortOrder(newSortOrder);

    const sortedCustomers = [...filteredCustomers];
    if (newSortOrder === 'asct') {
      sortedCustomers.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    } else if (newSortOrder === 'desct') {
      sortedCustomers.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    } else {
      sortedCustomers.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    setFilteredCustomers(sortedCustomers);
  };

  const filterCustomers = (search, market) => {
    let filtered = customers;

    if (search) {
      filtered = filtered.filter(customer =>
        customer.name.toLowerCase().includes(search.toLowerCase()) ||
        customer.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (market) {
      filtered = filtered.filter(customer => customer.market._id === market);
    }

    setFilteredCustomers(filtered);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedCustomersPage = filteredCustomers.slice(startIndex, startIndex + itemsPerPage);
  
/*
  if (user && customers.length === 0 && data) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }*/

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}

      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>

        <div className='overflow-x overflow-y-hidden custom-scrollbar hidden md:block'>
          <LoginAlert />
        
          <div className='shrink hidden md:block'>

          <div className='flex justify-between '>
            <div>
              <h2 className='text-2xl my-6 pl-4'>ลูกค้าของฉัน</h2>
            </div>

            <div className='flex mt-4 mb-3 mr-2'>
              <div>
                {user ? 
                  <div className='flex'>
                    <Link to="/add-customer">
                      <button className='
                        py-2 px-4 rounded
                        bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition text-white text-sm'
                        type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่ม
                      </button>
                    </Link>
                  </div>
                  : 
                  <button 
                    className='
                      my-6 py-2 px-4 rounded
                      bg-blue-300 text-white text-sm cursor-not-allowed'
                    type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่ม
                  </button>
                }
              </div>
            </div>
          </div>

          <div className='flex justify-between mb-6 bg-white py-4 rounded-lg mx-1 border'>
            <div className='flex pl-4'>
              <Search
                placeholder="ค้นหาชื่อลูกค้า, เบอร์โทร, ที่อยู่"
                allowClear
                style={{ width: 300, marginRight: 10 }}
                onSearch={handleSearch}
                value={searchQuery}
                onChange={e => handleSearch(e.target.value)}
              />

              <Select
                placeholder="เลือกร้านค้า"
                value={market}
                onChange={handleMarketChange}
                style={{ width: 200 }}
              >
                <Option value="">ทั้งหมด</Option>
                {markets.map(market => (
                  <Option key={market._id} value={market._id}>{market.name}</Option>
                ))}
              </Select>

              <div>
                <Popconfirm
                  title="ยืนยันการลบลูกค้า"
                  description="คุณต้องการลบลูกค้าที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={handleBulkDelete}
                  okText="ยืนยัน"
                  cancelText="ยกเลิก"
                  disabled={selectedCustomers.length === 0}
                >
                  {selectedCustomers.length === 0 ? (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                  ) : (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                  )}
                </Popconfirm>
              </div>
            </div>
          </div>

          <div className="hidden md:block grid-cols-1 gap-2">
            <div className=''>
              <div>
                <div className='flex flex-row ml-4 text-sm text-gray-500'>
                  <div className='w-8'>
                    <input
                      type="checkbox"
                      checked={selectedCustomers.length === customers.length}
                      onChange={handleSelectAll}
                      className='cursor-pointer transform scale-125'
                    />
                  </div>
                  <div className='w-60'>ชื่อ</div>
                  <div className='w-36 pl-2'>เบอร์โทร</div>
                  <div className='w-72'>ที่อยู่</div>
                  <div className='w-32'>ร้านค้า</div>
                  <div className='w-28 cursor-pointer flex' onClick={handleSortByTime}>อัปเดตแล้ว
                    {sortOrder === 'asct' ? (
                      <div className='flex flex-col pl-1'>
                        <CaretUpOutlined className='opacity-50 '/> 
                        <CaretDownOutlined className='-translate-y-1.5 '/>
                      </div>  
                    ) : sortOrder === 'desct' ? (
                      <div className='flex flex-col pl-1'>
                        <CaretUpOutlined className=''/> 
                        <CaretDownOutlined className='-translate-y-1.5 opacity-50'/>
                      </div> 
                    ) : (
                      <div className='flex flex-col pl-1 opacity-50'>
                        <CaretUpOutlined className=''/> 
                        <CaretDownOutlined className='-translate-y-1.5'/>
                      </div>
                    )} 
                  </div>
                  <div className=' text'>ดำเนินการ</div>
                </div>
              </div>

              <div className='text-sm'>
                {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[102px]">
                          <Skeleton.Avatar active size="large" shape="default" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 1 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : customers.length === 0 ? (
                    <div className="bg-white p-4 my-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                selectedCustomersPage.map(customer => (
                  <div key={customer._id} className={`flex flex-row px-4 py-4 mb-3 mx-1 rounded-lg hover:drop-shadow ${selectedCustomers.includes(customer._id) ? 'bg-indigo-50' : 'bg-white'}`}>
                    <div className='w-[30px] min-w-1 max-w-8'>
                      <input
                        type="checkbox"
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => handleSelectCustomer(customer._id)}
                        className='cursor-pointer transform scale-125'
                      />
                    </div>
                    
                    <div className='min-w-12 w-16 rounded-full overflow-hidden'>
                        <a>
                        {customer.imageUrls  && customer.imageUrls[0]  ? (
                          <Image 
                            className="rounded-lg" src={getImageCustomerUrl(customer.imageUrls[0])} alt={customer.name} width="60" 
                            onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                          />
                        ) : (
                          <Image className="rounded-lg" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/user.png`} width="60" />
                        )}
                        </a>
                    </div>
                    <div className='h-16 w-40 max-w-40 grid content-between'>
                      <div className='pl-2 px-1 max-h-10 cursor-pointer break-words truncate-2-lines' onClick={() => handleDetail(customer._id)}>{customer.name}</div>
                    </div>
                    <div className='w-36 max-w-36 px-4 pl-6 truncate cursor-pointer' onClick={() => handleDetail(customer._id)}>{customer.phone}</div>
                    <div className='w-72 max-w-72 max-h-16 px-4 cursor-pointer break-words overflow-hidden' onClick={() => handleDetail(customer._id)}>{customer.address}</div>
                    <div className='w-36 max-w-36 px-4 truncate cursor-pointer' onClick={() => handleDetail(customer._id)}>
                          {customer.market ? customer.market.name : 'ไม่ได้ระบุร้าน' }
                    </div>
                    <div className='flex flex-wrap max-w-14 px-1 cursor-pointer' onClick={() => handleDetail(customer._id)}>{new Date(customer.updatedAt).toLocaleString()}</div>
                    
                    <div className='flex-row w-24 max-w-24 pl-4'>
                      <div className='flex justify-end'>
                        <button onClick={() => handleEdit(customer._id)}><p className="text-indigo-600">แก้ไข</p></button>
                      </div>

                      <div className='flex justify-end pt-1'>
                        <Dropdown 
                          placement="topRight"
                          overlay={
                            <div className='bg-white p-1 rounded-lg' style={{ boxShadow: '0 6px 15px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)' }}>
                              <div className='hover:bg-gray-100 pl-2 pr-10 py-1 rounded cursor-pointer' onClick={() => handleDetail(customer._id)}>
                                <button>
                                  <p className="text-indigo-600">ดูรายละเอียด</p>
                                </button>
                              </div>

                              <Popconfirm
                                title="ยืนยันการลบลูกค้า"
                                description="คุณต้องการลบลูกค้ารายนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => handleDelete(customer._id, customer.imageUrls)}
                                okText="ยืนยัน"
                                cancelText="ยกเลิก"
                              >
                                <div className='hover:bg-gray-100 pl-2 pr-10 py-1 rounded cursor-pointer'>
                                  <button>
                                    <p className="text-indigo-600">ลบ</p>
                                  </button>
                                </div>
                              </Popconfirm>
                            </div>
                          }
                        >
                          <p className="text-indigo-600 cursor-pointer">เพิ่มเติม <DownOutlined className='scale-75'/></p>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                    ))
                  )}
              </div>
            </div>
          </div>

          <div>
            <div className="pagination mb-28">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={customers.length}
                onChange={handlePageChange}
                onShowSizeChange={handleItemsPerPageChange}
                showSizeChanger
                pageSizeOptions={['10', '20', '50', '100']}
              />
            </div>
          </div>

          </div>
        </div>

        {/* mini */}
        <div className='grow md:hidden mx-1'>
          <LoginAlert />

          <div className='flex justify-between mx-2'>

            <div>
              <h2 className='text-2xl my-4 '>ลูกค้าของฉัน</h2>
            </div>

            <div>
              {user?
              <div className='flex flex-col items-end	'>
                <Link to="/add-customer" >
                  <button className='
                      p-2 my-4 mr-2 rounded
                      bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white text-sm'
                    type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่ม
                  </button>
                </Link>
              </div>
              : 
              <button 
                className='
                  my-4 py-2 px-4 rounded
                  bg-blue-300 text-white text-sm cursor-not-allowed'
                type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่ม
              </button>
              }
            </div>
          </div>
          
          <div className='mb-4 mx-2'>

            <div className='flex '>
                <Search
                    placeholder="ค้นหาชื่อลูกค้า, เบอร์โทร, ที่อยู่"
                    allowClear
                    style={{  marginRight: 10 }}
                    onSearch={handleSearch}
                    value={searchQuery}
                    onChange={e => handleSearch(e.target.value)}
                />
                <Select
                    placeholder="เลือกร้านค้า"
                    value={market}
                    onChange={handleMarketChange}
                    style={{ width: 170 }}
                >
                    <Option value="">ทั้งหมด</Option>
                    {markets.map(market => (
                    <Option key={market._id} value={market._id}>{market.name}</Option>
                    ))}
                </Select>
            </div>

            <div>
                <Popconfirm
                    title="ยืนยันการลบลูกค้า"
                    description="คุณต้องการลบลูกค้าที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={handleBulkDelete}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                    className='ml-8 mt-2'
                    disabled={selectedCustomers.length === 0}
                >
                    {selectedCustomers.length === 0 ? (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                    ) : (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                    )}
                </Popconfirm>
            </div>

          </div>

          <div className="grid grid-cols-1 gap-2">
            <div>
              <div className='flex justify-between'>
                {customers.length === 0 ? "" :
                <div className='flex flex-row mb-3 ml-3 text-sm text-gray-500'>
                    <div className='w-8'>
                    <input
                        type="checkbox"
                        checked={selectedCustomers.length === filteredCustomers.length}
                        onChange={handleSelectAll}
                        className='cursor-pointer transform scale-125'
                    />
                    </div>
                </div>
                }
                <div className='opacity-50 pr-2 space-x-4 flex'>
                    <div className='cursor-pointer flex' onClick={handleSortByTime}>เวลา
                        {sortOrder === 'asct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>
                        : sortOrder === 'desct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div>
                            : <div className='flex flex-col pl-1  opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>}
                    </div>
                  </div>
              </div>

              <div className='text-sm '>
                  {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[102px]">
                          <Skeleton.Avatar active size="large" shape="default" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 1 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : customers.length === 0 ? (
                    <div className="bg-white p-4 my-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                  selectedCustomersPage.map(customer => (
                <div key={customer._id} className='bg-white px-4 py-2 rounded-lg mx-1 mb-3 overflow-hidden break-all whitespace-normal'>
                    <div className='flex'>
                      <div className='mb-1'>
                        <input
                            type="checkbox"
                            checked={selectedCustomers.includes(customer._id)}
                            onChange={() => handleSelectCustomer(customer._id)}
                            className='cursor-pointer transform scale-125'
                        />
                      </div>
                      <div className='pl-2 px-1 truncate text-xs text-gray-500'>{customer.market === "undefined" ? '' : customer.market.name }</div>
                    </div>

                    <div className='flex flex-row'>
                    <div className='w-20 max-w-20'>
                            <a>
                            {customer.imageUrls  && customer.imageUrls[0]  ? (
                            <Image 
                              className="rounded-lg" src={getImageCustomerUrl(customer.imageUrls[0])} alt={customer.name} width="80" 
                              onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                            />
                            ) : (
                            <Image className="rounded-lg" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/user.png`} width="80" />
                            )}
                            </a>
                        </div>
                        <div className='flex flex-col text-wrap w-full truncate cursor-pointer' onClick={() => handleDetail(customer._id)}>
                            <div className='px-2 pt-1 max-h-12 truncate-2-lines'>{customer.name}</div>
                            <div className='pt-1 ml-2 truncate text-amber-600'>เบอร์โทร: {customer.phone}</div>
                            <div className='px-2 pt-1 max-h-12 text-indigo-800 truncate-2-lines '>ที่อยู่: {customer.address}</div>
                        </div>
                    </div>

                    <button onClick={() => handleEdit(customer._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300">แก้ไข</p></button>
                    
                    <Dropdown
                        className='cursor-pointer'
                        placement="topLeft"
                        overlay={
                            <div className='bg-white p-1 rounded-lg' style={{ boxShadow: '0 6px 15px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)' }}>
                            <div className='flex'>
                                <button className='bg-gray-200 rounded py-1 px-4 grow mb-1  active:bg-gray-300' onClick={() => handleDetail(customer._id)}><p className="w-full text-center">ดู</p></button>
                            </div>

                            <div className='flex'>
                                <Popconfirm
                                title="ยืนยันการลบลูกค้า"
                                description="คุณต้องการลบลูกค้ารายนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => handleDelete(customer._id)}
                                okText="ยืนยัน"
                                cancelText="ยกเลิก"
                                >
                                <button className='bg-gray-200 rounded py-1 px-4 grow  active:bg-gray-300'><p className="w-full text-center">ลบ</p></button>
                                </Popconfirm>
                            </div>
                            </div>
                        }
                        >
                        <button className="bg-gray-200 rounded py-1 px-4 mt-2 max-w-24 focus:bg-gray-300">เพิ่มเติม <DownOutlined className='scale-75'/></button>
                        </Dropdown>
                  </div>
                    ))
                  )}
              </div>
              
            </div>
          </div>

          <div>
            <div className="flex justify-center mb-24">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={customers.length}
                onChange={handlePageChange}
                onShowSizeChange={handleItemsPerPageChange}
                showSizeChanger
                pageSizeOptions={['10', '20', '50' , '100']}
              />
            </div>
          </div>

        </div>

        <div></div>
      </div>
    </>
  );
}

export default ManageCustomer;