import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined } from '@ant-design/icons';
import './styles/detail-product.css'
import {EditOutlined,EyeOutlined,MailFilled,PhoneFilled ,HeartFilled , CloseCircleFilled , StopFilled , QuestionCircleOutlined } from '@ant-design/icons';
import { Spin, FloatButton ,Image ,Carousel,Switch, Tooltip,Popconfirm,message } from 'antd';
import { Transition } from '@headlessui/react'
;
import logoUndefined from '../../assets/marketundefined.svg';
import lineIcon from '../../assets/lineLogo1.png';
import facebookIcon from '../../assets/facebookLogo2.png';
import instagramIcon from '../../assets/igLogo2.png';
import tiktokIcon from '../../assets/Tiktok_logo2.png';
import youtubeIcon from '../../assets/youtubeLogo2.png';
import xIcon from '../../assets/XLogo.jpeg';
import telegramIcon from '../../assets/telegramLogo.png';
import wechatIcon from '../../assets/WechatLogo.png';
import shopeeIcon from '../../assets/ShopeeLogo.png';
import lazadaIcon from '../../assets/LazadaLogo.png';
import whatsappIcon from '../../assets/whatsappLogo.png';
import snapchatIcon from '../../assets/SnapchatLogo.png';
import phoneIcon from '../../assets/phoneLogo.png';
import mailIcon from '../../assets/MailLogo.png';
  
import map from '../../assets/map.jpg';

  import default_theme from '../../assets/market/default_theme.jpg'
  import dark_theme from '../../assets/market/dark_theme.jpg'
  import beige_theme from '../../assets/market/beige_theme.jpg'
  import blue_theme from '../../assets/market/blue_theme.jpg'

import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

function DetailMarket() {
    const { id } = useParams();
    const { user } = useUserAuth();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [market, setMarket] = useState(null);
    const [data, setData] = useState(true);
    const [statusform, setStatusForm] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [initialPosition, setInitialPosition] = useState({ lat: 13.7248785, lng: 100.4683055 });
    const userId = user ? user._id : null;

  const fetchMarket = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market/from_id_market/${id}?populate=location`);
      const marketData = response.data;

      if (marketData.location && marketData.location.lat !== undefined && marketData.location.lng !== undefined) {
        const lat = marketData.location.lat;
        const lng = marketData.location.lng;
    
        setSelectedPosition({ lat, lng });
        setInitialPosition({ lat, lng });
      }

      if(response.data.length === 0){
        setData(false)
      }

      setMarket(marketData);
    } catch (err) {
      console.error('Error fetching market:', err);
    }
  };
  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }

    const contactIcons = {
      line: lineIcon,
      facebook: facebookIcon,
      instagram: instagramIcon,
      tiktok: tiktokIcon,
      youtube: youtubeIcon,
      x: xIcon,
      telegram: telegramIcon,
      wechat: wechatIcon,
      shopee: shopeeIcon,
      lazada: lazadaIcon,
      whatsapp: whatsappIcon,
      snapchat: snapchatIcon,
      phone: phoneIcon ,
      mail: mailIcon ,
    };

  useEffect(() => {
    fetchMarket();
  }, [id, navigate, user]);

  const handleEdit = (id) => {
    if (userId) {
      navigate(`/edit-market/${id}`);
    }
  };

  const handleSubmitChangeStatus = async (status) => {
    try {
      await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/market/change-status/${id}`,
        { status: status },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );
      message.success('เปลี่ยนการแสดงผลร้านค้าสำเร็จ');
      fetchMarket();
      setStatusForm(false);
    } catch (error) {
      message.error('เปลี่ยนการแสดงผลร้านค้าไม่สำเร็จ');
      console.log(error)
    }
  };

    // กำหนดขนาดแผนที่
    const mapContainerStyle = {
      height: '400px',
      borderRadius: '0.5rem',
      overflow: 'hidden',
    };
    // การตรวจสอบว่ามีการโหลด Script อยู่แล้วหรือไม่
    const shouldLoadMap = market && market.location && market.location.lat !== undefined && data;
  
    // ใช้ useMemo เพื่อหลีกเลี่ยงการเรนเดอร์ซ้ำ
    const LoadMap = useMemo(() => (
      shouldLoadMap && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={selectedPosition || initialPosition}
            zoom={12}
          >
            {selectedPosition && <MarkerF position={selectedPosition} />}
          </GoogleMap>
      )
    ), [initialPosition, selectedPosition, shouldLoadMap]);

    const handleStatusOpen = (e) => {
      e.preventDefault();
      setStatusForm(true);
    };

    const handleStatusClose = (e) => {
      e.preventDefault();
      setStatusForm(false);
    };

    if (!market) {
      return (
        <>
        {user ? <Nav /> : (<NavNoUser />)}
        <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin  fullscreen size="large" /></div>
        </div>
      </>
      );
    }

  return (
    <>
      {user ? 
        <div className='md:inline hidden'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink flex-initial mx-1'>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-market" className='text-sm text-gray-600' > <LeftOutlined /> จัดการร้านค้า</Link>
            <h2 className='text-2xl mb-4 mt-2'>{market.name}</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-market" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">{market.name}</h2>
          </div>

          <div className='custom-scrollbar md:max-w-[923px] md:mt-0 mt-[62px]'>
          <div className='flex md:flex-row flex-col md:flex-wrap justify-center md:p-6 mt-3 mb-4 h-xl rounded-2xl shadow-lg bg-white relative'>
            <Transition
              show={statusform}
              enter="transition-opacity duration-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-0"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="overlay fixed inset-0 bg-black opacity-50 z-40" onClick={handleStatusClose}></div>    
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4 z-50">
                  <div className="md:w-[500px] w-screen bg-white p-6 px-4 md:px-20 shadow-lg rounded-2xl text-white text-xl flex flex-col gap-3 text-center">
                    <p className='text-black mb-2 md:hidden'>{market.name}</p>
                    {market.status === 'open' ? 
                    <div className='bg-gradient-to-r from-emerald-400 to-emerald-500 ring-8 ring-emerald-200 select-none rounded-2xl py-4 px-10'><HeartFilled className='mr-2' />เปิดทำการปกติ</div>
                    :
                    <div className='bg-gradient-to-r from-emerald-400 to-emerald-500 hover:from-emerald-300 hover:to-emerald-400 active:ring ring-emerald-200 select-none rounded-2xl py-4 px-10 hover:cursor-pointer' onClick={() => handleSubmitChangeStatus('open')}>
                      <HeartFilled className='mr-2' />เปิดทำการปกติ
                    </div>
                    }
                    {market.status === 'vacation' ? 
                    <div className='bg-gradient-to-r from-amber-400 to-amber-500 ring-8 ring-amber-200 select-none rounded-2xl py-4 px-10'><StopFilled className='mr-2' />หยุดพักร้อน</div>
                    :
                    <Popconfirm
                      title="การหยุดพักร้อน จะยังสามารถเข้าดูหน้าร้านค้าได้ปกติ แต่จะแสดงข้อความแจ้งเตือนว่าร้านปิดพักร้อนอยู่"
                      description=""
                      icon={<QuestionCircleOutlined style={{ color: 'orange' }} />}
                      onConfirm={() => handleSubmitChangeStatus('vacation')}
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                    >
                      <div className='bg-gradient-to-r from-amber-400 to-amber-500 hover:from-amber-300 hover:to-amber-400 active:ring ring-amber-200 select-none rounded-2xl py-4 px-10 hover:cursor-pointer'>
                        <StopFilled  className='mr-2' />หยุดพักร้อน
                      </div></Popconfirm>
                    }
                    {market.status === 'closed' ? 
                    <div className='bg-gradient-to-r from-rose-400 to-rose-500 ring-8 ring-rose-200 select-none rounded-2xl py-4 px-10'><CloseCircleFilled className='mr-2' />ปิดใช้งาน</div>
                    :
                    <Popconfirm
                      title="การปิดใช้งาน จะทำให้ไม่สามารถเข้าดูหน้าร้านค้าได้"
                      description=""
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => handleSubmitChangeStatus('closed')}
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                    >
                    <div className='bg-gradient-to-r from-rose-400 to-rose-500 hover:from-rose-300 hover:to-rose-400 active:ring ring-rose-200 select-none rounded-2xl py-4 px-10 hover:cursor-pointer'>
                      <CloseCircleFilled className='mr-2' />ปิดใช้งาน
                    </div></Popconfirm>
                    }

                    <div className='flex justify-center w-full mt-4'>
                      <button onClick={handleStatusClose} className='bg-white hover:bg-gray-100 text-red-600 hover:text-red-700 border border-red-600 hover:border-red-700 rounded-2xl px-4 py-1 w-full'>
                      ยกเลิก
                    </button>
                  </div>
                </div>  
              </div>     
            </Transition>

            <div className="p-4 md:p-6 ">
              {Array.isArray(market.logoUrl) && market.logoUrl.some(logoUrl => logoUrl.trim() !== '') ? (
                market.logoUrl.map((logoUrl, index) => (
                  logoUrl.trim() !== '' ? (
                    <div className='object-contain rounded-lg ' key={index}>
                      <img src={getImageMarketUrl(logoUrl)} alt={market.name} className='border-8 object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60' />
                    </div>
                  ) : ''
                ))
              ) : (
                <img src={logoUndefined} alt={market.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60' />
              )}
              <div className="text-center mx-10 md:mb-4 md:mt-4 mt-8 hover:cursor-pointer z-0" onClick={handleStatusOpen}>
                {market.status === 'open' && (
                  <div className='text-white px-4 py-2 rounded-full bg-gradient-to-r from-emerald-400 to-emerald-500 active:from-emerald-300 active:to-emerald-400 select-none w-48 m-auto relative group transition active:duration-300 ease-in-out'>
                    <div className='md:group-hover:-translate-x-4 md:translate-x-0 -translate-x-4 transition duration-300 ease-in-out'><HeartFilled className='mr-2' /> เปิดทำการปกติ</div>
                    <EditOutlined className='p-3 rounded-full bg-emerald-400 group-active:bg-emerald-300 overflow-hidden object-cover h-10 w-10 absolute right-0 top-1/2 transform -translate-y-1/2 md:opacity-0 group-hover:opacity-100 transition-opacity duration-200' />
                  </div>
                )}
                {market.status === 'vacation' && (
                  <div className='text-white px-4 py-2 rounded-full bg-gradient-to-r from-amber-400 to-amber-500 active:from-amber-300 active:to-amber-400 select-none w-48 m-auto relative group transition active:duration-300 ease-in-out'>
                    <div className='group-hover:-translate-x-4 transition duration-300 ease-in-out'><StopFilled className='mr-2' /> หยุดพักร้อน</div>
                    <EditOutlined className='p-3 rounded-full bg-amber-400 group-active:bg-amber-300 overflow-hidden object-cover h-10 w-10 absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200' />
                  </div>
                )}
                {market.status === 'closed' && (
                  <div className='text-white px-4 py-2 rounded-full bg-gradient-to-r from-rose-400 to-rose-500 active:from-rose-300 active:to-rose-400 select-none w-48 m-auto relative group transition active:duration-300 ease-in-out'>
                    <div className='group-hover:-translate-x-4 transition duration-300 ease-in-out'><CloseCircleFilled className='mr-2' /> ปิดทำการ</div>
                    <EditOutlined className='p-3 rounded-full bg-rose-400 group-active:bg-rose-300 overflow-hidden object-cover h-10 w-10 absolute right-0 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200' />
                  </div>
                )}
              </div>
            </div>

            {/* mini */}
            <div className=" grow md:py-0  py-4 md:max-w-xl md:px-0 px-4">

              <div className="md:max-w-[523px] min-h-10 overflow-hidden mx-4 mt-3 p-2 rounded-lg bg-gray-100">
                  <h3 className="break-words">{market.description ===""? <div className='text-center'>ยังไม่ได้ระบุรายละเอียด</div> : market.description}</h3>
              </div>

              <div className="md:p-4 pt-4 md:pb-0 pb-2 p-0">
                  {market.contactlink && market.contactlink.map((contact, index) => {
                    const isLink = /^https?:\/\/[^\s/$.?#].[^\s]*$/i.test(contact.word); // ตรวจสอบว่าเป็นลิงก์หรือไม่

                    return (
                      <div key={index} className='flex'>
                        <div className='min-w-16'>
                          <img 
                            src={contactIcons[contact.source]} 
                            className='md:w-16 w-14 p-2' 
                            alt={`${contact.source} Icon`} 
                          />
                        </div>
                        <div className="md:min-w-96 w-60 p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-wrap bg-gray-100">
                          {isLink ? (
                            <a href={contact.word} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                              {contact.word}
                            </a>
                          ) : (
                            <p>{contact.word || '-'}</p>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div className='flex flex-col md:flex-row w-full md:gap-2'>
                    <div className="flex min-w-[210px] p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-gray-100 break-all">
                      <MailFilled className="mr-2 p-1 rounded-full" /> 
                      <p>{market.email || 'ยังไม่ได้ระบุ'}</p>
                    </div>
                    <div className="flex min-w-[210px] p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-gray-100 break-all">
                      <PhoneFilled className="mr-2 p-1 rounded-full" /> 
                      <p>{market.phone || 'ยังไม่ได้ระบุ'}</p>
                    </div>
                  </div>
              </div>

            </div>

            <div className="flex flex-col items-center justify-center md:mt-4 md:mr-6">
              <div className='mt-2 bg-gray-100 rounded-lg border'>
                <div className='flex flex-col px-3 py-1'>
                  ธีมร้านค้า
                </div>
                <div className='relative flex items-center justify-center'>
                  {market.theme === 'default'? <img src={default_theme} className='w-32 rounded-b-lg'></img> :''}
                  {market.theme === 'dark'? <img src={dark_theme} className='w-32 rounded-b-lg'></img> :''}
                  {market.theme === 'beige'? <img src={beige_theme} className='w-32 rounded-b-lg'></img> :''}
                  {market.theme === 'blue'? <img src={blue_theme} className='w-32 rounded-b-lg'></img> :''}

                  <p className="absolute text-center select-none invert" >{market.theme}</p>
                </div>
              </div>
            </div>

            <div className="flex grow flex-col items-center justify-center mt-4 md:px-0 md:pb-0 px-4 pb-4">
              <div className='mt-2 w-full bg-gray-100 rounded-lg'>
                <div className='flex justify-between p-1'>
                  <div className='pl-2'>ตำแหน่งร้านค้า</div>
                    <div className=''>
                      <Tooltip
                        title="กรุณาไปที่ แก้ไขข้อมูลร้านค้า"
                      >
                        <Switch
                          style={{ width: '90px' }}
                          checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                          disabled
                          defaultChecked={market.location && market.location.status === true}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {!shouldLoadMap ? 
                    <div className=' h-[400px] overflow-hidden rounded-lg relative flex items-center justify-center'>
                      <img className="w-full object-cover h-full grayscale" src={map} alt="Map Background" />
                      <p className="absolute text-center select-none" >ยังไม่ได้ระบุตำแหน่งร้านค้า</p>
                    </div>
                  : LoadMap}
              </div>
            </div>

          </div>

            <div className='grid grid-cols-6 mb-4'>
              <div className='col-start-1 col-end-7 rounded-2xl overflow-auto'>
                <Carousel arrows autoplay>
                  {market?.imageUrls?.map((imageUrl, index) => (  // Use optional chaining here
                    <div className='bg-gray-400 object-contain aspect-video'>
                      <Image key={index} src={getImageMarketUrl(imageUrl)} alt={market.name} 
                        className='object-contain m-auto h-full aspect-video' />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>

                <div className='bg-white md:p-6 p-1 mt-4 flex-none md:max-w-6xl max-w-full rounded-2xl break-all whitespace-normal drop-shadow-md mb-24'>
                    <div className='flex w-full'>
                        <div className='flex-1 py-4	bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 text-center w-max' onClick={() => handleEdit(market._id)}>
                            <button ><EditOutlined className='opacity-75 md:scale-150 scale-100 md:pr-3 pr-1'/>แก้ไขข้อมูลร้านค้า</button>
                        </div>
                        <a 
                          href={`/${market.id_text}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className='flex-1 py-4 ml-4 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 text-center w-max'
                        >
                          <button>
                            <EyeOutlined className='opacity-75 md:scale-150 scale-100 md:pr-3 pr-1'/>ดูหน้าร้านค้า
                          </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div></div>
      </div>
    </>
  );
}

export default DetailMarket;
