import React, { useEffect , useState, useMemo} from 'react';
import axios from 'axios';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { getCurrentPosition } from './fn';
import { Link , useNavigate } from 'react-router-dom';
import { InboxOutlined,LeftOutlined ,PlusOutlined , MailOutlined,PhoneOutlined,LinkOutlined,QuestionCircleOutlined , MinusCircleOutlined } from '@ant-design/icons';
import { LoadingOutlined } from "@ant-design/icons";
import ImgCrop from 'antd-img-crop';
import { FloatButton , message, Upload , Form, Input, Button, Switch, Tooltip, Modal, Spin } from 'antd';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { Radio , List ,ListItem ,ListItemPrefix ,Typography  } from "@material-tailwind/react";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import './styles/add-market.css'
import lineIcon from '../../assets/lineLogo1.png';
import facebookIcon from '../../assets/facebookLogo2.png';
import instagramIcon from '../../assets/igLogo2.png';
import tiktokIcon from '../../assets/Tiktok_logo2.png';
import youtubeIcon from '../../assets/youtubeLogo2.png';
import xIcon from '../../assets/XLogo.jpeg';
import telegramIcon from '../../assets/telegramLogo.png';
import wechatIcon from '../../assets/WechatLogo.png';
import shopeeIcon from '../../assets/ShopeeLogo.png';
import lazadaIcon from '../../assets/LazadaLogo.png';
import whatsappIcon from '../../assets/whatsappLogo.png';
import snapchatIcon from '../../assets/SnapchatLogo.png';
import phoneIcon from '../../assets/phoneLogo.png';
import mailIcon from '../../assets/MailLogo.png';

import default_theme from '../../assets/market/default_theme.jpg'
import dark_theme from '../../assets/market/dark_theme.jpg'
import beige_theme from '../../assets/market/beige_theme.jpg'
import blue_theme from '../../assets/market/blue_theme.jpg'
import green_theme from '../../assets/market/green_theme.jpg'


const { TextArea } = Input;

function AddMarket() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [selectedTheme, setSelectedTheme] = useState('default');
  const [contactlinks, setContactLinks] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [initialPosition, setInitialPosition] = useState({ lat: 13.7248785, lng: 100.4683055 });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true); // สถานะการโหลด
  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  useEffect(() => {
    if (user !== undefined) {
      setLoading(false); // หยุดการโหลดเมื่อ user ถูกกำหนดค่าแล้ว
    }
    if (!user && !loading) {
      navigate('../manage-market');
    }

  }, [user, loading, navigate]);

  //จำนวนไฟล์ภาพที่แสดง
  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //ตรวจสอบไฟล์ว่าเป็นรูปบ่
  const props = {
    beforeUpload: (file) => {
      const isImage = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/gif';
      if (!isImage) {
        message.error(`${file.name} ไม่รองรับไฟล์ภาพประเภทนี้`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };

  const [fileListLogo, setFileListLogo] = useState([]);
  const [fileListBanners, setFileListBanners] = useState([]);

  const handleChangeLogo = ({ fileList: newFileList }) => setFileListLogo(newFileList);
  const handleChangeBanners = ({ fileList: newFileList }) => setFileListBanners(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  useEffect(() => {
    getCurrentPosition((position) => {
      setInitialPosition(position); // ใช้ setInitialPosition เพื่ออัปเดตค่า
    });
  }, []);

  // กำหนดขนาดแผนที่
  const mapContainerStyle = {
      height: '400px',
  };
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedPosition({ lat, lng });
    setInitialPosition({ lat, lng });
  };

const shouldLoadMap = user; // เช็คว่ามีค่าหรือไม่

// ใช้ useMemo เพื่อหลีกเลี่ยงการเรนเดอร์ซ้ำ
const LoadMap = useMemo(() => (
  shouldLoadMap && (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={selectedPosition || initialPosition}
        zoom={12}
        onClick={handleMapClick}
      >
        {selectedPosition && <MarkerF position={selectedPosition} />}
      </GoogleMap>
  )
  ), [initialPosition, selectedPosition, shouldLoadMap]);

  const contactIcons = {
    line: lineIcon,
    facebook: facebookIcon,
    instagram: instagramIcon,
    tiktok: tiktokIcon,
    youtube: youtubeIcon,
    x: xIcon,
    telegram: telegramIcon,
    wechat: wechatIcon,
    shopee: shopeeIcon,
    lazada: lazadaIcon,
    whatsapp: whatsappIcon,
    snapchat: snapchatIcon,
    phone: phoneIcon ,
    mail: mailIcon ,
  };
  const handleAddContact = () => {
    setIsModalVisible(true);
  };
  const handleSelectContactType = (type) => {
    if (contactlinks.length < 8) {
      const newContact = {
        type,
        icon: contactIcons[type], // กำหนดรูปภาพตามประเภทที่เลือก
        source: type,
        word: '',
      };
      setContactLinks([...contactlinks, newContact]);
    }
    setIsModalVisible(false);
  };
    // ลบตัวเลือกหลัก
  const handleRemoveContact = (index) => {
    const newContactLinks = [...contactlinks];
    newContactLinks.splice(index, 1);
    setContactLinks(newContactLinks);
    
    // Reset form fields related to the removed ContactLinks
    const currentValues = form.getFieldsValue();
    delete currentValues.contactlinks[index];
    currentValues.contactlinks = currentValues.contactlinks.filter(Boolean);
    
    // Reset fields
    const resetContactFields = () => {
      const newSources = { ...currentValues.sources };
      const newWords = { ...currentValues.words };
      
      Object.keys(newSources).forEach(key => {
        if (parseInt(key) >= combinations.length) {
          delete newSources[key];
          delete newWords[key];
        }
      });
      
      return { newSources, newWords };
    };
    
    const { newSources, newWords } = resetContactFields();
    
    // Update form with new values
    form.setFieldsValue({
      ...currentValues,
      sources: newSources,
      words: newWords,
    });
  };

  const onSubmit = async (data) => {
    try {
        setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('phone', data.phone);
        formData.append('email', data.email);
        formData.append('theme', selectedTheme);
        formData.append('user', user._id);

        const locationData = {
          desc: `ตำแหน่งของร้าน ${data.name}`,
          user: user._id,
          status: data.locationStatus
        };
        if (selectedPosition) {
          locationData.lat = selectedPosition.lat;
          locationData.lng = selectedPosition.lng;
        }
        const locationResponse = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/location`,
          locationData,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
        );

        formData.append('location', locationResponse.data._id);

        if (fileListLogo.length > 0) {
            formData.append('logo', fileListLogo[0].originFileObj);
        }

        fileListBanners.forEach(file => {
            formData.append('banners', file.originFileObj);
        });

        formData.append('contactlink', JSON.stringify(contactlinks)); // ส่งเป็น JSON String

        await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/market`,
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
        );

        message.success('เพิ่มร้านค้าสำเร็จ');
        navigate('../manage-market');
    } catch (err) {
        console.error('Error adding market:', err);
        if (err.response) {
            message.error(`Error: ${err.response.data.message || 'Network error'}`);
        } else {
            message.error('Failed to add market. Please check your network connection.');
        }
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
};

if (user && !initialPosition) {
  return (
    <>    
    <div className='flex justify-between'>
      <div>
        <Sidebar />
      </div>
      <div><Spin  fullscreen size="large" /></div>
    </div>
  </>
  );
}


  return (
    <>
      {user ? 
        <div className='md:inline hidden'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink flex-initial mx-1 md:mb-28 mb-5' style={{width: "48rem"}}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-market" className='text-sm text-gray-600' > <LeftOutlined /> จัดการร้านค้า</Link>
            <h2 className='text-2xl mb-4 mt-2'>เพิ่มร้านค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-market" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">เพิ่มร้านค้า</h2>
          </div>

            <Form layout="vertical" onFinish={onSubmit} form={form} className=''
            initialValues={{
              name: '',
              description: '',
              phone: '',
              email: '',
              status: 'open',
              locationStatus: true,
            }}
            >
            <div className='md:mt-4 mt-[62px] bg-white p-6 rounded-lg'>
              <div  className='flex justify-center	'><label>รูปปกร้านค้า</label></div>
                <div className='flex justify-center	'>
                  <ImgCrop rotationSlider>
                    <Upload
                      {...props}
                      action=""
                      listType="picture-circle"
                      fileList={fileListLogo}
                      onChange={handleChangeLogo}
                      >
                      {fileListLogo.length ? null : uploadButton}
                    </Upload>
                  </ImgCrop>
                </div>
                <Form.Item label="ชื่อร้านค้า" name="name" className='pt-6' rules={[{ required: true, message: 'กรุณาใส่ชื่อร้านค้า' }]}>
                  <Input 
                    placeholder='ชื่อร้านค้า'
                  />
                </Form.Item>

                <Form.Item label="คำอธิบายร้านค้า" name="description" className='mt-5'>
                  <TextArea 
                    placeholder='คำอธิบายร้านค้า'
                  />
                </Form.Item>
              </div>

              <div className='mt-4 bg-white p-6 rounded-lg'>
                <Form.Item label="เบอร์โทร" name="phone" 
                  tooltip={{
                    icon:  <PhoneOutlined />,
                  }}
                >
                  <Input placeholder=''/>
                </Form.Item>

                <Form.Item label="อีเมล" name="email" className='pt-6'
                  tooltip={{
                    icon:   <MailOutlined />,
                  }}
                >
                  <Input placeholder=''/>
                </Form.Item>

                <div className='pb-1 pt-6'><label>ช่องทางการติดต่อ <LinkOutlined className='opacity-50'/></label></div>
                {contactlinks.map((contact, index) => (
                  <div key={index} class="flex md:gap-10 gap-2">
                    <div class="place-self-center md:translate-x-4">              
                      <img src={contact.icon} className="w-10 rounded-lg" alt={`${contact.source} Icon`} />
                    </div>
                    <div className='w-full'>
                      <label>{contact.source.charAt(0).toUpperCase() + contact.source.slice(1)}</label>
                      <div className='flex gap-2'>
                        <Form.Item 
                          label="" 
                          name={['contactlinks', index, 'word']}
                          rules={[{ required: true, message: 'กรุณาใส่ชื่อตัวเลือก' }]}
                          className='grow'
                        >
                          <Input
                            placeholder="ช่องทางการติดต่อ"
                            value={contact.word}
                            onChange={(e) => {
                              const newContacts = [...contactlinks];
                              newContacts[index].word = e.target.value;
                              setContactLinks(newContacts);
                            }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{ fontSize: '16px', color: 'red' }}
                          onClick={() => handleRemoveContact(index)}
                          className='mb-2'
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {contactlinks.length < 8 && (
                  <Button
                    type="dashed"
                    onClick={handleAddContact}
                    className='w-full flex justify-center items-center	'        
                  >
                    <PlusOutlined/> เพิ่มช่องทางการติดต่อ
                  </Button>
                )}

                <Modal
                  title="เลือกช่องทางการติดต่อ"
                  visible={isModalVisible}
                  footer={null}
                  onCancel={() => setIsModalVisible(false)}
                >
                  <div className="grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-2">
                    {Object.keys(contactIcons).map((type) => (
                      <Button
                        key={type}
                        onClick={() => handleSelectContactType(type)}
                        type="default"
                        className='w-full h-full flex flex-col items-center justify-center space-y-2 pt-2'
                      >
                        <img src={contactIcons[type]} alt={`${type} Icon`} className="rounded-lg w-8 h-8" />
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Button>
                    ))}
                  </div>
                </Modal>
            </div>

            <div className='mt-4 bg-white p-6 rounded-lg'>
              <div className='pb-4 flex justify-between'>
                <label>ตำแหน่งของร้านค้า</label>
                <div className='flex'>
                  <Tooltip title="หากปิดไว้ จะไม่แสดงแผนที่ในหน้าร้านค้า" className="hover:cursor-help text-gray-400 mr-2 mb-2"><QuestionCircleOutlined /></Tooltip>
                  <Form.Item
                    name="locationStatus"
                    valuePropName="checked"
                  >
                    <Switch
                      style={{ width: '90px' }}
                      checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                    />
                  </Form.Item>
                </div>
              </div>

              {LoadMap}

            </div>

              <div className='mt-4 bg-white p-6 rounded-lg'>
                <div className='pb-4'><label>ธีมร้านค้า</label></div>
                  <List className="flex-row flex-wrap">
                    <ListItem className="p-0 max-w-40">
                      <label
                        htmlFor="horizontal-list-default"
                        className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            defaultChecked
                            name="horizontal-list"
                            id="horizontal-list-default"
                            ripple={false}
                            className="hover:before:opacity-0"
                            containerProps={{className: "p-0",}}
                            onChange={() => setSelectedTheme('default')} // Set theme
                          />
                        </ListItemPrefix>
                        <Typography>
                          <img
                            width={200}
                            src={default_theme}
                            className='rounded-xl border-dashed border-4 border-gray-200'
                          />
                        </Typography>
                      </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                        <label
                          htmlFor="horizontal-list-dark"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            name="horizontal-list"
                            id="horizontal-list-dark"
                            ripple={false}
                            className="hover:before:opacity-0"
                            containerProps={{className: "p-0",}}
                            onChange={() => setSelectedTheme('dark')} // Set theme
                          />
                        </ListItemPrefix>
                          <Typography>
                                      <img
                                        width={200}
                                        src={dark_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                                  <label
                                    htmlFor="horizontal-list-beige"
                                    className="flex w-full cursor-pointer items-center px-3 py-2"
                                  >
                                    <ListItemPrefix className="mr-3">
                                      <Radio
                                        name="horizontal-list"
                                        id="horizontal-list-beige"
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        onChange={() => setSelectedTheme('beige')} // Set theme
                                      />
                                    </ListItemPrefix>
                                    <Typography>
                                      <img
                                        width={200}
                                        src={beige_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                                  <label
                                    htmlFor="horizontal-list-blue"
                                    className="flex w-full cursor-pointer items-center px-3 py-2"
                                  >
                                    <ListItemPrefix className="mr-3">
                                      <Radio
                                        name="horizontal-list"
                                        id="horizontal-list-blue"
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        onChange={() => setSelectedTheme('blue')} // Set theme
                                      />
                                    </ListItemPrefix>
                                    <Typography>
                                      <img
                                        width={200}
                                        src={blue_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                                  <label
                                    htmlFor="horizontal-list-green"
                                    className="flex w-full cursor-pointer items-center px-3 py-2"
                                  >
                                    <ListItemPrefix className="mr-3">
                                      <Radio
                                        name="horizontal-list"
                                        id="horizontal-list-green"
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        onChange={() => setSelectedTheme('green')}
                                      />
                                    </ListItemPrefix>
                                    <Typography>
                                      <img
                                        width={200}
                                        src={green_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                  </List>

              </div>

              <div className='mt-4 bg-white p-6 rounded-lg'>
                <div className='pb-4'><label>รูปแบนเนอร์ร้านค้า</label></div>
                <div className='flex justify-center'>
                  <Form.Item >
                    <Form.Item name="banners" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                      <Upload.Dragger
                        name="files"
                        multiple={true}
                        maxCount={4}
                        onChange={handleChangeBanners}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">อัปโหลดรูปภาพ</p>
                        <p className="ant-upload-hint">อัตราส่วนรูปภาพที่แนะนำคือ 16 ต่อ 9 หรือ 1600×900พิกเซล</p>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                </div>


              </div>
              

              <Form.Item className=' flex justify-end mt-5'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                    {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </Form.Item>

              <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
              >
                <div className=''>
                      <button 
                        type="submit"
                        disabled={isSubmitting}
                        className= 
                        "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"> 
                        {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                      </button>
                </div>
              </Form.Item>
              

            </Form>
          
        </div>

        <div className=''></div>
      </div>
    </>
  );
}

export default AddMarket;
