import React, { useState, useEffect } from 'react';
import { Link} from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useUserAuth } from '../context/UserContext'; 
import { calculateRemainingUsage } from '../pages/script';
import axios from 'axios';


export function LoginAlert() {
  const {user} = useUserAuth();

    return(
        <>
        {user ? '' :
            <div className="bg-white rounded-md py-5 mt-4 mx-2" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-400">
                <ExclamationTriangleIcon className="h-6 w-6 text-yellow-50" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center">
                กรุณา<Link to="../loginpage" className='text-blue-600 hover:text-blue-700'>เข้าสู่ระบบ</Link>ก่อนใช้งาน
              </div>
              
            </div>
            }
        </>
    )

}

export function ExpireAlerts() {
  const {user} = useUserAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // สถานะการโหลด

  const [remainingInfo, setRemainingInfo] = useState({
    remainingDays: 0,
    remainingHours: 0,
    remainingMinutes: 0,
    formattedExpirationDate: ''
  });

    //ดึงข้อมูลผู้ใช้ขึ้นมาแสดง
    const fetchUser = async () => {
      if (user && user._id) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/user/id/${user._id}`);
            setUserData(response.data);

            const usageInfo = calculateRemainingUsage(response.data); //อายุการใช้งานคงเหลือ
            setRemainingInfo(usageInfo);
            

          } catch (err) {
            console.error('Error fetching user:', err);
          } finally {
            setLoading(false); // เมื่อโหลดเสร็จแล้ว ให้เปลี่ยนเป็น false
          }
      } else {
        setLoading(false); // ถ้าไม่มี user ก็ให้โหลดเสร็จทันที
      }
    };

    useEffect(() => {
      fetchUser();
    }, [user]);

    if (loading) {
      return null; // หรือแสดง <LoadingComponent /> ถ้าต้องการ
    }

    return (
      <>
        {user && userData && !userData.admin ? (
          remainingInfo.remainingDays === 0 ? (
            <div className="bg-white rounded-md py-5 mt-4 mx-2" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-400">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-50" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center">
                บัญชีของท่านหมดอายุการใช้งานแล้ว กรุณาต่ออายุการใช้งานที่<Link to="../accountpage" className='text-blue-600 hover:text-blue-700'>หน้าบัญชี</Link>
              </div>
            </div>
          ) : null
        ) : null}
      </>
  );
}



export function AboutToExpire() {
  const {user} = useUserAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // สถานะการโหลด

  const [remainingInfo, setRemainingInfo] = useState({
    remainingDays: 0,
    remainingHours: 0,
    remainingMinutes: 0,
    formattedExpirationDate: ''
  });

      //ดึงข้อมูลผู้ใช้ขึ้นมาแสดง
    const fetchUser = async () => {
      if (user && user._id) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/user/id/${user._id}`);
            setUserData(response.data);

            const usageInfo = calculateRemainingUsage(response.data); //อายุการใช้งานคงเหลือ
            setRemainingInfo(usageInfo);

          } catch (err) {
            console.error('Error fetching user:', err);
          } finally {
            setLoading(false); // เมื่อโหลดเสร็จแล้ว ให้เปลี่ยนเป็น false
          }
      } else {
        setLoading(false); // ถ้าไม่มี user ก็ให้โหลดเสร็จทันที
      }
    };

    useEffect(() => {
      fetchUser();
    }, [user]);

    if (loading) {
      return null; // หรือแสดง <LoadingComponent /> ถ้าต้องการ
    }

    return(
        <>
        {user && userData && !userData.admin ? (
          remainingInfo.remainingDays < 3 && remainingInfo.remainingDays != 0 ? (
            <div className="bg-white rounded-md py-5 mt-4 mx-2" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-400">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-50" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center">
                <p>บัญชีของท่าน เหลืออายุการใช้งาน อายุการใช้งานคงเหลือ: {remainingInfo.remainingDays} วัน {remainingInfo.remainingHours} ชั่วโมง {remainingInfo.remainingMinutes} นาที </p>
                <p>กรุณาต่ออายุการใช้งานที่<Link to="../accountpage" className='text-blue-600 hover:text-blue-700'>หน้าบัญชี</Link> เพื่อให้สามารถทำงานได้อย่างต่อเนื่อง</p>
              </div>
              
            </div>
          ) : null
        ) : null}
        </>
    )

}