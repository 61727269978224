import React, { useState , Fragment, useEffect, useRef } from 'react';
import { Listbox , Transition } from '@headlessui/react';
import axios from 'axios' //connect backend
import { useParams , useNavigate, Link ,useLocation} from "react-router-dom";

import { useUserAuth } from '../../context/UserContext.jsx';
import './Calcu.css';

import { LeftOutlined , UpOutlined } from '@ant-design/icons'

import {
  Button,
  Checkbox,
  Form,
  InputNumber,
  Select,
  Switch,
  Drawer
} from 'antd';
  

export function B2CCalcu() {
  const { user } = useUserAuth();
  const userId = user ? user._id : null;
  const { id } = useParams();
  const navigate = useNavigate();
  const [carriers, setCarriers] = useState([]);
  const formRef = useRef(null);
  const [disabled_button, setDisabledButton] = useState(false);

  const fetchCarrier = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/carrier`, {
          params: { userId: user._id }
        });
        const carrierData = response.data;
        setCarriers(carrierData);
        setSelectedCarrier(carrierData.length > 0 ? carrierData[0]._id : null);
      } catch (error) {
        console.error("Error fetching carriers", error);
      }
  };
  const fetchShippingRates = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/shippingrate`);
        const shippingRateData = response.data;
        setShippingRates(shippingRateData);
      } catch (error) {
        console.error("Error fetching shipping rates", error);
      }
  };
  function getImageCarrierUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_CARRIER_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/`;
    return `${basePath}/${filename}`;
  }

  useEffect(() => {
    if (user && user._id) {
      fetchCarrier();
      fetchShippingRates();
    }

  }, [ user, id]);

    const [placement, setPlacement] = useState('right');
    const [small, setSmall] = useState(false);
    // ฟังก์ชันเพื่อตรวจสอบขนาดหน้าจอและปรับค่าต่าง ๆ
    const updateLayout = () => {
      if (window.innerWidth < 768) {
        setPlacement('bottom');
        setSmall(true);
      } else {
        setPlacement('right');
        setSmall(false);
      }
    };
  
    // ใช้ useEffect เพื่อตรวจสอบขนาดหน้าจอเมื่อ component mount หรือขนาดหน้าจอเปลี่ยนแปลง
    useEffect(() => {
      updateLayout(); // เรียกฟังก์ชันตอน mount
      window.addEventListener('resize', updateLayout); // เพิ่ม event listener เมื่อขนาดหน้าจอเปลี่ยนแปลง
  
      return () => {
        window.removeEventListener('resize', updateLayout); // ลบ event listener เมื่อ component unmount
      };
    }, []);

  //คำนวณค่าจัดส่ง
  const [calcushipping, setCalcushipping] = useState(false);
  const [shippingCost, setShippingCost] = useState(45);
  const [ShippingError, setShippingError] = useState(false)
  const [shippingRates, setShippingRates] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [weight, setWeight] = useState(1);

  //ฟังชันเปิดปิดค่าส่ง
  const calcushippingOpen = () => {
      setCalcushipping(true);
  };
  const calcushippingClose = () => {
      setCalcushipping(false);
  };

  const handleCalcushippingChange = (e, setter) => {
      const { value } = e.target;
      // ตรวจสอบว่าค่าที่ป้อนเข้ามาเป็นตัวเลขหรือไม่
      if (!isNaN(value)) {
      setter(value);
      }
  };


  useEffect(() => {
    if (selectedCarrier && weight) {
      const currentCarrierRates = shippingRates.filter(rate => rate.carrier === selectedCarrier);
      let maxWeight = Math.max(...currentCarrierRates.map(rate => rate.weight_to || Infinity));

      if (weight > maxWeight) {
        setShippingError(true);
        setShippingCost(0);
      } else {
        setShippingError(false);
        if (currentCarrierRates.length === 1 && !currentCarrierRates[0].weight_from && !currentCarrierRates[0].weight_to) {
          setShippingCost(currentCarrierRates[0].rate);
        } else {
          let matchingRate = currentCarrierRates.find(rate => weight >= rate.weight_from && weight <= rate.weight_to);
          setShippingCost(matchingRate ? matchingRate.rate : 0);
        }
      }
    }
  }, [weight, selectedCarrier, shippingRates]);

  const [sellprice, setSellPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [productcost, setProductCost] = useState(0);
  const [othercost, setOtherCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [checked, setChecked] = useState(false);

  const [codfeeValue, setCodfeeValue ] = useState(0);
  const [vatValue, setVatValue] = useState(0);

  const [allincom, setAllIncome] = useState(0);
  const [allprofit, setAllProfit] = useState(0); 
  const [allprofit_percent, setAllProfitPercent] = useState(0); 

  const [customprofit, setCustomprofit] = useState(0);
  const [CustomSellingPrice, setCustomSellingPrice] = useState(0);
  const [CustomLastProfit, setCustomLastProfit] = useState(0);
    
  // ฟังก์ชันสำหรับการเปลี่ยนแปลงค่าในช่องข้อมูล
  const onChange = (value) => {
    setDisabledButton(false)
  };

  // ฟังก์ชันสำหรับการเปลี่ยนแปลงค่าในช่อง check custom
  const toggleChecked = (value) => {
    setChecked(value);
    setDisabledButton(false)
  };
    
  //เริ่มการคำนวณค่าธรรมเนียม
  const handleSubmit = (values, shouldScroll) => {
    setDisabledButton(true)

    const { sellprice, productcost, othercost, shipping,
      quantity, discount, codfeecheck,
      vatcheck, customcheck, customprofit} = values; // เรียกใช้ค่าราคาขายและต้นทุนสินค้าจากฟอร์ม
      let codfee=0.03;
      let vatfee=0.07;
      let vatValue=0;
      let CustomSellingPrice=0;
      let CustomLastProfit=0;
    
    let sellpriceValue=(sellprice-discount)*quantity; //ราคาที่ขาย

    let shippingValue = shipping*quantity;
    
    let allcost=(productcost+othercost+shipping)*quantity; //ต้นทุนสินค้า

    //ตรวจสอบการเลือกชำระเงินปลายทาง
    let codfeeValue = 0;
    if(codfeecheck==true){
      codfeeValue=sellpriceValue*codfee;
    }

    //ตรวจสอบว่าคำนวณvat ด้วยไหม
    if(vatcheck==true){
      vatValue=sellpriceValue*vatfee;
    }

    //ตรวจสอบว่าคำนวณกำไรตามต้องการไหม
    if(customcheck==true){
      let customprofitValue = (customprofit/100)*allcost;

      let totalprofitsandcost = allcost+customprofitValue;
      
      let CustomCodfee = 0;
      if(codfeecheck==true){
        CustomCodfee=totalprofitsandcost*codfee;
      }

      let CustomVatfee=0;
      if(vatcheck==true){
        CustomVatfee=vatfee*totalprofitsandcost;
      }
      
      CustomSellingPrice=(totalprofitsandcost+CustomCodfee+CustomVatfee);
      CustomLastProfit=(CustomSellingPrice-allcost-(CustomCodfee+CustomVatfee));

      setCustomprofit(customprofit);
      setCustomSellingPrice(CustomSellingPrice/quantity);
      setCustomLastProfit(CustomLastProfit);

      console.log('check', totalprofitsandcost);
      console.log('CustomVatfee', CustomVatfee);
      console.log('CustomSellingPrice', CustomSellingPrice);

    }
    
    //iายได้และกำไรรวม
    let allincom = sellpriceValue-(codfeeValue+vatValue) //รายได้รวม
    let allprofit = sellpriceValue-(allcost+codfeeValue+vatValue) //กำไรรวม
    let allprofit_percent = (allprofit/sellpriceValue)*100

    // กำหนดค่ากำไรให้กับสถานะ
    setSellPrice(sellprice);
    setProductCost(productcost);
    setOtherCost(othercost);
    setDiscount(discount);
    setShippingValue(shippingValue);
    setQuantity(quantity);

    setCodfeeValue(codfeeValue);
    setVatValue(vatValue);

    setAllIncome(allincom);
    setAllProfit(allprofit);
    setAllProfitPercent(allprofit_percent)

    if (sellprice && productcost) {
      scrollToBottom();
    }
    if (!sellprice || !productcost) {
      scrollToTop();
    }

    //console
    console.log('customcheck', customcheck);
  };

  const reset_result = () => {
    setCustomprofit(0);
    setCustomSellingPrice(0);
    setCustomLastProfit(0);

    setSellPrice(0);
    setProductCost(0);
    setOtherCost(0);
    setDiscount(0);
    setShippingValue(0);
    setQuantity(0);

    setCodfeeValue(0);
    setVatValue(0);

    setAllIncome(0);
    setAllProfit(0);
    setAllProfitPercent(0)
  }
  
  const handleSubmitWithScroll = (values) => {
    handleSubmit(values, true);
    formRef.current.submit();
  };
  const handleSubmitWithoutScroll = (values) => {
    handleSubmit(values, false);
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const scrollToBottom = () => {
    const bottomComponent = document.getElementById('result-component');
    bottomComponent.scrollIntoView({ behavior: 'smooth' });
  };

  const checkScrollPosition = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  
  return (
    <div>
    <div className='flex flex-wrap justify-center'>
      <div
          className={`fixed bottom-20 right-4 bg-white px-4 py-2 rounded-full shadow-lg hover:bg-gray-50 transition-opacity duration-500 aspect-square	 ${
            showScrollButton ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
      > <UpOutlined onClick={scrollToTop} className='text-xl mt-1.5 text-gray-500'/>
      </div>

      <div className="form-container rounded-2xl max-w-max mx-2 grow mb-2 mt-5" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', paddingTop: '15px'}}>
        {id ?
        <div className="text-gray-600">
          <label className="cursor-pointer" onClick={() => navigate(`/detail-product/${id}`)}><LeftOutlined /> ย้อนกลับ</label>
        </div>
        :''}

        <div className='flex justify-between md:block hidden'>
          <label className='top_label text-right'> คำนวณกำไรจากการขายโดยตรง </label>
        </div>

        <div className="flex justify-center">
        <Form
            ref={formRef}
            onFinish={handleSubmitWithoutScroll}
            className='px-[15px] md:pt-[30px] pb-[15px] max-w-[440px]'
            labelCol={{ span: 11 }}
            wrapperCol={{ span: 13}}
            disabled={!user}
          >

          <Form.Item
            className='middle_label '
            label="ราคาขาย"
            name="sellprice"
            rules={[{ required: true, message: 'กรุณากรอกข้อมูล!' }]}
          >
            <InputNumber  min={0} placeholder='0' onChange={onChange}/>
          </Form.Item>  
      
          <Form.Item
            label="ต้นทุนสินค้า"
            name="productcost"
            rules={[{ required: true, message: 'กรุณากรอกข้อมูล!' }]}
          >
            <InputNumber  min={0} placeholder='0'      onChange={onChange}/>
          </Form.Item>

          <Form.Item
            label="ค่าใช้จ่ายอื่นๆ"
            name="othercost"
            tooltip="ค่ากล่อง ค่าแพ็คเกจสินค้า ค่าจ้างพนักงาน และอื่นๆ"
            initialValue={0}
          >
            <InputNumber  min={0} placeholder='0' onChange={onChange}/>

          </Form.Item>

          {/* คำนวณค่าจัดส่ง */}
          {user && (
            <div>
                <Drawer
                  title={small ? "":'คำนวณค่าจัดส่ง'}
                  placement={placement}
                  width={500}
                  onClose={calcushippingClose}
                  closable={small ? false:true}
                  open={calcushipping}
                  extra={<div></div>}
                >
                    <div>
                      <form className="flex flex-col">
                        <label>น้ำหนัก (กิโลกรัม)</label>
                        <input
                            type="number"
                            value={weight}
                            onChange={(e) => handleCalcushippingChange(e, setWeight)}
                            className="border rounded p-2 mb-2"
                            placeholder="KG"
                        />

                        <label>จัดส่งโดย</label>
                        {carriers.length > 0 && (
                        <Listbox value={selectedCarrier} onChange={setSelectedCarrier}>
                          {({ open }) => (
                            <>
                              <Listbox.Button className="flex border rounded p-2 max-w-44 text-ellipsis overflow-hidden">
                                <img src={getImageCarrierUrl(carriers.find(carrier => carrier._id === selectedCarrier)?.imageUrls)} alt="img_carrier" className='w-12 mr-1.5 rounded' />
                                <p className='inline-block pt-1'>{carriers.find(carrier => carrier._id === selectedCarrier)?.name || "เลือกผู้ให้บริการขนส่ง"}</p>                       
                              </Listbox.Button>
                              <Transition
                                show={open}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute py-1 overflow-auto min-w-44 text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  {carriers.map(carrier => (
                                    <Listbox.Option key={carrier._id} value={carrier._id} className="flex cursor-pointer p-1 px-2 hover:bg-indigo-700 hover:text-white">
                                      <img src={getImageCarrierUrl(carrier.imageUrls[0])} alt="img_carrier" className='w-12 h-12 mr-1.5 rounded' />
                                      <p className='inline-block pt-1'>{carrier.name}</p>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </>
                          )}
                        </Listbox>
                        )}

                        <p className='font-bold text-xl mt-2'>ค่าส่ง: {shippingCost} บาท</p>
                        {ShippingError &&
                            <p className='font-bold text-xl text-red-500'>น้ำหนักเกิน!</p>
                        }
                        <button type="button" onClick={calcushippingClose} className="bg-red-500 text-white py-2 px-4 mt-4 hover:bg-red-600 rounded hidden">
                            ปิด
                        </button>
                        </form>
                    </div>
                </Drawer>
            </div>

         
          )}
          
          <div className="flex justify-center" style={{ display: 'flex', alignItems: 'center' }}>
          
          {user ? 
            <label 
              onClick={calcushippingOpen} 
              className="rounded-lg p-1.5 text-md mr-3 whitespace-nowrap text-white bg-indigo-500 hover:bg-indigo-800 cursor-pointer" 
            >

              คำนวณค่าส่ง
            </label>
            : 
            <label 
              className="rounded-lg p-1.5 text-md mr-3 whitespace-nowrap text-white bg-indigo-500 cursor-not-allowed" 
            >

              คำนวณค่าส่ง
            </label>
            }

            <Form.Item
              label="ค่าจัดส่ง"
              name="shipping"
              initialValue={45}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18}}
            >
              <InputNumber  min={0}  placeholder='0' onChange={onChange}/>
            </Form.Item>
          </div>

          <Form.Item
            label="จำนวน"
            name="quantity"
            tooltip="กรณีที่ต้องการคำนวณกำไรที่ได้จากการขายสินค้าหลายชิ้น"
            initialValue={1} 
          >
            <InputNumber  min={1} placeholder='0' onChange={onChange}/>

          </Form.Item>
      
          <Form.Item
            label="ส่วนลดให้ลูกค้า"
            name="discount"
            tooltip="ส่วนลดที่ทางร้านออกให้ลูกค้าเอง (โคดส่วนลด/โปรโมชั่นส่วนลด)"
            initialValue={0}
          >
            <InputNumber  min={0} placeholder='0' defaultValue={''} onChange={onChange}/>
          </Form.Item>


          <div className="flex flex-row pb-2">
              <p className="line-through 	w-full h-px bg-gray-300 mt-4"></p>
          </div>

          <div className='px-8'>
            <Form.Item
              name='codfeecheck'
              valuePropName="checked"
              label="ชำระเงินปลายทาง"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 10}}
              tooltip="อัตราค่าธรรมเนียมการชำระเงินปลายทางอยู่ที่ 3%"
              >
                <Switch
                    style={{ width: '45px' }}
                    onChange={onChange}     
                >
                  ชำระเงินปลายทาง
                </Switch>
            </Form.Item>

            <Form.Item
              name='vatcheck'
              valuePropName="checked"
              label="คำนวณ VAT 7%"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 10}}
              >
                <Switch
                    style={{ width: '45px' }}
                    onChange={onChange}     
                >
                  คำนวณ VAT 7%
                </Switch>
            </Form.Item>

            <Form.Item
              name='customcheck'
              valuePropName="checked"
              label="คำนวณกำไรที่ต้องการ"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 10}}
              >
                <Switch
                    style={{ width: '45px' }}
                    checked={checked}
                    onChange={toggleChecked}
                >
                    คำนวณกำไรที่ต้องการ
                </Switch>
            </Form.Item>
          </div>

          {checked?
              <Form.Item
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 10}}
                  label="เปอร์เซ็นต์กำไร"
                  name='customprofit'
                  initialValue={0}
                  tooltip="จำนวนเปอร์เซ็นกำไรที่ต้องการ คิดจากต้นทุนสินค้า (10=10% , 40=40%)"
                  >
                    <InputNumber min={0} placeholder='%' onChange={onChange}/>

              </Form.Item>
             : null}
            
          {/* ปุ่มsubmit mini */}
          <div
            className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen pt-3 pb-3 px-3'
            style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
          >
            <div className='grid grid-cols-3 gap-3'>
              <button 
                type="button"
                onClick={() => {  
                  handleSubmitWithScroll(formRef.current.getFieldsValue());
                  scrollToBottom();
                }}
                className={`text-xl px-6 py-2 rounded-md col-span-2 ${
                  user && !disabled_button
                    ? 'bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-800 text-white cursor-pointer'
                    : 'bg-gray-300 text-gray-500 cursor-auto'
                }`}                        
                disabled={!user || disabled_button}
                    
                > 
                  คำนวณ
              </button>

              <Button 
                danger 
                className='h-full text-lg'
                onClick={() => {
                  formRef.current.resetFields();
                  reset_result();
                  if (checked) {
                    toggleChecked(false);
                  }
                  scrollToTop();
                  setDisabledButton(false);
                }}
              >
                เริ่มใหม่
              </Button>
            </div>
          </div>
        </Form>
      </div>
      </div>

      <div id="result-component" className="bg-white min-w-80 md:max-w-max max-h-fit rounded-2xl md:ml-6 mx-2 mb-2 md:mt-5 mt-4" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
        {/* ปุ่มsubmit */}
        <div className='hidden md:block px-3 pt-3'>
          <div class="grid grid-cols-3 gap-2 no-wrap">
            <button 
              type="button"
              className={`text-xl px-6 py-2 rounded-md col-span-2 ${
                user && !disabled_button
                  ? 'bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-800 text-white cursor-pointer'
                  : 'bg-gray-300 text-gray-500 cursor-auto'
              }`}          
              onClick={() => formRef.current.submit()} // เรียก form.submit()
              disabled={!user || disabled_button}
            > 
              คำนวณ
            </button>

            <Button 
              className='h-full text-lg'
              danger 
              onClick={() => {
                formRef.current.resetFields();
                if (checked) {
                  toggleChecked(false);
                }
                setDisabledButton(false);
                reset_result();
              }}
            >
              เริ่มใหม่
            </Button>
          </div>
        </div>
        <div className='rounded-2xl px-5 pb-5 grow md:mt-3 pt-[15px] border'>
          <h2 className='text-center text-xl underline decoration-wavy	'>ผลลัพธ์</h2>
          
          <div className='flex-col mid_label' >

            <div className='mt-5'>
              <label className=''>ราคาขาย : </label>
              <span>{sellprice ? sellprice.toFixed(2) : '0.00'} บาท</span>
            </div>

            <div>
              <label className='yellow'>ต้นทุนสินค้า : </label>
              <span>{productcost ? productcost.toFixed(2) : '0.00'} บาท</span>
            </div>

            <div>
              <label className='yellow'>ค่าใช้จ่ายอื่นๆ : </label>
              <span>{othercost.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='yellow'>ส่วนลดให้ลูกค้า : </label>
              <span>{discount.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='yellow'>ค่าจัดส่งที่ร้านจ่าย : </label>
              <span>{shippingValue.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className=''>จำนวน : </label>
              <span>{quantity.toFixed(0)} ชิ้น</span>
            </div>

            <div className="flex flex-row pb-2">
                <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
            </div>

            <div>
              <label className='yellow'>ค่าธรรมเนียม : </label>
              <span>{codfeeValue.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='yellow'>หักค่า Vat7% : </label>
              <span>{vatValue.toFixed(2)} บาท</span>
            </div>

            <div className="flex flex-row pb-2">
                <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
            </div>

            <div>
              <label className='darkgreen'>รวมยอดที่ร้านจะได้รับ : </label>
              <span>{allincom.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='green'>รวมกำไรที่จะได้รับ : </label>
              <span>{allprofit.toFixed(2)} บาท</span>
              <span className='text-sm'> ({allprofit_percent.toFixed(2)}%)</span>
            </div>


          {checked == true ?
          <>
            <div className="flex flex-row pb-2">
                <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
            </div>

            <div>
              <label className='blue'>หากต้องการกำไรสินค้า </label>
              <span>{customprofit.toFixed(2)} %</span>
            </div>

            <div>
              <label>ราคาที่ควรขายคือ </label>
              <span>{CustomSellingPrice.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='green'>จะได้กำไรประมาณ </label>
              <span>{CustomLastProfit.toFixed(2)} บาท</span>            
            </div>
          </>
            : ''}

          </div>

        </div>
      </div>
    </div>
    </div>

  );

  
}
