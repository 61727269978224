import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Sidebar, Nav , NavNoUser } from "../../components/Nav.jsx";
import axios from 'axios';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Spin} from 'antd';
import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded';
import { LeftOutlined } from '@ant-design/icons';
import './styles/AccountPage.css'
import { calculateRemainingUsage , calculateExpirationDate , calculateNewExpirationDate } from '../script.jsx';
import { LoadingOutlined } from "@ant-design/icons";

function ChangePackage(){
    const { user } = useUserAuth();
    const token = localStorage.getItem('token');
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // สถานะการโหลด
    const [Disable, setDisable] = useState(true); // สถานะการโหลด
    const now = new Date();

    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedtype, setSelectedType] = useState('');
    const [activeMobileView, setActiveMobileView] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

    //ดึงข้อมูลผู้ใช้ขึ้นมาแสดง
    const fetchUser = async () => {
      if (user && user._id) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/user/id/${user._id}`);
            setUserData(response.data);

            if(response.data.length === 0){
              setData(false)
            }

          } catch (err) {
            console.error('Error fetching user:', err);
          } finally {
            setLoading(false)
          }
      }
    };

    // ส่งข้อมูลไป Backend เมื่อกดยืนยัน
    const handleSubmit = async () => {
      try {
        setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

        // แปลงแพ็กเกจที่เลือกเป็นจำนวนวัน
        const planDays = selectedPlan === "30d" ? 30 : 365;
        let price = 0

        if(userData.membershipType === "silver" && selectedPlan === "30d"){
          price = 99
        }else if(userData.membershipType === "silver" && selectedPlan === "365d"){
          price = 1000
        }else if(userData.membershipType === "gold" && selectedPlan === "30d"){
          price = 199
        }else if(userData.membershipType === "gol" && selectedPlan === "365d"){
          price = 2000
        }else if(userData.membershipType === "platinum" && selectedPlan === "30d"){
          price = 699
        }else if(userData.membershipType === "platinum" && selectedPlan === "365d"){
          price = 7000
        }else{
          price = null
          console.log("เกิดข้อผิดพลาดในการกำหนดราคา")
        }

        // คำนวณวันหมดอายุใหม่
        const newDate = calculateNewExpirationDate(userData.membershipType,selectedtype,userData.membershipExpirationDate, planDays);

        console.log(newDate)

      } catch (error) {
          console.error('Error extending membership:', error);
      } finally {
        setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
      }
  };

    useEffect(() => {
      fetchUser();
      if (!user) {
        navigate('/homepage');
      }
    }, [user, navigate]); 
    
    useEffect(() => {
      if (userData && userData.membershipType) {
        setSelectedType(userData.membershipType);
        setActiveMobileView(userData.membershipType)
      }
    }, [userData]);

    useEffect(() => {
      // Enable the button only when a plan is selected AND the selected type is DIFFERENT from current type
      if(selectedPlan && selectedtype !== userData.membershipType){
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [selectedPlan, selectedtype, userData]); 

    if (user && loading) {
      return (
        <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin  fullscreen size="large" /></div>
        </div>
      </>
    );
    }

    return(
        <>
        <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
          <Link to="../account" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
            <LeftOutlined />
          </Link>
          <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">เปลี่ยนประเภทสมาชิก</h2>
        </div>
        
        <div className='flex md:justify-between mt-[53px] md:mt-2'>
          <div></div>

          <div className='grow md:grow-0 overflow-hidden overflow-x-auto'>
            <div className='md:mx-4 mb-10'>
                <div className='mt-3 ml-4 md:block hidden'>
                  <Link to="../account" className='text-lg text-gray-600' > <LeftOutlined /> ย้อนกลับ</Link>
                </div>

                <div className='mt-6 mb-4 ml-2 flex flex-col md:block hidden'>
                  <label className='text-4xl font-bold'>เปลี่ยนประเภทสมาชิก</label>
                </div>

                {/* top */}
                <div className="flex flex-col md:rounded-t-lg md:pb-12 pb-10 select-none custom-gradient">
                  
                  <div className='w-full text-center text-white text-3xl md:my-7 my-5'>
                    เลือกแพ็คเก็จ
                  </div>

                  {/* Top pricing cards */}
                  <div className="pb-6 md:px-6 px-4 flex flex-col md:flex-row justify-center gap-4 ">
                    {/* Silver */}
                    <div 
                      className={`bg-white rounded-lg shadow-lg p-6 w-full md:w-72 cursor-pointer  ${
                        selectedtype === "silver" ? "outline outline-[4px] outline-blue-300 outline-offset-4 " : ""
                      }`}
                      onClick={() => setSelectedType("silver")}
                    >
                      <div className="font-medium mb-2"><p className=' text-white rounded-lg px-4 py-2 md:text-xl bg-gradient-to-r from-slate-400 to-slate-200 shadow select-none'><DiamondRoundedIcon /> SILVER</p></div>
                      <div className="text-sm text-gray-500 mb-4">สำหรับผู้เริ่มต้น</div>
                      
                      <div className="flex items-baseline mb-6">
                        <span className="text-4xl font-bold">฿99</span>
                        <span className="text-sm text-gray-500 ml-2">ต่อเดือน</span>
                      </div>
                      
                      <div className="mb-2 text-sm">คุณสมบัติ:</div>
                      <ul className="space-y-2">
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          สร้างร้านค้าได้ 1 ร้าน
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ธีมร้านค้าให้เลือก 5 แบบ (classic)
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ลงสินค้าได้สูงสุด 100 รายการ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ระบบออกใบเสร็จรับเงิน
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ระบบคำนวณค่าธรรมเนียมจากขายสินค้าในแพลตฟอร์มต่าง ๆ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ฝ่ายสนับสนุนพร้อมให้ความช่วยเหลือในการใช้งาน
                        </li>
                      </ul>
                      {userData.membershipType === "silver" ? <p className='mt-4 text-center text-blue-500'>*แพ็คเก็จปัจจุบัน</p> : ''}
                    </div>
                    
                    {/* Gold */}
                    <div 
                      className={`bg-white rounded-lg shadow-lg p-6 w-full md:w-72 cursor-pointer ${
                        selectedtype === "gold" ? "outline outline-[4px] outline-blue-300 outline-offset-4 " : ""
                      }`}
                      onClick={() => setSelectedType("gold")}
                    >
                      <div className="font-medium mb-2"><p className=' text-white rounded-lg px-4 py-2 md:text-xl bg-gradient-to-r from-amber-400 to-amber-200 shadow select-none'><DiamondRoundedIcon /> GOLD</p></div>
                      <div className="text-sm text-gray-500 mb-4">คุ้มค่าที่สุด (แนะนำ)</div>
                      
                      <div className="flex items-baseline mb-6">
                        <span className="text-4xl font-bold">฿199</span>
                        <span className="text-sm text-gray-500 ml-2">ต่อเดือน</span>
                      </div>
                      
                      <div className="mb-2 text-sm">คุณสมบัติ:</div>
                      <ul className="space-y-2">
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          สร้างร้านค้าได้สูงสุด 3 ร้าน
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ธีมร้านค้าให้เลือก 10 แบบ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ลงสินค้าได้สูงสุด 1,000 รายการ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ระบบออกใบเสร็จรับเงิน / ใบเสนอราคา / ใบแจ้งหนี้
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ระบบคำนวณค่าธรรมเนียมจากขายสินค้าในแพลตฟอร์มต่าง ๆ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ฝ่ายสนับสนุนพร้อมให้ความช่วยเหลือในการใช้งาน
                        </li>
                      </ul>
                      {userData.membershipType === "gold" ? <p className='mt-4 text-center text-blue-500'>*แพ็คเก็จปัจจุบัน</p> : ''}
                    </div>
                    
                    {/* Plat */}
                    <div 
                      className={`bg-white rounded-lg rounded-b-xl shadow-lg p-6 w-full md:w-72 cursor-pointer ${
                        selectedtype === "platinum" ? "outline outline-[4px] outline-blue-300 outline-offset-4 " : ""
                      }`}
                      onClick={() => setSelectedType("platinum")}
                    >
                      <div className="font-medium mb-2"><p className=' text-white rounded-lg px-3 py-2 md:text-xl bg-gradient-to-r from-blue-400 to-blue-200 shadow select-none'><DiamondRoundedIcon /> PLATINUM</p></div>
                      <div className="text-sm text-gray-500 mb-4">สำหรับธุรกิจขนาดใหญ่</div>
                      
                      <div className="flex items-baseline mb-6">
                        <span className="text-4xl font-bold">฿699</span>
                        <span className="text-sm text-gray-500 ml-2">ต่อเดือน</span>
                      </div>
                      
                      <div className="mb-2 text-sm">คุณสมบัติ:</div>
                      <ul className="space-y-2">
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          สร้างร้านค้าได้สูงสุด 8 ร้าน
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ใช้งานได้ทุกธีมร้านค้า (20แบบ)
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ลงสินค้าได้สูงสุด 10,000 รายการ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ระบบออกใบเสร็จรับเงิน / ใบเสนอราคา / ใบแจ้งหนี้
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ระบบคำนวณค่าธรรมเนียมจากขายสินค้าในแพลตฟอร์มต่าง ๆ
                        </li>
                        <li className="flex items-center text-sm text-gray-600">
                          <div className="w-2 h-2 bg-gray-300 rounded-full mr-2"></div>
                          ฝ่ายสนับสนุนพร้อมให้ความช่วยเหลือในการใช้งาน
                        </li>
                      </ul>
                      {userData.membershipType === "platinum" ? <p className='mt-4 text-center text-blue-500'>*แพ็คเก็จปัจจุบัน</p> : ''}
                    </div>
                  </div>
                </div>

                {/* Detailed Comparison Table */}
                <div className="bg-white md:rounded-b-lg py-6 md:mx-0">
                  <div className="max-w-4xl md:mx-8">
                    {/* Table Header */}
                    <div className="grid md:grid-cols-4 grid-cols-3 gap-4 mb-4 text-center px-4">
                      <div className="text-left font-medium md:block hidden"></div>
                      <div 
                        className={`font-medium md:cursor-default cursor-pointer ${
                          activeMobileView === "silver" 
                            ? "text-slate-600 border-b-2 border-slate-600 md:border-b-0" 
                            : "text-slate-400 md:text-slate-600"
                        }`}
                        onClick={() => setActiveMobileView("silver")}
                      >
                        Silver
                      </div>
                      <div 
                        className={`font-medium md:cursor-default cursor-pointer ${
                          activeMobileView === "gold" 
                            ? "text-amber-600 border-b-2 border-amber-600 md:border-b-0" 
                            : "text-slate-400 md:text-amber-600"
                        }`}
                        onClick={() => setActiveMobileView("gold")}
                      >
                        Gold
                      </div>
                      <div 
                        className={`font-medium md:cursor-default cursor-pointer ${
                          activeMobileView === "platinum" 
                            ? "text-blue-600 border-b-2 border-blue-600 md:border-b-0" 
                            : "text-slate-400 md:text-blue-600"
                        }`}
                        onClick={() => setActiveMobileView("platinum")}
                      >
                        Platinum
                      </div>
                    </div>
                                          
                      {/* ส่วนที่ 1 */}
                    <div className="py-4">
                      <div className="font-semibold mb-4 bg-slate-50 p-2 px-4 md:rounded-lg">ร้านค้า</div>

                      <div className='mx-4'>
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ร้านค้าที่สร้างได้</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>1</div>
                          <div className={`text-center ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>3</div>
                          <div className={`text-center ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>10</div>
                        </div>
                          
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ธีมร้านค้า</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>5</div>
                          <div className={`text-center ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>8</div>
                          <div className={`text-center ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>ทั้งหมด (20แบบ)</div>
                        </div>
                          
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">จำนวนสินค้าสูงสุด</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>100</div>
                          <div className={`text-center ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>1,000</div>
                          <div className={`text-center ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>10,000</div>
                        </div>
                          
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ตัวเลือกสินค้าสูงสุด (ต่อหนึ่งรายการสินค้า)</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>10</div>
                          <div className={`text-center ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>20</div>
                          <div className={`text-center ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>50</div>
                        </div>

                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ระบบส่งเสริมการขาย (โปรโมชั่นพิเศษ) *อยู่ระหว่างการพัฒนา</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>—</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>

                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2">
                          <div className="text-sm">ระบบสนับสนุนลูกค้าผ่าน Live Chat *อยู่ระหว่างการพัฒนา</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>—</div>
                          <div className={`text-center ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>—</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>
                      </div>
                    </div>
                      
                    {/* ส่วนที่ 2 */}
                    <div className="py-4">
                      <div className="font-semibold mb-4 bg-slate-50 p-2 px-4 md:rounded-lg">ฟังก์ชันเสริม</div>

                      <div className='mx-4'>
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ระบบคำนวณค่าธรรมเนียมจากขายสินค้าในแพลตฟอร์มต่าง ๆ</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>
                          
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ระบบออกใบเสร็จรับเงิน</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>
                        
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                          <div className="text-sm">ระบบออกใบเสนอราคา</div>
                          <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>—</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>
                          
                          <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                            <div className="text-sm">ระบบออกใบสั่งซื้อ</div>
                            <div className={`text-center ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>—</div>
                            <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                            <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                          </div>

                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2">
                          <div className="text-sm">ระบบคำนวณค่าจัดส่ง</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>
                      </div>

                    </div>
                      
                      {/* ส่วนที่ 3 */}
                    <div className="py-4">
                      <div className="font-semibold mb-4 bg-slate-50 p-2 px-4 md:rounded-lg">การสนับสนุน</div>
                        <div className='mx-4'>
                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2 border-b border-gray-100">
                        <div className="text-sm">ฝ่ายสนับสนุนดูแลระหว่างการใช้งาน</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>✓</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>

                        <div className="grid md:grid-cols-4 grid-cols-2 gap-4 py-2">
                          <div className="text-sm">ปรับเปลี่ยนธีมเว็บไซต์ตามแบบของลูกค้า</div>
                          <div className={`text-center  ${activeMobileView === "silver" ? "block" : "hidden md:block"}`}>—</div>
                          <div className={`text-center  ${activeMobileView === "gold" ? "block" : "hidden md:block"}`}>—</div>
                          <div className={`text-center text-green-500 ${activeMobileView === "platinum" ? "block" : "hidden md:block"}`}>✓</div>
                        </div>
                      </div>
                    </div>

                    </div>
                  </div>

                {/* last */}
                <div className='bg-white md:mx-0 mx-2 md:mt-2 rounded-lg md:max-w-full border'>
                  
                  <div className='p-6 pb-0'>
                    <div className='flex mb-4'>
                        <label className='text-lg md:text-xl'>เลือกอายุการใช้งาน</label>
                    </div>

                      {/* Select Plan */}
                      <div className="mt-4">
                        <div className="mt-2 flex flex-col gap-4 select-none">

                          <div
                            className={`p-4 border-4 rounded-lg cursor-pointer flex justify-between ${
                              selectedPlan === "30d" ? "border-blue-500 " : "border-gray-300"
                            }`}
                            onClick={() => setSelectedPlan("30d")}
                          >
                            <p className="text-lg font-semibold md:hidden">30 วัน</p>
                            <p className="text-lg font-semibold hidden md:block">(หนึ่งเดือน) 30 วัน</p>
                            <p className="text-lg font-semibold ml-2">
                              {selectedtype === 'silver' ? '99 บาท' :
                              selectedtype === 'gold' ? '199 บาท' :
                              selectedtype === 'platinum' ? '699 บาท' : '99 บาท'}
                            </p>
                          </div>

                          <div
                            className={`p-4 border-4 rounded-lg cursor-pointer flex justify-between ${
                              selectedPlan === "365d" ? "border-blue-500 " : "border-gray-300"
                            }`}
                            onClick={() => setSelectedPlan("365d")}
                          >
                            <p className="text-lg font-semibold md:hidden">1 ปี</p>
                            <p className="text-lg font-semibold hidden md:block">(หนึ่งปี) 365 วัน</p>
                            <div className='flex'>
                              <p className="">
                                {selectedtype === 'silver' ? '(ประหยัด 188 บาท)' :
                                selectedtype === 'gold' ? '(ประหยัด 388 บาท)' :
                                selectedtype === 'platinum' ? '(ประหยัด 1388 บาท)' : '(ประหยัด 188 บาท)'}
                              </p>

                              {/* กำหนดราคาให้ตรงกับประเภทสมาชิก */}
                              <p className="text-lg font-semibold ml-2">
                                {selectedtype === 'silver' ? '1000 บาท' :
                                selectedtype === 'gold' ? '2000 บาท' :
                                selectedtype === 'platinum' ? '7000 บาท' : '1000 บาท'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    <div className='border mt-4'></div>
                  </div>

                    {/* แสดงวันที่หมดอายุใหม่ */}
                    <div className='mt-2 text-center'>
                      <label className='flex justify-center text-md md:text-lg text-gray-700 gap-2'>
                        ใช้ได้จนถึง วันที่  
                        {new Date(userData.membershipExpirationDate) > now? 
                          <div>
                            {calculateExpirationDate(userData.membershipType, selectedtype, userData.membershipExpirationDate, selectedPlan)}
                          </div>
                          : 
                          " -"
                        }
                      </label>
                    </div>

                    <div className='flex gap-2 p-2'>
                      <div className='flex-1'>
                        <button
                          className={`p-4 py-2 text-white rounded-b w-full h-full transition ${
                            !Disable
                              ? "bg-blue-400 hover:bg-blue-500/90 active:bg-blue-500"
                              : "bg-gray-300 opacity-50"
                          }`}
                          onClick={handleSubmit}
                          disabled={Disable || isSubmitting}
                        >
                          {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "ยืนยัน"}
                        </button>
                      </div>
                    </div>
                    
                </div>
                
            </div>
          </div>

          <div></div>
        </div>

        
      </>
    );
}

export default ChangePackage;