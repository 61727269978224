import React, { useState , useEffect } from "react";
import axios from 'axios';
import { useUserAuth } from '../context/UserContext.jsx';
import { Nav,NavNoUser } from '../components/Nav.jsx';
import { Sidebar } from "./nav.jsx";
import { Form, InputNumber, Select , message, FloatButton , Input, Tooltip , Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

function SettingCalcu() {
    const { user } = useUserAuth();
    const [formType, setFormType] = useState("tiktok");
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [commissionData, setCommissionData] = useState([]);

    useEffect(() => {
        const fetchFee = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/fee/${formType}/all`);
                const allData = response.data;
        
                // กรองเฉพาะค่าธรรมเนียมที่เกี่ยวข้อง
                const relevantFees = allData.filter(item =>
                    ["tiktok_commission", "shopee_commission", "lazada_commission", 
                     "tiktok_transaction", "shopee_transaction", "lazada_transaction",
                     "tiktok_campaign",
                    ].includes(item.name)
                ).sort((a, b) => a.no - b.no);
        
                setCommissionData(relevantFees);
        
                // กำหนดค่าเริ่มต้นให้กับฟอร์ม
                const initialValues = {};
                relevantFees.forEach(item => {
                    initialValues[item._id] = { id: item._id, value: parseFloat(item.volume) };
                });
        
                form1.setFieldsValue(initialValues);  // กำหนดค่าเริ่มต้นให้ฟอร์ม1
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };
        fetchFee();



        const fetchCalcu = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/calcu/All`);
                const calcuData = response.data;

                const initialValues = {};
                calcuData.forEach(item => {
                    initialValues[item.name] = { id: item._id, value: item.value };
                });

                form2.setFieldsValue(initialValues);
            } catch (err) {
                console.error('Error fetching products:', err);
            }
        };
        fetchCalcu();

    }, [form,form1,form2,formType]);

    const onSubmit = async (values) => {
        try {
            const updates = Object.entries(values).map(([key, value]) => (
                {
                name: key,
                value: value.value
                }
            ));

            //console.log(updates)

            const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/calcu/bulk-update`, updates);
            message.success('Data updated successfully');
        } catch (err) {
            console.error('Error updating data:', err);
            message.error('Failed to update data');
        }
    };

    const onSubmitFee = async (values) => {
        try {
            const updates = Object.entries(values).map(([id, value]) => ({
                id, // ใช้ _id ที่ได้จากฐานข้อมูล
                volume: value.value,
            }));

            console.log("Data sent to backend:", updates); // เช็คค่าก่อนส่ง


            await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/fee/${formType}/bulk-update`, updates);
            message.success("Data updated successfully");
        } catch (err) {
            console.error("Error updating data:", err);
            message.error("Failed to update data");
        }
    };

    
        return (
            <>
                {user ? <Nav /> : (<NavNoUser />)}
                
                {user? 
                <div>
                    <a href="/chat" target="_blank" rel="noopener noreferrer">
                    <FloatButton 
                    tooltip="เปิดหน้าแชท" 
                    type="primary"
                    icon={<ChatBubbleOvalLeftEllipsisIcon />} 
                    className='mr-6 scale-150'
                    />
                    </a>
                </div>
                : ''}

        <div className='flex justify-between'>

            <div>
            <Sidebar />
            </div>

            <div className='mt-4'>
                <div>
                    <div>
                        <label className="text-2xl pl-6">โปรแกรมคำนวณค่าธรรมเนียม</label>
                    </div>
                    
                    <div className="flex flex-col ml-5">
                        <div className="flex justify-center ">
                            <Select 
                                placeholder="กรุณาเลือกประเภท"
                                style={{width:200}}
                                onChange={(value) => setFormType(value)}
                                className="mt-3 "
                                defaultValue="tiktok"

                            >
                                <Select.Option value="tiktok">Tiktok</Select.Option>
                                <Select.Option value="lazada">Lazada</Select.Option>
                                <Select.Option value="shopee">Shopee</Select.Option>
                            </Select>
                        </div>
                        {(formType === "tiktok" || !formType) && (
                            <>
                                <Form 
                                    onFinish={onSubmitFee}
                                    layout="horizontal"
                                    form={form1}
                                >
                                <div className="bg-white rounded-lg mt-2 mb-6">
                                    <div className="">
                                        <label className="flex justify-center text-lg pt-4" tooltip="Shipping Fee Program">
                                            ค่าธรรมเนียม <Tooltip title="0-1 เช่น 0.05=5%"><QuestionCircleOutlined className="ml-1 text-gray-400"/></Tooltip>
                                        </label>
                                    </div>
                                    <div className='bg-white md:px-6 px-1 py-4 rounded-lg'>
                                        <div className="p-1 border rounded-md pt-2">
                                            {/* ค่าคอมมิชชัน (เฉพาะ commission) */}                                                    
                                            <label className="pl-2 text-base">ค่าคอมมิชชัน (ประเภทสินค้า)</label>

                                            {commissionData.some(item => item.name === "tiktok_commission" && item.market === "none") && (
                                                <div className="border rounded-md p-2">
                                                    <label className="pl-2 text-base">ร้านค้าปกติ</label>
                                                    {commissionData.filter(item => item.name === "tiktok_commission" && item.market === "none").map((fee, index) => (
                                                        <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                            <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                        </Form.Item>
                                                    ))}
                                                </div>
                                            )}
                                            {commissionData.some(item => item.name === "tiktok_commission" && item.market === "mall") && (
                                                <div className="border rounded-md p-2 my-2">
                                                    <label className="pl-2 text-base">Tiktok Mall</label>
                                                    {commissionData.filter(item => item.name === "tiktok_commission" && item.market === "mall").map((fee, index) => (
                                                        <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                            <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                        </Form.Item>
                                                    ))}
                                                </div>
                                            )}

                                            <div className="border-y-2 pt-6 pb-0">
                                                {/* ค่าธรรมเนียมธุรกรรม (เฉพาะ transaction) */}
                                                {commissionData.some(item => item.name.includes("tiktok_transaction")) && (
                                                    <div>
                                                        {commissionData.filter(item => item.name.includes("tiktok_transaction")).map((fee, index) => (
                                                            <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                                <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                            </Form.Item>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            {/* ค่าธรรมเนียมธุรกรรม (เฉพาะ transaction) */}
                                            {commissionData.some(item => item.name.includes("tiktok_campaign")) && (
                                                <div>
                                                    {commissionData.filter(item => item.name.includes("tiktok_campaign")).map((fee, index) => (
                                                        <Form.Item 
                                                            key={fee._id} 
                                                            name={[fee._id, 'value']} 
                                                            className="pt-4"
                                                            label=">"
                                                            tooltip="Shipping Fee Program"
                                                        >
                                                            <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                        </Form.Item>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <Form.Item>
                                            <div className=' mt-4'>
                                                <button type="submit" className='bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition px-6 py-1 w-full text-2xl rounded-md text-white'>
                                                    บันทึก
                                                </button>
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                                </Form>

                                <Form 
                                    onFinish={onSubmit}
                                    layout="horizontal"
                                    form={form2}
                                >
                                    <div className="bg-white rounded-lg mt-2 mb-60">
                                        <div className="mt-2">
                                            <label className="flex justify-center text-lg pt-2">อัตราค่าจัดส่ง</label>
                                        </div>
                                        <div className='bg-white md:px-6 px-1  py-4 rounded-lg'>
                                            <div className="p-1 border rounded-md pt-2">
                                                <div className="flex">
                                                    <div><p className="text-green-600">ต้นทาง : </p></div>
                                                    <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p> </div>
                                                </div>
                                                <div className="flex pb-1">
                                                    <div><p className="text-orange-600">ปลายทาง : </p></div>
                                                    <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p></div>
                                                </div>
                                                <Form.Item name={['shipping2_2_1', 'value']}>
                                                <InputNumber addonBefore="น้อยกว่า 1 kg" size="large"/>
                                                </Form.Item>
                                                <Form.Item name={['shipping2_2_1-2', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 1-2 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_2_2-3', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 2-3 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_2_3-5', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 3-5 kg"  size="large"/>
                                                </Form.Item>
                                                <Form.Item name={['shipping2_2_5-10', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 5-10 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_2_10-15', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 10-15 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_2_15', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 15 kg" size="large" />
                                                </Form.Item>
                                            </div>

                                            <div className="p-1 border rounded-md pt-2 mt-4">
                                                <div className="flex">
                                                    <div><p className="text-green-600">ต้นทาง : </p></div>
                                                    <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p> </div>
                                                </div>
                                                <div className="flex pb-1">
                                                    <div><p className="text-orange-600">ปลายทาง : </p></div>
                                                    <div><p className="pl-1"> ต่างจังหวัด </p></div>
                                                </div>
                                                <Form.Item name={['shipping2_1_1', 'value']}>
                                                <InputNumber addonBefore="น้อยกว่า 1 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_1_1-2', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 1-2 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_1_2-3', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 2-3 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_1_3-5', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 3-5 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_1_5-10', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 5-10 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_1_10-15', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 10-15 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping2_1_15', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 15 kg" size="large" />
                                                </Form.Item>
                                            </div>

                                            <div className="p-1 border rounded-md pt-2 mt-4">
                                                <div className="flex">
                                                    <div><p className="text-green-600">ต้นทาง : </p></div>
                                                    <div><p className="pl-1"> ต่างจังหวัด </p> </div>
                                                </div>
                                                <div className="flex pb-1">
                                                    <div><p className="text-orange-600">ปลายทาง : </p></div>
                                                    <div><p className="pl-1"> ต่างจังหวัด/กรุงเทพฯและปริมณฑล </p></div>
                                                </div>
                                                <Form.Item name={['shipping1_1_1', 'value']}>
                                                <InputNumber addonBefore="น้อยกว่า 1 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping1_1_1-2', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 1-2 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping1_1_2-3', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 2-3 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping1_1_3-5', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 3-5 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping1_1_5-10', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 5-10 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping1_1_10-15', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 10-15 kg" size="large" />
                                                </Form.Item>
                                                <Form.Item name={['shipping1_1_15', 'value']}>
                                                <InputNumber addonBefore="มากกว่า 15 kg" size="large" />
                                                </Form.Item>
                                            </div>

                                            <Form.Item>
                                                <div className='flex justify-end mt-4'>
                                                    <button htmlType="submit" className='bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition px-6 py-1 rounded-md text-white'>
                                                        บันทึก
                                                    </button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                        {formType === "lazada" && (
                            <>
                            <div className="mb-6">
                            <Form 
                                onFinish={onSubmitFee}
                                layout="horizontal"
                                form={form1}
                            >
                            <div className="bg-white rounded-lg mt-2">
                                <div className="">
                                    <label className="flex justify-center text-lg pt-4" tooltip="Shipping Fee Program">
                                        ค่าธรรมเนียม <Tooltip title="0-1 เช่น 0.05=5%"><QuestionCircleOutlined className="ml-1 text-gray-400"/></Tooltip>
                                    </label>
                                </div>
                                <div className='bg-white md:px-6 px-1 py-4 rounded-lg'>
                                    <div className="p-1 border rounded-md pt-2">

                                        <label className="pl-2 text-base" >ค่าธรรมเนียมคำสั่งซื้อ (ประเภทสินค้า)</label>

                                        {commissionData.some(item => item.name === "lazada_commission" && item.market === "none") && (
                                            <div className="border rounded-md p-2">
                                                <label className="pl-2 text-base">ร้านค้าปกติ</label>
                                                {commissionData.filter(item => item.name === "lazada_commission" && item.market === "none").map((fee, index) => (
                                                    <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                        <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                    </Form.Item>
                                                ))}
                                            </div>
                                        )}
                                        {commissionData.some(item => item.name === "lazada_commission" && item.market === "mall") && (
                                            <div className="border rounded-md p-2 my-2">
                                                <label className="pl-2 text-base">Laz Mall</label>
                                                {commissionData.filter(item => item.name === "lazada_commission" && item.market === "mall").map((fee, index) => (
                                                    <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                        <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                    </Form.Item>
                                                ))}
                                            </div>
                                        )}
                                        

                                        <div className="border-y-2 pt-6 pb-0">
                                            {/* ค่าธรรมเนียมธุรกรรม (เฉพาะ transaction) */}
                                            {commissionData.some(item => item.name.includes("lazada_transaction")) && (
                                                <div>
                                                    {commissionData.filter(item => item.name.includes("lazada_transaction")).map((fee, index) => (
                                                        <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                            <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                        </Form.Item>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                    <Form.Item>
                                        <div className=' mt-4'>
                                            <button type="submit" className='bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition px-6 py-1 w-full text-2xl rounded-md text-white'>
                                                บันทึก
                                            </button>
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                            </Form>
                            </div>
                            <div>

                            <Form 
                                onFinish={onSubmit}
                                layout="horizontal"
                                form={form2}
                            >
                            <div className='bg-white md:px-6 px-1 py-4 rounded-lg mt-2 mb-60'>
                                <div className="pb-4">
                                    <label className="flex justify-center text-lg">อัตราค่าจัดส่ง</label>
                                </div>
                                <div className="p-1 border rounded-md pt-2">
                                    <div className="flex">
                                        <div><p className="text-green-600">ต้นทาง : </p></div>
                                        <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p> </div>
                                    </div>
                                    <div className="flex pb-1">
                                        <div><p className="text-orange-600">ปลายทาง : </p></div>
                                        <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p></div>
                                    </div>
                                    <Form.Item name={['Laz_shipping2_2_1', 'value']}>
                                        <InputNumber addonBefore="น้อยกว่า 1 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_1-2', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 1-2 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_2-3', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 2-3 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_3-5', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 3-5 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_5-10', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 5-10 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_10-15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 10-15 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_15-20', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 15-20 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_20-25', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 20-25 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_25-26', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 25-26 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_26-43', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 26-43 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_43-65', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 43-65 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_65-87', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 65-87 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_87-99', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 87-99 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_2_99', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 99 kg" size="large"/>
                                    </Form.Item>

                                </div>

                                <div className="p-1 border rounded-md pt-2 mt-4">
                                    <div className="flex">
                                        <div><p className="text-green-600">ต้นทาง : </p></div>
                                        <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p> </div>
                                    </div>
                                    <div className="flex pb-1">
                                        <div><p className="text-orange-600">ปลายทาง : </p></div>
                                        <div><p className="pl-1"> ต่างจังหวัด </p></div>
                                    </div>
                                    <Form.Item name={['Laz_shipping2_1_1', 'value']}>
                                        <InputNumber addonBefore="น้อยกว่า 1 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_1-2', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 1-2 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_2-3', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 2-3 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_3-5', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 3-5 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_5-10', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 5-10 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_10-15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 10-15 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_15-20', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 15-20 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_20-21', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 20-21 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_21-22', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 21-22 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_22-43', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 22-43 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_43-87', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 43-87 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_87-99', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 87-99 kg" size="large"/>
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping2_1_99', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 99 kg" size="large"/>
                                    </Form.Item>
                                </div>

                                <div className="p-1 border rounded-md pt-2 mt-4">
                                    <div className="flex">
                                        <div><p className="text-green-600">ต้นทาง : </p></div>
                                        <div><p className="pl-1"> ต่างจังหวัด </p> </div>
                                    </div>
                                    <div className="flex pb-1">
                                        <div><p className="text-orange-600">ปลายทาง : </p></div>
                                        <div><p className="pl-1"> ต่างจังหวัด/กรุงเทพฯและปริมณฑล </p></div>
                                    </div>
                                    <Form.Item name={['Laz_shipping1_1_1', 'value']}>
                                        <InputNumber addonBefore="น้อยกว่า 1 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_1-2', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 1-2 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_2-3', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 2-3 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_3-5', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 3-5 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_5-10', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 5-10 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_10-15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 10-15 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_15-20', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 15-20 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_20-99', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 20-99 kg" size="large" />
                                    </Form.Item>
                                    <Form.Item name={['Laz_shipping1_1_99', 'value']}>
                                    <InputNumber addonBefore="มากกว่า 99 kg" size="large"/>
                                    </Form.Item>
                                </div>

                                <Form.Item>
                                    <div className='flex justify-end mt-4'>
                                        {/*<button type="danger" htmlType="submit" className='bg-white border hover:bg-gray-50 px-6 py-1 mr-2 rounded-md'>
                                            ค่าเริ่มต้น
                                        </button>*/}
                                        <button htmlType="submit" className='bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition px-6 py-1 rounded-md text-white'>
                                            บันทึก
                                        </button>
                                    </div>
                                </Form.Item>
                            </div>
                            </Form>
                            </div>
                            </>
                        )}
                        {formType === "shopee" && (
                            <>
                            <div className="mb-6">
                            <Form 
                                onFinish={onSubmitFee}
                                layout="horizontal"
                                form={form1}
                            >
                            <div className="bg-white rounded-lg mt-2">
                                <div className="">
                                    <label className="flex justify-center text-lg pt-4" tooltip="Shipping Fee Program">
                                        ค่าธรรมเนียม <Tooltip title="0-1 เช่น 0.05=5%"><QuestionCircleOutlined className="ml-1 text-gray-400"/></Tooltip>
                                    </label>
                                </div>
                                <div className='bg-white md:px-6 px-1 py-4 rounded-lg'>
                                    <div className="p-1 border rounded-md pt-2">

                                        {commissionData.some(item => item.name === "shopee_commission" && item.market === "none") && (
                                            <div className="border rounded-md p-2">
                                                <label className="pl-2 text-base">ร้านค้าปกติ</label>
                                                {commissionData.filter(item => item.name === "shopee_commission" && item.market === "none").map((fee, index) => (
                                                    <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                        <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                    </Form.Item>
                                                ))}
                                            </div>
                                        )}
                                        {commissionData.some(item => item.name === "shopee_commission" && item.market === "mall") && (
                                            <div className="border rounded-md p-2 my-2">
                                                <label className="pl-2 text-base">Shoppee Mall</label>
                                                {commissionData.filter(item => item.name === "shopee_commission" && item.market === "mall").map((fee, index) => (
                                                    <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                        <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                    </Form.Item>
                                                ))}
                                            </div>
                                        )}
                                        

                                        <div className="border-y-2 pt-6 pb-0">
                                            {/* ค่าธรรมเนียมธุรกรรม (เฉพาะ transaction) */}
                                            <label className="pl-2 text-base" >ค่าธรรมเนียมธุรกรรม (วิธีการชำระเงิน)</label>
                                            {commissionData.some(item => item.name.includes("shopee_transaction")) && (
                                                <div>
                                                    {commissionData.filter(item => item.name.includes("shopee_transaction")).map((fee, index) => (
                                                        <Form.Item key={fee._id} name={[fee._id, 'value']}>
                                                            <InputNumber addonBefore={fee.des} size="large" min={0} max={100} />
                                                        </Form.Item>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                    <Form.Item>
                                        <div className=' mt-4'>
                                            <button type="submit" className='bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition px-6 py-1 w-full text-2xl rounded-md text-white'>
                                                บันทึก
                                            </button>
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                            </Form>
                            </div>

                        <div className="bg-white rounded-lg mt-2 mb-60">
                            <Form 
                                onFinish={onSubmit} 
                                layout="horizontal"
                                form={form2}
                            >
                                <div className="mt-2">
                                    <label className="flex justify-center text-lg pt-2">อัตราค่าจัดส่ง</label>
                                </div>
                                <div className='bg-white md:px-6 px-1 py-4 rounded-lg'>
                                    <div className="p-1 border rounded-md pt-2">
                                        <div className="flex">
                                            <div><p className="text-green-600">ต้นทาง : </p></div>
                                            <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p> </div>
                                        </div>
                                        <div className="flex pb-1">
                                            <div><p className="text-orange-600">ปลายทาง : </p></div>
                                            <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p></div>
                                        </div>
                                        <Form.Item name={['shopee_shipping2_2_1', 'value']}>
                                        <InputNumber addonBefore="น้อยกว่า 1 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_1-2', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 1-2 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_2-3', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 2-3 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_3-5', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 3-5 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_5-10', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 5-10 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_10-11', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 10-11 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_11-15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 11-15 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_2_15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 15 kg" size="large"/>
                                        </Form.Item>
                                    </div>

                                    <div className="p-1 border rounded-md pt-2 mt-4">
                                        <div className="flex">
                                            <div><p className="text-green-600">ต้นทาง : </p></div>
                                            <div><p className="pl-1"> กรุงเทพฯและปริมณฑล </p> </div>
                                        </div>
                                        <div className="flex pb-1">
                                            <div><p className="text-orange-600">ปลายทาง : </p></div>
                                            <div><p className="pl-1"> ต่างจังหวัด </p></div>
                                        </div>
                                        <Form.Item name={['shopee_shipping2_1_1', 'value']}>
                                        <InputNumber addonBefore="น้อยกว่า 1 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_1_1-2', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 1-2 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_1_2-3', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 2-3 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_1_3-5', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 3-5 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_1_5-10', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 5-10 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_1_10-15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 10-15 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping2_1_15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 15 kg" size="large"/>
                                        </Form.Item>
                                    </div>

                                    <div className="p-1 border rounded-md pt-2 mt-4">
                                        <div className="flex">
                                            <div><p className="text-green-600">ต้นทาง : </p></div>
                                            <div><p className="pl-1"> ต่างจังหวัด </p> </div>
                                        </div>
                                        <div className="flex pb-1">
                                            <div><p className="text-orange-600">ปลายทาง : </p></div>
                                            <div><p className="pl-1"> ต่างจังหวัด/กรุงเทพฯและปริมณฑล </p></div>
                                        </div>
                                        <Form.Item name={['shopee_shipping1_1_1', 'value']}>
                                        <InputNumber addonBefore="น้อยกว่า 1 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping1_1_1-2', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 1-2 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping1_1_2-3', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 2-3 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping1_1_3-5', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 3-5 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping1_1_5-10', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 5-10 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping1_1_10-15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 10-15 kg" size="large"/>
                                        </Form.Item>
                                        <Form.Item name={['shopee_shipping1_1_15', 'value']}>
                                        <InputNumber addonBefore="มากกว่า 15 kg" size="large"/>
                                        </Form.Item>
                                    </div>

                                    <Form.Item>
                                        <div className='flex justify-end mt-4 '>
                                            <button htmlType="submit" className='bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition px-6 py-1 rounded-md text-white'>
                                                บันทึก
                                            </button>
                                        </div>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                        </>
                        )}
                    </div>
                </div>
            </div>

            <div></div>
        </div>
    </>

  );
}

export default SettingCalcu;