import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined, UploadOutlined , MailOutlined , PhoneOutlined ,HomeOutlined ,IdcardOutlined,ShopOutlined,UserOutlined  } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Upload, message, FloatButton, Switch,Spin } from 'antd';
import ImgCrop from 'antd-img-crop';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function AddCustomer() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');

  const [markets, setMarkets] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  
  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('../manage-product');
    }

    const fetchMarkets = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, {
          params: { userId: user._id }
      });
        if (Array.isArray(response.data)) {
          setMarkets(response.data);
        } else {
          setMarkets([]);
        }
      } catch (err) {
        console.error('Error fetching markets:', err);
        setMarkets([]);
      }
    };
    fetchMarkets();
  }, [user, navigate]);

  const props = {
    beforeUpload: (file) => {
      const isImage = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/gif';
      if (!isImage) {
        message.error(`${file.name} ไม่รองรับไฟล์ภาพประเภทนี้`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
      const images = newFileList.map(file => file.originFileObj);
      setImageFiles(images);
    },
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

    const formData = new FormData();
    formData.append('market', values.market);
    formData.append('name', values.name);
    formData.append('nationalId', values.nationalId);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('address', values.address);
    formData.append('description', values.description);
    formData.append('status', values.status);
    formData.append('user', user._id);

    if (fileList.length > 0) {
        formData.append('logo', fileList[0].originFileObj);
    }

    try {
      const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/customer`,
        formData,  // Body ของ request
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      message.success('เพิ่มลูกค้าสำเร็จ');
      navigate('../manage-customer');
    } catch (err) {
      console.error('Error adding customer:', err);
      message.error('Failed to add customer');
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };


  return (
    <>
      {user ? 
        <div className='md:block hidden'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 flex-initial md:mb-28 mb-10' style={{ width: "48rem" }}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-customer" className='text-sm text-gray-600' > <LeftOutlined /> จัดการรายชื่อลูกค้า</Link>
            <h2 className='text-2xl mb-4 mt-2'>เพิ่มรายชื่อลูกค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-customer" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">เพิ่มรายชื่อลูกค้า</h2>
          </div>

          <Form 
            onFinish={onSubmit} layout="vertical" className='md:mt-0 mt-[62px]' 
            initialValues={{
              name: '',
              description: '',
              nationalId: '',
              address: '',
              phone: '',
              email: '',
              status: true,
              market: markets.length > 0 ? markets[0]._id : '',
            }}
          
          >
            <div className='bg-white p-6 rounded-lg '>
              <Form.Item
                label="อัปโหลดภาพ"
                tooltip="อัปโหลดได้สูงสุด 1รูป"
              >
                <ImgCrop rotationSlider>
                  <Upload
                    listType="picture-card"
                    action=""
                    fileList={fileList}
                    onChange={props.onChange}
                    maxCount={1}
                    {...props}
                  >
                    {fileList.length >= 1 ?
                      null
                      : <p><UploadOutlined /> อัปโหลดรูป</p>}
                  </Upload>
                </ImgCrop>
              </Form.Item>

              <Form.Item
                label="ร้านค้า"
                name="market"
                rules={[{ required: true, message: 'กรุณาเลือกร้านค้า' }]}
                className='mt-8'
                tooltip={{
                    icon:  <ShopOutlined />,
                  }}
              >
                <Select>
                  {markets.map(market => (
                    <Select.Option key={market._id} value={market._id}>{market.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="ชื่อลูกค้า"
                name="name"
                rules={[{ required: true, message: 'กรุณาใส่ชื่อลูกค้า' }]}
                className=""
                tooltip={{
                    icon:  <UserOutlined />,
                  }}
              >
                <Input
                  placeholder='กรุณาใส่ชื่อลูกค้า'
                  
                />
              </Form.Item>
            </div>

            <div className='bg-white p-6 rounded-lg mt-4'>
                <Form.Item 
                    label="ที่อยู่" 
                    name="address"
                    rules={[{ required: true, message: 'กรุณาใส่ที่อยู่' }]}
                    tooltip={{
                        icon:  <HomeOutlined />,
                      }}
                >
                    <TextArea
                        placeholder='ป้อนที่อยู่'
                        style={{ minHeight: '80px' }}
                    />
                </Form.Item>

                <Form.Item 
                    label="เบอร์โทร" 
                    name="phone"
                    rules={[{ required: true, message: 'กรุณาใส่เบอร์โทร' }]}
                    tooltip={{
                        icon:  <PhoneOutlined />,
                      }}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="อีเมล" name="email" 
                    tooltip={{
                        icon:  <MailOutlined />,
                    }}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="เลขผู้เสียภาษี" name="nationalId"
                    tooltip={{
                        icon:  <IdcardOutlined />,
                    }}
                >
                    <Input />
                </Form.Item>


            </div>

            <div className='bg-white p-6 rounded-lg mt-4'>
                <Form.Item label="คำอธิบายลูกค้า" name="description">
                    <TextArea
                    placeholder='ป้อนคำอธิบายลูกค้า'
                    style={{ minHeight: '80px' }}
                    />
                </Form.Item>
            </div>

            <div className='bg-white p-6 pb-0.5 pt-2.5 rounded-lg mt-4 md:mb-0 mb-10 flex justify-between'>
              <label className='text-lg'>การแสดงผล</label>                       
              <Form.Item
                name='status'
                valuePropName="checked"
              >
                <Switch
                  style={{ width: '90px' }}
                  checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                  defaultChecked
                  className='md:mr-0 mr-4  md:scale-100 scale-150'
                >
                </Switch>
              </Form.Item>
            </div>

            <Form.Item>
              <div className='flex justify-end mt-4'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
            >
              <div className=''>
                <button 
                  type="submit"
                  disabled={isSubmitting}
                  className= 
                  "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"> 
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

          </Form>
        </div>

        <div></div>
      </div>
    </>
  );
}

export default AddCustomer;
