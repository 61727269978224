import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import "./styles/market.css";
import './styles/theme.css';

import Classic from './theme-product/classic.jsx';
//import Classic_dark from './theme-product/classic-dark.jsx';
import MarketClose from './market-close.jsx'; // นำเข้า MarketClose

function Product_theme() {
    const { id_text_product: urlProduct } = useParams();

  const [marketData, setMarketData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [theme, setTheme] = useState('default');
  const [isMarketClosed, setIsMarketClosed] = useState(false); 

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/product/id_text_product/${urlProduct}`);
        setMarketData(response.data.market);
        setProductData(response.data);
        
      } catch (err) {
        console.error('Error fetching product:', err);
      }
    };

    fetchProduct();
  
  }, [urlProduct]);

  if (isMarketClosed) {
    return <MarketClose />;
  }

  if (!marketData) {
    return (
      <div><Spin fullscreen size="large" /></div>
    );
  }

  return (
    <>
      {theme === 'dark' ? (
        <Classic market={marketData} product={productData} />
      ) : (
        <Classic market={marketData} product={productData} />
      )}
    </>
  );
}

export default Product_theme;
