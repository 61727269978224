import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BottomNav , Nav, NavNoUser } from '../../../components/Nav.jsx';
import { useUserAuth } from '../../../context/UserContext.jsx';
import { Sidebar } from '../../../components/Nav.jsx';
import { Link, useNavigate } from 'react-router-dom';
import {LoginAlert} from '../../../components/Alert.jsx';
import { parseDate , formatToBuddhistYear } from './script.jsx';

import { Pagination, message, Popconfirm, Input, Select , Dropdown,Image, Skeleton  } from 'antd';
import { QuestionCircleOutlined , CaretDownOutlined , DownOutlined ,CaretUpOutlined , EyeOutlined  } from '@ant-design/icons';
import {  TagIcon,UsersIcon , DocumentTextIcon } from '@heroicons/react/20/solid'
import StoreIcon from '@mui/icons-material/Store';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Spin , FloatButton } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

const { Option } = Select;
const { Search } = Input;

function ManageInvoice() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');

  const userId = user ? user._id : null;
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [market, setMarket] = useState('');
  const [markets, setMarkets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('default');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // สถานะการโหลด
  
  const fetchInvoices = async () => {
    if (user && user._id) {
      setLoading(true); // เริ่มโหลด
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/invoice`, {
          params: { userId: user._id }
        });

        if (response.data.length === 0) {
          setData(false)
        }

        if (Array.isArray(response.data)) {
          const sortedInvoices = response.data.sort((a, b) => {
            // Parse dates for comparison
            const dateA = parseDate(a.issue_date);
            const dateB = parseDate(b.issue_date);
          
            if (dateA.getTime() !== dateB.getTime()) {
              return dateB.getTime() - dateA.getTime(); // Latest date first
            }
            
            // If same date, compare by updatedAt
            return new Date(b.updatedAt) - new Date(a.updatedAt); // Latest update first
          });
  
          setInvoices(sortedInvoices);
          setFilteredInvoices(sortedInvoices);
        } else {
          console.error('Fetched invoices are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching invoices:', err);
      } finally {
        setLoading(false); // โหลดเสร็จ
      }
    }
  };

useEffect(() => {
  const fetchData = async () => {
    await fetchInvoices();
    setLoading(false); // ปิดสถานะโหลดเมื่อข้อมูลพร้อม
  };
  fetchData();
}, [user]);

  const handleBulkDelete = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/invoice/bulk-delete`,
        { invoiceIds: selectedInvoices },  // Body ของ request
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      setInvoices(invoices.filter(invoice => !selectedInvoices.includes(invoice._id)));
      setSelectedInvoices([]);
      navigate(0);
      message.success("ลบเอกสารสำเร็จ");
    } catch (err) {
      console.error('Error deleting invoices:', err);
    }
};

  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/invoice/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },
            data: { imageUrls }
        }
      );

      setInvoices(invoices.filter(invoice => invoice._id !== id));
      navigate(0)
      message.success("ลบเอกสารสำเร็จ")
    } catch (err) {
      console.error('Error deleting invoice:', err);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-invoice/${id}`);
  };

  const handleSelectInvoice = (id) => {
    setSelectedInvoices(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(invoiceId => invoiceId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSelectAll = () => {
    if (selectedInvoices.length === invoices.length) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(invoices.map(invoice => invoice._id));
    }
  };

  const handleItemsPerPageChange = (current, size) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    filterInvoices(value, market);
  };
  const handleMarketChange = (value) => {
    setMarket(value);
    filterInvoices(searchQuery, value);
  };

  const handleSortByIssue = () => {
    const sortedInvoices = [...filteredInvoices];
    const newSortOrder = sortOrder === 'default' ? 'asct' : sortOrder === 'asct' ? 'desct' : 'default';
    setSortOrder(newSortOrder);
  
    sortedInvoices.sort((a, b) => {
      // Parse dates for comparison
      const dateA = parseDate(a.issue_date);
      const dateB = parseDate(b.issue_date);
      
      if (dateA.getTime() !== dateB.getTime()) {
        return newSortOrder === 'asct' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }
      
      // If same date, compare by updatedAt
      return newSortOrder === 'asct'
        ? new Date(a.updatedAt) - new Date(b.updatedAt)
        : new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  
    setFilteredInvoices(sortedInvoices);
  };

  const filterInvoices = (search, market) => {
    let filtered = invoices;

    if (search) {
      filtered = filtered.filter(invoice =>
        invoice.name.toLowerCase().includes(search.toLowerCase()) ||
        invoice.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredInvoices(filtered);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedInvoicesPage = filteredInvoices.slice(startIndex, startIndex + itemsPerPage);
  
/*
  if (user && invoices.length === 0 && data) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }*/

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}

      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>

        <div className='overflow-x overflow-y-hidden custom-scrollbar hidden md:block'>
          <LoginAlert />

          <div className='grid grid-cols-4 gap-2 mt-2 text-sm mx-2'>
            <Link to="/manage-receipt" className='w-full'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2 px-2 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-gray-300 bg-white"></span>
                </span>
                ใบเสร็จรับเงิน
              </div>
            </Link>

            <Link to="/manage-bill" className='w-full'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2 px-2 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-gray-300 bg-white"></span>
                </span>
                ใบวางบิล
              </div>
            </Link>

            <Link to="/manage-invoice" className='w-full'>
              <div className='flex items-center text-center bg-blue-50 rounded-md w-full py-2 px-2  ring-1 '>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-sky-500 bg-white"></span>
                </span>
                ใบแจ้งหนี้
              </div>
            </Link>

            <Link to="/manage-quotation" className='w-full'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2 px-2 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-gray-300 bg-white"></span>
                </span>
                ใบเสนอราคา
              </div>
            </Link>


          </div>
        
          <div className='shrink hidden md:block'>
          <div className='flex justify-between '>
            <div></div>

            <div className='flex mt-4 mb-3 mr-2'>
              <div>
                {user ? 
                  <div className='flex'>
                    <Link to="/add-invoice">
                      <button className='
                        py-2 px-4 rounded
                        bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition text-white text-sm'
                        type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>สร้างใบแจ้งหนี้
                      </button>
                    </Link>
                  </div>
                  : 
                  <button 
                    className='
                      my-6 py-2 px-4 rounded
                      bg-blue-300 text-white text-sm cursor-not-allowed'
                    type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>สร้างใบแจ้งหนี้
                  </button>
                }
              </div>
            </div>
          </div>

          <div className='flex justify-between mb-6 bg-white py-4 rounded-lg mx-1 border'>
            <div className='flex pl-4'>
              <Search
                placeholder="ค้นหาใบเสร็จ"
                allowClear
                style={{ width: 300, marginRight: 10 }}
                onSearch={handleSearch}
                value={searchQuery}
                onChange={e => handleSearch(e.target.value)}
              />

              <div>
                <Popconfirm
                  title="ยืนยันการลบเอกสาร"
                  description="คุณต้องการลบเอกสารที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={handleBulkDelete}
                  okText="ยืนยัน"
                  cancelText="ยกเลิก"
                  disabled={selectedInvoices.length === 0}
                >
                  {selectedInvoices.length === 0 ? (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                  ) : (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                  )}
                </Popconfirm>
              </div>
            </div>
          </div>


          <div className="hidden md:block grid-cols-1 gap-2">
            <div className=''>
              <div>
                <div className='flex flex-row ml-4 text-sm text-gray-500'>
                  <div className='w-8'>
                    <input
                      type="checkbox"
                      checked={selectedInvoices.length === invoices.length}
                      onChange={handleSelectAll}
                      className='transform scale-125 cursor-pointer'
                    />
                  </div>
                  <div className='w-44 min-w-44'>เลขที่เอกสาร</div>
                  <div className='w-52 ml-2'>ลูกค้า</div>
                  <div className='w-40'>
                    <div className='cursor-pointer flex' onClick={handleSortByIssue}>วันที่ออก
                        {sortOrder === 'asct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>
                        : sortOrder === 'desct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div>
                            : <div className='flex flex-col pl-1  opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>}
                    </div>
                  </div>
                  <div className='w-16 text-nowrap'>มูลค่าสุทธิ</div>
                  <div className='w-24 text-nowrap text-right'>ดำเนินการ</div>
                </div>
              </div>

              <div className='text-sm mt-2'>
                  {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[72px]">
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 0 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : invoices.length === 0 ? (
                    <div className="bg-white p-4 mt-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                    
                selectedInvoicesPage.map(invoice => (

                  <div key={invoice._id} className={`flex flex-row px-4 py-4 mb-3 rounded-lg mx-1 hover:drop-shadow ${selectedInvoices.includes(invoice._id) ? 'bg-indigo-50' : 'bg-white'}`}>

                    <div className='w-[20px] max-w-8'>
                      <input
                        type="checkbox"
                        checked={selectedInvoices.includes(invoice._id)}
                        onChange={() => handleSelectInvoice(invoice._id)}
                        className='transform scale-125 cursor-pointer'
                        />
                    </div>
                    

                    <div className=' grid content-between'>
                      <div className='pl-2 px-1 w-40 max-w-40 cursor-pointer break-words truncate-2-lines text-blue-500' onClick={() => handleEdit(invoice._id)}>{invoice.document_number}</div>
                    </div>
                    <div className='w-52 max-w-52 px-4 pl-6 truncate cursor-pointer' onClick={() => handleEdit(invoice._id)}>{invoice.customer_name}</div>
                    <div className='w-36 max-w-36 px-4 pl-6 cursor-pointer' onClick={() => handleEdit(invoice._id)}>{formatToBuddhistYear(invoice.issue_date)}</div>
                    <div className='w-24 max-w-24  pl-6 truncate cursor-pointer text-right' onClick={() => handleEdit(invoice._id)}>{invoice.total_price}</div>
                    
                    <div className='flex-row min-w-24 max-w-24 pl-4'>
                      <div className='flex justify-end'>
                        <button onClick={() => handleEdit(invoice._id)}><p className="text-indigo-600">ดูรายละเอียด</p></button>
                      </div>

                      <div className='flex justify-end'>
                        <Popconfirm
                          title="ยืนยันการลบเอกสาร"
                          description="คุณต้องการลบเอกสารนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => handleDelete(invoice._id, invoice.imageUrls)}
                          okText="ยืนยัน"
                          cancelText="ยกเลิก"
                        >
                          <button>
                            <p className="text-indigo-600">ลบ</p>
                          </button>
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                    ))
                  )}
              </div>
            </div>
          </div>

          <div>
            <div className="pagination mb-28">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={invoices.length}
                onChange={handlePageChange}
                onShowSizeChange={handleItemsPerPageChange}
                showSizeChanger
                pageSizeOptions={['10', '20', '50', '100']}
              />
            </div>
          </div>

          </div>
        </div>

        {/* mini */}
        <div className='grow md:hidden mx-1'>
          <LoginAlert />

          <div className='grid grid-cols-2 mt-2 gap-2 mx-1.5'>
            <Link to="/manage-receipt" className='w-full'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2.5 px-2 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-gray-300 bg-white"></span>
                </span>
                ใบเสร็จรับเงิน
              </div>
            </Link>

            <Link to="/manage-bill" className='w-full'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2.5 px-2 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-gray-300 bg-white"></span>
                </span>
                ใบวางบิล
              </div>
            </Link>

            <Link to="/manage-invoice" className='w-full'>
              <div className='flex items-center text-center bg-blue-50 rounded-md w-full py-2.5 px-2 ring-1 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-sky-500 bg-white"></span>
                </span>
                ใบแจ้งหนี้
              </div>
            </Link>

            <Link to="/manage-quotation" className='w-full'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2.5 px-2 shadow	 hover:cursor-pointer select-none'>
                <span class="relative flex h-3 w-3 mx-2">
                  <span class="relative inline-flex rounded-full h-3 w-3 ring-2 ring-inset ring-gray-300 bg-white"></span>
                </span>
                ใบเสนอราคา
              </div>
            </Link>
          </div>

          <div className='flex justify-between mx-2'>

            <div></div>

            <div>
              {user?
              <div className='flex flex-col items-end'>
                <Link to="/add-invoice" >
                  <button className='
                      p-2 my-6 mr-2 rounded
                      bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white text-sm'
                    type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>สร้างใบแจ้งหนี้
                  </button>
                </Link>
              </div>
              : 
              <button 
                className='
                  my-6 py-2 px-4 rounded
                  bg-blue-300 text-white text-sm cursor-not-allowed'
                type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>สร้างใบแจ้งหนี้
              </button>
              }
            </div>
          </div>
          
          <div className='mb-4 mx-2'>

            <div className='flex '>
                <Search
                    placeholder="ค้นหาเลขใบเสร็จ, ชื่อลูกค้า"
                    allowClear
                    style={{  marginRight: 10 }}
                    onSearch={handleSearch}
                    value={searchQuery}
                    onChange={e => handleSearch(e.target.value)}
                />
            </div>

            <div>
                <Popconfirm
                    title="ยืนยันการลบเอกสาร"
                    description="คุณต้องการลบเอกสารที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={handleBulkDelete}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                    className='ml-8 mt-2'
                    disabled={selectedInvoices.length === 0}
                >
                    {selectedInvoices.length === 0 ? (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                    ) : (
                    <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                    )}
                </Popconfirm>
            </div>

          </div>

          <div className="grid grid-cols-1 gap-2">
            <div>
              <div className='flex justify-between'>
                {invoices.length === 0 ? "" :
                <div className='flex flex-row mb-3 ml-3 text-sm text-gray-500'>
                    <div className='w-8'>
                    <input
                        type="checkbox"
                        checked={selectedInvoices.length === filteredInvoices.length}
                        onChange={handleSelectAll}
                        className='transform scale-125'
                    />
                    </div>
                </div>
                }
                <div className='opacity-50 pr-2 space-x-4 flex'>
                    <div className='cursor-pointer flex' onClick={handleSortByIssue}>วันที่ออก
                        {sortOrder === 'asct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>
                        : sortOrder === 'desct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div>
                            : <div className='flex flex-col pl-1  opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>}
                    </div>
                </div>
              </div>

              <div className='text-sm '>
                {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[72px]">
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 0 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : invoices.length === 0 ? (
                    <div className="bg-white p-4 mt-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                
                selectedInvoicesPage.map(invoice => (
                <div key={invoice._id} className='bg-white px-4 py-2 rounded-lg mx-1 mb-3 overflow-hidden break-all whitespace-normal'>
                    <div className='flex justify-between'>
                      <div className='flex grow mb-1 '>
                        <input
                            type="checkbox"
                            checked={selectedInvoices.includes(invoice._id)}
                            onChange={() => handleSelectInvoice(invoice._id)}
                            className='transform scale-125'
                        />
                        <div className='px-2 pt-1 max-h-12 truncate-2-lines cursor-pointer grow' onClick={() => handleEdit(invoice._id)}>{invoice.customer_name}</div>
                      </div>
                      <div className='text-blue-500 cursor-pointer text-nowrap' onClick={() => handleEdit(invoice._id)}>
                        เลขที่ : {invoice.document_number}
                      </div>
                    </div>

                    <div className='flex '>
                        <div className='flex flex-row justify-between text-wrap w-full truncate cursor-pointer' onClick={() => handleEdit(invoice._id)}>
                            <div className='pt-1 ml-2 truncate text-gray-600 text-xs'>วันที่ออก: {formatToBuddhistYear(invoice.issue_date)}</div>
                            <div className='px-2 pt-1 max-h-12 text-indigo-800 truncate-2-lines '>มูลค่ารวม: {invoice.total_price}</div>
                        </div>
                    </div>

                    <button onClick={() => handleEdit(invoice._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300">แก้ไข</p></button>

                    <Popconfirm
                      title="ยืนยันการลบเอกสาร"
                      description="คุณต้องการลบเอกสารนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => handleDelete(invoice._id)}
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                    >
                      <button className='bg-gray-200 rounded py-1 px-4 grow  active:bg-gray-300'><p className="w-full text-center">ลบ</p></button>
                    </Popconfirm>
                  </div>
                    ))
                  )}
              </div>
              
            </div>
          </div>

          <div>
            <div className="flex justify-center mb-24">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={invoices.length}
                onChange={handlePageChange}
                onShowSizeChange={handleItemsPerPageChange}
                showSizeChanger
                pageSizeOptions={['10', '20', '50' , '100']}
              />
            </div>
          </div>

        </div>

        <div className=''></div>
      </div>
    </>
  );
}

export default ManageInvoice;