import { GoogleMap, MarkerF } from '@react-google-maps/api';
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { getCurrentPosition } from './fn';
import { LeftOutlined ,UploadOutlined , MailOutlined,PhoneOutlined,LinkOutlined,QuestionCircleOutlined, MinusCircleOutlined,PlusOutlined  } from '@ant-design/icons';
import { Button, Form, Input, Upload, message ,Spin,Modal, Switch,Tooltip} from 'antd';
import { Radio , List ,ListItem ,ListItemPrefix ,Typography  } from "@material-tailwind/react";
import { LoadingOutlined } from "@ant-design/icons";
import ImgCrop from 'antd-img-crop';
import lineIcon from '../../assets/lineLogo1.png';
import facebookIcon from '../../assets/facebookLogo2.png';
import instagramIcon from '../../assets/igLogo2.png';
import tiktokIcon from '../../assets/Tiktok_logo2.png';
import youtubeIcon from '../../assets/youtubeLogo2.png';
import xIcon from '../../assets/XLogo.jpeg';
import telegramIcon from '../../assets/telegramLogo.png';
import wechatIcon from '../../assets/WechatLogo.png';
import shopeeIcon from '../../assets/ShopeeLogo.png';
import lazadaIcon from '../../assets/LazadaLogo.png';
import whatsappIcon from '../../assets/whatsappLogo.png';
import snapchatIcon from '../../assets/SnapchatLogo.png';
import phoneIcon from '../../assets/phoneLogo.png';
import mailIcon from '../../assets/MailLogo.png';

import default_theme from '../../assets/market/default_theme.jpg'
import dark_theme from '../../assets/market/dark_theme.jpg'
import beige_theme from '../../assets/market/beige_theme.jpg'
import blue_theme from '../../assets/market/blue_theme.jpg'
import green_theme from '../../assets/market/green_theme.jpg'

function EditMarket() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [market, setMarket] = useState(null);
  const [contactlinks, setContactLinks] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [initialPosition, setInitialPosition] = useState({ lat: 13.7248785, lng: 100.4683055 });
  const [id_location, setId_location] = useState(null);
  const [data, setData] = useState(true);

  const [fileListLogo, setFileListLogo] = useState([]);
  const [oldLogoUrl, setOldLogoUrl] = useState([]);
  const [remainingLogoUrl, setRemainingLogoUrl] = useState([]);

  const [fileListBanners, setFileListBanners] = useState([]);
  const [oldImageUrls, setOldImageUrls] = useState([]);
  const [remainingImageUrls, setRemainingImageUrls] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก
  
  useEffect(() => {
    //ดึงข้อมูลร้านค้าขึ้นมาแสดง
    const fetchMarket = async () => {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market/from_id_market/${id}?populate=location`);
          const fetchedMarket = response.data;

          if (!fetchedMarket) {
            setData(false);
          }else {
            setMarket(fetchedMarket);
            form.setFieldsValue({
              name: fetchedMarket.name,
              description: fetchedMarket.description,
              phone: fetchedMarket.phone,
              email: fetchedMarket.email,
              selectedTheme: fetchedMarket.theme,
              locationStatus: fetchedMarket.location?.status ?? false,
            });
            
            // แปลงข้อมูล contactlink และตั้งค่า form fields สำหรับแต่ละ contact
            const formattedContacts = fetchedMarket.contactlink.map((contact, index) => ({
              source: contact.source,
              word: contact.word,
              icon: contactIcons[contact.source],
              id: contact._id
            }));
            setContactLinks(formattedContacts);
            // ตั้งค่า form fields สำหรับ contactlinks
            const contactFormValues = {};
            formattedContacts.forEach((contact, index) => {
              contactFormValues[`contactlinks_${index}`] = contact.word;
            });
            form.setFieldsValue(contactFormValues);
            
            setSelectedTheme(fetchedMarket.theme);
            setOldLogoUrl(fetchedMarket.logoUrl);

            setFileListLogo(fetchedMarket.logoUrl.map((filename, index) => ({
              uid: index,
              name: `logo${index + 1}.png`,
              status: 'done',
              url: getImageUrl(filename),
            })));

            setOldImageUrls(fetchedMarket.imageUrls);
            setFileListBanners(fetchedMarket.imageUrls.map((filename, index) => ({
              uid: index,
              name: `banner${index + 1}.png`,
              status: 'done',
              url: getImageUrl(filename),
            })));

            setId_location(fetchedMarket.location?._id);

            if (fetchedMarket && fetchedMarket.location && fetchedMarket.location.lat && fetchedMarket.location.lng) {
              const lat = fetchedMarket.location.lat;
              const lng = fetchedMarket.location.lng;

              setSelectedPosition({ lat, lng });
              setInitialPosition({ lat, lng });
            }
          }
    
        } catch (err) {
          console.error('Error fetching market:', err);
        }
    };

    fetchMarket();
  }, [id, form ,navigate,user]);

  function getImageUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }

  //ตรวจสอบไฟล์ว่าเป็นรูปบ่
  const props = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error(`${file.name} ไม่รองรับไฟล์ประเภทนี้`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };

  const contactIcons = {
    line: lineIcon,
    facebook: facebookIcon,
    instagram: instagramIcon,
    tiktok: tiktokIcon,
    youtube: youtubeIcon,
    x: xIcon,
    telegram: telegramIcon,
    wechat: wechatIcon,
    shopee: shopeeIcon,
    lazada: lazadaIcon,
    whatsapp: whatsappIcon,
    snapchat: snapchatIcon,
    phone: phoneIcon ,
    mail: mailIcon ,
  };
    const handleAddContact = () => {
      setIsModalVisible(true);
    };
    const handleSelectContactType = (type) => {
      if (contactlinks.length < 8) {
        const newContact = {
          type,
          icon: contactIcons[type],
          source: type,
          word: '',
        };
        setContactLinks([...contactlinks, newContact]);
      }
      setIsModalVisible(false);
    };
      
    // ลบตัวเลือก
    const handleRemoveContact = (index) => {
      const newContactLinks = [...contactlinks];
      newContactLinks.splice(index, 1);
      setContactLinks(newContactLinks);
      
      // Reset form fields related to the removed ContactLinks
      const currentValues = form.getFieldsValue();
      delete currentValues.contactlinks[index];
      currentValues.contactlinks = currentValues.contactlinks.filter(Boolean);
      
      // Reset fields
      const resetContactFields = () => {
        const newSources = { ...currentValues.sources };
        const newWords = { ...currentValues.words };
        
        Object.keys(newSources).forEach(key => {
          if (parseInt(key) >= combinations.length) {
            delete newSources[key];
            delete newWords[key];
          }
        });
        
        return { newSources, newWords };
      };
      
      const { newSources, newWords } = resetContactFields();
      
      // Update form with new values
      form.setFieldsValue({
        ...currentValues,
        sources: newSources,
        words: newWords,
      });
    };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const imageUrl = file.url || file.preview;
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
    setPreviewTitle(file.name || imageUrl.substring(imageUrl.lastIndexOf('/') + 1));
  };

  const handleChangeLogo = ({ fileList }) => {
    setFileListLogo(fileList);
    const remainingUrls = fileList.filter(file => file.status === 'done' && !file.originFileObj).map(file => file.url);
    setRemainingLogoUrl(remainingUrls);
};

const handleChangeBanners = ({ fileList }) => {
    setFileListBanners(fileList);
    const remainingUrls = fileList.filter(file => file.status === 'done' && !file.originFileObj).map(file => file.url);
    setRemainingImageUrls(remainingUrls);
};

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div style={{marginTop: 8,}}>Upload</div>
    </div>
  );

  useEffect(() => {
    getCurrentPosition((position) => {
      setInitialPosition(position); // ใช้ setInitialPosition เพื่ออัปเดตค่า
    });
  }, []);

  // กำหนดขนาดแผนที่
  const mapContainerStyle = {
      height: '400px',
  };
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedPosition({ lat, lng });
    setInitialPosition({ lat, lng });
  };

  // การตรวจสอบว่ามีการโหลด Script อยู่แล้วหรือไม่
  const shouldLoadMap = user && market !== null && data;

  // ใช้ useMemo เพื่อหลีกเลี่ยงการเรนเดอร์ซ้ำ
  const LoadMap = useMemo(() => (
    shouldLoadMap && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={selectedPosition || initialPosition}
          zoom={12}
          onClick={handleMapClick}
        >
          {selectedPosition && <MarkerF position={selectedPosition} />}
        </GoogleMap>
    )
  ), [initialPosition, selectedPosition, shouldLoadMap]);


  const onSubmit = async (values) => {
    setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('theme', selectedTheme);
    formData.append('updatedAt', new Date().toISOString());

    const locationData = {
      desc: `ตำแหน่งของร้าน ${values.name}`,
      user: user._id,
      status: values.locationStatus
    };
    if (selectedPosition) {
      locationData.lat = selectedPosition.lat;
      locationData.lng = selectedPosition.lng;
    }

    let locationId = id_location;  // Default to existing locationId

    // ถ้าไม่มี locationId (กรณีไม่มีข้อมูล location ผูกไว้), ให้สร้าง location ใหม่
    if (!locationId) {
      try {
        const locationResponse = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/location`,
          locationData,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
        );

        locationId = locationResponse.data._id; // ใช้ _id ของ location ที่สร้างใหม่
      } catch (err) {
          console.error('Error creating location:', err);
          message.error("ไม่สามารถสร้างตำแหน่งร้านได้");
          return; // หยุดการทำงานถ้ามีข้อผิดพลาดในการสร้าง location
      }
    } else {
        // ถ้ามี locationId ให้ทำการอัปเดต location เดิม
        try {
          await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/location/${locationId}`,
            locationData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                }
            }
          );

        } catch (err) {
            console.error('Error updating location market:', err);
            message.error("ไม่สามารถอัปเดตตำแหน่งร้านได้");
            return;
        } finally {
          setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
        }
    }

    fileListLogo.length > 0 ? formData.append('oldLogoUrl', JSON.stringify(oldLogoUrl)) : '';
    formData.append('remainingLogoUrls', JSON.stringify(remainingLogoUrl));

    fileListBanners.length > 0 ? formData.append('oldImageUrls', JSON.stringify(oldImageUrls)) : '';
    formData.append('remainingImageUrls', JSON.stringify(remainingImageUrls));

    fileListLogo.forEach(file => {
      if (file.originFileObj) {
        formData.append('logo', file.originFileObj);
      }
    });

    fileListBanners.forEach(file => {
      if (file.originFileObj) {
        formData.append('banners', file.originFileObj);
      }
    });

    formData.append('contactlink', JSON.stringify(contactlinks)); // ส่งเป็น JSON String

    try {
      const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/market/${id}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      message.success("แก้ไขข้อมูลร้านค้าสำเร็จ");
      navigate('/manage-market');
    } catch (err) {
      console.error('Error updating market:', err);
      message.error("แก้ไขข้อมูลร้านค้าไม่สำเร็จ");
    }
  };

  if (user && market === null && data ) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
    );
  }

  return (
    <>
      {user ? 
        <div className='md:inline hidden'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 md:mb-20 mb-10' style={{width: "48rem"}}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-market" className='text-sm text-gray-600' > <LeftOutlined /> จัดการร้านค้า</Link>
            <h2 className='text-2xl mb-4 mt-2'>แก้ไขร้านค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-market" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">แก้ไขร้านค้า</h2>
          </div>

          <Form form={form} onFinish={onSubmit} layout="vertical">
            <div className='bg-white p-6 rounded-lg md:mt-0 mt-[62px]'>
              <div className='flex justify-center mb-2'>
                <label>รูปปกร้านค้า</label>
              </div>
              <div className='flex justify-center'>
                <Form.Item name="logo" valuePropName="fileListLogo">
                  <ImgCrop rotationSlider>
                      <Upload
                        {...props}
                        listType="picture-circle"
                        fileList={fileListLogo}
                        onChange={handleChangeLogo}
                        onPreview={handlePreview}
                        >
                        {fileListLogo.length >= 1 ? null : uploadButton}
                      </Upload>
                    </ImgCrop>
                </Form.Item>
              </div>
              <Form.Item
                label="ชื่อร้านค้า"
                name="name"
                rules={[{ required: true, message: 'กรุณาใส่ชื่อร้านค้า' }]}
              >
                <Input placeholder='กรุณาใส่ชื่อร้านค้า' />
              </Form.Item>

              <Form.Item label="คำอธิบายร้านค้า" name="description" className='mt-4'>
                <TextArea placeholder='ป้อนคำอธิบายร้านค้า' />
              </Form.Item>

            </div>

            <div className='mt-4 bg-white p-6 rounded-lg'>
                <Form.Item label="เบอร์โทร" name="phone" 
                  tooltip={{
                    icon:  <PhoneOutlined />,
                  }}
                >
                  <Input placeholder=''/>
                </Form.Item>

                <Form.Item label="อีเมล" name="email" className='pt-6'
                  tooltip={{
                    icon:   <MailOutlined />,
                  }}
                >
                  <Input placeholder=''/>
                </Form.Item>

                <div className='pb-1 pt-6'><label>ช่องทางการติดต่อ <LinkOutlined className='opacity-50'/></label></div>
                {contactlinks.map((contact, index) => (
                  <div key={index} class="flex md:gap-10 gap-2">
                    <div class="place-self-center md:translate-x-4">              
                      <img src={contactIcons[contact.source]} className="w-10 rounded-lg" alt={`${contact.source} Icon`} />
                    </div>
                    <div className='w-full'>
                      <label>{contact.source.charAt(0).toUpperCase() + contact.source.slice(1)}</label>
                      <div className='flex gap-2'>
                        <Form.Item 
                          label="" 
                          name={`contactlinks_${index}`}
                          initialValue={contact.word}
                          rules={[{ required: true, message: 'กรุณาใส่ชื่อตัวเลือก' }]}
                          className='grow'
                        >
                          <Input
                            placeholder="ช่องทางการติดต่อ"
                            onChange={(e) => {
                              const newContacts = [...contactlinks];
                              newContacts[index].word = e.target.value;
                              setContactLinks(newContacts);
                            }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{ fontSize: '16px', color: 'red' }}
                          onClick={() => handleRemoveContact(index)}
                          className='mb-2'
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {contactlinks.length < 8 && (
                  <Button
                    type="dashed"
                    onClick={handleAddContact}
                    className='w-full flex justify-center items-center	'        
                  >
                    <PlusOutlined/> เพิ่มช่องทางการติดต่อ
                  </Button>
                )}

                <Modal
                  title="เลือกช่องทางการติดต่อ"
                  visible={isModalVisible}
                  footer={null}
                  onCancel={() => setIsModalVisible(false)}
                >
                  <div className="grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-2">
                    {Object.keys(contactIcons).map((type) => (
                      <Button
                        key={type}
                        onClick={() => handleSelectContactType(type)}
                        type="default"
                        className='w-full h-full flex flex-col items-center justify-center space-y-2 pt-2'
                      >
                        <img src={contactIcons[type]} alt={`${type} Icon`} className="w-8 h-8" />
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Button>
                    ))}
                  </div>
                </Modal>
            </div>

            <div className='mt-4 bg-white p-6 rounded-lg'>
              <div className='pb-4 flex justify-between'>
                <label>ตำแหน่งของร้านค้า</label>
                <div className='flex'>
                  <Tooltip title="หากปิดไว้ จะไม่แสดงแผนที่ในหน้าร้านค้า" className="hover:cursor-help text-gray-400 mr-2 mb-2"><QuestionCircleOutlined /></Tooltip>
                  <Form.Item
                    name="locationStatus"
                    valuePropName="checked"
                  >
                    <Switch
                      style={{ width: '90px' }}
                      checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                    />
                  </Form.Item>
                </div>
              </div>
              {LoadMap}
            </div>

            <div className='mt-4 bg-white p-6 rounded-lg'>
                <div className='pb-4'><label>ธีมร้านค้า</label></div>
                  <List className="flex-row flex-wrap">
                    <ListItem className="p-0 max-w-40">
                      <label
                        htmlFor="horizontal-list-default"
                        className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            checked={selectedTheme === 'default'}
                            name="horizontal-list"
                            id="horizontal-list-default"
                            ripple={false}
                            className="hover:before:opacity-0"
                            containerProps={{className: "p-0",}}
                            onChange={() => setSelectedTheme('default')}
                          />
                        </ListItemPrefix>
                        <Typography>
                          <img
                            src={default_theme}
                            className='rounded-xl border-dashed  border-4  border-gray-200'
                          />
                        </Typography>
                      </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                        <label
                          htmlFor="horizontal-list-dark"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            checked={selectedTheme === 'dark'}
                            name="horizontal-list"
                            id="horizontal-list-dark"
                            ripple={false}
                            className="hover:before:opacity-0"
                            containerProps={{className: "p-0",}}
                            onChange={() => setSelectedTheme('dark')}
                          />
                        </ListItemPrefix>
                          <Typography>
                            <img
                            src={dark_theme}
                            className='rounded-xl border-dashed border-4 border-gray-200'
                            />
                          </Typography>
                          </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                                  <label
                                    htmlFor="horizontal-list-beige"
                                    className="flex w-full cursor-pointer items-center px-3 py-2"
                                  >
                                    <ListItemPrefix className="mr-3">
                                      <Radio
                                        checked={selectedTheme === 'beige'}
                                        name="horizontal-list"
                                        id="horizontal-list-beige"
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        onChange={() => setSelectedTheme('beige')}
                                      />
                                    </ListItemPrefix>
                                    <Typography>
                                      <img
                                        src={beige_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                                  <label
                                    htmlFor="horizontal-list-blue"
                                    className="flex w-full cursor-pointer items-center px-3 py-2"
                                  >
                                    <ListItemPrefix className="mr-3">
                                      <Radio
                                        checked={selectedTheme === 'blue'}
                                        name="horizontal-list"
                                        id="horizontal-list-blue"
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        onChange={() => setSelectedTheme('blue')}
                                      />
                                    </ListItemPrefix>
                                    <Typography>
                                      <img
                                        src={blue_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                    <ListItem className="p-0 max-w-40">
                                  <label
                                    htmlFor="horizontal-list-green"
                                    className="flex w-full cursor-pointer items-center px-3 py-2"
                                  >
                                    <ListItemPrefix className="mr-3">
                                      <Radio
                                        checked={selectedTheme === 'green'}
                                        name="horizontal-list"
                                        id="horizontal-list-green"
                                        ripple={false}
                                        className="hover:before:opacity-0"
                                        containerProps={{
                                          className: "p-0",
                                        }}
                                        onChange={() => setSelectedTheme('green')}
                                      />
                                    </ListItemPrefix>
                                    <Typography>
                                      <img
                                        src={green_theme}
                                        className='rounded-xl border-dashed border-4 border-gray-200'
                                      />
                                    </Typography>
                                  </label>
                    </ListItem>
                  </List>
            </div>

            <div className='mt-4 bg-white p-6 rounded-lg'>
                <div className='pb-4'><label>รูปแบนเนอร์ร้านค้า</label></div>
                <div className='flex justify-center	'>
                    <Form.Item name="banners" valuePropName="fileListBanners" >
                      <Upload.Dragger
                      {...props}
                      name="files"
                      multiple={true}
                      maxCount={4}
                      fileList={fileListBanners}
                      onChange={handleChangeBanners}
                      onPreview={handlePreview}
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">อัปโหลดรูปภาพ</p>
                        <p className="ant-upload-hint">อัตราส่วนรูปภาพที่แนะนำคือ 16 ต่อ 9 หรือ 1600×900พิกเซล</p>
                      </Upload.Dragger>
                    </Form.Item>
                </div>
              </div>

            <Form.Item>
              <div className='flex justify-end mt-4'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	pt-3 pb-3 px-3 z-50'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
              >
                <div className=''>
                      <button 
                        type="submit"
                        disabled={isSubmitting}
                        className= 
                          "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"> 
                          {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                      </button>
                </div>
              </Form.Item>

          </Form>

          <Modal 
            open={previewOpen} 
            title={previewTitle} 
            footer={null} onCancel={() => setPreviewOpen(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>

        <div></div>
      </div>
    </>
  );
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default EditMarket;