import React, { useState , Fragment, useEffect, useRef } from 'react';
import { Listbox , Transition } from '@headlessui/react';
import axios from 'axios' //connect backend
import { useParams , useNavigate, Link ,useLocation} from "react-router-dom";

import { useUserAuth } from '../../context/UserContext.jsx';
import './Calcu.css';

import { LeftOutlined , UpOutlined } from '@ant-design/icons'

import {
    Button,
    Checkbox,
    Form,
    InputNumber,
    Select,
    Switch,
  } from 'antd';
  
export function LineshopCalcu() {
  const {user} = useUserAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [fee, setFee] = useState(null);
  const [mallcheck, setMallcheck] = useState(false);
  const [filteredFees, setFilteredFees] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [calcuData, setCalcuData] = useState(null);
  const formRef = useRef(null);
  const [disabled_button, setDisabledButton] = useState(false);
  const [form] = Form.useForm(); 


  useEffect(() => {
    if (user && user._id) {
      const fetchFee = async () => {
        
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/fee/lineshop/all`);
          const FeeData = response.data;
          setFee(FeeData);
        } catch (err) {
          console.error('Error fetching Fee:', err);
        }
      };

      const fetchCalcu = async () => {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/calcu/All`);
          const calcuData = response.data;

          setCalcuData(calcuData);
        } catch (err) {
            console.error('Error fetching calcu:', err);
        }
      };
      fetchFee();
      fetchCalcu();
    }

  }, [user, id]);

  //คำนวณค่าจัดส่ง
  const [isCalcuShippingOpen, setIsCalcuShippingOpen] = useState(false);
  const [ShippingError, setShippingError] = useState(false)
  const [weight, setWeight] = useState(1);
  const [location1, setLocation1] = useState("1");
  const [location2, setLocation2] = useState("1");

  //ฟังชันเปิดปิดค่าส่ง
  useEffect(() => {
    if (isCalcuShippingOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up the class on unmount
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isCalcuShippingOpen]);

  const handleCalcushippingOpen = () => {
    setIsCalcuShippingOpen(!isCalcuShippingOpen);
  };
  const handleCalcushippingClose = () => {
    setIsCalcuShippingOpen(false);
  };

  const handleCalcushippingChange = (e, setter) => {
    const { value } = e.target;
    // ตรวจสอบว่าค่าที่ป้อนเข้ามาเป็นตัวเลขหรือไม่
    if (!isNaN(value)) {
      setter(value);
    }
  };

  const [shippingCost, setShippingCost] = useState(45);
  let ShippingCost = 45;
  useEffect(() => {
  if(location1==2 && location2==2 && calcuData && calcuData.length > 13){
    if(weight<=1){
      ShippingCost = calcuData[7].value;
    }else if(weight>1 && weight<=2){
      ShippingCost = calcuData[8].value;
    }else if(weight>2 && weight<=3){
      ShippingCost = calcuData[9].value;
    }else if(weight>3 && weight<=5){
      ShippingCost = calcuData[10].value;
    }else if(weight>5 && weight<=10){
      ShippingCost = calcuData[11].value;
    }else if(weight>10 && weight<=15){
      ShippingCost = calcuData[12].value;
    }else if(weight>15){
      ShippingCost = calcuData[13].value;
    }
  }
  else if (location1 === "2" && location2 === "1"  && calcuData && calcuData.length > 20){
    if(weight<=1){
      ShippingCost = calcuData[14].value;
    }else if(weight>1 && weight<=2){
      ShippingCost = calcuData[15].value;
    }else if(weight>2 && weight<=3){
      ShippingCost = calcuData[16].value;
    }else if(weight>3 && weight<=5){
      ShippingCost = calcuData[17].value;
    }else if(weight>5 && weight<=10){
      ShippingCost = calcuData[18].value;
    }else if(weight>10 && weight<=15){
      ShippingCost = calcuData[19].value;
    }else if(weight>15){
      ShippingCost = calcuData[20].value;
    }
  }
  else if (location1 === "1" && (location2 === "2" || location2 === "1")  && calcuData && calcuData.length > 27){
    if(weight<=1){
      ShippingCost = calcuData[21].value;
    }else if(weight>1 && weight<=2){
      ShippingCost = calcuData[22].value;
    }else if(weight>2 && weight<=3){
      ShippingCost = calcuData[23].value;
    }else if(weight>3 && weight<=5){
      ShippingCost = calcuData[24].value;
    }else if(weight>5 && weight<=10){
      ShippingCost = calcuData[25].value;
    }else if(weight>10 && weight<=15){
      ShippingCost = calcuData[26].value;
    }else if(weight>15){
      ShippingCost = calcuData[27].value;
    }
  }
  setShippingCost(ShippingCost);
  
  if(weight>20){
    setShippingError(true);
  }else{
    setShippingError(false);
  }
})

  const [sellprice, setSellPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [productcost, setProductCost] = useState(0);
  const [othercost, setOtherCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [checked, setChecked] = useState(false);

  const [pricefeesValue, setPricefeesValue] = useState(0);
  const [commissionfeesValue, setCommissionfeesValue] = useState(0);
  const [affiliate, setAffiliate] = useState(0);
  const [affiliateValue, setAffiliateValue] = useState(0);
  const [vatValue, setVatValue] = useState(0);

  const [allincom, setAllIncome] = useState(0);
  const [allprofit, setAllProfit] = useState(0); 
  const [allprofit_percent, setAllProfitPercent] = useState(0); 

  const [lastallincom, setLastAllIncome] = useState(0);
  const [lastallprofit, setLastAllProfit] = useState(0); // สถานะสำหรับเก็บค่ากำไร
  const [lastallprofit_percent, setLastAllProfitPercent] = useState(0);

  const [customprofit, setCustomprofit] = useState(0);
  const [CustomSellingPrice, setCustomSellingPrice] = useState(0);
  const [CustomLastProfit, setCustomLastProfit] = useState(0);

    
  // ฟังก์ชันสำหรับการเปลี่ยนแปลงค่าในช่องข้อมูล
  const onChange = (value) => {
    setDisabledButton(false)
  };

  // ฟังก์ชันสำหรับการเปลี่ยนแปลงค่าในช่อง check custom
  const toggleChecked = (value) => {
    setChecked(value);
    setDisabledButton(false)
  };


  //เริ่มการคำนวณค่าธรรมเนียม
  const handleSubmit = (values , shouldScroll) => {
    setDisabledButton(true)

    const { sellprice, productcost, othercost, shipping,
      quantity, discount, 
      vatcheck, customcheck, customprofit} = values; // เรียกใช้ค่าราคาขายและต้นทุนสินค้าจากฟอร์ม
      let pricefeesItem = fee.find(item => item.des === "ค่าธรรมเนียมการชำระเงิน");
      let pricefees = pricefeesItem ? parseFloat(pricefeesItem.volume) : 0.03;     
      let vatfee=0.07;
      let vatValue=0;
      let CustomSellingPrice=0;
      let CustomLastProfit=0;
      
      let selectedItem = fee.find(item => item.des === "ค่าธรรมเนียมการใช้บริการ");
      let commissionfees = selectedItem && selectedItem.volume ? parseFloat(selectedItem.volume) : 0.03;

    let sellpriceValue=(sellprice-discount)*quantity; //ราคาที่ขาย
    
    let allcost=(productcost+othercost)*quantity; //ต้นทุนสินค้า

    let shippingValue = shipping*quantity;

    let commissionfeesValue = (sellpriceValue+shippingValue)*commissionfees //ค่าคอมมิชชั่น

    let pricefeesValue = (sellpriceValue+shippingValue)*pricefees; //ค่าธรรมเนียมคำสั่งซื้อ

    //ตรวจสอบว่าคำนวณvat ด้วยไหม
    if(vatcheck==true){
      vatValue=sellpriceValue*vatfee;
    }

    //ตรวจสอบว่าคำนวณกำไรตามต้องการไหม
    if(customcheck==true){
      let customprofitValue = (customprofit/100)*allcost;

      let totalprofitsandcost = allcost+customprofitValue;
      let customcommissionfees = commissionfees*totalprofitsandcost;
      let custompricefees = pricefees*(totalprofitsandcost+shippingValue);
    
      let CustomVatfee=0;
      if(vatcheck==true){
        CustomVatfee=vatfee*totalprofitsandcost;
      }

      CustomSellingPrice=(totalprofitsandcost+customcommissionfees+custompricefees+CustomVatfee);
      CustomLastProfit=(CustomSellingPrice-allcost-(customcommissionfees+custompricefees+CustomVatfee));

      setCustomprofit(customprofit);
      setCustomSellingPrice(CustomSellingPrice/quantity);
      setCustomLastProfit(CustomLastProfit);

    }
    
    //ลูกค้าไม่ได้ซื้อผ่านแคมเปญของ Line Shopping
    let allincom = sellpriceValue-(pricefeesValue+vatValue) //รายได้รวม
    let allprofit = sellpriceValue-(allcost+pricefeesValue+vatValue) //กำไรรวม
    let allprofit_percent = (allprofit/sellpriceValue)*100

    //เมื่อลูกค้าซื้อผ่านแคมเปญของ Line Shopping
    let lastallincom = sellpriceValue-(commissionfeesValue+pricefeesValue+vatValue) //รายได้รวม
    let lastallprofit = sellpriceValue-(allcost+commissionfeesValue+pricefeesValue+vatValue) //กำไรรวม
    let lastallprofit_percent = (lastallprofit/sellpriceValue)*100

    // กำหนดค่ากำไรให้กับสถานะ
    setSellPrice(sellprice); 
    setProductCost(productcost);
    setOtherCost(othercost);
    setDiscount(discount);
    setQuantity(quantity);

    setPricefeesValue(pricefeesValue);
    setCommissionfeesValue(commissionfeesValue);
    setVatValue(vatValue);

    setAllIncome(allincom);
    setAllProfit(allprofit);
    setAllProfitPercent(allprofit_percent)

    setLastAllIncome(lastallincom);
    setLastAllProfit(lastallprofit);
    setLastAllProfitPercent(lastallprofit_percent);


    if (sellprice && productcost) {
      scrollToBottom();
    }
    if (!sellprice || !productcost) {
      scrollToTop();
    }

    //console
    console.log('sellprice', sellprice);
  };

  const reset_result = () => {
    setCustomprofit(0);
    setCustomSellingPrice(0);
    setCustomLastProfit(0);


    setSellPrice(0); 
    setProductCost(0);
    setOtherCost(0);
    setDiscount(0);
    setQuantity(0);

    setPricefeesValue(0);
    setCommissionfeesValue(0);
    setVatValue(0);

    setAllIncome(0);
    setAllProfit(0);
    setAllProfitPercent(0)

    setLastAllIncome(0);
    setLastAllProfit(0);
    setLastAllProfitPercent(0);
  }

  const handleSubmitWithScroll = (values) => {
    handleSubmit(values, true);
    formRef.current.submit();
  };
  const handleSubmitWithoutScroll = (values) => {
    handleSubmit(values, false);
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const scrollToBottom = () => {
    const bottomComponent = document.getElementById('result-component');
    bottomComponent.scrollIntoView({ behavior: 'smooth' });
  };
  const checkScrollPosition = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  useEffect(() => {
    if (!fee) return;
  
    const filtered = fee.filter(item => 
        item.name === 'tiktok_commission' && 
        (mallcheck ? item.market === 'mall' : item.market === 'none')
      ).sort((a, b) => a.no - b.no); // เรียงจากเลขน้อยไปมาก
  
    setFilteredFees(filtered);
    setDisabledButton(false);
  
  }, [mallcheck,fee]);



  return (
    <div>
    <div className='flex flex-wrap justify-center'>
      <div
        className={`fixed bottom-20 right-4 bg-white px-4 py-2 rounded-full shadow-lg hover:bg-gray-50 transition-opacity duration-500 aspect-square	 ${
          showScrollButton ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      > <UpOutlined onClick={scrollToTop} className='text-xl mt-1.5 text-gray-500'/>
      </div>

      <div className="form-container rounded-2xl max-w-max mx-2 grow mb-2 mt-5" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', paddingTop: '15px' }}>
        {id ?
        <div className="text-gray-600">
          <label className="cursor-pointer" onClick={() => navigate(`/detail-product/${id}`)}><LeftOutlined /> ย้อนกลับ</label>
        </div>
        :''}

        <div className='flex justify-between md:block hidden'>
          <label className='top_label text-right'> คำนวณค่าธรรมเนียม Line Shopping </label>
        </div>

        <div className="flex justify-center">

        <Form
            ref={formRef}
            onFinish={handleSubmitWithoutScroll}
            className='px-[15px] md:pt-[30px] pb-[15px] max-w-[440px]'
            labelCol={{ span: 11 }}
            wrapperCol={{ span: 13}}
            disabled={!user}
            form={form}
        >

          <Form.Item
            className='middle_label'
            label="ราคาขาย"
            name="sellprice"
            rules={[{ required: true, message: 'กรุณากรอกข้อมูล!' }]}
          >
            <InputNumber  min={0} placeholder='0' onChange={onChange}/>
          </Form.Item>  
      
          <Form.Item
            label="ต้นทุนสินค้า"
            name="productcost"
            rules={[{ required: true, message: 'กรุณากรอกข้อมูล!' }]}
          >
            <InputNumber  min={0} placeholder='0'      onChange={onChange}/>
          </Form.Item>

          <Form.Item
            label="ค่าใช้จ่ายอื่นๆ"
            name="othercost"
            tooltip="ค่ากล่อง ค่าแพ็คเกจสินค้า ค่าจ้างพนักงาน และอื่นๆ"
            initialValue={0}
            style={{ color: user ? '#000' : '#ccc' }}
          >
            <InputNumber  min={0} placeholder='0' onChange={onChange}/>

          </Form.Item>

          {/* คำนวณค่าจัดส่ง */}
          {user && (
              <Transition
                show={isCalcuShippingOpen}
                enter="transition-transform ease-out transition-opacity duration-300"
                enterFrom="opacity-0 -translate-y-10 "
                enterTo="opacity-100 translate-y-0"
                leave="transition-opacity ease-in duration-0"
                leaveFrom="opacity-100 "
                leaveTo="opacity-0"
              >  
              <>
              <div className="overlay fixed inset-0 bg-black opacity-50 z-40" onClick={handleCalcushippingClose}></div>
              <div className="fixed -mt-80 overscroll-none overflow-hidden  bg-white p-4 shadow-lg rounded z-50">
                <form className="flex flex-col">
                  <label>น้ำหนัก (กิโลกรัม)</label>
                  <input
                    type="number"
                    value={weight}
                    onChange={(e) => handleCalcushippingChange(e, setWeight)}
                    className="border rounded p-2 mb-2"
                    placeholder="KG"
                  />

                  <label>จัดส่งจาก</label>
                  <Listbox value={location1} onChange={setLocation1}>
                    {({ open }) => (
                      <>
                        <Listbox.Button className="border rounded p-2  block">
                          {location1 === "1" ? "ต่างจังหวัด" : "กรุงเทพฯ และปริมณฑล"}
                        </Listbox.Button>
                        <Transition
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute py-1 overflow-auto min-w-44 text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            <Listbox.Option value="1" className="cursor-pointer p-2 hover:bg-indigo-700 hover:text-white">ต่างจังหวัด</Listbox.Option>
                            <Listbox.Option value="2" className="cursor-pointer p-2 hover:bg-indigo-700 hover:text-white">กรุงเทพฯ และปริมณฑล</Listbox.Option>
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>
                  
                  <label className="mt-2">ส่งไปไปที่</label>
                  <Listbox value={location2} onChange={setLocation2}>
                    {({ open }) => (
                      <>
                        <Listbox.Button className="border rounded p-2 block">
                          {location2 === "1" ? "ต่างจังหวัด" : "กรุงเทพฯ และปริมณฑล"}
                        </Listbox.Button>
                        <Transition
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-20"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute  py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            <Listbox.Option value="1" className="cursor-pointer p-2 hover:bg-indigo-700 hover:text-white">ต่างจังหวัด</Listbox.Option>
                            <Listbox.Option value="2" className="cursor-pointer p-2 hover:bg-indigo-700 hover:text-white">กรุงเทพฯ และปริมณฑล</Listbox.Option>
                          </Listbox.Options>
                        </Transition>
                      </>
                    )}
                  </Listbox>

                  <p className='font-bold text-xl mt-2'>ค่าส่ง: {shippingCost} บาท</p>
                  {ShippingError &&
                    <p className='font-bold text-xl text-red-500'>น้ำหนักเกิน!</p>
                  }
                  <button type="button" onClick={handleCalcushippingClose} className="bg-red-500 text-white py-2 px-4 mt-4 hover:bg-red-600 rounded">
                    ปิด
                  </button>
                </form>
                </div>
                </>
              </Transition>
         
          )}

          <div className="flex justify-center" style={{ display: 'flex', alignItems: 'center' }}>
            
            {user ? 
            <label 
              onClick={handleCalcushippingOpen} 
              className="rounded-lg p-1.5 text-md mr-3 whitespace-nowrap text-white bg-indigo-500 hover:bg-indigo-800 cursor-pointer" 
            >

              คำนวณค่าส่ง
            </label>
            : 
            <label 
              className="rounded-lg p-1.5 text-md mr-3 whitespace-nowrap text-white bg-indigo-500 cursor-not-allowed" 
            >

              คำนวณค่าส่ง
            </label>
            }

            <Form.Item
              label="ค่าจัดส่ง"
              name="shipping"
              initialValue={45}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18}}
            >
              <InputNumber  min={0}  placeholder='0' onChange={onChange}/>
            </Form.Item>
          </div>

          <Form.Item
            label="จำนวน"
            name="quantity"
            tooltip="กรณีที่ต้องการคำนวณกำไรที่ได้จากการขายสินค้าหลายชิ้น"
            initialValue={1} 
          >
            <InputNumber  min={1} placeholder='0' onChange={onChange}/>

          </Form.Item>
      
          <Form.Item
            label="ส่วนลดให้ลูกค้า"
            name="discount"
            tooltip="ส่วนลดที่ทางร้านออกให้ลูกค้าเอง (โคดส่วนลด/โปรโมชั่นส่วนลด)"
            initialValue={0}
          >
            <InputNumber  min={0} placeholder='0' defaultValue={''} onChange={onChange}/>
          </Form.Item>


          <div className="flex flex-row pb-2">
              <p className="line-through 	w-full h-px bg-gray-300"></p>
          </div>

          <div className='px-8'>

            <Form.Item
              name='vatcheck'
              valuePropName="checked"
              label="คำนวณ VAT 7%"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 10}}
              >
                <Switch
                    style={{ width: '45px' }}
                    onChange={onChange}     
                >
                  คำนวณ VAT 7%
                </Switch>
            </Form.Item>

            <Form.Item
              name='customcheck'
              valuePropName="checked"
              label="คำนวณกำไรที่ต้องการ"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 10}}
              >
                <Switch
                    style={{ width: '45px' }}
                    checked={checked}
                    onChange={toggleChecked}
                >
                    คำนวณกำไรที่ต้องการ
                </Switch>
            </Form.Item>
          </div>

          {checked?
              <Form.Item
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 10}}
                  label="เปอร์เซ็นต์กำไร"
                  name='customprofit'
                  initialValue={0}
                  tooltip="จำนวนเปอร์เซ็นกำไรที่ต้องการ คิดจากต้นทุนสินค้า (10=10% , 40=40%)"
                  >
                    <InputNumber min={0} placeholder='%' onChange={onChange}/>

              </Form.Item>
             : null
          }

          {/* ปุ่มsubmit mini */}
          <div
            className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen pt-3 pb-3 px-3'
            style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
          >
            <div className='grid grid-cols-3 gap-3'>
              <button 
                type="button"
                onClick={() => {  
                  handleSubmitWithScroll(formRef.current.getFieldsValue());
                  scrollToBottom();
                }}
                className={`text-xl px-6 py-2 rounded-md col-span-2 ${
                  user && !disabled_button
                    ? 'bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-800 text-white cursor-pointer'
                    : 'bg-gray-300 text-gray-500 cursor-auto'
                }`}                        
                disabled={!user || disabled_button}
                    
                > 
                  คำนวณ
              </button>

              <Button 
                danger 
                className='h-full text-lg'
                onClick={() => {
                  formRef.current.resetFields();
                  reset_result();
                  if (checked) {
                    toggleChecked(false);
                  }
                  scrollToTop();
                  setDisabledButton(false);
                }}
              >
                เริ่มใหม่
              </Button>
            </div>
          </div>
        </Form>
        </div>
      </div>


      <div id="result-component" className="bg-white min-w-80 md:max-w-max max-h-fit rounded-2xl md:ml-6 mx-2 mb-2 md:mt-5 mt-4" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
        {/* ปุ่มsubmit */}
        <div className='hidden md:block px-3 pt-3'>
          <div class="grid grid-cols-3 gap-2 no-wrap">
            <button 
              type="button"
              className={`text-xl px-6 py-2 rounded-md col-span-2 ${
                user && !disabled_button
                  ? 'bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-800 text-white cursor-pointer'
                  : 'bg-gray-300 text-gray-500 cursor-auto'
              }`}       
              onClick={() => formRef.current.submit()} // เรียก form.submit()
              disabled={!user || disabled_button}
            > 
              คำนวณ
            </button>

            <Button 
              className='h-full text-lg'
              danger 
              onClick={() => {
                formRef.current.resetFields();
                reset_result();
                if (checked) {
                  toggleChecked(false);
                }
                setDisabledButton(false);
              }}
            >
              เริ่มใหม่
            </Button>
          </div>
        </div>

        <div className='rounded-2xl px-5 pb-5 grow md:mt-3 pt-[15px] border'>
          <h2 className='text-center text-xl underline decoration-wavy'>ผลลัพธ์</h2>
          
          <div className='flex-col mid_label' >

            <div className='mt-4 break-words whitespace-normal'>
              <label className=''>ราคาขาย : </label>
              <span>{sellprice ? sellprice.toFixed(2) : '0.00'} บาท</span>
              </div>

            <div>
              <label className='yellow break-words whitespace-normal'>ต้นทุนสินค้า : </label>
              <span>{productcost ? productcost.toFixed(2) : '0.00'} บาท</span>
            </div>

            <div>
              <label className='yellow break-words whitespace-normal'>ค่าใช้จ่ายอื่นๆ : </label>
              <span>{othercost.toFixed(2)} บาท</span>
              
            </div>

            <div>
              <label className='yellow break-words whitespace-normal'>ส่วนลดให้ลูกค้า : </label>
              <span>{discount.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='break-words whitespace-normal'>จำนวน : </label>
              <span>{quantity.toFixed(0)} ชิ้น</span>
            </div>

            <div className="flex flex-row pb-2">
                <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
            </div>

            <div>
              <label className='yellow break-words whitespace-normal'>ค่าธรรมเนียมการขาย : </label>
              <span>{pricefeesValue.toFixed(2)} บาท</span>
            </div>

            <div className='hidden'>
              <label className='yellow break-words whitespace-normal'>ค่าคอมพันธมิตร : </label>
              <span>{affiliateValue.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='yellow break-words whitespace-normal'>หักค่า Vat7% : </label>
              <span>{vatValue.toFixed(2)} บาท</span>
            </div>

            <div className="flex flex-row pb-2">
                <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
            </div>

            <Form.Item
                label=">"
                tooltip="คำสั่งซื้อที่มาจากการโปรโมทของทางร้านค้าเอง ได้แก่ คำสั่งซื้อโดยการออกออเดอร์ผ่านแชทของร้านค้า หรือผ่านลิงก์ร้านค้าโดยร้านแชร์ให้ผู้ซื้อโดยตรง (shop.line.me/@shopname)"
              >
              <div>
                <label className='text-sm text-slate-500'>คำสั่งซื้อที่มาจากการโปรโมทของทางร้านค้าเอง</label>
              </div>
            </Form.Item>

            <div>
              <label className='darkgreen break-words whitespace-normal'>รวมยอดที่ร้านจะได้รับ : </label>
              <span>{allincom.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='green'>รวมกำไรที่จะได้รับ : </label>
              <span className='break-words whitespace-normal'>{allprofit.toFixed(2)} บาท</span>
              <span className='text-sm'> ({allprofit_percent.toFixed(2)}%)</span>
            </div>


              <div className="flex flex-row pb-2">
                  <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
              </div>

              <Form.Item
                label=">"
                tooltip="เมื่อลูกค้าซื้อผ่านสื่อ และแคมเปญของ Line Shopping (Line Shopping Home feed , Live Hall , Event , Search , Gift Zone , WOW Items และอื่นๆ)"
              >
              <div>
                <label className='text-sm text-slate-500'>เมื่อลูกค้าซื้อผ่านแคมเปญของ Line Shopping</label>
              </div>
              </Form.Item>

              <div>
                <label className='yellow break-words whitespace-normal'>ค่าคอมมิชชั่น : </label>
                <span>{commissionfeesValue.toFixed(2)} บาท</span>
              </div>

              <div>
                <label className='darkgreen break-words whitespace-normal'>รวมยอดที่ร้านจะได้รับ : </label>
                <span>{lastallincom.toFixed(2)} บาท</span>
              </div>

              <div>
                <label className='green break-words whitespace-normal'>รวมกำไรที่จะได้รับ : </label>
                <span>{lastallprofit.toFixed(2)} บาท</span>
                <span className='text-sm'> ({lastallprofit_percent.toFixed(2)}%)</span>
              </div>


          {checked == true ?
          <>
            <div className="flex flex-row pb-2">
                <p className="line-through mt-4	w-full h-px bg-gray-300"></p>
            </div>

            <div>
              <label className='blue break-words whitespace-normal'>หากต้องการกำไรสินค้า </label>
              <span>{customprofit.toFixed(2)} %</span>
            </div>

            <div>
              <label>ราคาที่ควรขายคือ </label>
              <span className='break-words whitespace-normal'>{CustomSellingPrice.toFixed(2)} บาท</span>
            </div>

            <div>
              <label className='green'>จะได้กำไรประมาณ </label>
              <span className='break-words whitespace-normal'>{CustomLastProfit.toFixed(2)} บาท</span>
            </div>
          </>
            : ''}

          </div>
        </div>
      </div>
    </div>
    </div>

  );

  
}
