import React from 'react';

import { useUserAuth } from '../context/UserContext.jsx';
import { Nav,NavNoUser } from '../components/Nav';
import { Spin,FloatButton } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";


function About() {
  const{user} =  useUserAuth();
  console.log("user" , user)

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}

      <div className="flex justify-center items-center ">
        <div className='p-4 md:m-4 m-2 bg-white border rounded-lg max-w-7xl'>
            <div className="md:px-14 px-2 py-10">
              <div className=" whitespace-pre-line">
                  <p>หน้าร้านสำหรับแสดงสินค้า</p>
                  <p>สามารถลงได้หลายร้านในบัญชีเดียว</p>
                  <p>มีโปรแกรมคำนวณหาค่าธรรมเนียม รายได้ และกำไร จากการลงขายสินค้า สามารถคำนวณหาค่าธรรมเนียมและรายได้จากการลงขายสินค้าผ่านแพลตฟอมอีคอมเมิซชื่อดัง อย่าง Tiktok Shop , Lazada และ Shopee</p>
                  <p>ระบบออกใบเสร็จรับเงิน/ใบกำกับภาษี</p>
                  <p>ระบบออกใบเสนอราคา</p>
                  <p>บันทึกรายชื่อลูกค้า</p>
                  <p>สามารถใช้งานได้ผ่านเว็บไซต์ได้ทันที ใช้งานได้ทั้งสองแพลตฟอร์ม</p>
                  <p>ใช้งานผ่านอุปกรณ์คอมพิวเตอร์</p>
                  <p>ใช้งานผ่านโทรศัพมือถือ</p>
                  <p>มีทีมงานพร้อมให้ความช่วยเหลือ</p>    
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default About
