import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Popover, Space , message, Spin,Image,QRCode,Carousel, Tooltip } from 'antd';
import { LeftOutlined,ShareAltOutlined,MailFilled,PhoneFilled, EnvironmentFilled, StopFilled } from '@ant-design/icons';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import "./styles/detail-market.css";
import './styles/classic.css';

import lineIcon from '../../../../assets/lineLogo1.png';
import facebookIcon from '../../../../assets/facebookLogo2.png';
import instagramIcon from '../../../../assets/igLogo2.png';
import tiktokIcon from '../../../../assets/Tiktok_logo2.png';
import youtubeIcon from '../../../../assets/youtubeLogo2.png';
import xIcon from '../../../../assets/XLogo.jpeg';
import telegramIcon from '../../../../assets/telegramLogo.png';
import wechatIcon from '../../../../assets/WechatLogo.png';
import shopeeIcon from '../../../../assets/ShopeeLogo.png';
import lazadaIcon from '../../../../assets/LazadaLogo.png';
import whatsappIcon from '../../../../assets/whatsappLogo.png';
import snapchatIcon from '../../../../assets/SnapchatLogo.png';
import phoneIcon from '../../../../assets/phoneLogo.png';
import mailIcon from '../../../../assets/MailLogo.png';
import logoUndefined from '../../../../assets/marketundefined.svg';

function Classic({ product }) {
  const location = useLocation();
  const previousUrl = new URLSearchParams(location.search).get('previous');
  const navigate = useNavigate();
  const [markets, setMarkets] = useState({ logoUrl: [], name: '', description: '', linkline: '', linkfacebook: '', linkinstagram: '', linktiktok: '' });
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [initialPosition, setInitialPosition] = useState({ lat: 13.7248785, lng: 100.4683055 });
  const [locationButton, setLocationButton] = useState(false);
  const [locationStatus, setLocationStatus] = useState('ตำแหน่ง');
  const [theme, setTheme] = useState('default-theme');
  const [currentImage, setCurrentImage] = useState(null);
  const [loading, setLoading] = useState(true); // เพิ่ม state สำหรับโหลดข้อมูลตลาด


  useEffect(() => {
    let isMounted = true;
  
    if (!product || !product.market) return;
  
    const marketId = typeof product.market === 'object' ? product.market._id : product.market;

   console.log(product.variants)
  
    const fetchMarket = async () => {
      try {
        const marketResponse = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market/from_id_market/${marketId}?populate=location`);
  
        if (isMounted) {
          setTheme(marketResponse.data.theme);
          setMarkets(marketResponse.data);
          if (marketResponse.data.location?.lat && marketResponse.data.location?.lng) {
            setSelectedPosition({ lat: marketResponse.data.location.lat, lng: marketResponse.data.location.lng });
            setInitialPosition({ lat: marketResponse.data.location.lat, lng: marketResponse.data.location.lng });
          }
        }
      } catch (err) {
        console.error('Error fetching market:', err);
      } finally {
        if (isMounted) setLoading(false);
      }
    };
  
    fetchMarket();
  
    return () => {
      isMounted = false;
    };
  }, [product]);
  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }
  function getImageProductUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product`;
    return `${basePath}/${filename}`;
  }
  function getImageProductVariantUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_VARIANT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product/variant`;
    return `${basePath}/${filename}`;
  }

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(null);

  if (!product || !product.variants || !product.combinations) return null;

  const handleSelect = (variantName, optionName) => {
    let newSelectedOptions = { ...selectedOptions };

    // ถ้าเลือกตัวเลือกที่เลือกไปแล้ว ให้ยกเลิกการเลือก
    if (newSelectedOptions[variantName] === optionName) {
      newSelectedOptions[variantName] = undefined;
      setSelectedPrice(null); // รีเซ็ตราคาเมื่อยกเลิกการเลือก
    } else {
      // ถ้าเลือกตัวเลือกใหม่ ให้ตั้งค่าใหม่
      newSelectedOptions[variantName] = optionName;
    }
    
    // Check if all required variants have been selected
    const allVariantsSelected = product.variants.every(variant => 
      newSelectedOptions[variant.name] !== undefined
    );
    
    // Find a matching combination only if all variants are selected
    if (allVariantsSelected) {
      const foundCombination = product.combinations.find((comb) => {
        return Object.keys(newSelectedOptions).every(
          (key) => comb.variantCombination[key] === newSelectedOptions[key]
        );
      });
      
      if (foundCombination && foundCombination.stock > 0) {
        setSelectedPrice(foundCombination.price);
      } else {
        setSelectedPrice(null);
      }
    } else {
      // Don't update price until all variants are selected
      setSelectedPrice(null);
    }
    
    setSelectedOptions(newSelectedOptions);
  };

  // คำนวณราคาต่ำสุดและสูงสุดจาก combinations
const getPriceRange = () => {
  if (!product.combinations || product.combinations.length === 0) return { min: 0, max: 0 };

  const prices = product.combinations.map(comb => comb.price);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  const rangeprice = minPrice === maxPrice ? `${minPrice}` : `${minPrice} - ${maxPrice}`;

  return { rangeprice };
};
const { rangeprice } = getPriceRange();
  

  const contactIcons = {
    line: lineIcon,
    facebook: facebookIcon,
    instagram: instagramIcon,
    tiktok: tiktokIcon,
    youtube: youtubeIcon,
    x: xIcon,
    telegram: telegramIcon,
    wechat: wechatIcon,
    shopee: shopeeIcon,
    lazada: lazadaIcon,
    whatsapp: whatsappIcon,
    snapchat: snapchatIcon,
    phone: phoneIcon ,
    mail: mailIcon ,
  };

  useEffect(() => {
    // ตั้งค่า theme ให้กับ root element ของหน้านี้
    const bg1 = document.querySelector('.bg-800');
    if (bg1) {  // ตรวจสอบว่า pageContent มีอยู่
      bg1.classList.remove('default-theme', 'dark-theme', 'beige-theme');
      bg1.classList.add(`${theme}-theme`);
    }

  }, [theme]);

  // กำหนดขนาดแผนที่
  const mapContainerStyle = {
    height: '400px',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  };
  // การตรวจสอบว่ามีการโหลด Script อยู่แล้วหรือไม่
  const shouldLoadMap = markets && locationButton;

  // ใช้ useMemo เพื่อหลีกเลี่ยงการเรนเดอร์ซ้ำ
  const LoadMap = useMemo(() => (
    shouldLoadMap && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={selectedPosition || initialPosition}
          zoom={12}
        >
          {selectedPosition && <MarkerF position={selectedPosition} />}
        </GoogleMap>
    )
  ), [initialPosition, selectedPosition, shouldLoadMap]);

  const locationClick = () => {
    if (locationButton === false) {
      setLocationButton(true);
      setLocationStatus('ซ่อน');
    }
    if (locationButton === true) {
      setLocationButton(false);
      setLocationStatus('ตำแหน่ง');
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => message.success("คัดลอกลิงค์สำเร็จ"))
      .catch(err => console.error('เกิดข้อผิดพลาดในการคัดลอกลิงค์:', err));
  };

  const content = (
    <div className='flex border rounded-md'>
      <div className='flex flex-col'>
        <div>
          <QRCode value={window.location.href || '-'} />
        </div>
        <div className='p-1 rounded-md max-w-80 overflow-hidden overflow-x-auto'>
          <p>{window.location.href}</p>
        </div>
      </div>
      <div className='content-center'>
        <button className='object-cover px-2 border-l-2 ' onClick={() => copyLink(window.location.href)}> <div className='border p-1 rounded-lg hover:bg-gray-50 active:bg-gray-200'>คัดลอกลิงค์</div></button>
      </div>
    </div>
  );

  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  // คำนวณค่าความชัด (opacity) และ เบลอ (blur)
  const opacity = Math.min(scrollY / 500, 1); // ถ้า scrollY ถึง 500px = 1
  const blurAmount = Math.min(scrollY / 100, 10); // เบลอสูงสุด 10px

if (loading) {
  return (
    <div className='flex justify-center'>
      <Spin fullscreen size="large" />
    </div>
  );
}

  return (
    <>
    <div className='bg-800'>
    <div className='flex justify-center bg-700-900 min-h-svh'>
      <div className='overscroll-none custom-scrollbar '>
      {markets.status === 'vacation' ?
        <div className='sticky top-0 mb-3 md:block bg-white rounded-b-lg p-6 bg-vacation text-2xl select-none text-center z-50'>
            <StopFilled  className='mr-2' />ตอนนี้ร้านกำลังปิดพักร้อนอยู่
        </div> :''
        }

        <div className='shrink hidden md:block md:rounded-md rounded-b-lg mb-1.5 md:my-3 p-4 bg-600 max-w-[978px]'>
            <div className='flex justify-between '>
                <Link to={previousUrl || '/manage-product/'} className='cursor-pointer'>
                  <div className='flex items-center justify-center py-2 px-2 rounded-full bg-white text-sm button-500 w-10 h-10 ease-in-out select-none duration-300'>
                      <LeftOutlined />
                  </div>
                </Link>

                <div 
                  className="mx-[10px] mt-2 text-nowrap truncate transition-opacity duration-300"
                >                  
                  <label className="text-xl text-top text-wrap">{product.name}</label>
                </div>

                <div>              
                  <Tooltip placement="bottomRight" title='แชร์สินค้า'>
                    <Popover content={content} trigger="click">
                        <button 
                          className='flex items-center justify-center py-2 px-2 rounded-full bg-white text-sm button-500 w-10 h-10 ease-in-out select-none duration-300' 
                          type="button" 
                        > 
                          <ShareAltOutlined className='scale-150'/>
                        </button>
                    </Popover>
                  </Tooltip>
                </div>
            </div>
        </div>

        <div 
          className="shrink md:hidden rounded-b-lg mb-1.5 fixed top-0 right-0 left-0 z-30"
          style={{ 
            opacity: opacity,
            height: '72px', // ความสูงต้องตรงกับ div เนื้อหา
            backdropFilter: `blur(${blurAmount}px)`,
            WebkitBackdropFilter: `blur(${blurAmount}px)`,
          }}
        />
        
        <div 
          className="shrink md:hidden rounded-b-lg mb-1.5 p-4 fixed top-0 right-0 left-0 z-40 transition-all duration-300 bg-700-800"
          style={{ 
            opacity: opacity * 0.7, // ทำให้โปร่งแสงเล็กน้อย
          }}
        >        
          <div 
            className="mx-[54px] mt-2 text-nowrap truncate transition-opacity duration-300"
          >                  
            <label className="text-xl text-top">{product.name}</label>
          </div>
        </div>

        <div className="shrink md:hidden md:rounded-md rounded-b-lg mb-1.5 md:my-3 p-4 fixed top-0 right-0 left-0 z-50">
          <div className='flex justify-between '>

            <Link to={previousUrl || '/manage-product/'} className="cursor-pointer">
              <div className="flex items-center justify-center py-2 px-2 rounded-full bg-white text-sm button-500 w-10 h-10 ease-in-out select-none duration-300 scale-125 opacity-75 active:opacity-100">
                <LeftOutlined />
              </div>
            </Link>

            <div>              
              <Tooltip placement="bottomRight" title='แชร์สินค้า'>
                <Popover content={content} trigger="click">
                    <button 
                      className='flex items-center justify-center py-2 px-2 rounded-full bg-white text-sm button-500 w-10 h-10 ease-in-out select-none duration-300 scale-125 opacity-75 active:opacity-100' 
                      type="button" 
                    > 
                      <ShareAltOutlined className='scale-150'/>
                    </button>
                </Popover>
              </Tooltip>
            </div>

          </div>
        </div>



          <div className='shrink bg-white md:rounded-md rounded-b-md bg-600 overflow-hidden'>
            <div className='flex md:flex-row flex-col '>
              
                {product.imageUrls && product.imageUrls.length > 0 && (
                  <div
                    preview={{
                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    }}
                  >
                    <div class="max-w-[600px]">
                        <div class="grid grid-cols-1">
                            <div className='aspect-square w-full  max-w-full lg:aspect-auto '>
                              {currentImage ? (
                                  currentImage.type === 'product' ? (
                                      <img 
                                        src={getImageProductUrl(currentImage.url)} 
                                        alt={product.name} 
                                        className="object-contain w-full max-w-[600px] h-auto aspect-square"
                                        />
                                  ) : (
                                      <img
                                        src={getImageProductVariantUrl(currentImage.url)} 
                                        alt={product.name} 
                                        className="object-contain w-full max-w-[600px] h-auto aspect-square"
                                        />
                                  )
                              ) : ( 
                                  <img
                                    src={getImageProductUrl(product.imageUrls[0])} 
                                    alt={product.name} 
                                    className="object-contain w-full max-w-[600px] h-auto aspect-square"
                                    />
                              )}

                              {/* Thumbnail Container with Fixed Position Buttons */}
                              <div className="relative mt-2"> {/* Parent container */}
                              {/* Scrollable Thumbnail Container */}
                                <div 
                                  id="thumbnail-container"
                                  className='flex flex-nowrap gap-2 md:overflow-x-hidden overflow-x-auto overflow-y-hidden'
                                  style={{ scrollBehavior: 'smooth' }}
                                  onScroll={(e) => {
                                    const container = e.currentTarget;
                                    const leftButton = document.getElementById('left-nav-button');
                                    const rightButton = document.getElementById('right-nav-button');
                                    
                                    // Hide left button when scrolled to the left edge
                                    if (leftButton) {
                                      leftButton.style.display = container.scrollLeft <= 1 ? 'none' : 'block';
                                    }
                                    // Hide right button when scrolled to the right edge
                                    if (rightButton) {
                                      const isAtRightEdge = container.scrollLeft + container.clientWidth >= container.scrollWidth - 1;
                                      rightButton.style.display = isAtRightEdge ? 'none' : 'block';
                                    }
                                  }}
                                >
                                  {product.imageUrls.slice(0).map((imageUrl, index) => (
                                    <div 
                                      key={index}
                                      className={`relative inline-block w-[106px] h-[106px] cursor-pointer flex-shrink-0
                                        ${currentImage && currentImage.type === 'product' && currentImage.url === imageUrl 
                                          ? 'before:absolute before:inset-0 before:z-40 before:border-2 before:border-black' 
                                          : ''
                                      }`}
                                      onMouseEnter={() => setCurrentImage({ type: 'product', url: imageUrl })}
                                    >
                                      <img
                                        src={getImageProductUrl(imageUrl)} 
                                        alt={product.name} 
                                        className="object-contain cursor-pointer"
                                      />
                                    </div>
                                  ))}
                                </div>

                                {/* Navigation Buttons - Outside the scrollable container */}
                                {product.imageUrls.length > 5 && (
                                  <>
                                    <button 
                                      id="left-nav-button"
                                      className='absolute left-0 top-1/2 transform -translate-y-1/2 button-400 bg-opacity-70 py-4 px-2 z-30 shadow-md hover:bg-opacity-100'
                                      style={{ display: 'none' }} // Initially hidden
                                      onClick={() => {
                                        const thumbnailContainer = document.getElementById('thumbnail-container');
                                        if (thumbnailContainer) {
                                          // Scroll all the way to the beginning
                                          thumbnailContainer.scrollTo({ left: 0, behavior: 'smooth' });
                                        }
                                      }}
                                    >
                                      <div className="transform">
                                        <LeftOutlined className='text-gray-200' />
                                      </div>
                                    </button>
                                    <button 
                                      id="right-nav-button"
                                      className='absolute right-0 top-1/2 transform -translate-y-1/2 button-400 bg-opacity-70 py-4 px-2 z-30 shadow-md hover:bg-opacity-100'
                                      style={{ display: 'block' }} // Initially visible
                                      onClick={() => {
                                        const thumbnailContainer = document.getElementById('thumbnail-container');
                                        if (thumbnailContainer) {
                                          // Scroll all the way to the end
                                          thumbnailContainer.scrollTo({ left: thumbnailContainer.scrollWidth, behavior: 'smooth' });
                                        }
                                      }}
                                    >
                                      <div className="transform rotate-180">
                                        <LeftOutlined className='text-gray-200' />
                                      </div>
                                    </button>
                                  </>
                                )}
                                
                              </div>

                            </div>                          

                        </div>
                    </div>
                  </div>
                )}
              
                <div className='flex flex-col overflow-hidden p-4 md:min-w-96 md:max-w-[475px]'>
                    <div className='md:hidden'>
                        <label className='text-2xl '>{product.name}</label>
                    </div>

                    {
                      product.price > 0 ?
                      <div className='mt-4 w-full pt-1 bg-550 rounded-md'>
                        <label className='text-3xl px-3 text-indigo-600 header-0'>฿ {product.price}</label>
                      </div>
                      :
                      <div className="mt-4 w-full pt-1 bg-550 rounded-md">
                        <label className="text-3xl px-3 text-indigo-600 header-0">
                          ฿ {selectedPrice !== null ? selectedPrice : rangeprice}
                        </label>
                      </div>
                    }

                  {product.variants.map((variant) => (
                    <div key={variant.name} className="mt-4">
                      <label className="font-semibold">{variant.name}</label>
                      <div className="flex gap-2 mt-2">
                        {variant.options.map((option) => {
                          // Create a potential selection with this option
                          const potentialSelection = {
                            ...selectedOptions,
                            [variant.name]: option.name
                          };
                          
                          // Check if this option can be part of a valid combination
                          // based on the current selections for other variants
                          const isAvailable = product.combinations.some(comb => {
                            // Check if this option matches the combination for this variant
                            if (comb.variantCombination[variant.name] !== option.name || comb.stock <= 0) {
                              return false;
                            }
                            
                            // Check if other selected variants match this combination
                            for (const otherVariant of product.variants) {
                              if (otherVariant.name === variant.name) continue; // Skip current variant
                              
                              const selectedOptionForOtherVariant = selectedOptions[otherVariant.name];
                              // If another variant has no selection yet, that's fine
                              if (!selectedOptionForOtherVariant) continue;
                              
                              // If the other selected variant doesn't match this combination, this option isn't valid
                              if (comb.variantCombination[otherVariant.name] !== selectedOptionForOtherVariant) {
                                return false;
                              }
                            }
                            
                            return true;
                          });

                          return (
                            <Tooltip placement="bottomRight" title={!isAvailable ? 'สินค้าหมด' : ''}>
                              <button
                                key={option._id}
                                onClick={() => handleSelect(variant.name, option.name)}
                                disabled={!isAvailable}
                                onMouseEnter={() => option.imageUrl && setCurrentImage({ type: 'variant', url: option.imageUrl })}
                                className={`px-4 py-2 rounded-md flex items-end justify-center text-sm ring-inset duration-300 ease-in-out
                                  ${!isAvailable ? "opacity-50 cursor-not-allowed ring-400" : ""}
                                  ${option.imageUrl ? "px-0 py-0 text-select" : ""}
                                  ${!option.imageUrl && selectedOptions[variant.name] !== option.name ? "ring-1 ring-400 ring-400hover" : ""}
                                  ${selectedOptions[variant.name] === option.name ? "ring-[2px] ring-1000 bg-300 hover:ring-[2px] " : "hover:ring-[1px] ring-400"}`}
                                style={{
                                  backgroundImage: option.imageUrl
                                    ? `url(${getImageProductVariantUrl(option.imageUrl)})`
                                    : "none",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  width: "70px",
                                  height: option.imageUrl ? "70px" : "40px",
                                }}
                              >
                                {option.name}
                              </button>
                            </Tooltip>
                          );
                        })}
                      </div>
                    </div>
                  ))}



                    <div  className='mt-6'>
                        <label>รายละเอียด</label>
                    </div>
                    <div className='p-2 bg-gray-50 rounded-md bg-500 whitespace-pre-wrap' >
                        <p>{product.description}</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='flex md:flex-row flex-col md:flex-wrap justify-center md:mt-3 md:mb-3 mt-4 h-xl md:rounded-lg rounded-t-lg shadow-lg bg-white bg-600 relative '>
          <div className="p-3 md:p-6 content-center">
            <div>
            {Array.isArray(markets.logoUrl) && markets.logoUrl.some(logoUrl => logoUrl.trim() !== '') ? (
              markets.logoUrl.map((logoUrl, index) => (
                logoUrl.trim() !== '' ? (
                  <div className='object-contain rounded-lg ' key={index}>
                    <img src={getImageMarketUrl(logoUrl)} alt={markets.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60 bg-500' />
                  </div>
                ) : ''
              ))
            ) : (
              <img src={logoUndefined} alt={markets.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60' />
            )}
            </div>

            <a 
              href={`/${markets.id_text}`}
              className="block" 
            >
              <div className='hidden md:block text-center mt-6 button-500 hover:cursor-pointer py-2 px-3 rounded-full ease-in-out select-none duration-300'>
                <StorefrontRoundedIcon className='text-icon' /> ดูร้านค้า
              </div>
            </a>
          </div>

          <div className="grow md:p-6 p-0 md:py-6 py-2 md:max-w-xl md:px-0 px-4">
            <div className="max-w-xl min-h-10 overflow-hidden ">
              <h3 className="break-words md:text-3xl text-xl text-center">{markets.name}</h3>
              <a 
                href={`/${markets.id_text}`}
                className="block" 
              >
                <div className='md:hidden text-center mt-3 mb-6 button-500 hover:cursor-pointer w-[80%] mx-auto py-2 px-3 rounded-full ease-in-out select-none duration-300'>
                  <StorefrontRoundedIcon className='text-icon' /> ดูร้านค้า
                </div>
              </a>
            </div>

            <div className="max-w-xl min-h-10 overflow-hidden  mt-3">
                <h3 className="break-words text">{markets.description}</h3>
            </div>

            <div className="md:p-4 pt-4 md:pb-0 pb-2 p-0">
                {markets.contactlink && markets.contactlink.map((contact, index) => (
                  <div key={index} className='flex'> 
                    <div className='min-w-16'>
                      <img 
                        src={contactIcons[contact.source]} 
                        className='md:w-16 w-14 p-2' 
                        alt={`${contact.source} Icon`} 
                      /> 
                    </div> 
                    <div className="md:min-w-96 w-60 p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-wrap bg-550"> 
                      <p>{contact.word || '-'}</p> 
                    </div> 
                  </div>
                ))}
                
                {(markets.phone || markets.email) ?
                  <div className='flex flex-col md:flex-row w-full md:gap-2'>
                    {markets.email  && (
                      <div className="flex p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-550 break-all">
                        <MailFilled className="mr-2 p-1 rounded-full" /> 
                        <p>{markets.email || '-'}</p>
                      </div>
                    )}
                    {markets.phone  && (
                      <div className="flex p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-550 break-all">
                        <PhoneFilled className="mr-2 p-1 rounded-full" /> 
                        <p>{markets.phone || '-'}</p>
                      </div>
                    )}
                  </div>
                :''}

                {markets.location && markets.location.status === true ? (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <div className='max-w-28 button-500 hover:cursor-pointer p-1 px-3 rounded-full ease-in-out select-none duration-300' onClick={locationClick}>
                      <EnvironmentFilled className='text-red-600'/> {locationStatus}
                    </div>
                    <div className='mt-2 w-full'>
                    {LoadMap}
                    </div>
                  </div>
                ): ''}
            </div>

          </div>

        </div>


      </div>
    </div>
    </div>
    </>
  );
}

export default Classic;