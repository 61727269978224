import React, { useState, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Link,useNavigate } from 'react-router-dom';
import { Sidebar, Nav , NavNoUser } from "../../components/Nav.jsx";
import axios from 'axios';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Spin} from 'antd';
import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded';
import { LeftOutlined } from '@ant-design/icons';
import './styles/AccountPage.css'
import { calculateRemainingUsage , calculateExpirationDate , calculateNewExpirationDate } from '../script.jsx';
import { LoadingOutlined } from "@ant-design/icons";

const stripePromise = loadStripe("pk_test_51PbieOKYDoQ6D6RxWR8kXQzGUSxubTzTuNCTXdtPPQlqCSICIRh2S0i4L7fYZ7GohEcKFcVBqoKZKiH97qeXg1w5003ItbsdeB");

function ExtendPackage(){
    const { user } = useUserAuth();
    const token = localStorage.getItem('token');

    const [clientSecret, setClientSecret] = useState("");

    const [userData, setUserData] = useState([]);
    const [data, setData] = useState(true);
    const navigate = useNavigate();
    const now = new Date();

    const [remainingInfo, setRemainingInfo] = useState({
      remainingDays: 0,
      remainingHours: 0,
      remainingMinutes: 0,
      formattedExpirationDate: '',
    });

    const [selectedPlan, setSelectedPlan] = useState(''); // ค่าเริ่มต้น
    const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

    //ดึงข้อมูลผู้ใช้ขึ้นมาแสดง
    const fetchUser = async () => {
      if (user && user._id) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/user/id/${user._id}`);
          setUserData(response.data);
    
          if (response.data.length === 0) {
            setData(false);
          } else {
            const expirationDate = new Date(response.data.membershipExpirationDate);
    
            let usageInfo; // กำหนดตัวแปรแบบถูกต้อง
    
            if (expirationDate < now) {
              // กรณีหมดอายุแล้ว แสดง "-"
              usageInfo = {
                remainingDays: 0,
                remainingHours: 0,
                remainingMinutes: 0,
                formattedExpirationDate: "-",
              };
            } else {
              // คำนวณอายุการใช้งานคงเหลือ
              usageInfo = calculateRemainingUsage(response.data);
            }
    
            setRemainingInfo(usageInfo);
          }
        } catch (err) {
          console.error('Error fetching user:', err);
        }
      }
    };
    

// ส่งข้อมูลไป Backend เมื่อกดยืนยัน
const handleSubmit = async () => {
  try {
    setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

    // แปลงแพ็กเกจที่เลือกเป็นจำนวนวัน
    const planDays = selectedPlan === "30d" ? 30 : 365;
    let price = 0

    if(userData.membershipType === "silver" && selectedPlan === "30d"){
      price = 99
    }else if(userData.membershipType === "silver" && selectedPlan === "365d"){
      price = 1000
    }else if(userData.membershipType === "gold" && selectedPlan === "30d"){
      price = 199
    }else if(userData.membershipType === "gold" && selectedPlan === "365d"){
      price = 2000
    }else if(userData.membershipType === "platinum" && selectedPlan === "30d"){
      price = 699
    }else if(userData.membershipType === "platinum" && selectedPlan === "365d"){
      price = 7000
    }else{
      price = null
      console.log("เกิดข้อผิดพลาดในการกำหนดราคา")
    }

    // คำนวณวันหมดอายุใหม่
    const newDate = calculateNewExpirationDate(userData.membershipType,userData.membershipType,userData.membershipExpirationDate, planDays);

    console.log(newDate)

    await axios.post(`${import.meta.env.VITE_BACKEND_URL}stripe/user/create-payment-intent/`,
      {
          headers: {
              'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
          }
      }      
    );

  } catch (error) {
    console.error('Error extending membership:', error);
  } finally {
    setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
  }
};

    useEffect(() => {
      if (!user) {
        navigate('/homepage'); // ไปยังเส้นทาง homepage
      }
      fetchUser();
    }, [user, navigate]);

    if (user && userData.length === 0 && data) {
      return (
        <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin  fullscreen size="large" /></div>
        </div>
      </>
    );
    }

    return(
        <>
        <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
          <Link to="../account" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
            <LeftOutlined />
          </Link>
          <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">ต่ออายุการใช้งาน</h2>
        </div>
        
        <div className='flex md:justify-between mt-[60px] md:mt-2'>
          <div></div>

          <div className='grow md:grow-0 overflow-hidden overflow-x-auto'>
            <div className='md:mx-4 mx-2 mb-10'>
                <div className='mt-3 ml-4 md:block hidden'>
                  <Link to="../account" className='text-lg text-gray-600' > <LeftOutlined /> ย้อนกลับ</Link>
                </div>

                <div className='mt-6 mb-4 ml-2 flex flex-col md:block hidden'>
                  <label className='text-4xl font-bold'>ต่ออายุการใช้งาน</label>
                </div>

                <div className='bg-white md:mt-2 rounded-lg md:max-w-full border md:w-[700px]'>
                  
                  <div className='p-6 pb-0'>
                    <div className='flex mb-4'>
                        <label className='text-lg md:text-xl'>ประเภทสมาชิก : </label>
                        {userData.membershipType === 'diamond' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-blue-200 to-blue-400 shadow select-none'><DiamondRoundedIcon /> DIAMOND</p>
                        :''}
                        {userData.membershipType === 'platinum' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-blue-200 to-blue-400 shadow select-none'><DiamondRoundedIcon /> PLATINUM</p>
                        :''}
                        {userData.membershipType === 'gold' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-amber-200 to-amber-400 shadow select-none'><DiamondRoundedIcon /> GOLD</p>
                        :''}
                        {userData.membershipType === 'silver' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-slate-200 to-slate-400 shadow select-none'><DiamondRoundedIcon /> SILVER</p>
                        :''}
                    </div>

                    <div>
                        <label className='text-md md:text-xl'>อายุการใช้งานคงเหลือ: {remainingInfo.remainingDays} วัน {remainingInfo.remainingHours} ชั่วโมง {remainingInfo.remainingMinutes} นาที</label>
                    </div>

                    <div className=''>
                      <label className='text-md md:text-lg text-gray-700'>
                        ครบกำหนดชำระเงินครั้งต่อไป: {remainingInfo.formattedExpirationDate}
                      </label>
                    </div>

                      {/* Select Plan */}
                      <div className="mt-4">
                        <div className="mt-2 flex flex-col gap-4 select-none">

                          <div
                            className={`p-4 border-4 rounded-lg cursor-pointer flex justify-between ${
                              selectedPlan === "30d" ? "border-blue-500 " : "border-gray-300"
                            }`}
                            onClick={() => setSelectedPlan("30d")}
                          >
                            <p className="text-lg font-semibold md:hidden">30 วัน</p>
                            <p className="text-lg font-semibold hidden md:block">(ต่ออายุหนึ่งเดือน) 30 วัน</p>
                            <p className="text-lg font-semibold ml-2">
                              {userData.membershipType === 'silver' ? '99 บาท' :
                              userData.membershipType === 'gold' ? '199 บาท' :
                              userData.membershipType === 'platinum' ? '699 บาท' : '99 บาท'}
                            </p>
                          </div>

                          <div
                            className={`p-4 border-4 rounded-lg cursor-pointer flex justify-between ${
                              selectedPlan === "365d" ? "border-blue-500 " : "border-gray-300"
                            }`}
                            onClick={() => setSelectedPlan("365d")}
                          >
                            <p className="text-lg font-semibold md:hidden">1 ปี</p>
                            <p className="text-lg font-semibold hidden md:block">(ต่ออายุหนึ่งปี) 365 วัน</p>
                            <div className='flex'>
                              <p className="">
                                {userData.membershipType === 'silver' ? '(ประหยัด 188 บาท)' :
                                userData.membershipType === 'gold' ? '(ประหยัด 388 บาท)' :
                                userData.membershipType === 'platinum' ? '(ประหยัด 1388 บาท)' : '(ประหยัด 188 บาท)'}
                              </p>

                              {/* กำหนดราคาให้ตรงกับประเภทสมาชิก */}
                              <p className="text-lg font-semibold ml-2">
                                {userData.membershipType === 'silver' ? '1000 บาท' :
                                userData.membershipType === 'gold' ? '2000 บาท' :
                                userData.membershipType === 'platinum' ? '7000 บาท' : '1000 บาท'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    <div className='border mt-4'></div>
                  </div>

                    {/* แสดงวันที่หมดอายุใหม่ */}
                    <div className='mt-2 text-center'>
                      <label className='flex justify-center text-md md:text-lg text-gray-700 gap-2'>
                        ใช้ได้จนถึง วันที่  
                        {new Date(userData.membershipExpirationDate) > now? 
                          <div>
                            {calculateExpirationDate(userData.membershipType, userData.membershipType, userData.membershipExpirationDate, selectedPlan)}
                          </div>
                          : 
                          " -"
                        }
                      </label>
                    </div>

                    <div className='flex gap-2 p-2'>
                      <div className='flex-1'>
                        <button
                          className={`p-4 py-2 text-white rounded-b w-full h-full transition ${
                            selectedPlan
                              ? "bg-blue-400 hover:bg-blue-500/90 active:bg-blue-500"
                              : "bg-gray-300 opacity-50"
                          }`}
                          onClick={handleSubmit}
                          disabled={!selectedPlan || isSubmitting} // ปิดการใช้งานปุ่มถ้ายังไม่เลือกแพ็กเกจ
                        >
                          {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "ยืนยัน"}
                        </button>
                      </div>
                    </div>
                    
                </div>
                
            </div>
          </div>

          <div></div>
        </div>

        
      </>
    );
}

export default ExtendPackage;