import { InputRegister } from '../components/login';
import { NavNoUser } from '../components/Nav';
import { useUserAuth } from '../context/UserContext';
import { Navigate } from 'react-router-dom';



function RegisterPage() {
  const{user} =  useUserAuth();

  //ถ้าเป็นสมาชิกอยู่แล้ว จะไม่สามารถเข้าหน้านี้ได้ ต้องlogOut ก่อน
  if (user){
    return <Navigate to="/" />
  }

  return (
    <>
      <NavNoUser />
      <div>
        <InputRegister />
      </div>
    </>
  );
}

export default RegisterPage