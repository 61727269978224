import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import "./styles/market.css";
import './styles/theme.css';

import Classic from './theme-market/classic.jsx';
//import Classic_dark from './theme-market/classic-dark.jsx';
import MarketClose from './market-close.jsx'; // นำเข้า MarketClose

function Market_theme() {
  const { id_text: urlMarket } = useParams(); 
  
  const [marketData, setMarketData] = useState(null);
  const [theme, setTheme] = useState('default');
  const [isMarketClosed, setIsMarketClosed] = useState(false); // ใช้ state เพื่อตรวจสอบว่าเป็นร้านปิดหรือไม่
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarket = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_BACKEND_URL}api/market/id_text_market/${urlMarket}`
        );

        if (response.data?.status === 'closed') {
          setIsMarketClosed(true); // ถ้าร้านปิดให้แสดง MarketClose
          return;
        }

        setMarketData(response.data);
        setTheme(response.data.theme || 'default');
      } catch (err) {
        console.error('Error fetching market:', err);
        if (err.response?.status === 404) {
          setIsMarketClosed(true); // ถ้าไม่พบร้านค้าให้แสดง MarketClose
        }
      }
    };

    fetchMarket();
  }, [urlMarket]);

  if (isMarketClosed) {
    return <MarketClose />; // ถ้าเป็นร้านปิดหรือไม่พบข้อมูลให้แสดง MarketClose
  }

  if (!marketData) {
    return (
      <div><Spin  fullscreen size="large" /></div>
    );
  }
  

  return (
    <>
      {theme === 'dark' ? <Classic market={marketData} /> : <Classic market={marketData} />}
    </>
  );
}

export default Market_theme;
