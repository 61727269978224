import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Input, Select , Result } from 'antd';
import "./styles/market.css";
import './styles/theme.css';

const { Option } = Select;
const { Search } = Input;

function MarketClose() {
  const [theme, setTheme] = useState('default-theme');

  const [sortOrder, setSortOrder] = useState('default');
  const navigate = useNavigate();


  useEffect(() => {
    // ตั้งค่า theme ให้กับ root element ของหน้านี้
    const bg1 = document.querySelector('.bg-800');
    if (bg1) {  // ตรวจสอบว่า pageContent มีอยู่
      bg1.classList.remove('default-theme', 'dark-theme', 'beige-theme', 'blue-theme');
      bg1.classList.add(`${theme}-theme`);
    }

  }, [theme]);

  return (
    <div className='flex justify-center items-center h-screen'>
        <Result
            status="404"
            title="ไม่พบร้านค้า "
            subTitle="อาจเกิดจากร้านค้าถูกปิดไว้อยู่หรือถูกลบไปแล้ว"
            className=''
        />
    </div>
  );
}

export default MarketClose;