import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Popover, Space , message, Spin,Image,QRCode,Carousel, Tooltip } from 'antd';
import { LeftOutlined,ShareAltOutlined,MailFilled,PhoneFilled, EnvironmentFilled, StopFilled } from '@ant-design/icons';
import "./styles/detail-market.css";
import './styles/theme.css';

import lineIcon from '../../../assets/lineLogo1.png';
import facebookIcon from '../../../assets/facebookLogo2.png';
import instagramIcon from '../../../assets/igLogo2.png';
import tiktokIcon from '../../../assets/Tiktok_logo2.png';
import youtubeIcon from '../../../assets/youtubeLogo2.png';
import xIcon from '../../../assets/XLogo.jpeg';
import telegramIcon from '../../../assets/telegramLogo.png';
import wechatIcon from '../../../assets/WechatLogo.png';
import shopeeIcon from '../../../assets/ShopeeLogo.png';
import lazadaIcon from '../../../assets/LazadaLogo.png';
import whatsappIcon from '../../../assets/whatsappLogo.png';
import snapchatIcon from '../../../assets/SnapchatLogo.png';
import phoneIcon from '../../../assets/phoneLogo.png';
import mailIcon from '../../../assets/MailLogo.png';
import logoUndefined from '../../../assets/marketundefined.svg';

function Market() {
  const { productId: urlProduct } = useParams();
  const location = useLocation();
  const previousUrl = new URLSearchParams(location.search).get('previous');
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [markets, setMarkets] = useState({ logoUrl: [], name: '', description: '', linkline: '', linkfacebook: '', linkinstagram: '', linktiktok: '' });
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [initialPosition, setInitialPosition] = useState({ lat: 13.7248785, lng: 100.4683055 });
  const [locationButton, setLocationButton] = useState(false);
  const [locationStatus, setLocationStatus] = useState('ตำแหน่ง');
  const [theme, setTheme] = useState('default-theme');

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/product/from_id_product/${urlProduct}`);
        const productData = response.data;
        setProduct(productData);

        // ตรวจสอบและแปลงค่าที่ได้เป็น String หากเป็นอ็อบเจ็กต์
        const marketId = typeof productData.market === 'object' ? productData.market._id : productData.market;

        if (productData.market) {
          try {
            const marketResponse = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market/from_id_market/${marketId}?populate=location`);

            if (marketResponse.data.status === 'closed') {
              navigate('/market_not_found');
              return;
            }

            setTheme(marketResponse.data.theme);
            setMarkets(marketResponse.data);
            if (marketResponse.data.location && marketResponse.data.location.lat && marketResponse.data.location.lng) {
              const lat = marketResponse.data.location.lat;
              const lng = marketResponse.data.location.lng;
        
              setSelectedPosition({ lat, lng });
              setInitialPosition({ lat, lng });
            }
          }
          catch (err) {
            console.error('Error fetching market:', err);
          }
        }

        
      } catch (err) {
        console.error('Error fetching product:', err);
      }
    };

    fetchProduct();
  
  }, [urlProduct]);
  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }
  function getImageProductUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product`;
    return `${basePath}/${filename}`;
  }

  const contactIcons = {
    line: lineIcon,
    facebook: facebookIcon,
    instagram: instagramIcon,
    tiktok: tiktokIcon,
    youtube: youtubeIcon,
    x: xIcon,
    telegram: telegramIcon,
    wechat: wechatIcon,
    shopee: shopeeIcon,
    lazada: lazadaIcon,
    whatsapp: whatsappIcon,
    snapchat: snapchatIcon,
    phone: phoneIcon ,
    mail: mailIcon ,
  };

  useEffect(() => {
    // ตั้งค่า theme ให้กับ root element ของหน้านี้
    const bg1 = document.querySelector('.bg-800');
    if (bg1) {  // ตรวจสอบว่า pageContent มีอยู่
      bg1.classList.remove('default-theme', 'dark-theme', 'beige-theme');
      bg1.classList.add(`${theme}-theme`);
    }

  }, [theme]);

  // กำหนดขนาดแผนที่
  const mapContainerStyle = {
    height: '400px',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  };
  // การตรวจสอบว่ามีการโหลด Script อยู่แล้วหรือไม่
  const shouldLoadMap = markets && locationButton;

  // ใช้ useMemo เพื่อหลีกเลี่ยงการเรนเดอร์ซ้ำ
  const LoadMap = useMemo(() => (
    shouldLoadMap && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={selectedPosition || initialPosition}
          zoom={12}
        >
          {selectedPosition && <MarkerF position={selectedPosition} />}
        </GoogleMap>
    )
  ), [initialPosition, selectedPosition, shouldLoadMap]);

  const locationClick = () => {
    if (locationButton === false) {
      setLocationButton(true);
      setLocationStatus('ซ่อน');
    }
    if (locationButton === true) {
      setLocationButton(false);
      setLocationStatus('ตำแหน่ง');
    }
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => message.success("คัดลอกลิงค์สำเร็จ"))
      .catch(err => console.error('เกิดข้อผิดพลาดในการคัดลอกลิงค์:', err));
  };

  const content = (
    <div className='flex border rounded-md'>
      <div className='flex flex-col'>
        <div>
          <QRCode value={window.location.href || '-'} />
        </div>
        <div className='p-1 rounded-md max-w-80 overflow-hidden overflow-x-auto'>
          <p>{window.location.href}</p>
        </div>
      </div>
      <div className='content-center'>
        <button className='object-cover px-2 border-l-2 ' onClick={() => copyLink(window.location.href)}> <div className='border p-1 rounded-lg hover:bg-gray-50 active:bg-gray-200'>คัดลอกลิงค์</div></button>
      </div>
    </div>
  );

  if (!product) {
    return (
      <div className='flex justify-center'>
        <Spin fullscreen size="large" />
      </div>
    );
  }

  return (
    <>
    <div className='bg-800'>
    <div className='flex justify-center bg-700-900 min-h-svh'>
      <div className='overscroll-none custom-scrollbar '>
      {markets.status === 'vacation' ?
        <div className='sticky top-0 mb-3 md:block bg-white rounded-b-lg p-6 bg-vacation text-2xl select-none text-center z-50'>
            <StopFilled  className='mr-2' />ตอนนี้ร้านกำลังปิดพักร้อนอยู่
        </div> :''
        }

        <div className='shrink md:block bg-white md:rounded-md rounded-b-lg mb-1.5 md:my-3 p-4 bg-600'>
            <div className='flex justify-between '>
                <Link to={previousUrl || '/manage-product/'} className='cursor-pointer'>
                <div className='content-center text-gray-500 header-0'>
                    <LeftOutlined />ย้อนกลับ
                </div>
                </Link>
                <div>              
                  <Tooltip placement="bottomRight" title='แชร์สินค้า'>
                    <Popover content={content} trigger="click">
                        <button 
                          className='flex items-center justify-center py-2 px-2 rounded-full bg-white text-sm button-500 w-10 h-10 ease-in-out select-none duration-300' 
                          type="button" 
                        > 
                          <ShareAltOutlined className='scale-150'/>
                        </button>
                    </Popover>
                  </Tooltip>
                </div>
            </div>
        </div>

        <div className='shrink md:block bg-white rounded-md bg-600 overflow-hidden'>
            <div className='flex md:flex-row flex-col '>
                <div>
                    {product.imageUrls && product.imageUrls.length > 0 && (
                        <Image.PreviewGroup
                            preview={{
                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                            }}
                        >
                        <div className=' max-w-md'>
                            <div className=''>
                                <Image src={getImageProductUrl(product.imageUrls[0])} alt={product.name} className='h-md max-w-md ' />
                            </div>
                            <div className='flex overflow-hidden overflow-x-auto custom-scrollbar '>
                                {product.imageUrls.slice(1).map((imageUrl, index) => (
                                <Image key={index} src={getImageProductUrl(imageUrl)} alt={product.name} className='md:min-w-24 md:min-h-24 md:w-24 md:h-24  max-w-44' />
                                ))}
                            </div>
                        </div>
                        </Image.PreviewGroup>
                    )}
                </div>
                <div className='flex flex-col overflow-hidden p-4 md:min-w-96 max-w-[475px]'>
                    <div className=''>
                        <label className='text-2xl '>{product.name}</label>
                    </div>
                    <div className='mt-4'>
                        <label className='text-3xl text-indigo-600 header-0'>฿ {product.price}</label>
                    </div>
                    <div  className='mt-6'>
                        <label>รายละเอียด</label>
                    </div>
                    <div className='p-2 bg-gray-50 rounded-md max-w-xl bg-500 whitespace-pre-wrap' >
                        <p>{product.description}</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='flex md:flex-row flex-col md:flex-wrap justify-center md:mt-3 md:mb-3 mt-4 h-xl md:rounded-lg rounded-t-lg shadow-lg bg-white bg-600 relative '>
          <div className="p-3 md:p-6 content-center">
            {Array.isArray(markets.logoUrl) && markets.logoUrl.some(logoUrl => logoUrl.trim() !== '') ? (
              markets.logoUrl.map((logoUrl, index) => (
                logoUrl.trim() !== '' ? (
                  <div className='object-contain rounded-lg ' key={index}>
                    <img src={getImageMarketUrl(logoUrl)} alt={markets.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60 bg-500' />
                  </div>
                ) : ''
              ))
            ) : (
              <img src={logoUndefined} alt={markets.name} className='border object-contain rounded-full m-auto md:max-w-60 max-w-28 w-60' />
            )}
          </div>

          <div className=" grow md:p-6 p-0 md:py-6 py-2 md:max-w-xl md:px-0 px-4">
            <div className="max-w-xl min-h-10 overflow-hidden ">
                <h3 className="break-words md:text-3xl text-xl text-center">{markets.name}</h3>
            </div>

            <div className="max-w-xl min-h-10 overflow-hidden  mt-3">
                <h3 className="break-words text">{markets.description}</h3>
            </div>

            <div className="md:p-4 pt-4 md:pb-0 pb-2 p-0">
                {markets.contactlink && markets.contactlink.map((contact, index) => (
                  <div key={index} className='flex'> 
                    <div className='min-w-16'>
                      <img 
                        src={contactIcons[contact.source]} 
                        className='md:w-16 w-14 p-2' 
                        alt={`${contact.source} Icon`} 
                      /> 
                    </div> 
                    <div className="md:min-w-96 w-60 p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-wrap bg-550"> 
                      <p>{contact.word || '-'}</p> 
                    </div> 
                  </div>
                ))}
                
                {(markets.phone || markets.email) ?
                  <div className='flex flex-col md:flex-row w-full md:gap-2'>
                    {markets.email  && (
                      <div className="flex p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-550 break-all">
                        <MailFilled className="mr-2 p-1 rounded-full" /> 
                        <p>{markets.email || '-'}</p>
                      </div>
                    )}
                    {markets.phone  && (
                      <div className="flex p-2 my-1 rounded-lg break-words grow overflow-hidden text-ellipsis whitespace-normal bg-550 break-all">
                        <PhoneFilled className="mr-2 p-1 rounded-full" /> 
                        <p>{markets.phone || '-'}</p>
                      </div>
                    )}
                  </div>
                :''}

                {markets.location && markets.location.status === true ? (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <div className='max-w-28 button-500 hover:cursor-pointer p-1 px-3 rounded-full ease-in-out select-none duration-300' onClick={locationClick}>
                      <EnvironmentFilled className='text-red-600'/> {locationStatus}
                    </div>
                    <div className='mt-2 w-full'>
                    {LoadMap}
                    </div>
                  </div>
                ): ''}
            </div>

          </div>

        </div>


      </div>
    </div>
    </div>
    </>
  );
}

export default Market;