import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import './index.css'

import CookieBar from './components/Cookie.jsx';

import App from './App.jsx'

import RegisterPage from './pages/RegisterPage.jsx'
import RegisterSuccess from './pages/RegisterSuccess.jsx';
import LoginPage from './pages/LoginPage.jsx'
import HomePage from './pages/HomePage.jsx';

import Calcutiktok from './pages/calcu/tiktok.jsx';
import CalcuShopee from './pages/calcu/shopee.jsx';
import CalcuLazada from './pages/calcu/lazada.jsx';
import CalcuLineshop from './pages/calcu/lineshop.jsx';
import CalcuNocnoc from './pages/calcu/nocnoc.jsx';
import CalcuB2C from './pages/calcu/b2c.jsx';

import AddProduct from './pages/product/add-product.jsx';
import AddMarket from './pages/product/add-market.jsx';
import AddFirstMarket from './pages/product/add-first-market.jsx';
import AddCategory from './pages/product/add-category.jsx';
import EditCategory from './pages/product/edit-category.jsx';
import ManageProduct from './pages/product/manage-product.jsx';
import ManageMarket from './pages/product/manage-market.jsx';
import EditProduct from './pages/product/edit-product.jsx'
import EditMarket from './pages/product/edit-market.jsx';
import DetailProduct from './pages/product/detail-product.jsx'
import MarketClose from './pages/product/market/market-close.jsx';
import DetailMarket from './pages/product/detail-market.jsx';

import FrontMarketId from './pages/product/market/market-id.jsx';
import FrontProductId from './pages/product/market/product-id.jsx';
import FrontMarketName from './pages/product/market/market-name.jsx';
import FrontProductName from './pages/product/market/product-name.jsx'

import ManageCustomer from './pages/customer/manage-customer.jsx';
import AddCustomer from './pages/customer/add-customer.jsx'
import EditCustomer from './pages/customer/edit-customer.jsx';
import DetailCustomer from './pages/customer/detail-customer.jsx';

import ManageReceipt from './pages/customer/document/manage-receipt.jsx';
import AddReceipt from './pages/customer/document/add-receipt.jsx';
import EditReceipt from './pages/customer/document/edit-receipt.jsx';
import ExportReceipt from './pages/customer/document/export-receipt.jsx';

import ManageBill from './pages/customer/document/manage-bill.jsx';
import AddBill from './pages/customer/document/add-bill.jsx';
import EditBill from './pages/customer/document/edit-bill.jsx';
import ExportBill from './pages/customer/document/export-bill.jsx';

import ManageInvoice from './pages/customer/document/manage-invoice.jsx';
import AddInvoice from './pages/customer/document/add-invoice.jsx';
import EditInvoice from './pages/customer/document/edit-invoice.jsx';
import ExportInvoice from './pages/customer/document/export-invoice.jsx';

import ManageQuotation from './pages/customer/document/manage-quotation.jsx';
import AddQuotation from './pages/customer/document/add-quotation.jsx';
import EditQuotation from './pages/customer/document/edit-quotation.jsx';
import ExportQuotation from './pages/customer/document/export-quotation.jsx';

import ManageCarrier from './pages/calcu/manage-carrier.jsx';
import AddCarrier from './pages/calcu/add-carrier.jsx';
import EditCarrier from './pages/calcu/edit-carrier.jsx';

import AccountPage from './pages/accountpage/AccountPage.jsx';
import ExtendPackage from './pages/accountpage/ExtendPackage.jsx';
import ChangePackage from './pages/accountpage/ChangePackage.jsx';
import Payment from './pages/payment/payment.jsx';

import ChatPage from './pages/ChatPage.jsx';
import About from './pages/About.jsx';
import Help from './pages/Help.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import TermOfUse from './pages/TermOfUse.jsx';
import ManageUser from './admin/manageuserPage.jsx';
import SettingCalcu from './admin/settingCalcuPage.jsx'
import Error404 from './pages/Error.jsx';
import ProtectedRoute from './auth/protectedRoute.jsx';
import { UserProvider } from './context/UserContext.jsx';

import './App.css';

const googleMapsApiKey = "AIzaSyBL2LMm7u8i-2NoKj265x98M03d6NEr00I";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/loginpage",
    element: <LoginPage />
  },  
  {
    path: "/registerpage",
    element: <RegisterPage />
  },  
  {
    path: "/registersuccess",
    element: <RegisterSuccess />
  },
  {
    path: "/homepage",
    element: <HomePage />
  },  
  {
    path: "/checkfee/tiktok",
    element: <Calcutiktok /> 
  },  
  {
    path: "/checkfee/shopee",
    element: <CalcuShopee /> 
  }, 
  {
    path: "/checkfee/lazada",
    element: <CalcuLazada /> 
  },
  {
    path: "/checkfee/lineshop",
    element: <CalcuLineshop /> 
  },
  {
    path: "/checkfee/nocnoc",
    element: <CalcuNocnoc /> 
  },
  {
    path: "/checkfee/b2c",
    element: <CalcuB2C /> 
  },
  {
    path: "/checkfee/tiktok/:id",
    element: <Calcutiktok /> 
  },  
  {
    path: "/checkfee/shopee/:id",
    element: <CalcuShopee /> 
  }, 
  {
    path: "/checkfee/lazada/:id",
    element: <CalcuLazada /> 
  },
  {
    path: "/checkfee/lineshop/:id",
    element: <CalcuLineshop /> 
  },
  {
    path: "/checkfee/nocnoc/:id",
    element: <CalcuNocnoc /> 
  },
  {
    path: "/checkfee/b2c/:id",
    element: <CalcuB2C /> 
  },
  {
    path: "/add-product",
    element: <AddProduct /> 
  },
  {
    path: "/add-market",
    element: <AddMarket /> 
  },
  {
    path: "/add-first-market",
    element: <AddFirstMarket /> 
  },
  {
    path: "/add-category",
    element: <AddCategory /> 
  },
  {
    path: "/edit-category/:id",
    element: <EditCategory /> 
  },
  {
    path: "/manage-market",
    element: <ManageMarket /> 
  },
  {
    path: "/manage-product",
    element: <ManageProduct /> 
  },
  {
    path: "/:userId/:market",
    element: <FrontMarketId /> 
  },
  {
    path: "/:id_text",
    element: <FrontMarketName /> 
  },
  {
    path: "/product/:id_text_product",
    element: <FrontProductName /> 
  },
  {
    path: "/productid/:productId",
    element: <FrontProductId /> 
  },
  {
    path: "/Market_not_found",
    element: <MarketClose /> 
  },
  {
    path: "/edit-product/:id",
    element: <EditProduct /> 
  },
  {
    path: "/edit-market/:id",
    element: <EditMarket /> 
  },
  {
    path: "/detail-product/:id",
    element: <DetailProduct /> 
  },
  {
    path: "/detail-market/:id",
    element: <DetailMarket /> 
  },
  {
    path: "/add-receipt",
    element: <AddReceipt /> 
  },
  {
    path: "/edit-receipt/:id",
    element: <EditReceipt /> 
  },
  {
    path: "/manage-receipt",
    element: <ManageReceipt /> 
  },
  {
    path: "/export-receipt/:id",
    element: <ExportReceipt /> 
  },
  {
    path: "/add-bill",
    element: <AddBill /> 
  },
  {
    path: "/edit-bill/:id",
    element: <EditBill /> 
  },
  {
    path: "/manage-bill",
    element: <ManageBill /> 
  },
  {
    path: "/export-bill/:id",
    element: <ExportBill /> 
  },
  {
    path: "/add-invoice",
    element: <AddInvoice /> 
  },
  {
    path: "/edit-invoice/:id",
    element: <EditInvoice /> 
  },
  {
    path: "/manage-invoice",
    element: <ManageInvoice /> 
  },
  {
    path: "/export-invoice/:id",
    element: <ExportInvoice /> 
  },
  {
    path: "/add-quotation",
    element: <AddQuotation /> 
  },
  {
    path: "/edit-quotation/:id",
    element: <EditQuotation /> 
  },
  {
    path: "/manage-quotation",
    element: <ManageQuotation /> 
  },
  {
    path: "/export-quotation/:id",
    element: <ExportQuotation /> 
  },
  {
    path: "/manage-customer",
    element: <ManageCustomer /> 
  },
  {
    path: "/add-customer",
    element: <AddCustomer /> 
  },
  {
    path: "/edit-customer/:id",
    element: <EditCustomer /> 
  },
  {
    path: "/detail-customer/:id",
    element: <DetailCustomer /> 
  },
  {
    path: "/manage-carrier/",
    element: <ManageCarrier /> 
  },
  {
    path: "/add-carrier/",
    element: <AddCarrier /> 
  },
  {
    path: "/edit-carrier/:id",
    element: <EditCarrier /> 
  },
  {
    path: "/about",
    element: <About />
  },  
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />
  },
  {
    path: "/termofuse",
    element: <TermOfUse />
  },
  {
    path: "/help",
    element: <Help />
  },
  {
    path: "/account",
    element: <AccountPage />
  },
  {
    path: "/account/payment",
    element: <Payment />
  },
  {
    path: "/account/extendmembership",
    element: <ExtendPackage />
  },
  {
    path: "/account/changemembership",
    element: <ChangePackage />
  },
  {
    path: "/chat",
    element: <ChatPage />
  },
  {
    path: "/404",
    element: <Error404 />
  },
  {
    path: "/admin/manageuserpage",
    element: <ProtectedRoute> <ManageUser /> </ProtectedRoute>
  },
  {
    path: "/admin/settingcalcupage",
    element: <ProtectedRoute> <SettingCalcu /> </ProtectedRoute>
  },

])

ReactDOM.createRoot(document.getElementById('root')).render(
      <UserProvider>
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <CookieBar />
            <RouterProvider router={router} />
        </LoadScript>
      </UserProvider>
);
