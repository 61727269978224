import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_51PbieOKYDoQ6D6RxWR8kXQzGUSxubTzTuNCTXdtPPQlqCSICIRh2S0i4L7fYZ7GohEcKFcVBqoKZKiH97qeXg1w5003ItbsdeB");

import CheckoutForm from "../../components/payment/CheckoutForm.jsx";

import { message } from 'antd';

function Payment(){
  const token = localStorage.getItem('token');
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    try {
        const response = axios.post(`${import.meta.env.VITE_BACKEND_URL}stripe/create-payment-intent/`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }      
          );
        console.log(response.data)
        setClientSecret(response.data.clientSecret)
      } catch (err) {
        message.error('เกิดข้อผิดพลาดในการชำระเงิน');
        return false;
      }

  }, []);

  return (
    <div>
      {clientSecret && (
        <Elements
          options={{ clientSecret, appearance, loader }}
          stripe={stripePromise}
        >
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

export default Payment;