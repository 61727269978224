import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, MailOutlined, LockOutlined, LoadingOutlined} from '@ant-design/icons';
import { Input, Cascader , Spin} from 'antd';
import { Link , useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import loginImage from '../assets/login_Image.png';
import registerImage from '../assets/register.png';

import { useUserAuth } from '../context/UserContext.jsx';


import './login.css';


//หน้าlogin โดยใช้ user password
export function UserLoginForm({ toggleLoginForm }) {
        const { login } = useUserAuth(); // ใช้ context
        const [capVal, setCapVal] = useState(true);
        const apiReCAPTCHA = '6LdlTOYpAAAAAGWWUFRKcRgvH9nEZBkUHu9CYGu4';

        //ซ่อนและแสดงรหัสผ่าน
        const [showPassword, setShowPassword] = useState(false);
        const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

        //เก็บค่าusername และpassword
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');

        //เมื่อloginเสร็จ ให้navigate ไปหน้าอื่น
        let navigate = useNavigate();

        // สร้างตัวแปร state เพื่อเก็บค่าของข้อความเตือนเมื่อไม่ได้กรอก
        const [usernameError, setUsernameError] = useState('');
        const [passwordError, setPasswordError] = useState('');

        const handleLogin = async (e) => {
            setIsSubmitting(true); // ตั้งค่าเป็นกำลังส่งข้อมูล
            try {
            e.preventDefault(); //ป้องกันการรีเฟรชจอ

            let hasError = false;
            // ตรวจสอบว่ามีการกรอกชื่อผู้ใช้หรือไม่
            if (!username) {
                setUsernameError('กรุณากรอกชื่อผู้ใช้');
                hasError = true;
            } else {
                setUsernameError('');
            }
            // ตรวจสอบว่ามีการกรอกรหัสผ่านหรือไม่
            if (!password) {
                setPasswordError('กรุณากรอกรหัสผ่าน');
                hasError = true;
            } else {
                setPasswordError('');
            }
            // ถ้าไม่มีข้อผิดพลาด ทำการเข้าสู่ระบบ
            if (!hasError) {
                try {
                    // Use the full backend URL
                    const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/auth/loginbyuser`,
                        { username, password },
                        { 
                            headers: { 'Content-Type': 'application/json' },
                            validateStatus: () => true 
                        }
                    );
                    
                    // Log response for debugging
                    console.log('Login response:', response);

                    if (response.data && typeof response.data === 'string' && response.data.includes('<!doctype html>')) {
                        throw new Error('Received HTML instead of JSON. API endpoint might be incorrect.');
                    }

                    if (response.status !== 200) {
                        throw new Error(response.data.message || 'เกิดข้อผิดพลาดในการเข้าสู่ระบบ');
                    }

                    const { token, payload } = response.data;

                    // บันทึก token ลง localStorage
                    localStorage.setItem('token', token);

                    // บันทึกข้อมูลผู้ใช้ลงใน Context
                    login(payload);
                
                    navigate("/homepage");
                } catch (error) {
                    console.error('เกิดข้อผิดพลาดในการเข้าสู่ระบบ:', error.response.data.message);
                    hasError = true;
                    setPasswordError('อีเมลหรือรหัสผ่านไม่ถูกต้อง');
                    console.log(error.response.data.message);
                }
            }   
        
            } finally {
                setIsSubmitting(false); // ตั้งค่าเป็นไม่กำลังส่งข้อมูล
            }
        }


return (
    <div className="flex flex-row justify-center overflow-x-auto overflow-y-hidden">
        <div className="flex flex-col ">
            <div className="flex flex-row mt-6" style={{fontSize: '20px'}}>
                <div>
                    <h3 style={{fontSize: '24px',fontWeight: '700'}}>เข้าสู่ระบบ</h3>
                </div>
            </div>

            <div className='flex flex-row log-form-container items-center mt-4 mb-2 rounded-md shrink-0' style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} >
                <div className='overflow-hidden hidden md:flex' style={{minWidth: '30rem'}}>      
                    <div dir="ltr">
                        <img
                        className="mx-auto rounded-l-md  " 
                        style={{minWidth: '34rem'}}
                        src={loginImage}
                        alt="loginImage"
                        />                        
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='text-right mt-2'>
                        <a href='#' className='text-blue-700 hover:text-blue-800 active:text-blue-950 pr-6' onClick={toggleLoginForm}>เข้าสู่ระบบด้วยอีเมล</a>    
                    </div>

                    <div className='px-6 pb-6' >  
                        <form>
                            <label>ชื่อผู้ใช้</label>
                            <div className='pb-4 pt-1'>
                                <Input
                                    className={`px-4 w-80 h-11 ${usernameError ? 'border-red-500' : ''}`}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Username"
                                    prefix={<UserOutlined className="mr-2 scale-150 opacity-25" />}
                                />
                                {/* แสดงข้อความเตือน */}
                                {usernameError && <p className="text-red-500">{usernameError}</p>}
                            </div>

                            <label>รหัสผ่าน</label>
                            <a href='#' className='text-sm ml-40'>ลืมรหัสผ่านใช่ไหม</a>
                            <div className='pb-2 pt-1'>
                                <div className='pb-2 pt-1'>
                                    <Input.Password
                                        className={`px-4 w-80 h-11 ${passwordError ? 'border-red-500' : ''}`}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        suffix={showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                        type={showPassword ? 'text' : 'password'}
                                        prefix={<LockOutlined className="mr-2 scale-150 opacity-25" />}
                                    />
                                    {/* แสดงข้อความเตือน */}
                                    {passwordError && <p className="text-red-500">{passwordError}</p>}
                                </div>
                            </div>

                            {/*
                            <div>
                                <ReCAPTCHA 
                                sitekey={apiReCAPTCHA}
                                onChange={(val) => setCapVal(val)}
                                className='mb-2 hidden'
                                />
                            </div>
                            */}

                            <div className='pt-1.5'>
                                {/* บันทึกรหัสผ่าน */}
                                <label htmlFor="browserDefault">
                                    <input id="browserDefault" type="checkbox" className=" accent-blue-400" defaultChecked />
                                    <span class="inline-block align-top text-sm text-gray-500 mx-1.5">บันทึกรหัสผ่าน</span>
                                </label>
                                {/* เมื่อคลิกปุ่ม 'Login' จะเรียกใช้ handleLogin */}
                                {capVal?
                                <button className='
                                    p-2 px-4 rounded-md size-full mt-5
                                    bg-gradient-to-r from-blue-800 to-blue-600 hover:from-blue-900 hover:to-blue-700 active:from-blue-950 active:to-blue-800 transition  text-white' 
                                    type="button" onClick={handleLogin} >
                                        {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "เข้าสู่ระบบ"}
                                </button>
                                :
                                <button className='
                                p-2 px-4 rounded-md size-full mt-5
                                bg-blue-300  text-white cursor-default	' 
                                type="button"  >เข้าสู่ระบบ
                                </button>
                                }
                            </div>

                            <div className='pt-4'>
                                <div class="flex flex-row">
                                    <p class="line-through 	w-full h-px bg-gray-300 mt-2.5"></p>
                                    <p class="text-gray-500 text-sm mx-2">หรือ</p>
                                    <p class="line-through 	w-full h-px bg-gray-300 mt-2.5"></p>
                                </div>
                                {/* สมัครสามชิก */}
                                <span class="pt-2 pb-1 inline-block align-top text-sm">ยังไม่มีบัญชีผู้ใช้?</span>
                                <Link to="../registerpage">
                                    <button className='
                                        p-2 px-4 rounded-md size-full
                                        hover:bg-neutral-300 active:bg-neutral-400 bg-neutral-200  text-black' 
                                        type="button" >สมัครสมาชิก
                                    </button>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

//หน้าlogin โดยใช้ email password
export function EmailLoginForm({ toggleLoginForm }) {
    const apiReCAPTCHA = '6LdlTOYpAAAAAGWWUFRKcRgvH9nEZBkUHu9CYGu4';
    const { login } = useUserAuth(); // ใช้ context
    const [capVal, setCapVal] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

    //ซ่อนและแสดงรหัสผ่าน
    const [showPassword, setShowPassword] = useState(false);

    //เก็บค่าemail และpassword
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //เมื่อloginเสร็จ ให้navigate ไปหน้าอื่น
    let navigate = useNavigate();

    // สร้างตัวแปร state เพื่อเก็บค่าของข้อความเตือนเมื่อไม่ได้กรอก
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault(); //ป้องกันการรีเฟรชจอ
    try {
        setIsSubmitting(true); // ตั้งค่าเป็นกำลังส่งข้อมูล

        let hasError = false;
        if (!email) { // ตรวจสอบว่ามีการกรอกอีเมลหรือไม่
            setEmailError('กรุณากรอกอีเมล');
            hasError = true;
        } else {
            setEmailError('');
        }

        if (!password) { // ตรวจสอบว่ามีการกรอกรหัสผ่านหรือไม่
            setPasswordError('กรุณากรอกรหัสผ่าน');
            hasError = true;
        } else {
            setPasswordError('');
        }

        // ถ้าไม่มีข้อผิดพลาด ทำการเข้าสู่ระบบ
        if (!hasError) {
            try {
                const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/auth/login`, { email, password });

                // อัปเดต context ด้วยข้อมูลผู้ใช้ที่ได้รับ
                login(response.data.payload);

                navigate("/homepage");
            } catch (error) {
                console.error('เกิดข้อผิดพลาดในการเข้าสู่ระบบ:', error.response.data.message);
                hasError = true;
                setPasswordError('อีเมลหรือรหัสผ่านไม่ถูกต้อง');
                console.log(error.response.data.message);
            }
        }   
        } finally {
            setIsSubmitting(false); // ตั้งค่าเป็นไม่กำลังส่งข้อมูล
        }
    };



return (
<div className="flex flex-row justify-center overflow-x-auto overflow-y-hidden">
    <div className="flex flex-col ">
        <div className="flex flex-row mt-6 " style={{fontSize: '20px'}}>
            <div>
                <h3 style={{fontSize: '24px',fontWeight: '700'}}>เข้าสู่ระบบ</h3>
            </div>
        </div>

        <div className='flex flex-row log-form-container items-center mt-4 mb-2 shrink-0' style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} >
            <div className='overflow-hidden hidden md:flex' style={{minWidth: '30rem'}}>      
                <div dir="ltr">
                    <img
                    className="mx-auto rounded-l-md  " 
                    style={{minWidth: '34rem'}}
                    src={loginImage}
                    alt="loginImage"
                     />                        
                 </div>
            </div>

            <div className='flex flex-col'>
                <div className='text-right mt-2'>
                    <a href='#' className='text-blue-700 hover:text-blue-800 active:text-blue-950 pr-6' onClick={toggleLoginForm}>เข้าสู่ระบบด้วยชื่อผู้ใช้</a>    
                </div>

                <div className='px-6 pb-6' >  
                        <form>
                            <label>อีเมล</label>
                            <div className='pb-4 pt-1'>
                                <Input
                                    className={`px-4 w-80 h-11 ${emailError ? 'border-red-500' : ''}`}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    prefix={<MailOutlined className="mr-2 scale-150 opacity-25" />}
                                />
                                {/* แสดงข้อความเตือน */}
                                {emailError && <p className="text-red-500">{emailError}</p>}
                            </div>

                            <label>รหัสผ่าน</label>
                            <a href='#' className='text-sm ml-40'>ลืมรหัสผ่านใช่ไหม</a>
                            <div className='pb-2 pt-1'>
                                <div className='pb-2 pt-1'>
                                    <Input.Password
                                        className={`px-4 w-80 h-11 ${passwordError ? 'border-red-500' : ''}`}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Password"
                                        suffix={showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                        type={showPassword ? 'text' : 'password'}
                                        prefix={<LockOutlined className="mr-2 scale-150 opacity-25" />}
                                    />
                                    {/* แสดงข้อความเตือน */}
                                    {passwordError && <p className="text-red-500">{passwordError}</p>}
                                </div>
                            </div>

                            {/*
                            <div>
                                <ReCAPTCHA 
                                sitekey={apiReCAPTCHA}
                                onChange={(val) => setCapVal(val)}
                                className='mb-2 hidden'
                                />
                            </div>
                            */}

                            <div className='pt-1.5'>
                                {/* บันทึกรหัสผ่าน */}
                                <label htmlFor="browserDefault">
                                    <input id="browserDefault" type="checkbox" className=" accent-blue-400" defaultChecked />
                                    <span class="inline-block align-top text-sm text-gray-500 mx-1.5">บันทึกรหัสผ่าน</span>
                                </label>
                                {/* เมื่อคลิกปุ่ม 'Login' จะเรียกใช้ handleLogin */}
                                {capVal?
                                <button className='
                                    p-2 px-4 rounded-md size-full mt-5
                                    bg-gradient-to-r from-blue-800 to-blue-600 hover:from-blue-900 hover:to-blue-700 active:from-blue-950 active:to-blue-800 transition  text-white' 
                                    type="button" onClick={handleLogin} >
                                    {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "เข้าสู่ระบบ"}
                                </button>
                                :
                                <button className='
                                p-2 px-4 rounded-md size-full mt-5
                                bg-blue-300  text-white cursor-default	' 
                                type="button"  >เข้าสู่ระบบ
                                </button>
                                }
                            </div>

                            <div className='pt-4'>
                                <div class="flex flex-row">
                                    <p class="line-through 	w-full h-px bg-gray-300 mt-2.5"></p>
                                    <p class="text-gray-500 text-sm mx-2">หรือ</p>
                                    <p class="line-through 	w-full h-px bg-gray-300 mt-2.5"></p>
                                </div>
                                {/* สมัครสามชิก */}
                                <span class="pt-2 pb-1 inline-block align-top text-sm">ยังไม่มีบัญชีผู้ใช้?</span>
                                <Link to="../registerpage">
                                    <button className='
                                        p-2 px-4 rounded-md size-full
                                        hover:bg-neutral-300 active:bg-neutral-400 bg-neutral-200  text-black' 
                                        type="button" >สมัครสมาชิก
                                    </button>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

//สมัคร
export function InputRegister() {
    const { login } = useUserAuth(); // ใช้ context
    const [capVal, setCapVal] = useState(true);
    const apiReCAPTCHA = '6LdlTOYpAAAAAGWWUFRKcRgvH9nEZBkUHu9CYGu4';
    const token = localStorage.getItem('token');

    //ซ่อนและแสดงรหัสผ่าน
    const [showPassword, setShowPassword] = useState(false);

    //เก็บค่าusername และpassword
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [membershiptype, setSelectedMembershipType] = useState('');
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [ustatus, setUstatus] = useState(true);
    const {signUp} = useUserAuth();
    let navigate = useNavigate();

    // สร้างตัวแปร state เพื่อเก็บค่าของข้อความเตือนเมื่อไม่ได้กรอก
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [selectedMembershipTypeError, setSelectedMembershipTypeError] = useState('');
    const [emailError, setEmailError] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

    // ฟังก์ชั่นสำหรับการตรวจสอบความถูกต้องของอีเมล
    const validateEmail = (email) => {
    // ใช้ Regular Expression ในการตรวจสอบรูปแบบของอีเมล
    const emailPattern = /\S+@\S+\.\S+/;
    return emailPattern.test(email);
    };
    //ตรวจสอบความถูกต้องของอีเมล แจ้งเตือนทันที
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        
        // ตรวจสอบความถูกต้องของอีเมล
        if (!validateEmail(newEmail)) {
            setEmailError('รูปแบบอีเมลไม่ถูกต้อง');
        } else {
            setEmailError('');
        }
    };

    const handleRegister = async (e) => {
        
        //ป้องกันการรีเฟรชจอ
        e.preventDefault();

    try {
        setIsSubmitting(true); // ตั้งค่าเป็นกำลังส่งข้อมูล
        let hasError = false;

        // ตรวจสอบว่ามีการกรอกชื่อผู้ใช้หรือไม่
        if (!username) {
            setUsernameError('กรุณากรอกชื่อผู้ใช้');
            hasError = true;
        }else{
            hasError = false;
        }

        // ตรวจสอบว่ามีการกรอกรหัสผ่านหรือไม่
        if (!password) {
            setPasswordError('กรุณากรอกรหัสผ่าน');
            hasError = true;
        }else{
            hasError = false;
        }

        // ตรวจสอบว่ามีการกรอกยืนยันรหัสผ่านหรือไม่
        if (!confirmPassword) {
            setConfirmPasswordError('กรุณากรอกยืนยันรหัสผ่าน');
            hasError = true;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('รหัสผ่านไม่ตรงกัน');
            hasError = true;
        }else{
            hasError = false;
        }

        // ตรวจสอบว่ามีการเลือกประเภทสมาชิกหรือไม่
        if (!membershiptype) {
            setSelectedMembershipTypeError('กรุณาเลือกประเภทสมาชิก');
            hasError = true;
        }else{
            hasError = false;
        }

        if (!email) {
            setEmailError('กรุณากรอกอีเมล'); // ตรวจสอบว่ามีการกรอกอีเมลหรือไม่
            hasError = true;
        } else if (!validateEmail(email)) {
            setEmailError('รูปแบบอีเมลไม่ถูกต้อง'); // ตรวจสอบว่าอีเมลถูกต้องหรือไม่
            hasError = true;
        }else{
            hasError = false;
        }

        if (email) {
            try {
                await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/auth/checkEmail`, { email });
                setEmailError('');
            } catch (error) {
                setEmailError('อีเมลนี้มีในระบบแล้ว');
                hasError = true;
            }
        }else{
            hasError = false;
        }

        if (username) {
            try {
                await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/auth/checkUsername`, { username });
                setUsernameError('');
            } catch (error) {
                setUsernameError('ชื่อผู้ใช้นี้มีในระบบแล้ว');
                hasError = true;
            }
        }else{
            hasError = false;
        }


        // ถ้าไม่มีข้อผิดพลาด ทำการสมัครและเข้าสู่ระบบ
        if (!hasError) {

            try {
                const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/auth/register`, {
                  email,
                  username,
                  password,
                  membershipType: membershiptype[1],
                  ustatus,
                });

                // User ID returned from registration response
                const userId = response.data.userId;

                const { token, payload } = response.data;

                // บันทึก token ลง localStorage
                localStorage.setItem('token', token);

                // บันทึกข้อมูลผู้ใช้ลงใน Context
                login(payload);

                // List of categories to add
                const categories = [
                    'สินค้าอุปโภคบริโภค',
                    'สินค้าแฟชั่น',
                    'อุปกรณ์เครื่องใช้ภายในบ้าน',
                    'เครื่องประดับแฟชั่น',
                    'อื่นๆ'
                ];

                // Function to add categories
                const addCategories = async () => {
                    for (const category of categories) {
                        await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/category`, {
                            name: category,
                            user: userId
                        }, {
                            headers: {
                                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                            }
                        });
                    }
                };
                await addCategories();

                const carriers = [
                {
                    carrier: "J&T Express",
                    name: "J&T กรุงเทพและปริมณฑล",
                    description: "ค่าเริ่มต้นของระบบ",
                    user: userId,
                    cod: false,
                    status: true,
                    imageUrls: ["jt.jpg"],
                    shippingRates: JSON.stringify([
                        { name: "0-1 kg", weight_from: 0, weight_to: 1, rate: 23 },
                        { name: "1.01-2 kg", weight_from: 1.01, weight_to: 2, rate: 39 },
                        { name: "2.01-3 kg", weight_from: 2.01, weight_to: 3, rate: 49 },
                        { name: "3.01-4 kg", weight_from: 3.01, weight_to: 4, rate: 60 },
                        { name: "4.01-5 kg", weight_from: 4.01, weight_to: 5, rate: 65 },
                        { name: "5.01-6 kg", weight_from: 5.01, weight_to: 6, rate: 70 },
                        { name: "6.01-7 kg", weight_from: 6.01, weight_to: 7, rate: 85 },
                        { name: "7.01-8 kg", weight_from: 7.01, weight_to: 8, rate: 90 },
                        { name: "8.01-9 kg", weight_from: 8.01, weight_to: 9, rate: 95 },
                        { name: "9.01-10 kg", weight_from: 9.01, weight_to: 10, rate: 100 },
                        { name: "10.01-11 kg", weight_from: 10.01, weight_to: 11, rate: 130 },
                        { name: "11.01-12 kg", weight_from: 11.01, weight_to: 12, rate: 145 },
                        { name: "12.01-13 kg", weight_from: 12.01, weight_to: 13, rate: 160 },
                        { name: "13.01-14 kg", weight_from: 13.01, weight_to: 14, rate: 175 },
                        { name: "14.01-15 kg", weight_from: 14.01, weight_to: 15, rate: 185 },
                        { name: "15.01-16 kg", weight_from: 15.01, weight_to: 16, rate: 270 },
                        { name: "16.01-17 kg", weight_from: 16.01, weight_to: 17, rate: 275 },
                        { name: "17.01-18 kg", weight_from: 17.01, weight_to: 18, rate: 280 },
                        { name: "18.01-19 kg", weight_from: 18.01, weight_to: 19, rate: 285 },
                        { name: "19.01-20 kg", weight_from: 19.01, weight_to: 20, rate: 290 },
                        { name: "20.01-21 kg", weight_from: 20.01, weight_to: 21, rate: 300 },
                        { name: "21.01-22 kg", weight_from: 21.01, weight_to: 22, rate: 310 },
                        { name: "22.01-23 kg", weight_from: 22.01, weight_to: 23, rate: 320 },
                        { name: "23.01-24 kg", weight_from: 23.01, weight_to: 24, rate: 330 },
                        { name: "24.01-25 kg", weight_from: 24.01, weight_to: 25, rate: 340 },
                        { name: "25.01-26 kg", weight_from: 25.01, weight_to: 26, rate: 350 },
                        { name: "26.01-27 kg", weight_from: 26.01, weight_to: 27, rate: 360 },
                        { name: "27.01-28 kg", weight_from: 27.01, weight_to: 28, rate: 370 },
                        { name: "28.01-29 kg", weight_from: 28.01, weight_to: 29, rate: 380 },
                        { name: "29.01-30 kg", weight_from: 29.01, weight_to: 30, rate: 390 },
                        { name: "30.01-31 kg", weight_from: 30.01, weight_to: 31, rate: 400 },
                        { name: "31.01-32 kg", weight_from: 31.01, weight_to: 32, rate: 410 },
                        { name: "32.01-33 kg", weight_from: 32.01, weight_to: 33, rate: 420 },
                        { name: "33.01-34 kg", weight_from: 33.01, weight_to: 34, rate: 430 },
                        { name: "34.01-35 kg", weight_from: 34.01, weight_to: 35, rate: 440 },
                        { name: "35.01-36 kg", weight_from: 35.01, weight_to: 36, rate: 450 },
                        { name: "36.01-37 kg", weight_from: 36.01, weight_to: 37, rate: 460 },
                        { name: "37.01-38 kg", weight_from: 37.01, weight_to: 38, rate: 470 },
                        { name: "38.01-39 kg", weight_from: 38.01, weight_to: 39, rate: 480 },
                        { name: "39.01-40 kg", weight_from: 39.01, weight_to: 40, rate: 490 },
                        { name: "40.01-41 kg", weight_from: 40.01, weight_to: 41, rate: 500 },
                        { name: "41.01-42 kg", weight_from: 41.01, weight_to: 42, rate: 510 },
                        { name: "42.01-43 kg", weight_from: 42.01, weight_to: 43, rate: 520 },
                        { name: "43.01-44 kg", weight_from: 43.01, weight_to: 44, rate: 530 },
                        { name: "44.01-45 kg", weight_from: 44.01, weight_to: 45, rate: 540 },
                        { name: "45.01-46 kg", weight_from: 45.01, weight_to: 46, rate: 550 },
                        { name: "46.01-47 kg", weight_from: 46.01, weight_to: 47, rate: 560 },
                        { name: "47.01-48 kg", weight_from: 47.01, weight_to: 48, rate: 570 },
                        { name: "48.01-49 kg", weight_from: 48.01, weight_to: 49, rate: 580 },
                        { name: "49.01-50 kg", weight_from: 49.01, weight_to: 50, rate: 590 }
                    ])
                },
                {
                    carrier: "J&T Express",
                    name: "J&T ต่างจังหวัด",
                    description: "ค่าเริ่มต้นของระบบ",
                    user: userId,
                    cod: false,
                    status: true,
                    imageUrls: ["jt.jpg"],
                    shippingRates: JSON.stringify([
                        { name: "0-1 kg", weight_from: 0, weight_to: 1, rate: 30 },
                        { name: "1.01-2 kg", weight_from: 1.01, weight_to: 2, rate: 49 },
                        { name: "2.01-3 kg", weight_from: 2.01, weight_to: 3, rate: 49 },
                        { name: "3.01-4 kg", weight_from: 3.01, weight_to: 4, rate: 60 },
                        { name: "4.01-5 kg", weight_from: 4.01, weight_to: 5, rate: 65 },
                        { name: "5.01-6 kg", weight_from: 5.01, weight_to: 6, rate: 70 },
                        { name: "6.01-7 kg", weight_from: 6.01, weight_to: 7, rate: 85 },
                        { name: "7.01-8 kg", weight_from: 7.01, weight_to: 8, rate: 90 },
                        { name: "8.01-9 kg", weight_from: 8.01, weight_to: 9, rate: 95 },
                        { name: "9.01-10 kg", weight_from: 9.01, weight_to: 10, rate: 100 },
                        { name: "10.01-11 kg", weight_from: 10.01, weight_to: 11, rate: 130 },
                        { name: "11.01-12 kg", weight_from: 11.01, weight_to: 12, rate: 145 },
                        { name: "12.01-13 kg", weight_from: 12.01, weight_to: 13, rate: 160 },
                        { name: "13.01-14 kg", weight_from: 13.01, weight_to: 14, rate: 175 },
                        { name: "14.01-15 kg", weight_from: 14.01, weight_to: 15, rate: 185 },
                        { name: "15.01-16 kg", weight_from: 15.01, weight_to: 16, rate: 270 },
                        { name: "16.01-17 kg", weight_from: 16.01, weight_to: 17, rate: 275 },
                        { name: "17.01-18 kg", weight_from: 17.01, weight_to: 18, rate: 280 },
                        { name: "18.01-19 kg", weight_from: 18.01, weight_to: 19, rate: 285 },
                        { name: "19.01-20 kg", weight_from: 19.01, weight_to: 20, rate: 290 },
                        { name: "20.01-21 kg", weight_from: 20.01, weight_to: 21, rate: 300 },
                        { name: "21.01-22 kg", weight_from: 21.01, weight_to: 22, rate: 310 },
                        { name: "22.01-23 kg", weight_from: 22.01, weight_to: 23, rate: 320 },
                        { name: "23.01-24 kg", weight_from: 23.01, weight_to: 24, rate: 330 },
                        { name: "24.01-25 kg", weight_from: 24.01, weight_to: 25, rate: 340 },
                        { name: "25.01-26 kg", weight_from: 25.01, weight_to: 26, rate: 350 },
                        { name: "26.01-27 kg", weight_from: 26.01, weight_to: 27, rate: 360 },
                        { name: "27.01-28 kg", weight_from: 27.01, weight_to: 28, rate: 370 },
                        { name: "28.01-29 kg", weight_from: 28.01, weight_to: 29, rate: 380 },
                        { name: "29.01-30 kg", weight_from: 29.01, weight_to: 30, rate: 390 },
                        { name: "30.01-31 kg", weight_from: 30.01, weight_to: 31, rate: 400 },
                        { name: "31.01-32 kg", weight_from: 31.01, weight_to: 32, rate: 410 },
                        { name: "32.01-33 kg", weight_from: 32.01, weight_to: 33, rate: 420 },
                        { name: "33.01-34 kg", weight_from: 33.01, weight_to: 34, rate: 430 },
                        { name: "34.01-35 kg", weight_from: 34.01, weight_to: 35, rate: 440 },
                        { name: "35.01-36 kg", weight_from: 35.01, weight_to: 36, rate: 450 },
                        { name: "36.01-37 kg", weight_from: 36.01, weight_to: 37, rate: 460 },
                        { name: "37.01-38 kg", weight_from: 37.01, weight_to: 38, rate: 470 },
                        { name: "38.01-39 kg", weight_from: 38.01, weight_to: 39, rate: 480 },
                        { name: "39.01-40 kg", weight_from: 39.01, weight_to: 40, rate: 490 },
                        { name: "40.01-41 kg", weight_from: 40.01, weight_to: 41, rate: 500 },
                        { name: "41.01-42 kg", weight_from: 41.01, weight_to: 42, rate: 510 },
                        { name: "42.01-43 kg", weight_from: 42.01, weight_to: 43, rate: 520 },
                        { name: "43.01-44 kg", weight_from: 43.01, weight_to: 44, rate: 530 },
                        { name: "44.01-45 kg", weight_from: 44.01, weight_to: 45, rate: 540 },
                        { name: "45.01-46 kg", weight_from: 45.01, weight_to: 46, rate: 550 },
                        { name: "46.01-47 kg", weight_from: 46.01, weight_to: 47, rate: 560 },
                        { name: "47.01-48 kg", weight_from: 47.01, weight_to: 48, rate: 570 },
                        { name: "48.01-49 kg", weight_from: 48.01, weight_to: 49, rate: 580 },
                        { name: "49.01-50 kg", weight_from: 49.01, weight_to: 50, rate: 590 }
                    ])
                },
                {
                    carrier: "Kerry Express",
                    name: "Kerry กรุงเทพและปริมณฑล",
                    description: "ค่าเริ่มต้นของระบบ",
                    user: userId,
                    cod: false,
                    status: true,
                    imageUrls: ["kerry.jpg"],
                    shippingRates: JSON.stringify([
                        { name: "0-1 kg", weight_from: 0, weight_to: 1, rate: 30 },
                        { name: "1.01-2 kg", weight_from: 1.01, weight_to: 2, rate: 35 },
                        { name: "2.01-3 kg", weight_from: 2.01, weight_to: 3, rate: 40 },
                        { name: "3.01-4 kg", weight_from: 3.01, weight_to: 4, rate: 50 },
                        { name: "4.01-5 kg", weight_from: 4.01, weight_to: 5, rate: 60 },
                        { name: "5.01-6 kg", weight_from: 5.01, weight_to: 6, rate: 75 },
                        { name: "6.01-7 kg", weight_from: 6.01, weight_to: 7, rate: 90 },
                        { name: "7.01-8 kg", weight_from: 7.01, weight_to: 8, rate: 100 },
                        { name: "8.01-9 kg", weight_from: 8.01, weight_to: 9, rate: 115 },
                        { name: "9.01-10 kg", weight_from: 9.01, weight_to: 10, rate: 125 },
                        { name: "10.01-11 kg", weight_from: 10.01, weight_to: 11, rate: 135 },
                        { name: "11.01-12 kg", weight_from: 11.01, weight_to: 12, rate: 145 },
                        { name: "12.01-13 kg", weight_from: 12.01, weight_to: 13, rate: 155 },
                        { name: "13.01-14 kg", weight_from: 13.01, weight_to: 14, rate: 165 },
                        { name: "14.01-15 kg", weight_from: 14.01, weight_to: 15, rate: 175 },
                        { name: "15.01-16 kg", weight_from: 15.01, weight_to: 16, rate: 185 },
                        { name: "16.01-17 kg", weight_from: 16.01, weight_to: 17, rate: 195 },
                        { name: "17.01-18 kg", weight_from: 17.01, weight_to: 18, rate: 205 },
                        { name: "18.01-19 kg", weight_from: 18.01, weight_to: 19, rate: 215 },
                        { name: "19.01-20 kg", weight_from: 19.01, weight_to: 20, rate: 225 },
                        { name: "20.01-21 kg", weight_from: 20.01, weight_to: 21, rate: 250 },
                        { name: "21.01-22 kg", weight_from: 21.01, weight_to: 22, rate: 275 },
                        { name: "22.01-23 kg", weight_from: 22.01, weight_to: 23, rate: 300 },
                        { name: "23.01-24 kg", weight_from: 23.01, weight_to: 24, rate: 325 },
                        { name: "24.01-25 kg", weight_from: 24.01, weight_to: 25, rate: 350 },
                        { name: "25.01-26 kg", weight_from: 25.01, weight_to: 26, rate: 375 },
                        { name: "26.01-27 kg", weight_from: 26.01, weight_to: 27, rate: 400 },
                        { name: "27.01-28 kg", weight_from: 27.01, weight_to: 28, rate: 425 },
                        { name: "28.01-29 kg", weight_from: 28.01, weight_to: 29, rate: 450 },
                        { name: "29.01-30 kg", weight_from: 29.01, weight_to: 30, rate: 475 },
                    ])
                },
                {
                    carrier: "Kerry Express",
                    name: "Kerry ต่างจังหวัด",
                    description: "ค่าเริ่มต้นของระบบ",
                    user: userId,
                    cod: false,
                    status: true,
                    imageUrls: ["kerry.jpg"],
                    shippingRates: JSON.stringify([
                        { name: "0-1 kg", weight_from: 0, weight_to: 1, rate: 40 },
                        { name: "1.01-2 kg", weight_from: 1.01, weight_to: 2, rate: 45 },
                        { name: "2.01-3 kg", weight_from: 2.01, weight_to: 3, rate: 50 },
                        { name: "3.01-4 kg", weight_from: 3.01, weight_to: 4, rate: 60 },
                        { name: "4.01-5 kg", weight_from: 4.01, weight_to: 5, rate: 70 },
                        { name: "5.01-6 kg", weight_from: 5.01, weight_to: 6, rate: 85 },
                        { name: "6.01-7 kg", weight_from: 6.01, weight_to: 7, rate: 95 },
                        { name: "7.01-8 kg", weight_from: 7.01, weight_to: 8, rate: 105 },
                        { name: "8.01-9 kg", weight_from: 8.01, weight_to: 9, rate: 120 },
                        { name: "9.01-10 kg", weight_from: 9.01, weight_to: 10, rate: 135 },
                        { name: "10.01-11 kg", weight_from: 10.01, weight_to: 11, rate: 150 },
                        { name: "11.01-12 kg", weight_from: 11.01, weight_to: 12, rate: 165 },
                        { name: "12.01-13 kg", weight_from: 12.01, weight_to: 13, rate: 180 },
                        { name: "13.01-14 kg", weight_from: 13.01, weight_to: 14, rate: 195 },
                        { name: "14.01-15 kg", weight_from: 14.01, weight_to: 15, rate: 210 },
                        { name: "15.01-16 kg", weight_from: 15.01, weight_to: 16, rate: 225 },
                        { name: "16.01-17 kg", weight_from: 16.01, weight_to: 17, rate: 240 },
                        { name: "17.01-18 kg", weight_from: 17.01, weight_to: 18, rate: 255 },
                        { name: "18.01-19 kg", weight_from: 18.01, weight_to: 19, rate: 270 },
                        { name: "19.01-20 kg", weight_from: 19.01, weight_to: 20, rate: 285 },
                        { name: "20.01-21 kg", weight_from: 20.01, weight_to: 21, rate: 310 },
                        { name: "21.01-22 kg", weight_from: 21.01, weight_to: 22, rate: 335 },
                        { name: "22.01-23 kg", weight_from: 22.01, weight_to: 23, rate: 360 },
                        { name: "23.01-24 kg", weight_from: 23.01, weight_to: 24, rate: 385 },
                        { name: "24.01-25 kg", weight_from: 24.01, weight_to: 25, rate: 410 },
                        { name: "25.01-26 kg", weight_from: 25.01, weight_to: 26, rate: 435 },
                        { name: "26.01-27 kg", weight_from: 26.01, weight_to: 27, rate: 460 },
                        { name: "27.01-28 kg", weight_from: 27.01, weight_to: 28, rate: 485 },
                        { name: "28.01-29 kg", weight_from: 28.01, weight_to: 29, rate: 510 },
                        { name: "29.01-30 kg", weight_from: 29.01, weight_to: 30, rate: 535 },
                    ])
                },
                ];

                // Function to add carrier
                const addCarriers = async () => {
                    await Promise.all(carriers.map(carrier =>
                        axios.post(`${import.meta.env.VITE_BACKEND_URL}api/carrier`, carrier, {
                            headers: { 'Authorization': `Bearer ${token}` }
                        })
                    ));
                };
                await addCarriers();
          
                navigate("/registersuccess");
              } catch (error) {
                // จัดการข้อผิดพลาดที่ปลอดภัยยิ่งขึ้น
                console.error('Registration error:', error);
                let errorMessage = 'เกิดข้อผิดพลาดในการสมัครสมาชิก';
                
                if (error.response && error.response.data) {
                    errorMessage = error.response.data.message || errorMessage;
                    console.error('Server error details:', error.response.data);
                } else if (error.request) {
                    // มีการส่งคำขอแล้วแต่ไม่ได้รับการตอบกลับ
                    console.error('No response received:', error.request);
                    errorMessage = 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้';
                } else {
                    // มีบางอย่างผิดพลาดในการตั้งค่าคำขอ
                    console.error('Request error:', error.message);
                }              
            }
        }
    } finally {
        setIsSubmitting(false); // ตั้งค่าเป็นไม่กำลังส่งข้อมูล
    }
    
    };

    //ตรวจสอบความยาวของชื่อผู้ใช้ แจ้งเตือนทันที
    const handleUsernameChange = (e) => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        
        if (newUsername.length < 6) {
            setUsernameError('ชื่อผู้ใช้งานต้องมีความยาวอย่างน้อย 6 ตัวอักษร');
        } else {
            setUsernameError('');
        }
    };

    //ตรวจสอบความยาวของรหัสผ่าน แจ้งเตือนทันที
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        
        if (newPassword.length < 8) {
            setPasswordError('รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร');
        } else {
            setPasswordError('');
        }
    };

    //ตรวจสอบความยาวของการยืนยันรหัสผ่าน แจ้งเตือนทันที
    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        
        if (newConfirmPassword.length < 8) {
            setConfirmPasswordError('รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร');
        } else if (newConfirmPassword !== password) {
            setConfirmPasswordError('รหัสผ่านไม่ตรงกัน');
        } else {
            setConfirmPasswordError('');
        }
    };



return (
<div className="flex flex-row justify-center">
    <div className="flex flex-col">
        <div className="flex flex-row mt-6" style={{fontSize: '20px'}}>
            <div>
                <h3 style={{fontSize: '24px',fontWeight: '700'}} className='pl-4'>สมัครสมาชิก</h3>
            </div>
        </div>

        <div className='flex flex-row log-form-container items-center mt-4 mb-2 rounded-md' style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} >
            <div >      
                <div className="h-96 w-96 m-11 hidden md:flex">
                    <img
                        className="mx-auto rounded-s-md w-full max-w-xl"
                        src={registerImage}
                        alt=""
                    />                        
                </div>
            </div>

            <div className='my-8 px-6' >      
                <form>
                    <label>อีเมล</label>
                    <div className='pb-4 pt-1'>
                        <Input
                            className={`px-4 w-80 h-11 ${emailError ? 'border-red-500' : ''}`}
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email Address"
                            prefix={<MailOutlined className="mr-2 scale-150 opacity-25" />}
                        />
                        {/* แสดงข้อความเตือน */}
                        {emailError && <p className="text-red-500">{emailError}</p>}
                    </div>

                    <label>ชื่อผู้ใช้</label>
                    <div className='pb-4 pt-1'>
                        <Input
                            className={`px-4 w-80 h-11 ${usernameError ? 'border-red-500' : ''}`}
                            value={username}
                            onChange={handleUsernameChange}
                            placeholder="Username"
                            prefix={<UserOutlined className="mr-2 scale-150 opacity-25" />}
                            />
                        {/* แสดงข้อความเตือน */}
                        {usernameError && <p className="text-red-500">{usernameError}</p>}
                    </div>

                    <div className="flex flex-wrap">
                        <div className='pr-4'>
                            <label>รหัสผ่าน</label>
                            <div className='pb-2 pt-1'>
                                <div className='pb-2 pt-1'>
                                    <Input.Password
                                        className={`px-4 w-60 h-11 ${passwordError ? 'border-red-500' : ''}`}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        placeholder="Password"
                                        suffix={showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                        type={showPassword ? 'text' : 'password'}
                                        prefix={<LockOutlined className="mr-2 scale-150 opacity-25" />}
                                    />
                                    {/* แสดงข้อความเตือน */}
                                    {passwordError && <p className="text-red-500">{passwordError}</p>}
                                </div>
                            </div>
                        </div>

                        <div>
                            <label>ยืนยันรหัสผ่าน</label>
                            <div className='pb-2 pt-1'>
                                <div className='pb-2 pt-1'>
                                    <Input.Password
                                        className={`px-4 w-60 h-11 ${confirmPasswordError ? 'border-red-500' : ''}`}
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        placeholder="Confirm Password"
                                        suffix={showPassword ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                        type={showPassword ? 'text' : 'password'}
                                        prefix={<LockOutlined className="mr-2 scale-150 opacity-25" />}

                                    />
                                    {/* แสดงข้อความเตือน */}
                                    {confirmPasswordError && <p className="text-red-500">{confirmPasswordError}</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                            {/*
                            <div>
                                <ReCAPTCHA 
                                sitekey={apiReCAPTCHA}
                                onChange={(val) => setCapVal(val)}
                                className='mb-2 hidden'
                                />
                            </div>
                            */}

                    <div className='pt-1.5'>
                        {/* ยอมรับเงื่อนไข */}
                        <label htmlFor="browserDefault">
                            <input 
                                id="browserDefault" 
                                type="checkbox" 
                                className=" accent-blue-400 cursor-pointer" 
                                defaultChecked ={acceptedTerms}  
                                onChange={(e) => setAcceptedTerms(e.target.checked)}
                            />
                            <span className="inline-block align-top text-sm text-gray-500 mx-1.5 cursor-pointer">ยอมรับ</span>
                        </label>
                        <span className="inline-block align-top text-sm text-gray-500">
                            <Link to='../termofuse'> <a href='#' className='text-blue-600 hover:text-blue-800 active:text-indigo-900 pr-1'>เงื่อนไขการใช้งาน</a></Link>
                            และ <Link to='../privacypolicy'><a  href='#' className='text-blue-600 hover:text-blue-800 active:text-indigo-900'>นโยบายความเป็นส่วนตัว</a></Link>
                        </span>

                        {/* เมื่อคลิกปุ่ม 'สมัคร' จะเรียกใช้ handleRegister */}
                        <button //กดไม่ได้ ถ้าไม่ได้ติกยอมรับข้อตกลง
                            className='p-2 px-4 rounded-md size-full mt-5 
                                      bg-blue-500  text-white opacity-50'
                            type="button" 
                            disabled={!acceptedTerms || !capVal}
                            hidden={acceptedTerms && capVal}
                            >
                                สมัครสมาชิก
                        </button>
                        <button 
                            className='p-2 px-4 rounded-md size-full mt-5 
                                    bg-gradient-to-r from-blue-800 to-blue-600 hover:from-blue-900 hover:to-blue-700 active:from-blue-950 active:to-blue-800 transition  text-white'
                            type="button" 
                            onClick={handleRegister} 
                            disabled={!acceptedTerms || !capVal || isSubmitting}
                            hidden={!acceptedTerms || !capVal}
                            >
                                {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "สมัครสมาชิก"}
                        </button>
                    </div>

                    <div className='pt-4'>
                        <div className="flex flex-row">
                            <p className="line-through 	w-full h-px bg-gray-300 mt-2.5"></p>
                            <p className="text-gray-500 text-sm mx-2">หรือ</p>
                            <p className="line-through 	w-full h-px bg-gray-300 mt-2.5"></p>
                        </div>
                        {/* เข้าหน้าล็อคอิน */}
                        <span className="pt-2 pb-1 inline-block align-top text-sm">เป็นสมาชิกอยู่แล้ว?</span>
                        <Link to="../loginpage" >
                            <button className='
                                p-2 px-4 rounded-md size-full
                                hover:bg-neutral-300 active:bg-neutral-400 bg-neutral-200  text-black' 
                                type="button">เข้าหน้าล็อคอิน
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
)
}