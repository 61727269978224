import React, { useState } from 'react';
import { useUserAuth } from '../context/UserContext.jsx';
import { Sidebar,Nav,NavNoUser } from '../components/Nav.jsx';
import { Spin,FloatButton , Radio, Tabs, Modal, Collapse  } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'


function Help() {
  const{user} =  useUserAuth();
  console.log("user" , user)

  const [isModalAccountOpen, setIsModalAccountOpen] = useState(false);
  const [isModalMarketOpen, setIsModalMarketOpen] = useState(false);
  const [isModalCalcuOpen, setIsModalCalcuOpen] = useState(false);
  const [isModalDocumentOpen, setIsModalDocumentOpen] = useState(false);


  const showModalAccount = () => {
    setIsModalAccountOpen(true);
  };
  const showModalMarket = () => {
    setIsModalMarketOpen(true);
  };
  const showModalCalcu = () => {
    setIsModalCalcuOpen(true);
  };
  const showModalDocument = () => {
    setIsModalDocumentOpen(true);
  };
  const handleClose = () => {
    setIsModalAccountOpen(false);
    setIsModalMarketOpen(false);
    setIsModalCalcuOpen(false);
    setIsModalDocumentOpen(false);
  };


  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}
      
        <div className='flex md:justify-between'>
          <div>
            <Sidebar />
          </div>

          <div className=' md:mx-2 md:ml-4 mx-1 overflow-hidden'>
            <div className=''>
                <div className='my-6 text-2xl md:ml-0 ml-4'>ศูนย์ช่วยเหลือ</div>
                <div className='grid grid-cols-2 gap-4 mt-2 w-full bg-white rounded-lg p-6 mb-10'>
                    <div className='bg-white hover:bg-gray-200 select-none cursor-pointer p-2 md:pl-10 md:pr-16 rounded-lg border' onClick={showModalAccount}>
                      บัญชีผู้ใช้
                    </div>
                    <div className='bg-white hover:bg-gray-200 select-none cursor-pointer p-2 md:pl-10 md:pr-16 rounded-lg border' onClick={showModalMarket}>
                      ร้านค้า
                    </div>
                    <div className='bg-white hover:bg-gray-200 select-none cursor-pointer p-2 md:pl-10 md:pr-16 rounded-lg border' onClick={showModalCalcu}>
                      เครื่องมือคำนวณกำไรสินค้า
                    </div>
                    <div className='bg-white hover:bg-gray-200 select-none cursor-pointer p-2 md:pl-10 md:pr-16 rounded-lg border' onClick={showModalDocument}>
                      เอกสาร
                    </div>
                </div>

                <Modal title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>บัญชีผู้ใช้</span>} open={isModalAccountOpen} onCancel={handleClose} footer={null} width={800}>
                  <div className='flex flex-col gap-4 text-lg'>
                    ไม่มีข้อมูล
                  </div>
                </Modal>

                <Modal title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>ร้านค้า</span>} open={isModalMarketOpen} onCancel={handleClose} footer={null} width={800}>
                  <div className='flex flex-col gap-4'>
                    <Collapse
                      items={[
                        {
                          key: '21',
                          label: <span style={{ fontSize: '20px' }}>ฉันสามารถสร้างร้านค้าได้สูงสุดกี่ร้านค้าต่อหนึ่งบัญชี?</span>,
                          children: <p className='text-lg'>4 ร้านค้าต่อหนึ่งบัญชี</p>,
                        },
                      ]}
                    />
                    <Collapse
                      items={[
                        {
                          key: '22',
                          label: <span style={{ fontSize: '20px' }}>สินค้าที่บันทึกไว้ไม่แสดงในหน้าร้าน เกิดจากอะไร?</span>,
                          children: <p className='text-lg'>เกิดจากผู้ใช้ "ปิดการใช้งาน" สินค้าจะทำการซ่อนไม่ให้แสดงในหน้าร้านค้า </p>,
                        },
                      ]}
                    />
                    <Collapse
                      items={[
                        {
                          key: '23',
                          label: <span style={{ fontSize: '20px' }}>ฉันจะแก้ไขประเภทสินค้าได้อย่างไร?</span>,
                          children: <p  className='text-lg'>
                            สามารถทำการแก้ไขชื่อประเภทสินค้า หรือลบได้ที่
                              การตั้งค่า - ประเภทสินค้า 

                          </p>,
                        },
                      ]}
                    />
                  </div>
                </Modal>

                <Modal title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>เครื่องมือคำนวณกำไรสินค้า</span>} open={isModalCalcuOpen} onCancel={handleClose} footer={null} width={800}>
                  <div className='flex flex-col gap-4'>
                    <Collapse
                      items={[
                        {
                          key: '31',
                          label: <span style={{ fontSize: '20px' }}>ฉันจะสามารถแก้ไขข้อมูลขนส่งที่ต้องใช้ในการคำนวณอย่างไร?</span>,
                          children: <p  className='text-lg'>สามารถแก้ไข หรือเพิ่มการจัดส่งใหม่ได้โดย ไปที่ การตั้งค่า - ตัวเลือกการจัดส่งสินค้า</p>,
                        },
                      ]}
                    />
                  </div>
                </Modal>

                <Modal title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>เอกสาร</span>} open={isModalDocumentOpen} onCancel={handleClose} footer={null} width={800}>
                  <div className='flex flex-col gap-4 text-lg'>
                    ยังไม่มีข้อมูล
                  </div>
                </Modal>
            </div>

              <div className='text-center flex justify-center mt-2'>
                <p className='text-sm text-gray-600 hover:cursor-pointer select-none'><a href='/TermOfUse' target="_blank" rel="noopener noreferrer">เงื่อนไขการใช้งาน</a></p>
                <p className='text-sm text-gray-600 select-none mx-2'>/</p>
                <p className='text-sm text-gray-600 hover:cursor-pointer select-none'><a href='/PrivacyPolicy' target="_blank" rel="noopener noreferrer">นโยบายความเป็นส่วนตัว</a></p>
              </div>
              <div className='flex flex-wrap bg-white border w-full rounded-lg mt-2 p-4 justify-center'>
                <div>ติดต่อเจ้าหน้าที่</div> 
                <div><MailOutlined className='ml-4  text-gray-400'/> shopchum.service@gmail.com</div>
                <div><PhoneOutlined  className='ml-4 text-gray-400' /> 095-783-8911</div> 
              </div>
          </div>

          <div></div>
        </div>

    </>
  )
}

export default Help
