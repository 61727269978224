import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar, Nav , NavNoUser } from "../../components/Nav.jsx";
import axios from 'axios';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Spin ,message,Input ,Popconfirm} from 'antd';
import { Transition } from '@headlessui/react';

import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './styles/AccountPage.css'
import { calculateRemainingUsage } from '../script.jsx';
import { LoadingOutlined } from "@ant-design/icons";


function AccountPage(){
    const { user } = useUserAuth();
    const token = localStorage.getItem('token');
    const [userData, setUserData] = useState([]);
    const [data, setData] = useState(true);
    const navigate = useNavigate();
    const [resetpasswordform, setResetPasswordForm] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

    const [remainingInfo, setRemainingInfo] = useState({
      remainingDays: 0,
      remainingHours: 0,
      remainingMinutes: 0,
    });

    //ดึงข้อมูลผู้ใช้ขึ้นมาแสดง
    const fetchUser = async () => {
      if (user && user._id) {
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/user/id/${user._id}`);
            setUserData(response.data);

            if(response.data.length === 0){
              setData(false)
            }else {
              const usageInfo = calculateRemainingUsage(response.data); //อายุการใช้งานคงเหลือ
              setRemainingInfo(usageInfo);
            }

          } catch (err) {
            console.error('Error fetching user:', err);
          }
      }
    };

    useEffect(() => {
      fetchUser();
      if (!user) {
        navigate('/homepage'); // ไปยังเส้นทาง homepage
    }
    }, [user, navigate]);


    const handleresetpasswordOpen = (e) => {
      //ป้องกันการรีเฟรชจอ
      e.preventDefault();
      setResetPasswordForm(true);
    };
    const handleresetpasswordClose = (e) => {
      e.preventDefault();
      setResetPasswordForm(false);
    };

    const handleSubmitChangepassword = async () => {
      setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

      let hasError = false;

      // ตรวจสอบว่ามีการกรอกยืนยันรหัสผ่านหรือไม่
      if (!confirmPassword) {
        setConfirmPasswordError('กรุณากรอกยืนยันรหัสผ่าน');
        hasError = true;
        message.error('กรุณากรอกยืนยันรหัสผ่าน')
      } else if (password !== confirmPassword) {
          setConfirmPasswordError('รหัสผ่านไม่ตรงกัน');
        hasError = true;
        message.error('รหัสผ่านไม่ตรงกัน')
      }

      if (!hasError) {
        try {
          const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/user/reset-password/${user._id}`,
            { password: password },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                }
            }
          );

          fetchUser(); // รีเฟรชข้อมูลผู้ใช้หลังจากอัปเดต
          message.success('แก้ไขรหัสผ่านสำเร็จ');
          setResetPasswordForm(false);
        } catch (error) {
          message.error('แก้ไขรหัสผ่านไม่สำเร็จ');
          console.log(error)
        } finally {
          setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
        }
      }
    };

    //ตรวจสอบความยาวของรหัสผ่าน แจ้งเตือนทันที
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        
        if (newPassword.length < 6) {
            setPasswordError('รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร');
        } else {
            setPasswordError('');
        }
    };

    //ตรวจสอบความยาวของการยืนยันรหัสผ่าน แจ้งเตือนทันที
    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        
        if (newConfirmPassword.length < 6) {
            setConfirmPasswordError('รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร');
        } else if (newConfirmPassword !== password) {
            setConfirmPasswordError('รหัสผ่านไม่ตรงกัน');
        } else {
            setConfirmPasswordError('');
        }
    };

    if (user && userData.length === 0 && data) {
      return (
        <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin  fullscreen size="large" /></div>
        </div>
      </>
    );
    }

    return(
        <>
        {user ? <Nav /> : (<NavNoUser />)}
  
        <div className='flex md:justify-between'>
          <div>
            <Sidebar />
          </div>

  
          <div className='grow md:grow-0 overflow-hidden overflow-x-auto'>
            <div className='md:mx-4 mx-2 mb-10'>
            {user && (
                    <>
                      {/*reset password*/}
                      <Transition
                        show={resetpasswordform}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                          <div className="overlay fixed inset-0 bg-black opacity-50 z-40" onClick={handleresetpasswordClose}></div>    
                          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4 z-50">
                            <div className="w-full max-w-xl md:w-min overscroll-none overflow-hidden bg-white p-6 px-12 md:px-20 shadow-lg rounded-xl">
                              <div className=''>
                                    <label>ตั้งค่ารหัสผ่านใหม่</label>
                                    <div className='pb-2 pt-1'>
                                        <div className='pb-2 pt-1'>
                                            <Input.Password
                                                className={`px-4 min-w-60 h-11 ${passwordError ? 'border-red-500' : ''}`}
                                                value={password}
                                                onChange={handlePasswordChange}
                                                placeholder="Password"
                                            />
                                            {/* แสดงข้อความเตือน */}
                                            {passwordError && <p className="text-red-500">{passwordError}</p>}
                                        </div>
                                    </div>
                              </div>

                              <div>
                                    <label>ยืนยันรหัสผ่านใหม่</label>                            
                                      <div className='pb-2 pt-1'>
                                        <div className='pb-2 pt-1'>
                                            <Input.Password
                                                className={`px-4 min-w-60 h-11 ${confirmPasswordError ? 'border-red-500' : ''}`}
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                                placeholder="Confirm Password"
                                            />
                                            {/* แสดงข้อความเตือน */}
                                            {confirmPasswordError && <p className="text-red-500">{confirmPasswordError}</p>}
                                        </div>
                                    </div>
                              </div>

                              <div className='flex justify-center mt-4'>
                                    <button onClick={handleresetpasswordClose} className='bg-white hover:bg-gray-100 text-red-600 hover:text-red-700 border border-red-600 hover:border-red-700 rounded-md px-4 py-1'>
                                      ยกเลิก
                                    </button>
                                    <Popconfirm
                                      title="ยืนยันการตั้งค่ารหัสผ่านใหม่"
                                      description=""
                                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                      onConfirm={() => handleSubmitChangepassword(user._id, password)}
                                      okText="ยืนยัน"
                                      cancelText="ยกเลิก"
                                    >
                                      <button 
                                        disabled={isSubmitting}
                                        className='button-green text-white rounded-md px-4 py-1 ml-2'
                                      >
                                        {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "ยืนยัน"}
                                      </button>
                                    </Popconfirm>
                              </div>
                            </div>  
                          </div>
                      
                      </Transition>
                    </>
                    )}

                <div className='mt-6 ml-2 flex flex-col '>
                    <label className='text-4xl font-bold'>บัญชี</label>
                    <label className='my-1 mt-2 text-lg'>รายละเอียดการเป็นสมาชิก</label>
                </div>

                <div className='bg-white mt-2 rounded-lg md:max-w-full border md:w-[700px]'>
                  
                  <div className='p-6 pb-0'>
                    <div className='flex mb-4'>
                        <label className='text-lg md:text-xl'>ประเภทสมาชิก : </label>
                        {userData.membershipType === 'diamond' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-blue-200 to-blue-400 shadow select-none'><DiamondRoundedIcon /> DIAMOND</p>
                        :''}
                        {userData.membershipType === 'platinum' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-blue-200 to-blue-400 shadow select-none'><DiamondRoundedIcon /> PLATINUM</p>
                        :''}
                        {userData.membershipType === 'gold' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-amber-200 to-amber-400 shadow select-none'><DiamondRoundedIcon /> GOLD</p>
                        :''}
                        {userData.membershipType === 'silver' ?
                        <p className='ml-3 text-white rounded-lg px-4 md:text-xl bg-gradient-to-r from-slate-200 to-slate-400 shadow select-none'><DiamondRoundedIcon /> SILVER</p>
                        :''}
                    </div>

                    <div>
                        <label className='text-md md:text-xl'>อายุการใช้งานคงเหลือ: {remainingInfo.remainingDays} วัน {remainingInfo.remainingHours} ชั่วโมง {remainingInfo.remainingMinutes} นาที</label>
                    </div>

                    <div className=''>
                      <label className='text-md md:text-lg text-gray-700'>ครบกำหนดชำระเงินครั้งต่อไป: {remainingInfo.formattedExpirationDate}</label>
                    </div>

                    <div className='mt-2 hidden'>
                      <label className='text-xl text-gray-700'>ประเภทสมาชิก : {userData.membershipType}</label>
                    </div>

                    <div className='border mt-4'></div>
                  </div>

                  <div className="grid md:grid-cols-2 grid-cols-1 gap-2 pl-2 py-2 pr-2 w-full">
                    <a href='../account/extendmembership' className="p-4 py-2 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 md:rounded-l  w-full text-center hover:cursor-pointer select-none">
                      ต่ออายุการใช้งาน
                    </a>
                    <a href='../account/changemembership' className="p-4 py-2 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 md:rounded-r rounded-b w-full text-center hover:cursor-pointer select-none">
                      เปลี่ยนประเภทสมาชิก
                    </a>
                  </div>

                    
                </div>

                <div className='my-1 ml-2 mt-6'>
                  <label className='text-lg'>ข้อมูลบัญชี</label>
                </div>

                <div className='bg-white p-6 mt-2 rounded-lg md:max-w-full border md:w-[700px]'>
                    <div>
                        <label>อีเมล : </label>{userData.email}
                    </div>

                    <div>
                        <label>ชื่อผู้ใช้ : </label>{userData.username}
                    </div>

                    <div>
                        <label>รหัสผ่าน : *****</label>
                    </div>

                    <div>
                        <label>สถานะบัญชี : </label>
                        {userData.ustatus ? 
                            <label className='text-green-500'>เปิดใช้งาน</label> 
                        :   <label className='text-red-500'>ปิดใช้งาน</label>}
                    </div>

                    <div className='mt-4'>
                        <button className='p-4 py-2 button-resetpass rounded-lg transition duration-150 ease-in-out' onClick={handleresetpasswordOpen}>รีเซ็ตรหัสผ่าน</button>
                    </div>

                </div>
                
            </div>
          </div>

          <div className='hidden md:block'></div>
        </div>

        
      </>
    );
}

export default AccountPage;