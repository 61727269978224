import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined, EditOutlined, MailOutlined , PhoneOutlined ,HomeOutlined ,IdcardOutlined,ShopOutlined, UploadOutlined ,UserOutlined } from '@ant-design/icons';
import { Spin, FloatButton, Image } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

function DetailCustomer() {
    const { id } = useParams();
    const { user } = useUserAuth();
    const navigate = useNavigate();
    const [markets, setMarkets] = useState([]);
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/customer/from_id_customer/${id}`);
                const customerData = response.data;
                setCustomer(customerData);
            } catch (err) {
                console.error('Error fetching customer:', err);
            }
        };

        fetchCustomer();
    }, [id, navigate, user]);
    function getImageCustomerUrl(filename) {
        const basePath = import.meta.env.VITE_IMAGE_CUSTOMER_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/customer`;
        return `${basePath}/${filename}`;
    }

    const handleEdit = (id) => { navigate(`/edit-customer/${id}`); };

    if (!customer) {
        return (
            <>
                {user ? 
                    <div className='md:block hidden'>
                        <Nav />
                    </div>
                    : (<NavNoUser />)
                }
                <div className='flex justify-between'>
                    <div>
                        <Sidebar />
                    </div>
                    <div><Spin fullscreen size="large" /></div>
                </div>
            </>
        );
    }

    return (
        <>
            {user ? 
                <div className='md:block hidden'>
                    <Nav />
                </div>
            : (<NavNoUser />)
            }            

            <div className='flex justify-between'>
                <div id='sidebar' className=''>
                    <Sidebar />
                </div>

                <div className='shrink hidden md:block overflow-x-auto ml-2'>
                    <div className='ml-2 mr-2  custom-scrollbar'>
                        <div className='mt-3 max-w-72 break-all whitespace-normal '>
                            <div className='mt-3 ml-4 md:block hidden'>
                                <Link to="../manage-customer" className='text-sm text-gray-600' > <LeftOutlined /> จัดการรายชื่อลูกค้า</Link>
                                {customer.market.name ? (
                                    <div className='flex'>
                                        <ShopOutlined className='opacity-40 scale-150 mb-2'/><h2 className='text-2xl mb-4 mt-2 ml-4'>ร้าน {customer.market.name}</h2>
                                    </div>
                                )
                                    : 'ไม่ได้ระบุร้าน'}
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='bg-white p-6 mb-4 flex-none  rounded-lg break-all whitespace-normal drop-shadow-md min-w-[800px]'>
                                <div className='flex flex-row '>
                                    {customer.imageUrls && customer.imageUrls.length > 0 ? (
                                        <Image.PreviewGroup
                                            preview={{
                                                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                            }}
                                        >
                                            <div className='mb-4'>
                                                <div className='mb-2'>
                                                    <Image src={getImageCustomerUrl(customer.imageUrls[0])} alt={customer.name} className='w-40 rounded-xl border' />
                                                </div>
                                            </div>
                                        </Image.PreviewGroup>
                                    ) :

                                        <div className='mb-4 max-w-40'>
                                            <div className='mb-2'>
                                                <Image src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.jpg`} alt='Image not found' className='w-20' />
                                            </div>
                                        </div>
                                    }

                                    <div className='flex flex-col pl-10 pr-4 min-w-96'>
                                        <div className='max-w-fit'>
                                             <h2 className='text-2xl pb-6 '><UserOutlined className='opacity-30' /> {customer.name}</h2>
                                        </div>

                                        <div className=' min-w-60 max-w-60 text-lg'><HomeOutlined className='opacity-30'/> ที่อยู่</div>
                                        <div className='mt-2 mb-6 mx-4 max-w-lg  bg-gray-50 p-2 rounded-md '>
                                            {customer.address}
                                        </div>

                                        <div className='mb-4 max-w-lg text-lg'>
                                            <label><PhoneOutlined className='opacity-30'/> เบอร์โทร : </label>{customer.phone && customer.phone !== 'undefined' ? customer.phone : 'ไม่ระบุ'}
                                        </div>

                                        <div className='mb-4 max-w-lg text-lg'>
                                            <label><MailOutlined className='opacity-30'/> อีเมล : </label>{customer.email && customer.email !== 'undefined' ? customer.email : 'ไม่ระบุ'}
                                        </div>

                                        <div className='mb-4 max-w-lg  text-lg'>
                                            <label><IdcardOutlined className='opacity-30'/> เลขผู้เสียภาษี : </label>{customer.nationalId && customer.nationalId !== 'undefined' ? customer.nationalId : 'ไม่ระบุ'}
                                        </div>

                                        <div className='pt-4'>รายละเอียด</div>
                                        <div className='mt-2 mb-6 mx-4 max-w-lg  bg-gray-50 p-2 rounded-md '>
                                            {customer.description && customer.description !== 'undefined' ? customer.description : 'ไม่ระบุ'}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex w-full '>
                                    <div className='flex-1 py-4	bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 text-center w-max' onClick={() => handleEdit(customer._id)}>
                                        <button ><EditOutlined className='opacity-75 scale-150 pr-3' />แก้ไขข้อมูลลูกค้า</button>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>

            {/* mini */}
            <div className='shrink md:hidden'>
            <div className=' '>
                <div className='flex flex-wrap'>
                  <div className='bg-white flex-grow max-w-screen break-all whitespace-normal'>
                    <div className='mt-3 max-w-64 break-all whitespace-normal '>
                        <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
                            <Link to="../manage-customer" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
                                <LeftOutlined />
                            </Link>
                            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1"><ShopOutlined className='opacity-40 scale-125 mr-2'/> {customer.market.name ? customer.market.name : 'N/A'}</h2>
                        </div>
                    </div>

                        <div className="mt-[75px] flex justify-center">
                            <div style={{maxWidth: '40%'}}>
                                {customer.imageUrls  && customer.imageUrls[0] ? (
                                    <Image.PreviewGroup
                                        preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                        <div className='mb-4 w-fit'>
                                            <div className='w-full'>
                                                <Image src={getImageCustomerUrl(customer.imageUrls[0])} alt={customer.name} className="max-w-[128px] max-h-[128px] rounded-xl border"/>
                                            </div>
                                        </div>
                                    </Image.PreviewGroup>
                                    )
                                    :
                                    <div className='mb-4'>
                                        <div className='mb-2'>
                                            <Image src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/user.png`} alt='Image not found' className='max-w-[128px] max-h-[128px] rounded-xl border' />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='border mb-6'>
                            <div className='px-2 w-full'>
                                <h2 className='text-xl pt-2'><UserOutlined className='opacity-40'/> {customer.name}</h2>
                            </div>

                            <div className='pl-4 mt-5 '><HomeOutlined className='opacity-40'/> ที่อยู่</div>
                            <div className='mt-2 mb-6 mx-4  bg-gray-50 p-2 rounded-md  '>
                                {customer.address}
                            </div>

                            <div className='mb-4 max-w-fit  px-3'>
                                <label><PhoneOutlined className='opacity-40'/> </label>{customer.phone && customer.phone !== 'undefined' ? customer.phone : 'ไม่ระบุ'}
                            </div>
                        </div>

                        <div className='mb-4 max-w-fit  px-3'>
                            <label><MailOutlined className='opacity-40'/> </label>{customer.email && customer.email !== 'undefined' ? customer.email : 'ไม่ระบุ'}
                        </div>

                        <div className='mb-4 max-w-fit  px-3'>
                            <label><IdcardOutlined className='opacity-40'/> เลขผู้เสียภาษี : </label>{customer.nationalId && customer.nationalId !== 'undefined' ? customer.nationalId : 'ไม่ระบุ'}
                        </div>


                        <div className='pl-4 mt-6 '><label>รายละเอียด</label></div>
                        <div className='mt-2 mb-6 mx-4  bg-gray-50 p-2 rounded-md '>
                            {customer.description && customer.description !== 'undefined' ? customer.description : 'ไม่ระบุ'}

                        </div>

                        
                      <div className='flex w-full '>
                        <div className='border border-gray-200 flex-1 py-4 bg-gray-100 cursor-pointer hover:bg-gray-200 text-center w-max' onClick={() => handleEdit(customer._id)}>
                          <button ><EditOutlined className='opacity-75 scale-110 pr-3'/>แก้ไขข้อมูลลูกค้า</button>
                        </div>
                      </div>


                    </div>
                </div>

                
            </div>
        </div>

                <div></div>
            </div>
        </>
    );
}

export default DetailCustomer;
