import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link,  useParams,useNavigate, useLocation } from 'react-router-dom';

import { Form,Image } from 'antd';
import { CloseOutlined , LeftOutlined , PrinterFilled} from '@ant-design/icons';
import { InputNumber,Spin , FloatButton } from 'antd';

import en from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
dayjs.extend(buddhistEra);




function ExportReceipt() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(true);

  const [logoUrl, setLogoUrl] = useState('');

  const [totalWithVat, setTotalWithVat] = useState(0);
  const [vat, setVat] = useState(0);

  const [formState, setFormState] = useState({
    vat_check: false,
    document_number: '',
    issue_date: '',
    total_price: 0,
    remark: '',

    customer_signature_date: '',
    seller_signature_date: '',

    payment_method_cash: false,
    payment_method_bank: false,
    payment_method_check: false,
    payment_method_credit: false,
    payment_details: '',

    withholding_tax: false,
    withholding_tax_details: '',
  });

  const [customerDetails, setCustomerDetails] = useState({
    name: '' , address: '', taxNumber: '', email: '', phone: '', customer_signature: '',
  });

  const [issuerDetails, setIssuerDetails] = useState({
    name: '', taxNumber: '', phone: '', email: '', image_url:'', seller_signature: '',
  });

  const [products, setProducts] = useState([
    { sku: '', name: '', quantity: 1, unitPrice: 0, discount: 0, preTaxAmount: 0 },
  ]);

  useEffect(() => {
    calculateTotal();
  }, [formState.vat_check, products]);

  const calculateTotal = () => {
    const total = products.reduce((total, product) => total + product.preTaxAmount, 0);
    const totalWithVat = formState.vat_check ? total * 1.07 : total;
    const vat = formState.vat_check ? totalWithVat - total : total;

    setTotalWithVat(formState.vat_check ? totalWithVat : total);
    setVat(formState.vat_check ? vat : total);
  };
  

  const [form] = Form.useForm();

  const [receipt, setReceipt] = useState({});
  const [receipt_product, setReceipt_Product] = useState([]);
  const fetchReceipt = async () => {
    try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/receipt/${id}`);
        const receipt = response.data;

        const issueDate = dayjs(receipt.issue_date, 'DD/MM/YYYY').add(543, 'year').format('DD/MM/YYYY');

        setReceipt(receipt);
        setProducts(receipt.receiptProducts || []);

        setFormState({
            document_number: receipt.document_number,
            issue_date: issueDate,

            vat_check: receipt.vat_check,

            remark: receipt.remark,

            payment_method_cash: receipt.payment_method_cash,
            payment_method_bank: receipt.payment_method_bank,
            payment_method_check: receipt.payment_method_check,
            payment_method_credit: receipt.payment_method_credit,
            payment_details: receipt.payment_details,

            withholding_tax: receipt.withholding_tax,
            withholding_tax_details: receipt.withholding_tax_details,

            customer_signature_date: receipt.customer_signature_date,
            seller_signature_date: receipt.seller_signature_date,
        });

        setCustomerDetails({
            name: receipt.customer_name,
            address: receipt.customer_address,
            taxNumber: receipt.customer_tax_number,
            phone: receipt.customer_phone,
            email: receipt.customer_email,
            customer_signature: receipt.customer_signature,
        })

        setIssuerDetails({
            name: receipt.issuer_name,
            taxNumber: receipt.issuer_tax_number,
            phone: receipt.issuer_phone,
            email: receipt.issuer_email,
            seller_signature: receipt.seller_signature,
        })

        setLogoUrl(receipt.issuer_image_url)

    } catch (err) {
    console.error('Error fetching receipt:', err);
    }
  };

  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }

  useEffect(() => {
    fetchReceipt();
  },);

  const handlePrint = () => {
    window.print();
  };

  return (
    <>

    <div className='flex flex-col bg-neutral-700 h-screen'>
        <div className='flex justify-between bg-neutral-600 h-12 shadow z-50'>
            <div></div>
            <div></div>

            <div 
                className='transition duration-300 ease-in-out cursor-pointer rounded-full hover:bg-neutral-500 active:ring ring-neutral-400 self-center invert flex justify-center items-center mr-6' 
                onClick={handlePrint} 
            >
                <PrinterFilled className='h-10 w-10 scale-125 pl-3'/>
            </div>
        </div>
        <div className='flex justify-between overflow-auto pt-4'>
            <div></div>
            <div className='flex flex-col '  style={{width:'761px'}}>          
                <Form form={form} layout="vertical" className='' >
                <div id="print-section" className='bg-white shadow-lg p-4' name="ฟอร์มสำหรับปริ้น">
                <div className='flex justify-between' >
                    <div className='grow'>
                    <h1 className='m-4 ml-0 mb-0 text-3xl grow'>ใบเสร็จรับเงิน</h1>
                    <h1 className='mb-4 text-2xl  grow'>Receipt</h1>

                    </div>
                    <div className='flex justify-center min-w-60 max-w-full max-h-24 '>
                    <div className='max-w-20 scale-110'>
                        {logoUrl ? (
                            <Image
                                className=""
                                name="image_url"
                                src={getImageMarketUrl(logoUrl)}
                                alt={logoUrl}
                                width="120"
                            />
                        ) : ''}
                    </div>
                    </div>
                </div>

    {/* ลูกค้า และ เลขที่เอกสาร */}
                <section>
                    <div className="flex flex-col gap-1">
                    <div className="flex justify-between w-full">
                        <div className="text-sm flex">
                            <div>
                                ลูกค้า <label className="text-xs opacity-80 pr-4">/ Customer</label>
                            </div>                            
                            <label
                                name="name"
                                className='w-80'
                                style={{ minHeight: '0.5rem', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                            >{customerDetails.name ? customerDetails.name : '-' }</label> 
                            
                        </div>

                        <div className="text-sm">เลขที่เอกสาร 
                            <input
                            type="text"
                            name="document_number"
                            placeholder="ชื่อ"
                            value={formState.document_number}
                            className='ml-2'
                            />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className='flex'>
                        <div className="pr-4 text-sm mt-0.5">ที่อยู่ <label className="text-xs opacity-80">/ Address</label></div>
                            <label
                                name="address"
                                className="min-w-80 max-w-80 min-h-5 text-sm"
                                style={{ minHeight: '0.5rem', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            >{customerDetails.address ? customerDetails.address : '-' }
                            </label> 
                        </div>
                        <div className=''>
                        วันที่<label className="text-xs opacity-80"> / Issue</label>
                            <input
                                type="text"
                                name="issue_date"
                                placeholder="-"
                                value={formState.issue_date}
                                className='text-sm ml-1 max-h-6 '
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="pr-4 text-sm mt-0.5">เลขผู้เสียภาษี <label className="text-xs opacity-80">/ Tax ID</label></div>
                        <input type="text" name="taxNumber" placeholder="-" value={customerDetails.taxNumber} className="mr-2 max-h-5"/>
                        
                        <div className="flex flex-col gap-1">
                        <div className="flex">
                            <div className="px-2 text-sm place-content-center">เบอร์โทร :</div><input type="tel" name="phone" placeholder="-" value={customerDetails.phone}  className=""/>
                        </div>
                        <div className="flex">
                            <div className="px-2 text-sm place-content-center">อีเมล :</div><input type="text" name="email" placeholder="-" value={customerDetails.email} className="min-w-64 max-w-64"/>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                <div class="flex flex-row pb-1">
                    <p class="line-through mt-1.5	w-full  border"></p>
                </div>

    {/* ผู้ออก */}
                <section>
                    <div className="flex justify-between">
                    <div className="flex">
                        <h2 className="pr-4 text-sm text-nowrap">ผู้ออก</h2>
                            <input
                            type="text"
                            name="name"
                            placeholder="ชื่อ"
                            value={issuerDetails.name}
                            className="max-h-5 "
                            />
                    </div>

                    <div className="flex flex-col gap-1 ml-1">
                        <div className="flex ">
                        <div className="pr-4 text-sm mt-0.5">เลขผู้เสียภาษี <label className="text-xs opacity-80">/ Tax ID</label></div>
                        <input type="text" name="taxNumber" placeholder="-" value={issuerDetails.taxNumber}  className=""/>
                        </div>
                        <div className="flex ">
                        <div className="pr-2 text-sm mt-0.5 text-nowrap">เบอร์โทร :</div>
                        <input type="tel" name="phone" placeholder="-" value={issuerDetails.phone}  className="max-w-28"/>
                        <div className="pr-2 text-sm mt-0.5 ml-2 text-nowrap">อีเมล :</div>
                        <input type="text" name="email" placeholder="-" value={issuerDetails.email}  className="min-w-64 max-w-64"/>
                        </div>
                    </div>
                    </div>
                    
                </section>

    {/* สินค้า */}
                <div className='text-center bg-gray-500 no-print mt-2' >
                    <div class="flex flex-row ">
                    <p class="line-through mt-0.5	w-full  border"></p>
                    </div>
                    <label className='text-white'>สินค้า</label>
                    <div class="flex flex-row ">
                    <p class="line-through mt-0.5	w-full h-px border"></p>
                    </div>
                </div>
                
                <section className='text-sm'>
                    <table className='border-collapse border border-slate-600 mt-2 max-w-2	'>
                    <thead>
                        <tr className='text-xs'>
                        <td className=" border border-slate-600 min-w-14 max-w-14">
                            <div className=" ml-1 flex flex-col">
                            <label>รหัส</label>
                            <label className="opacity-80">SKU</label>
                            </div>
                        </td>
                        <td className="border border-slate-600 min-w-72 max-w-72">
                            <div className="ml-1 flex flex-col">
                            <label>คำอธิบาย</label>
                            <label className="opacity-80">Discription</label>
                            </div>
                        </td>
                        <td className="border border-slate-600 min-w-14 max-w-14">
                            <div className="ml-1  flex flex-col">
                            <label>จำนวน</label>
                            <label className="opacity-80">Quantity</label>
                            </div>
                        </td>
                        <td className="border border-slate-600 min-w-20 max-w-20">
                            <div className="ml-1  flex flex-col">
                            <label>ราคาต่อหน่วย</label>
                            <label className="opacity-80">Unit Price</label>
                            </div>
                        </td>
                        <td className=" border border-slate-600 min-w-24 max-w-24">
                            <div className="ml-1  flex flex-col">
                            <label>ส่วนลด</label>
                            <label className="opacity-80">Disc.</label>
                            </div>
                        </td>
                        <td className="border border-slate-600 min-w-24 max-w-24">
                            <div className="ml-1 flex flex-col">
                            <label>มูลค่าก่อนภาษี</label>
                            <label className="opacity-80">Pre-Tax Amount</label>
                            </div>
                        </td>
                        </tr>
                        {products.map((product, index) => (
                        <tr key={index} className="text-sm">
                            <td className='border border-slate-600 min-w-14 max-w-14 content-start'>
                                <label
                                name="sku"
                                className=" w-full break-words align-middle text-xxs overflow-y-hidden"
                                style={{ maxHeight:'22px',minHeight: '22px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                                >{product.sku}</label> 
                            </td>
                            <td className='border border-slate-600 min-w-64 max-w-64 content-start'>
                                <label
                                name="name"
                                className="w-full break-words align-middle text-xxs overflow-y-hidden"
                                style={{ maxHeight:'22px',minHeight: '22px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                                >{product.name}</label> 
                            </td>
                            <td className='border border-slate-600 min-w-16 max-w-16 content-start'>
                                <input
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    type="text"
                                    name="quantity"
                                    placeholder="จำนวน"
                                    value={product.quantity}
                                    className=" text-center max-w-full text-xxs"
                                />
                            </td>
                            <td className='border border-slate-600 min-w-24 max-w-24 content-start'>
                                <input
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                type="text"
                                name="unitPrice"
                                placeholder="ราคาต่อชิ้น"
                                value={product.unitPrice}
                                className="text-right max-w-full text-xxs pr-2"
                                />
                            </td>
                            <td className='border border-slate-600 min-w-24 max-w-24 content-start'>
                                <input
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                type="text"
                                name="discount"
                                placeholder="ส่วนลด"
                                value={product.discount}
                                className="text-right max-w-full text-xxs pr-2"
                                />                  
                            </td>
                            <td className='border border-slate-600 min-w-32 max-w-32 cursor-default	content-start'>
                                <input
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                type="text"
                                name="preTaxAmount"
                                placeholder="Pre-Tax Amount"
                                value={product.preTaxAmount}
                                className="text-right max-w-full cursor-default rounded-lg text-xxs pr-2"
                                readOnly
                                />   
                            </td>
                        </tr>
                        ))}
                    </thead>
                    </table>


                </section>


    {/* การชำระเงิน */}
                <section className='text-sm'>
                    <div className='flex justify-between mt-2'>
                    <div className='min-w-64 max-w-64'>
                        <h2 className="text-sm mt-0.5 text-nowrap">หมายเหตุ <label className="text-xs opacity-80">/ Remark</label></h2>
                        <label
                            name="remark"
                            className="min-w-80 max-w-80 min-h-5 text-sm overflow-y-hidden break-words ml-2 block"
                        >{formState.remark}</label> 
                    </div>

                    <div className='flex flex-col max-w-96'>
                        {formState.vat_check ?
                        <div className='flex flex-nowrap grow justify-end'>
                        <h2 className='pr-4 text-nowrap'>ภาษีมูลค่าเพิ่ม (Vat) : </h2><p>{vat.toFixed(2)}</p><h2 className='pl-1'> บาท</h2>
                        </div>:''
                        }
                        <div className='flex flex-nowrap grow justify-end'>
                        <h2 className='pr-4'>ราคารวม : </h2><p>{totalWithVat.toFixed(2)}</p><h2 className='pl-1'> บาท</h2>
                        </div>
                        <input
                        type="hidden"
                        name="total_price"
                        value={totalWithVat}
                        onChange={(e) => handleFormChange({ target: { name: 'total_price', value: totalWithVat } })}
                        />
                    </div>

                    </div>
                </section>

                <div class="flex flex-row pb-1">
                    <p class="line-through mt-1.5	w-full h-px border"></p>
                </div>

                <section className='text-sm '>
                    <div className='flex flex-row'>
                        <div className='flex flex-col'>
                        <h2 className="text-sm mt-0.5 text-nowrap">การชำระเงิน <label className="text-xs opacity-80">/ Payment</label></h2>
                        <div className='flex opacity-80'>
                            <label className='flex items-center'>
                            <input
                                type="checkbox"
                                name="payment_method_cash"
                                value={formState.payment_method_cash}
                                className="translate-y-0.5"
                                checked={formState.payment_method_cash}
                                style={{ display: 'none' }}
                            />
                            {formState.payment_method_cash ? (
                                <CloseOutlined className="mr-1 min-w-5 min-h-5 border-2 rounded p-0.5" />
                            ) : (
                                <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{ padding: '9px' }}></div>
                            )}
                            เงินสด
                            </label>
                            <label className='flex items-center ml-5'>
                            <input
                                type="checkbox"
                                name="payment_method_bank"
                                value={formState.payment_method_bank}
                                className="translate-y-0.5"
                                checked={formState.payment_method_bank}
                                style={{ display: 'none' }}
                            />
                            {formState.payment_method_bank ? (
                                <CloseOutlined className="mr-1 min-w-5 min-h-5 border-2 rounded p-0.5" /> 
                            ) : ( 
                                <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>
                            )}
                            ธนาคาร
                            </label>
                            <label className='flex items-center ml-5'>
                            <input
                                type="checkbox"
                                name="payment_method_check"
                                value={formState.payment_method_check}
                                className="translate-y-0.5"
                                checked={formState.payment_method_check}
                                style={{ display: 'none' }}
                            />
                            {formState.payment_method_check ? <CloseOutlined className="mr-1 min-w-5 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                            เช็ค
                            </label>
                            <label className='flex items-center ml-5'>
                            <input
                                type="checkbox"
                                name="payment_method_credit"
                                value={formState.payment_method_credit}
                                className="translate-y-0.5"
                                checked={formState.payment_method_credit}
                                style={{ display: 'none' }}
                            />
                            {formState.payment_method_credit ? <CloseOutlined className="mr-1 min-w-5 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                            บัตรเครดิต
                            </label>
                        </div>
                        <div className='mt-2 z-10'>
                            <label className='text-sm opacity-80'>ข้อมูลเพิ่มเติม </label>
                            <label
                            name="payment_details" 
                            className="min-w-64 max-w-64 min-h-5 text-sm overflow-y-hidden break-words ml-20 block"
                            >{formState.payment_details}</label> 
                        </div>
                        <label className='text-sm opacity-80 ml-20 -mt-3'>....................................................................</label>


                        <label className='flex items-center opacity-80 mt-4'>
                            <input
                            type="checkbox"
                            name="withholding_tax"
                            value={formState.withholding_tax}
                            className="translate-y-0.5"
                            checked={formState.withholding_tax}
                            style={{ display: 'none' }}
                            />
                            {formState.withholding_tax ? <CloseOutlined className="mr-1 min-w-5 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                            หัก ณ ที่จ่าย
                        </label>
                        <div className='mt-2 z-10'>
                            <label className='text-sm opacity-80'>ข้อมูลเพิ่มเติม </label>
                            <label
                            name="withholding_tax_details" 
                            className="min-w-64 max-w-64 min-h-5 text-sm overflow-y-hidden break-words ml-20 block"
                            >{formState.withholding_tax_details}</label> 
                        </div>
                        <label className='text-sm opacity-80 ml-20 -mt-3'>....................................................................</label>
                        </div>


                        <div className='flex gap-3 mt-[80px] h-[94px] ml-2'>
                            <div className='w-[182px] overflow-x-hidden'>
                                <input type="name" name="customer_signature" placeholder="" value={customerDetails.customer_signature} className="w-full" readOnly/>
                                <label class="text-sm opacity-80 -mt-3 select-none">....................................................................</label>
                                <div className='flex flex-nowrap text-nowrap mb-2'>
                                    วันที่ <label className="text-xs opacity-80 mx-1 mt-0.5">/ Date</label>
                                    <input
                                        type="text"
                                        name="customer_signature_date"
                                        placeholder="-"
                                        value={formState.customer_signature_date}
                                        className='text-sm ml-1 max-h-6' readOnly
                                    />
                                    
                                </div>
                                <div className=' text-nowrap text-center'>
                                    ผู้จ่ายเงิน <label className="text-xs opacity-80">/ Bill To</label>
                                </div>
                            </div>

                            <div className='w-[182px] overflow-x-hidden'>
                                <input type="name" name="seller_signature" placeholder="" value={issuerDetails.seller_signature} className="w-full" readOnly/>
                                <label class="text-sm opacity-80 -mt-3 select-none">....................................................................</label>
                                <div className='flex flex-nowrap text-nowrap mb-2'>
                                    วันที่ <label className="text-xs opacity-80 mx-1 mt-0.5">/ Date</label>
                                    <input
                                        type="text"
                                        name="seller_signature_date"
                                        placeholder="-"
                                        value={formState.seller_signature_date}
                                        className='text-sm ml-1 max-h-6' readOnly
                                    />
                                </div>
                                <div className=' text-nowrap text-center'>
                                    ผู้รับเงิน <label className="text-xs opacity-80">/ Received By</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                </div>

                </Form>
            </div>
            <div></div>
          </div>
        
    </div>
    </>
  );
}

export default ExportReceipt;