//UserContext.jsx

import React,{ createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem('user');
      const storedToken = localStorage.getItem('token');

      if (storedUser && storedToken) {
        setUser(JSON.parse(storedUser));

        // ตั้งค่า header ให้ axios ใช้ token อัตโนมัติ
        axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการโหลดข้อมูลผู้ใช้:", error);
    }
  }, []);

  const login = (userData) => {
    if (!userData || !userData.user) {
      console.error("ข้อมูลผู้ใช้ไม่ถูกต้อง");
      return;
    }

    setUser(userData.user);
    localStorage.setItem('user', JSON.stringify(userData.user));
    
    // ตั้งค่า header สำหรับ requests ในอนาคต
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  };

  const logout = async () => {
    try {
      const token = localStorage.getItem('token');
      
      // ลบข้อมูลผู้ใช้จาก state และ localStorage
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      
      // ลบ Authorization header
      delete axios.defaults.headers.common['Authorization'];
      
      // ส่งคำขอ logout ไปยัง server (ถ้าจำเป็น)
      if (token) {
        await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/auth/logout`);
      }
    } catch (err) {
      console.error("เกิดข้อผิดพลาดในการออกจากระบบ:", err.message);
    }
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};


export const useUserAuth = () => useContext(UserContext);