import React , { useEffect, useState  } from 'react';
import { Nav,NavNoUser,Sidebar,BottomNav } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { FloatButton } from 'antd';
import { LeftOutlined } from '@ant-design/icons'
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import {LoginAlert} from '../../components/Alert.jsx';
import { useNavigate , Link} from "react-router-dom";

import { SelectCalcu } from '../../components/calcu/selectcalcu.jsx';
import { LazadaCalcu } from '../../components/calcu/lazada.jsx';

function CalcuLazada() {
  const {user} = useUserAuth();
  const navigate = useNavigate();

  return (
    <>
      {user ? 
        <div className='md:block hidden sticky top-0 z-50'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }  
        <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>

          <div className='shrink  overscroll-none overflow-x overflow-hidden mb-[135px]'> 

            <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
              <Link to="../homepage" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
                <LeftOutlined />
              </Link>
              <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">คำนวณค่าธรรมเนียม Lazada </h2>
            </div>

            <div className="md:ml-2 ml-0 md:mt-0 mt-[52px]" >
              <LoginAlert />
            </div>

            <SelectCalcu />

            <LazadaCalcu />
            
          </div>

          <div className=''>
          
          </div>

        </div>


    </>
  );
}

export default CalcuLazada