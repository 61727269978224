
import React from 'react';
import { Nav } from '../components/Nav';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

function RegisterSuccess() {
  let navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Nav />
        <div className="flex-grow flex items-center justify-center bg-gray-100 px-6">
          <div className="bg-white shadow-lg rounded-2xl p-8 text-center max-w-md md:mb-[600px] mb-44 mt-10">
            <CheckCircleIcon className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-3xl font-bold text-gray-800 mb-2">สมัครสมาชิกสำเร็จ</h2>
            <p className="text-gray-600 text-lg mb-6">Successfully applied for membership</p>
            <div className="flex flex-col sm:flex-row gap-3 mt-4">
              <button
                type="primary"
                icon={<StorefrontIcon />}
                className="w-full  bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white py-2 px-6 rounded-lg shadow"
                onClick={() => navigate("/add-first-market")}
              >
                สร้างร้านค้าแรก
              </button>
              <button
                className="w-full  border border-gray-300 bg-white hover:bg-gray-200 active:bg-gray-300 text-gray-700 py-2 px-6 rounded-lg shadow"
                onClick={() => navigate("/homepage")}
              >
                หน้าแรก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterSuccess;