import React, { useState, useEffect} from "react";
import { useUserAuth } from '../context/UserContext.jsx';
import { Nav } from '../components/Nav';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';

import './manageuser.css';
import { Sidebar } from "./nav.jsx";
import { Button, message , Popconfirm, Tooltip, Form ,FloatButton, Modal,DatePicker} from 'antd';
import { QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { useNavigate } from 'react-router-dom';
import { calculateRemainingUsage } from '../pages/script';

function ManageUser(){
    const{user} =  useUserAuth();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedMembership, setSelectedMembership] = useState('');
    const [isModalMemberShipExpirationDateVisible, setIsModalMemberShipExpirationDateVisible] = useState(false);
    const [form] = Form.useForm();
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        fetchUsers();
    }, []);

    // ดึงข้อมูลผู้ใช้จาก API
    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/user/all`);

            const usersData = response.data.map(user => {
                const usageInfo = calculateRemainingUsage(user); // คำนวณอายุการใช้งานคงเหลือสำหรับแต่ละคน
                return { ...user, ...usageInfo }; // รวมข้อมูลผู้ใช้กับข้อมูลอายุการใช้งาน
            });

            setUsers(usersData);
            setSelectedMembership(usersData[0]?.membershipType);

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    if(!user || !user.admin){
        return <Navigate to="/" />
    }

    // ฟังก์ชันสำหรับเปิดใช้งานผู้ใช้
    const enableUser = async (userId)  => {
        try {
            const response = await axios.put(
                `${import.meta.env.VITE_BACKEND_URL}api/user/${userId}`,
                { ustatus: true },  // Body ของ request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                    }
                }
            );
            console.log(`User with ID: ${userId} enabled successfully`, response.data);
            fetchUsers(); // รีเฟรชข้อมูลผู้ใช้หลังจากอัปเดต
            success1() //แสดงข้อความ
        } catch (error) {
            console.error(`Error enabling user with ID: ${userId}`, error);
        }
    };

    // ฟังก์ชันสำหรับปิดใช้งานผู้ใช้
    const disbleUser = async (userId) => {
        try {
            const response = await axios.put(
                `${import.meta.env.VITE_BACKEND_URL}api/user/${userId}`,
                { ustatus: false },  // Body ของ request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                    }
                }
            );
            console.log(`User with ID: ${userId} disable successfully`, response.data);
            fetchUsers(); // รีเฟรชข้อมูลผู้ใช้หลังจากอัปเดต
            success2() //แสดงข้อความ
        } catch (error) {
            console.error(`Error enabling user with ID: ${userId}`, error);
        }
    };

    // ฟังก์ชันสำหรับแก้ไขรหัสผ่าน
    const resetpasswordUser = async (userId, username) => {
        try {
            const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/user/reset-password/${userId}`,
                { password: username },  // Body ของ request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                    }
                }
            );

            console.log(`Password for user with ID: ${userId} reset successfully`, response.data);
            fetchUsers(); // รีเฟรชข้อมูลผู้ใช้หลังจากอัปเดต
            success3() //แสดงข้อความ
        } catch (error) {
            console.error(`Error resetting password for user with ID: ${userId}`, error);
        }
    };

    // ฟังก์ชันสำหรับลบผู้ใช้
    const deleteUser = async (userId) => {
        try {
            const response = await axios.delete(
                `${import.meta.env.VITE_BACKEND_URL}api/user/${userId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                    }
                }
            );
            console.log(`User with ID: ${userId} deleted successfully`, response.data);
            fetchUsers(); // รีเฟรชข้อมูลผู้ใช้หลังจากลบ
            success4() //แสดงข้อความ
        } catch (error) {
            console.error(`Error deleting user with ID: ${userId}`, error);
        }
    };

    const handleMembershipChange = (userId, newMembership) => {
        setUsers(users.map(user =>
            user._id === userId ? { ...user, selectedMembership: newMembership } : user
        ));
    };
    const confirmMembershipChange = async (user) => {
        try {
            const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/user/${user._id}`,
                { membershipType: user.selectedMembership },  // Body ของ request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                    }
                }
            );
            console.log(`User with ID: ${user._id} changed membershipType successfully`, response.data);
            fetchUsers();
            success5();
        } catch (error) {
            console.error(`Error changing membershipType for user with ID: ${user._id}`, error);
        }
    };

    const showModalMemberShipExpirationDate = (user) => {
        setCurrentUser(user); // ตั้งค่าผู้ใช้ปัจจุบัน
        form.setFieldsValue({ expirationDate: dayjs(user.membershipExpirationDate) }); // ตั้งค่าเริ่มต้นเป็นวันที่หมดอายุของผู้ใช้
        setIsModalMemberShipExpirationDateVisible(true);
    };
    const handleMemberShipExpirationDateOk = async () => {
        try {
            const values = await form.validateFields();
            handleMemberShipExpirationDateChange(currentUser._id, values.expirationDate.format('YYYY-MM-DD'));
            setIsModalMemberShipExpirationDateVisible(false);
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };
    const handleMemberShipExpirationDateChange = async (id, date) => {
        try {
            const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/user/${id}`,
                { membershipExpirationDate: date },  // Body ของ request
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                    }
                }
            );
            console.log(`User with ID: ${id} changed membershipExpirationDate successfully`, response.data);
            fetchUsers();
            success6();
        } catch (error) {
            console.error(`Error changing membershipExpirationDate for user with ID: ${id}`, error);
        }
    };
    const handleMemberShipExpirationDateCancel = () => {
        setIsModalMemberShipExpirationDateVisible(false);
        form.resetFields(); // ทำการรีเซ็ตฟอร์มเมื่อยกเลิก
    };

    // ข้อความแจ้งเตือน
    const [messageApi, contextHolder] = message.useMessage();
    const success1 = () => {
        messageApi.open({
          type: 'success',
          content: 'เปิดใช้งานบัญชีสำเร็จ',
        });
      };
    const success2 = () => {
        messageApi.open({
          type: 'success',
          content: 'ปิดใช้งานบัญชีสำเร็จ',
        });
      };
    const success3 = () => {
        messageApi.open({
          type: 'success',
          content: 'รีเซ็ตรหัสผ่านสำเร็จ',
        });
      };
    const success4 = () => {
        messageApi.open({
          type: 'success',
          content: 'ลบบัญชีผู้ใช้งานสำเร็จ',
        });
      };
    const success5 = () => {
        messageApi.open({
          type: 'success',
          content: 'แก้ไขประเภทผู้ใช้สำเร็จ',
        });
    };
    const success6 = () => {
        messageApi.open({
          type: 'success',
          content: 'แก้ไขวันหมดอายุสำเร็จ',
        });
    };
    return(
        <>
        <Nav />
        {user? 
        <div>
            <a href="/chat" target="_blank" rel="noopener noreferrer">
            <FloatButton 
            tooltip="เปิดหน้าแชท" 
            type="primary"
            icon={<ChatBubbleOvalLeftEllipsisIcon />} 
            className='mr-6 scale-150 hidden'
            />
            </a>
        </div>
        : ''}

        <div>
            <Modal
                title="แก้ไขวันหมดอายุการใช้งาน"
                open={isModalMemberShipExpirationDateVisible}
                onOk={handleMemberShipExpirationDateOk}
                onCancel={handleMemberShipExpirationDateCancel}
            >
                {currentUser && ( // ตรวจสอบว่ามี currentUser อยู่
                    <Form form={form}>
                        <p>Username : {currentUser.username}</p>
                        <Form.Item
                            name="expirationDate"
                            label="วันหมดอายุ"
                            rules={[{ required: true, message: 'กรุณาเลือกวันหมดอายุ!' }]}
                        >
                            <DatePicker 
                                value={dayjs(currentUser.membershipExpirationDate)} // ใช้ Day.js
                                format='DD/MM/YYYY' 
                                onChange={(date) => form.setFieldsValue({ expirationDate: date })}
                            />
                        </Form.Item>
                    </Form>
                )}
            </Modal>

            <div className='flex justify-between '>
                <div>
                    <Sidebar />
                </div>

                <div className="overflow-auto">

                    <div className="ml-6 hidden md:block rounded-2xl mt-4 mb-10 bg-white p-4 min-w-fit mr-2" style={{  paddingTop: '15px'}}>
                        <div className="mb-5 flex justify-center">
                            <label className="text-2xl text-slate-600">รายชื่อผู้ใช้งานระบบ</label>
                            {contextHolder}
                        </div>
                        <div className="">

                            <table className="min-w-full divide-y divide-gray-200 table-fixed	">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <Tooltip title="ชื่อผู้ใช้งาน"><th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th></Tooltip>
                                        <Tooltip title="ประเภท"><th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th></Tooltip>
                                        <Tooltip title="สถานะ"><th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th></Tooltip>
                                        <Tooltip title="อายุการใช้งานคงเหลือ (วัน/ชั่วโมง/นาที)"><th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Remaining</th></Tooltip>
                                        <Tooltip title="การดำเนินการ"><th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manage</th></Tooltip>
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    {users.map((user) => (
                                        <tr key={user._id}>
                                            <td className="px-4 py-4 whitespace-nowrap">{user.username}</td>

                                            <td className="px-4 py-4 whitespace-nowrap">
                                                <select
                                                    value={user.selectedMembership || user.membershipType} // ใช้ค่าจาก selectedMembership หรือ membershipType
                                                    onChange={(e) => handleMembershipChange(user._id, e.target.value)}
                                                    className="cursor-pointer"
                                                >
                                                    {['diamond', 'platinum', 'gold', 'silver'].map(option => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                {user.selectedMembership && user.selectedMembership !== user.membershipType && (
                                                    <button 
                                                        onClick={() => confirmMembershipChange(user)}
                                                        className="ml-2 bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition text-white px-2 py-1 rounded"
                                                    >
                                                        ยืนยัน
                                                    </button>
                                                )}
                                            </td>

                                            <td className="px-4 py-4 whitespace-nowrap">
                                                    {user.ustatus ? (
                                                        <label className="text-green-500">เปิดใช้งาน</label>
                                                    ) : (
                                                        <label className="text-yellow-500">รออนุมัติ</label>
                                                    )}
                                            </td>

                                            <td className="px-4 py-4 whitespace-nowrap cursor-pointer" onClick={() => showModalMemberShipExpirationDate(user)}>
                                                {user.remainingDays} วัน {user.remainingHours} ชม. {user.remainingMinutes} น. <EditOutlined className="text-gray-400" />
                                            </td>

                                            <td className="px-4 py-1 whitespace-wrap text-right text-sm font-medium">
                                                {user.ustatus ? (
                                                        <button onClick={() => disbleUser(user._id)} className="button-disable rounded-lg p-1.5 mr-2 w-16 transition duration-150 ease-in-out my-1">Disable</button>
                                                    ) : (
                                                        <button onClick={() => enableUser(user._id)} className="button-enable rounded-lg p-1.5 mr-2 w-16 transition duration-150 ease-in-out my-1">Enable</button>
                                                    )}

                                                <Popconfirm
                                                    title="ยืนยันการรีเซ็ตรหัสผ่าน"
                                                    description="รหัสผ่านใหม่จะถูกตั้งให้เหมือนกับชื่อผู้ใช้งาน"
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                    onConfirm={() => resetpasswordUser(user._id, user.username)}                                         
                                                    okText="ยืนยัน"
                                                    cancelText="ยกเลิก"
                                                >
                                                    <button className="button-resetpass rounded-lg p-1.5 mr-2 transition duration-150 ease-in-out my-1">Reset Password</button>
                                                </Popconfirm>

                                                <Popconfirm
                                                    title="ยืนยันการลบบัญชีผู้ใช้งาน"
                                                    description="คุณต้องการลบบัญชีผู้ใช้งานนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                    onConfirm={() => deleteUser(user._id)}                                         
                                                    okText="ยืนยัน"
                                                    cancelText="ยกเลิก"
                                                >
                                                    <button className="button-delete rounded-lg p-1.5 mr-2 transition duration-150 ease-in-out my-1">Delete</button>
                                                </Popconfirm>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="md:hidden grid grid-cols-1 gap-2">
                        <div className="flex justify-center mt-2">
                            <label className="text-lg text-slate-600">รายชื่อผู้ใช้งานระบบ</label>
                            {contextHolder}
                        </div>
                        {users.map((user) => (

                        <div className="bg-white px-4 py-2 rounded-lg ml-5 mr-0.5 mb-1">
                            <div key={user._id}>
                                <div className="flex  justify-between space-x-2">
                                    <div>
                                        {user.username}
                                    </div>

                                    <div>
                                        {user.ustatus ? (
                                            <label className="text-green-500">เปิดใช้อยู่</label>
                                            ) : (
                                            <label className="text-yellow-500">รออนุมัติ</label>
                                        )}
                                    </div>
                                </div>

                                <div className="text-xs text-gray-400 mb-2">
                                    {user.membershipType}
                                </div>
                                
                                <div className="whitespace-wrap text-sm">
                                    {user.ustatus ? (
                                       <button onClick={() => disbleUser(user._id)} className="button-disable rounded-md p-1 mr-2 mb-2 transition duration-150 ease-in-out">Disable</button>
                                        ) : (
                                        <button onClick={() => enableUser(user._id)} className="button-enable rounded-md p-1 mr-2 mb-2 transition duration-150 ease-in-out">Enable</button>
                                    )}

                                    <Popconfirm
                                        title="ยืนยันการรีเซ็ตรหัสผ่าน"
                                        description="รหัสผ่านใหม่จะถูกตั้งให้เหมือนกับชื่อผู้ใช้งาน"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        onConfirm={() => resetpasswordUser(user._id, user.username)}                                         
                                        okText="ยืนยัน"
                                        cancelText="ยกเลิก"
                                    >
                                        <button className="button-resetpass rounded-md p-1 mr-2 mb-2 transition duration-150 ease-in-out">Reset Password</button>
                                    </Popconfirm>

                                    <Popconfirm
                                        title="ยืนยันการลบบัญชีผู้ใช้งาน"
                                        description="คุณต้องการลบบัญชีผู้ใช้งานนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        onConfirm={() => deleteUser(user._id)}                                         
                                        okText="ยืนยัน"
                                        cancelText="ยกเลิก"
                                    >
                                        <button className="button-delete rounded-md p-1 mr-2 mb-2 transition duration-150 ease-in-out">Delete</button>
                                    </Popconfirm>
                                </div>
                            </div>
                        </div>   

                        ))}  
                    </div>

                </div>

                <div></div>
            </div>

        </div>
        </>
    )
}

export default ManageUser

