import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined, UploadOutlined,QuestionCircleOutlined,DeleteFilled,EditFilled, MinusCircleOutlined, PlusOutlined,InfoCircleOutlined   } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Upload, message ,Spin, Switch,Popconfirm,Table,Modal} from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function EditProduct() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  
  // State declarations
  const [isLoading, setIsLoading] = useState(true);
  const [markets, setMarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [product, setProduct] = useState(null);
  const [variants, setVariants] = useState([]);
  const [combinations, setCombinations] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          navigate('../manage-product');
          return;
        }
        
        // Fetch markets
        const marketsResponse = await axios.get(
          `${import.meta.env.VITE_BACKEND_URL}api/market`,
          { params: { userId: user._id } }
        );
        setMarkets(marketsResponse.data);

        // Fetch categories
        const categoriesResponse = await axios.get(
          `${import.meta.env.VITE_BACKEND_URL}api/category`,
          { params: { userId: user._id } }
        );
        setCategories(Array.isArray(categoriesResponse.data) ? categoriesResponse.data : []);

        // Fetch product
        const productResponse = await axios.get(
          `${import.meta.env.VITE_BACKEND_URL}api/product/from_id_product/${id}`
        );
        const productData = productResponse.data;

        if (productData) {
          setProduct(productData);
          
          // Initialize form values
          const sanitized = {
            description: productData.description && productData.description !== "undefined" 
              ? productData.description : "",
            sku: productData.sku && productData.sku !== "undefined" 
              ? productData.sku : "",
          };

          form.setFieldsValue({
            name: productData.name || "",
            description: sanitized.description,
            weight: productData.weight || 0,
            cost: productData.cost || 0,
            price: productData.price || 0,
            market: productData.market?._id || "",
            category: productData.category || "",
            sku: sanitized.sku,
            status: productData.status ?? true,
          });

          // Initialize image related states
          setFileList(productData.imageUrls.map((filename, index) => ({
            uid: index,
            name: `image${index + 1}.png`,
            status: 'done',
            url: getImageProductUrl(filename),
          })));

          // Initialize variants and combinations
          if (Array.isArray(productData.variants) && productData.variants.length > 0) {
            const initialVariants = productData.variants.map(variant => ({
              name: variant.name,
              options: variant.options.map(option => ({
                value: option.name || '',
                image: option.imageUrl ? getImageProductVariantUrl(option.imageUrl) : null,
                originalImageUrl: option.imageUrl || null
              }))
            }));
            setVariants(initialVariants);

            form.setFieldsValue({
              variants: productData.variants.map(variant => ({
                name: variant.name,
                options: variant.options.map(option => option.name) // Changed from option.value to option.name
              }))
            });

            // Initialize combinations
            if (Array.isArray(productData.combinations) && productData.combinations.length > 0) {
              const combinationArray = productData.combinations.map(combo => {
                const variantValues = {};
                Object.entries(combo.variantCombination).forEach(([key, value]) => {
                  variantValues[key] = value;
                });
                
                // Find the corresponding image URL from variants
                const firstVariantOption = productData.variants[0]?.options.find(
                  opt => opt.name === variantValues[productData.variants[0].name]
                );
                
                return {
                  ...variantValues,
                  image: firstVariantOption?.imageUrl ? 
                    getImageProductVariantUrl(firstVariantOption.imageUrl) : null,
                  price: combo.price || 0,
                  stock: combo.stock || 0,
                  sku: combo.sku || ''
                };
              });
              setCombinations(combinationArray);

              // Set form values for combinations
              const prices = {};
              const stocks = {};
              const skus = {};
              productData.combinations.forEach((combo, index) => {
                prices[index] = combo.price || 0;
                stocks[index] = combo.stock || 0;
                skus[index] = combo.sku || '';
              });
              form.setFieldsValue({ 
                prices, 
                stocks, 
                skus,
                variants: productData.variants.map(variant => ({
                  name: variant.name,
                  options: variant.options.map(option => option.name)
                }))
              });            
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        message.error('Failed to load product data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user, id, navigate, form]);

  const validateVariantOptions = (variants) => {
    return variants.every(variant => variant.options && variant.options.length > 0);
  };

  const handleVariantImageChange = async (info, variantIndex, optionIndex) => {
    try {
      // กรณีลบรูปภาพ
      if (info.fileList.length === 0) {
        setVariants(prevVariants => {
          const newVariants = [...prevVariants];
          if (newVariants[variantIndex]?.options[optionIndex]) {
            newVariants[variantIndex].options[optionIndex] = {
              ...newVariants[variantIndex].options[optionIndex],
              image: null,
              imageUrl: null,
              originFileObj: null,
              keepExistingImage: false
            };
          }
          return newVariants;
        });
  
        // อัปเดต combinations เมื่อลบรูปภาพ
        setCombinations(prevCombinations => {
          return prevCombinations.map(combo => {
            if (variantIndex === 0) {
              const variantName = variants[variantIndex].name;
              const optionValue = variants[variantIndex].options[optionIndex].value;
              if (combo[variantName] === optionValue) {
                return { ...combo, image: null };
              }
            }
            return combo;
          });
        });
  
        return false;
      }
  
      const { file } = info;
      if (!file) return false;
      
      // ตรวจสอบไฟล์
      const fileObj = file.originFileObj || file;
      const isValidType = ['image/png', 'image/jpeg', 'image/jpg'].includes(fileObj.type);
      const isValidSize = fileObj.size / 1024 / 1024 < 5;

      if (!isValidType || !isValidSize) {
        message.error(`Invalid image: ${!isValidType ? 'Wrong format' : 'Too large'}`);
        return false;
      }
  
      // แปลงเป็น base64
      const base64 = await getBase64(fileObj);
      
      // อัปเดต variants
      setVariants(prevVariants => {
        const newVariants = [...prevVariants];
        if (!newVariants[variantIndex].options[optionIndex]) {
          newVariants[variantIndex].options[optionIndex] = {
            value: '',
            image: null,
          };
        }

        newVariants[variantIndex].options[optionIndex] = {
          ...newVariants[variantIndex].options[optionIndex],
          image: base64,
          originFileObj: fileObj,
          keepExistingImage: false
        };
  
        return newVariants;
      });
  
      // อัปเดต combinations เมื่อเพิ่มหรือแก้ไขรูปภาพ
      if (variantIndex === 0) {
        setCombinations(prevCombinations => {
          return prevCombinations.map(combo => {
            const variantName = variants[variantIndex].name;
            const optionValue = variants[variantIndex].options[optionIndex].value;
            if (combo[variantName] === optionValue) {
              return { ...combo, image: base64 };
            }
            return combo;
          });
        });
      }
  
      return false;
    } catch (error) {
      console.error('Error processing image:', error);
      message.error('Failed to process image');
      return false;
    }
  };

  const processVariantsForSubmission = () => {
    if (variants.length === 0) return null;
  
    return variants.map(variant => ({
      name: variant.name,
      options: variant.options.map((option, optIndex) => {
        // Start with basic option data
        const baseOption = {
          value: option.value
        };
  
        // If there's a new file to upload
        if (option.originFileObj) {
          baseOption.imageIndex = optIndex;
        } else if (option.image && !option.originFileObj) {
          // If there's an existing image we want to keep
          baseOption.keepExistingImage = true;
        }
  
        return baseOption;
      })
    }));
  };

// ฟังก์ชันสร้าง combinations พร้อมข้อมูลรูปภาพและราคา
const generateCombinations = (currentVariants, existingCombinations = []) => {
  if (!currentVariants?.length) return [];

  const validVariants = currentVariants.filter(v => 
    v?.options?.length > 0 && v.name
  );

  if (!validVariants.length) return [];

if (validVariants.length === 1) {
  return validVariants[0].options.map(option => {
    const optionValue = typeof option === 'object' ? option.value : option;
    // หา combination เดิมที่ตรงกัน
    const existingCombo = existingCombinations.find(combo => 
      combo[validVariants[0].name] === optionValue
    );
    
    return {
      [validVariants[0].name]: optionValue,
      image: typeof option === 'object' ? option.image : null,
      // เก็บค่าเดิมถ้ามี หรือใช้ค่าเริ่มต้น
      price: existingCombo?.price ?? 0,
      stock: existingCombo?.stock ?? 0,
      sku: existingCombo?.sku ?? ''
    };
  });
}

const [first, ...rest] = validVariants;
const restCombinations = generateCombinations(rest, existingCombinations);

return first.options.flatMap(option => {
  const optionValue = typeof option === 'object' ? option.value : option;

  return restCombinations.map(combo => {
    // หา combination เดิมที่ตรงกัน โดยเช็คทุกตัวเลือก
    const existingCombo = existingCombinations.find(c => 
      c[first.name] === optionValue && 
      Object.entries(combo)
        .filter(([key]) => !['image', 'price', 'stock', 'sku'].includes(key))
        .every(([key, val]) => c[key] === val)
    );
    
    return {
      [first.name]: optionValue,
      ...combo,
      image: first === validVariants[0] ? 
        (typeof option === 'object' ? option.image : null) : 
        combo.image,
      // เก็บค่าเดิมถ้ามี มิฉะนั้นใช้ค่าจาก combo หรือค่าเริ่มต้น
      price: existingCombo?.price ?? combo.price ?? 0,
      stock: existingCombo?.stock ?? combo.stock ?? 0,
      sku: existingCombo?.sku ?? combo.sku ?? ''
    };
  });
});
};

// เพิ่มตัวเลือก
const handleAddVariant = () => {
if (variants.length >= 2) return;

const newVariant = {
  name: '',
  options: [{ value: '', image: null }]
};

const newVariants = [...variants, newVariant];
setVariants(newVariants);

// Generate combinations immediately
const newCombinations = generateCombinations(newVariants);
setCombinations(newCombinations);
};

useEffect(() => {
// Generate new combinations while preserving existing values
const newCombinations = generateCombinations(variants, combinations);
if (JSON.stringify(newCombinations) !== JSON.stringify(combinations)) {
  setCombinations(newCombinations);
}
}, []);

// ลบตัวเลือกหลัก
const handleRemoveVariant = (index) => {
  const newVariants = variants.filter((_, i) => i !== index);
  setVariants(newVariants);
  
  if (newVariants.length === 0) {
    setCombinations([]);
  } else {
    const newCombinations = generateCombinations(newVariants, combinations);
    setCombinations(newCombinations);
  }
  
  const formValues = form.getFieldsValue();
  formValues.variants = formValues.variants.filter((_, i) => i !== index);
  form.setFieldsValue(formValues);
};

// ลบตัวเลือกย่อย
const handleRemoveOption = (variantIndex, optionIndex) => {
  const newVariants = [...variants];

  // ตรวจสอบว่าเหลือตัวเลือกย่อยมากกว่า 1 ตัวหรือไม่
  if (newVariants[variantIndex].options.length >= 1) {
    newVariants[variantIndex].options.splice(optionIndex, 1);
    setVariants(newVariants);

    // Update combinations
    const newCombinations = generateCombinations(newVariants);
    setCombinations(newCombinations);
  } else {
    message.warning('ต้องมีตัวเลือกย่อยอย่างน้อย 1 ตัว');
  }
};

// Generate table columns
const getColumns = () => {
const baseColumns = [];

// Add variant columns if they exist
if (variants.length > 0) {
  if (variants[0]?.name) {
    baseColumns.push({
      title: variants[0].name,
      dataIndex: variants[0].name,
      width: 77,
      align: 'center',
      render: (value, record) => (
        <div className="flex flex-col items-center">
          <div>{value || ''}</div>
          {record.image && (
            <img 
              src={record.image} 
              alt={value} 
              className="w-14 h-14 object-cover border rounded" 
            />
          )}
        </div>
      )
    });
  }

  if (variants[1]?.name) {
    baseColumns.push({
      title: variants[1].name,
      dataIndex: variants[1].name,
      width: 77,
      align: 'center',
      render: value => <div className="text-center">{value || ''}</div>
    });
  }
}

// Always add these columns
return [...baseColumns, 
  {
    title: 'ราคา',
    dataIndex: 'price',
    width: 150,
    align: 'center',
    render: (_, record, index) => (
      <Form.Item name={['prices', index]} initialValue={record.price}>
        <Input 
          type="number" 
          min={0}
          addonAfter="฿"
          className="hide-spin-buttons"
          onChange={e => {
            const newCombinations = [...combinations];
            newCombinations[index].price = Number(e.target.value);
            setCombinations(newCombinations);
          }}
        />
      </Form.Item>
    )
  },
  {
    title: 'คลัง',
    dataIndex: 'stock',
    width: 100,
    align: 'center',
    render: (_, record, index) => (
      <Form.Item name={['stocks', index]} initialValue={record.stock}>
        <Input 
          type="number" 
          min={0}
          className="hide-spin-buttons"
          onChange={e => {
            const newCombinations = [...combinations];
            newCombinations[index].stock = Number(e.target.value);
            setCombinations(newCombinations);
          }}
        />
      </Form.Item>
    )
  },
  {
    title: 'เลข SKU',
    dataIndex: 'sku',
    width: 200,
    align: 'center',
    render: (_, record, index) => (
      <Form.Item name={['skus', index]} initialValue={record.sku}>
        <TextArea
          autoSize={{ minRows: 1 }}
          maxLength={100}
          onChange={e => {
            const newCombinations = [...combinations];
            newCombinations[index].sku = e.target.value;
            setCombinations(newCombinations);
          }}
          style={{ resize: 'none' }}
        />
      </Form.Item>
    )
  }
];
};

// Component สำหรับแสดงในหน้าจอขนาดเล็ก
const MobileVariantCard = ({ record, index, variants, combinations, setCombinations }) => {
  return (
      <div className={`grid grid-cols-2 gap-2 ${index > 0 ?'mt-2 border-t border-gray-200': ''}`}>
        {/* ส่วนแสดงรูปและชื่อตัวเลือก */}
        <div className={`text-[18px] col-span-2 flex ${index > 0 ?'pt-[15px]': ''}`}>
          {record.image && (
            <img 
              src={record.image} 
              alt={record[variants[0].name]} 
              className="w-20 h-20 object-cover border rounded mr-3" 
            />
          )}
          {variants[0]?.name && (
            <div className="">
              {record[variants[0].name]}
            </div>
          )}
          {variants[1]?.name && (
            <div className="flex">
              <p> , </p> {record[variants[1].name]}
            </div>
          )}
        </div>

        {/* ราคา */}
        <div>
          <Form.Item name={['prices', index]} initialValue={record.price} label="ราคา" className="mb-0">
            <Input 
              type="number"
              min={0}
              maxLength={7}
              addonAfter="฿"
              className="hide-spin-buttons"
              onChange={e => {
                const newCombinations = [...combinations];
                newCombinations[index].price = Number(e.target.value);
                setCombinations(newCombinations);
              }}
            />
          </Form.Item>
        </div>
                
        {/* คลัง */}
        <div>
          <Form.Item 
            name={['stocks', index]} 
            initialValue={record.stock}
            label="คลัง"
            className="mb-0"
            tooltip={{ title: 'จำนวนคลัง หมายถึงจำนวนสินค้าทั้งหมดที่ผู้ขายมีอยู่ในคลังสินค้า', icon: <InfoCircleOutlined /> }}
          >
            <Input 
              type="number"
              min={0}
              maxLength={7}
              className="hide-spin-buttons"
              onChange={e => {
                const newCombinations = [...combinations];
                newCombinations[index].stock = Number(e.target.value);
                setCombinations(newCombinations);
              }}
            />
          </Form.Item>
        </div>

        {/* ส่วน SKU */}
        <div className="col-span-2">
          <Form.Item name={['skus', index]} initialValue={record.sku} label="เลข SKU" className="mb-0">
            <TextArea
              autoSize={{ minRows: 1 }}
              maxLength={100}
              onChange={e => {
                const newCombinations = [...combinations];
                newCombinations[index].sku = e.target.value;
                setCombinations(newCombinations);
              }}
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </div>
      </div>
  );
};

const renderOptionsList = (variantIndex) => (
<Form.List 
  name={['variants', variantIndex, 'options']}
>
  {(fields, { add, remove }) => {

    // ถ้ายังไม่มี fields ให้เพิ่มตัวเลือกแรกเข้าไป
    if (fields.length === 0) {
      add({ value: '', image: null });
    }

    return (
      <div className="flex flex-col w-full gap-2">
        {fields.map((field, optionIndex) => (
          <div key={field.key} className="flex flex-row gap-2 items-center">
            {/* อัปโหลดรูปภาพ */}
            {variantIndex === 0 && (
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={(info) => handleVariantImageChange(info, variantIndex, optionIndex)}
                  onPreview={handlePreview}
                  fileList={
                    variants[variantIndex]?.options[optionIndex]?.image
                      ? [{
                          uid: `-${optionIndex}`,
                          name: `option-${optionIndex}`,
                          status: 'done',
                          url: variants[variantIndex].options[optionIndex].image
                        }]
                      : []
                  }
                >
                  {!variants[variantIndex]?.options[optionIndex]?.image && '+ รูป'}
                </Upload>
              </ImgCrop>
            )}

            {/* Input สำหรับค่าตัวเลือก */}
            <Form.Item
              {...field}
              validateTrigger={['onChange', 'onBlur']}
              rules={[{ required: true, whitespace: true, message: 'กรุณาใส่ตัวเลือก' }]}
              className="grow"
            >
              <Input
                placeholder="ตัวเลือก (เช่น แดง, น้ำเงิน)"
                onChange={(e) => {
                  const newVariants = [...variants];
                  if (!newVariants[variantIndex].options[optionIndex]) {
                    newVariants[variantIndex].options[optionIndex] = {};
                  }
                  newVariants[variantIndex].options[optionIndex].value = e.target.value;
                  setVariants(newVariants);
                  
                  // Update combinations
                  const newCombinations = generateCombinations(newVariants);
                  setCombinations(newCombinations);
                }}
              />
            </Form.Item>
            {/* แสดงปุ่มลบเฉพาะเมื่อมีตัวเลือกมากกว่า 1 ตัว */}
            {optionIndex === 0 ? null :
              <MinusCircleOutlined 
                onClick={() => {
                  remove(field.name);
                  handleRemoveOption(variantIndex, optionIndex);
                }}
                className="mb-2" 
              />
            }
          </div>
        ))}
        <Button type="dashed" onClick={() => add()} block>
          เพิ่มตัวเลือกย่อย
        </Button>
      </div>
      );
    }}
</Form.List>
);

  function getImageProductVariantUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_VARIANT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product/variant`;
    return `${basePath}/${filename}`;
  }
  function getImageProductUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product`;
    return `${basePath}/${filename}`;
  }
  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/category/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },
            data: { imageUrls }
        }
      );

      message.success("ลบประเภทสินค้าสำเร็จ")
      navigate(0)
    } catch (err) {
      console.error('Error deleting category:', err);
    }
  };
  const handleEdit = (id) => {
    navigate(`/edit-category/${id}`);
  };

  if (isLoading) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }

  const handleImageChange = ({ fileList }) => {
    // เก็บไฟล์ใหม่ที่อัปโหลด
    const newImageFiles = fileList
        .filter(file => file.originFileObj) // คัดกรองเฉพาะไฟล์ใหม่
        .map(file => file.originFileObj);
    setImageFiles(newImageFiles);

    // อัปเดตรายการแสดงผล
    setFileList(fileList);
  };
  const handleRemoveImage = (file) => {
    // ถ้ารูปเป็นของเก่า (มี URL อยู่แล้ว) ให้เพิ่มเข้า removedImages
    if (file.url) {
        setRemovedImages(prev => [...prev, file.url]);
    }

    // อัปเดตรายการ fileList หลังจากลบ
    setFileList(prevList => prevList.filter(item => item.uid !== file.uid));
};


    // ฟังก์ชันสำหรับแสดงตัวอย่างภาพของตัวเลือกสินค้า
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    };
  
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const onSubmit = async (values) => {
      try {
        setIsSubmitting(true); // เปิดสถานะกำลังบันทึก
        const formData = new FormData();
    
        // Add basic product information
        formData.append('name', values.name);
        formData.append('description', values.description || '');
        formData.append('sku', values.sku || '');
        formData.append('price', values.price || 0);
        formData.append('weight', values.weight || 0);
        formData.append('status', values.status);
        formData.append('market', values.market);
        if (values.category) {
          formData.append('category', values.category);
        }

        // ส่งรายการรูปที่ต้องการเก็บไว้
        const existingImages = fileList
        .filter(file => file.url) // คัดกรองเฉพาะรูปเดิมที่ยังไม่ถูกลบ
        .map(file => file.url);

        formData.append('existingImages', JSON.stringify(existingImages));

        // ส่งรายการรูปที่ถูกลบ
        formData.append('removedImages', JSON.stringify(removedImages));

        // ส่งรูปใหม่
        imageFiles.forEach(file => {
        formData.append('product', file);
        });
        
        // Process variants and their images
        if (variants.length > 0) {
          const processedVariants = processVariantsForSubmission();

          formData.append('variants', JSON.stringify(processedVariants));
    
          // Add variant images
          variants.forEach(variant => {
            variant.options.forEach(option => {
              if (option.originFileObj) {
                formData.append('variant_image', option.originFileObj);
              }
            });
          });
    
          // Process combinations
          const processedCombinations = combinations.map(combo => {
            // หาค่าเดิมจาก form values
            const index = combinations.findIndex(c => 
              Object.entries(c).every(([key, val]) => 
                key === 'image' || combo[key] === val
              )
            );
    
            return {
              variantCombination: variants.reduce((acc, variant) => {
                if (combo[variant.name]) {
                  acc[variant.name] = combo[variant.name];
                }
                return acc;
              }, {}),
              price: Number(values.prices?.[index]) || combo.price || 0,
              stock: Number(values.stocks?.[index]) || combo.stock || 0,
              sku: values.skus?.[index] || combo.sku || ''
            };
          });
          formData.append('combinations', JSON.stringify(processedCombinations));
        }

        // ตรวจสอบการเลือกตัวเลือกสินค้า
        if (!validateVariantOptions(variants)) {
          message.error('กรุณากำหนดตัวเลือกสินค้า');
          return;
        }
    
        // ส่งข้อมูลไปยัง backend
        const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/product/${id}`,
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
        );
    
        if (response.data) {
          message.success('แก้ไขข้อมูลสินค้าสำเร็จ');
          navigate('../manage-product');
        }
    
      } catch (err) {
        console.error('Error updating product:', err);
        message.error('แก้ไขข้อมูลสินค้าไม่สำเร็จ');
      } finally {
        setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
      }
    };

  return (
    <>
      {user ? 
        <div className='md:inline hidden'>
          <Nav />
        </div>
        : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 flex-initial' style={{width: "48rem"}}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-product" className='text-sm text-gray-600' > <LeftOutlined /> จัดการผลิตภัณฑ์</Link>
            <h2 className='text-2xl mb-4 mt-2'>แก้ไขสินค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-product" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">แก้ไขสินค้า</h2>
          </div>

          <Modal
            open={previewOpen}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img alt="ตัวอย่าง" style={{ width: '100%' }} src={previewImage} />
          </Modal>

          <Form form={form} onFinish={onSubmit} layout="vertical" className='md:mt-0 mt-[62px]'>
            <div className=' bg-white p-6 rounded-lg'>
              <Form.Item 
                label="อัปโหลดภาพ"
                tooltip="อัปโหลดได้สูงสุด 9รูป"
              >
                <ImgCrop rotationSlider>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleImageChange}
                    onRemove={handleRemoveImage}
                    onPreview={handlePreview}
                    maxCount={9}
                    accept="image/*"
                  >
                    {fileList.length < 9 && (
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>


              <Form.Item
                label="ร้านค้าที่ลงขาย"
                name="market"
                rules={[{ required: true, message: 'กรุณาเลือกร้านค้าที่ลงขาย' }]}
                className='pt-2'
              >
                <Select placeholder="เลือกร้านค้า">
                  {markets.map(market => (
                    <Select.Option key={market._id} value={market._id}>
                      {market.name}
                    </Select.Option>
                  ))}

                  <Select.Option key="add-market" value="add-market" >
                    <Link to="/add-market" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มร้านค้า</Link>
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="ชื่อสินค้า"
                name="name"
                rules={[{ required: true, message: 'กรุณาใส่ชื่อสินค้า' }]}
                className='mt-6'
              >
                <Input placeholder='กรุณาใส่ชื่อสินค้า' />
              </Form.Item>

              <Form.Item
                label="ประเภทสินค้า"
                name="category"
              >
                <Select placeholder="เลือกประเภทสินค้า">
                  {categories.map(category => (
                      <Select.Option key={category._id} value={category._id}>
                        <div className='flex justify-between'>
                          <div>{category.name}</div> 
                          <div className='flex'>

                            <div className='mr-1'><EditFilled  className='hover:bg-orange-400 rounded p-0.5 opacity-50 scale-110' onClick={() => handleEdit(category._id)} /></div>
                            
                            <Popconfirm
                                    title="ยืนยันการลบประเภทสินค้า"
                                    description="คุณต้องการลบประเภทสินค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => handleDelete(category._id)}
                                    okText="ยืนยัน"
                                    cancelText="ยกเลิก"
                                  >
                            <div className='mr-1'><DeleteFilled className='hover:bg-red-600 rounded p-0.5 opacity-50 scale-110'/></div>
                            </Popconfirm>

                          </div>
                          
                        </div>
                      </Select.Option>
                    ))}
                  <Select.Option key="add-category" value="add-category" >
                    <Link to="/add-category" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มประเภทสินค้า</Link>
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className='bg-white p-6 rounded-lg mt-4'>
              <Form.Item label="คำอธิบายสินค้า" name="description">
                <TextArea 
                  placeholder='ป้อนคำอธิบายสินค้า' 
                  style={{minHeight:'300px'}}
                />
              </Form.Item>

              <Form.Item label="SKU" name="sku" className=''> 
                <Input />
              </Form.Item>

              <Form.Item 
                label="น้ำหนัก" 
                name="weight" 
                tooltip=""
                className='hidden'
              >
                <InputNumber min={0} placeholder='กรัม'/>
              </Form.Item>
            </div>

            {/* ตัวเลือกสินค้า */}
            <div className='bg-white p-6 rounded-lg mt-4'>
              <label>ตัวเลือกสินค้า</label>
              {variants.map((variant, index) => (
                <div key={index} className={`${index > 0 ? 'pt-4' : 'pb-4 border-b'}`}>
                  <div className='flex flex-row gap-2 w-full'>
                    <Form.Item
                      name={['variants', index, 'name']}
                      rules={[{ required: true, message: 'กรุณาใส่ชื่อตัวเลือก' }]}
                      className='grow'
                    >
                      <Input
                        placeholder="ชื่อ (เช่น สี, ขนาด)"
                        onChange={(e) => {
                          const newVariants = [...variants];
                          newVariants[index].name = e.target.value;
                          setVariants(newVariants);
                        }}
                      />
                    </Form.Item>
                    
                    <MinusCircleOutlined
                      style={{ fontSize: '16px', color: 'red' }}
                      onClick={() => handleRemoveVariant(index)}
                      className='mb-2'
                    />
                  </div>

                  
                  <div className="flex flex-row p-4 border rounded-lg bg-slate-50">
                    {renderOptionsList(index)}
                  </div>
                </div>
              ))}
              
              {variants.length < 2 && (
                <Button
                  type="dashed"
                  onClick={handleAddVariant}
                  className={`w-full flex justify-center items-center ${variants.length > 0 ? 'mt-4' : ''}`}
                >
                  <PlusOutlined /> เพิ่มตัวเลือกสินค้า
                </Button>
              )}
            </div>

            {/* ตารางสำหรับตั้งค่าราคา */}
            {variants.length > 0 && (
              <>
              <div className='bg-white rounded-lg mt-4 md:block hidden'>
                <Table
                  dataSource={combinations}
                  columns={getColumns()}
                  pagination={false}
                />
              </div>

              <div className='bg-white rounded-lg mt-4 p-4 md:hidden'>
                {combinations.map((record, index) => (
                  <MobileVariantCard
                    key={index}
                    record={record}
                    index={index}
                    variants={variants}
                    combinations={combinations}
                    setCombinations={setCombinations}
                  />
                ))}
              </div>
              </>
            )}

            {/* ราคาขายแบบตัวเลือกเดียว */}
            {variants.length < 1 &&
              <div className='bg-white p-6 rounded-lg mt-4'>
                <div className="flex">
                  <div className="flex-1 mr-8 hidden">
                    <Form.Item
                      label="ต้นทุน"
                      name="cost"
                    >
                      <InputNumber min={0} placeholder='0'/>
                    </Form.Item>
                  </div>

                  <div className="flex-1">
                    <Form.Item
                      label="ราคาขาย"
                      name="price"
                    >
                      <InputNumber min={0} placeholder='0'/>
                    </Form.Item>
                  </div>
                </div>
              </div>
            }

            <div className='bg-white p-6 pb-0.5 pt-2.5 rounded-lg mt-4 md:mb-0 flex justify-between'>
              <label className='text-lg'>การแสดงผล</label>              
              <Form.Item
              name='status'
              valuePropName="checked"
              >

                <Switch
                    style={{ width: '90px' }}
                    checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                    defaultChecked
                    className='md:mr-0 mr-4  md:scale-100 scale-150'
                >
                </Switch>
              </Form.Item>
            </div>

            <Form.Item>
              <div className='flex justify-end mt-4 mb-20'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
            >
              <div className=''>
                <button 
                  type="submit"
                  disabled={isSubmitting}
                  className= 
                    "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"> 
                      {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                  </button>
              </div>
            </Form.Item>

          </Form>
        </div>

        <div></div>
      </div>
    </>
  );
}

export default EditProduct;
