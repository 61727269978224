import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Nav, NavNoUser, Sidebar } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { LeftOutlined, UploadOutlined, DeleteFilled, QuestionCircleOutlined, EditFilled, MinusCircleOutlined, PlusOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select, Upload, message, Switch, Popconfirm, Table, Modal,Spin  } from 'antd';
import ImgCrop from 'antd-img-crop';
import './styles/add-product.css';
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function AddProduct() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const [markets, setMarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [variants, setVariants] = useState([]);
  const [combinations, setCombinations] = useState([]);
  const [previewImage, setPreviewImage] = useState(null); // เก็บภาพตัวอย่าง
  const [previewOpen, setPreviewOpen] = useState(false); // เก็บสถานะเปิด/ปิด modal
  const [imageProduct, setImageProduct] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if(!user){
      navigate('../manage-product');
    }

    const fetchData = async () => {
      try {
        const [marketsResponse, categoriesResponse] = await Promise.all([
          axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, { params: { userId: user._id } }),
          axios.get(`${import.meta.env.VITE_BACKEND_URL}api/category`, { params: { userId: user._id } })
        ]);

        setMarkets(Array.isArray(marketsResponse.data) ? marketsResponse.data : []);
        setCategories(Array.isArray(categoriesResponse.data) ? categoriesResponse.data : []);
      } catch (err) {
        console.error('Error fetching data:', err);
        message.error('Failed to load initial data');
      }
    };

    fetchData();
  }, [user, navigate]);

  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/category/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },data: { imageUrls }
        }
      );

      message.success("ลบประเภทสินค้าสำเร็จ")
      navigate(0)
    } catch (err) {
      console.error('Error deleting category:', err);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-category/${id}`);
  };

  const handleProductImageChange = ({ fileList }) => {
    const validFiles = fileList.filter((file) => {
      const isValidType = ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
      const isValidSize = file.size / 1024 / 1024 < 5;
      
      if (!isValidType) message.error(`${file.name} is not a supported image type`);
      if (!isValidSize) message.error(`${file.name} must be smaller than 5MB`);

      return isValidType && isValidSize;
    });
  
    setImageProduct(validFiles);
  };

    // ฟังก์ชันสำหรับแสดงตัวอย่างภาพของตัวเลือกสินค้า
    const handlePreview = async (file) => {
      if (!file.preview && file.originFileObj) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.preview || file.url);
      setPreviewOpen(true);
    };
  
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };

// ฟังก์ชันตรวจสอบว่ามีตัวเลือกย่อยอย่างน้อย 1 ตัว
const validateVariantOptions = (variants) => {
  return variants.every(variant => variant.options && variant.options.length > 0);
};

const handleVariantImageChange = async (info, variantIndex, optionIndex) => {
  // ถ้าเป็นการลบไฟล์ (fileList is empty)
  if (info.fileList.length === 0) {
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      if (newVariants[variantIndex]?.options[optionIndex]) {
        newVariants[variantIndex].options[optionIndex] = {
          ...newVariants[variantIndex].options[optionIndex],
          image: null,
          originFileObj: null
        };
      }
      return newVariants;
    });
    return false;
  }

  const { file } = info;
  if (!file) return false;
  
  try {
    // Get the actual file object
    const fileObj = file.originFileObj || file;
    
    // Validate file type and size
    const isValidType = ['image/png', 'image/jpeg', 'image/jpg'].includes(fileObj.type);
    const isValidSize = fileObj.size / 1024 / 1024 < 5;

    if (!isValidType || !isValidSize) {
      message.error(`Invalid image: ${!isValidType ? 'Wrong format' : 'Too large'}`);
      return false;
    }

    // Convert to base64
    const base64 = await getBase64(fileObj);
    
    // Update variants state
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      
      // Ensure the option object exists and has the correct structure
      if (!newVariants[variantIndex].options[optionIndex] || 
        typeof newVariants[variantIndex].options[optionIndex] !== 'object') {
          newVariants[variantIndex].options[optionIndex] = {
            value: typeof newVariants[variantIndex].options[optionIndex] === 'object' 
              ? newVariants[variantIndex].options[optionIndex].value 
              : newVariants[variantIndex].options[optionIndex] || '',
            image: null,
            originFileObj: null
        };
      }
      
      // Update the image
      newVariants[variantIndex].options[optionIndex].image = base64;
      newVariants[variantIndex].options[optionIndex].originFileObj = fileObj;

      return newVariants;
    });

    return false;
  } catch (error) {
    console.error('Error processing image:', error);
    message.error('Failed to process image');
    return false;
  }
};

// ฟังก์ชันสร้าง combinations พร้อมข้อมูลรูปภาพและราคา
const generateCombinations = (currentVariants) => {
  if (!currentVariants?.length) return [];

  const validVariants = currentVariants.filter(v => 
    v?.options?.length > 0 && v.name
  );
  
  if (!validVariants.length) return [];
  
  if (validVariants.length === 1) {
    return validVariants[0].options.map(option => ({
      [validVariants[0].name]: typeof option === 'object' ? option.value : option,
      image: typeof option === 'object' ? option.image : null,
      price: 0,
      stock: 0,
      sku: ''
    }));
  }

  const [first, ...rest] = validVariants;
  const restCombinations = generateCombinations(rest);

  return first.options.flatMap(option => 
    restCombinations.map(combo => ({
      [first.name]: typeof option === 'object' ? option.value : option,
      ...combo,
      image: first === validVariants[0] ? (typeof option === 'object' ? option.image : null) : combo.image,
      price: 0,
      stock: 0,
      sku: ''
    }))
  );
};

// เพิ่มตัวเลือกหลัก
const handleAddVariant = () => {
  if (variants.length >= 2) return;

  const newVariant = {
    name: '',
    options: [{ value: '', image: null }]
  };

  const newVariants = [...variants, newVariant];
  setVariants(newVariants);
  
  // Generate combinations immediately
  const newCombinations = generateCombinations(newVariants);
  setCombinations(newCombinations);
};

  useEffect(() => {
    const newCombinations = generateCombinations(variants);
    setCombinations(newCombinations);
  }, [variants]);

  // ลบตัวเลือกหลัก
  const handleRemoveVariant = (index) => {
    const newVariants = variants.filter((_, i) => i !== index);
    setVariants(newVariants);
    
    if (newVariants.length === 0) {
      setCombinations([]);
    } else {
      const newCombinations = generateCombinations(newVariants);
      setCombinations(newCombinations);
    }
    
    // Update form fields
    const formValues = form.getFieldsValue();
    formValues.variants = formValues.variants.filter((_, i) => i !== index);
    form.setFieldsValue(formValues);
  };

  // ลบตัวเลือกย่อย
  const handleRemoveOption = (variantIndex, optionIndex) => {
    const newVariants = [...variants];

    // ตรวจสอบว่าเหลือตัวเลือกย่อยมากกว่า 1 ตัวหรือไม่
    if (newVariants[variantIndex].options.length >= 1) {
      newVariants[variantIndex].options.splice(optionIndex, 1);
      setVariants(newVariants);

      // Update combinations
      const newCombinations = generateCombinations(newVariants);
      setCombinations(newCombinations);
    } else {
      message.warning('ต้องมีตัวเลือกย่อยอย่างน้อย 1 ตัว');
    }
  };

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

      const formData = new FormData();

      // 1. แก้ไขการส่งข้อมูลพื้นฐานของสินค้า
      formData.append('name', values.name);
      formData.append('description', values.description || '');
      formData.append('price', values.price || 0);
      formData.append('stock', values.stock || 0);
      formData.append('sku', values.sku || '');
      formData.append('cost', values.cost || 0);
      formData.append('weight', values.weight || 0);
      formData.append('user', user._id);

      if (values.category) {
        formData.append('category', values.category);
      }
      if (values.market) {
          formData.append('market', values.market);
      }

      // 2. Product images
      if (imageProduct.length > 0) {
          imageProduct.forEach((file) => {
              if (file.originFileObj) {
                  formData.append('product', file.originFileObj);
              }
          });
      }

      // Process variants and their images
      let variantImageIndex = 0;
      const processedVariants = variants.map(variant => {
        return {
          name: variant.name,
          options: variant.options.map(option => {
            const processedOption = {
              value: option.value || option,
              imageIndex: null
            };
                
            // Only process images for the first variant (usually color variants)
            if (option.image) {
              // Get the original file from option.originFileObj if it exists
              if (option.originFileObj) {
                formData.append('variant_image', option.originFileObj);
                processedOption.imageIndex = variantImageIndex;
                variantImageIndex++;
              }
            }
                
            return processedOption;
          })
        };
      });

      // ตรวจสอบว่า combinations เป็นอาร์เรย์ก่อนที่จะใช้ map
      const processedCombinations = combinations.map((combo, index) => ({
        variantCombination: Object.fromEntries(
          Object.entries(combo).filter(([key]) => 
            !['image', 'price', 'stock', 'sku'].includes(key)
          )
        ),
        price: Number(values.prices?.[index] || 0),
        stock: Number(values.stocks?.[index] || 0),
        sku: values.skus?.[index] || ''
      }));

      // 3. ส่ง variants และ combinations
      formData.append('variants', JSON.stringify(processedVariants));
      formData.append('combinations', JSON.stringify(processedCombinations));

      // ตรวจสอบการเลือกตัวเลือกสินค้า
      if (!validateVariantOptions(variants)) {
        message.error('กรุณากำหนดตัวเลือกสินค้า');
        return;
      }

      // 4. ส่งข้อมูลไปยัง backend
      const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/product`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      if (response.data) {
        message.success('เพิ่มสินค้าใหม่สำเร็จ');
        navigate('../manage-product');
      }
    } catch (err) {
      console.error('Error updating product:', err);
      message.error('แก้ไขข้อมูลสินค้าไม่สำเร็จ');
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };

// Generate table columns
const getColumns = () => {
  const baseColumns = [];

  // Add variant columns if they exist
  if (variants.length > 0) {
    if (variants[0]?.name) {
      baseColumns.push({
        title: variants[0].name,
        dataIndex: 'image',
        width: 77,
        align: 'center',
        render: (_, record) => (
          <div className="flex flex-col items-center">
            <div>{record[variants[0].name] || ''}</div>
            {record.image && (
              <img 
                src={record.image} 
                alt={record[variants[0].name]} 
                className="w-14 h-14 object-cover border rounded" 
              />
            )}
          </div>
        )
      });
    }

    if (variants[1]?.name) {
      baseColumns.push({
        title: variants[1].name,
        dataIndex: variants[1].name,
        width: 77,
        align: 'center',
        render: value => <div className="text-center">{value || ''}</div>
      });
    }
  }

  // Always add these columns
  return [...baseColumns, 
    {
      title: 'ราคา',
      dataIndex: 'price',
      width: 150,
      align: 'center',
      render: (_, record, index) => (
        <Form.Item name={['prices', index]} initialValue={0}>
          <Input 
            type="number" 
            min={0}
            maxLength={7}
            addonAfter="฿"
            className="hide-spin-buttons"
            onChange={e => {
              const newCombinations = [...combinations];
              newCombinations[index].price = Number(e.target.value);
              setCombinations(newCombinations);
            }}
          />
        </Form.Item>
      )
    },
    {
      title: 'คลัง',
      dataIndex: 'stock',
      width: 100,
      align: 'center',
      render: (_, record, index) => (
        <Form.Item name={['stocks', index]} 
          initialValue={0}
          tooltip={{ title: 'จำนวนคลัง หมายถึงจำนวนสินค้าทั้งหมดที่ผู้ขายมีอยู่ในคลังสินค้า', icon: <InfoCircleOutlined /> }}>
          <Input 
            type="number" 
            min={0}
            maxLength={7}
            className="hide-spin-buttons"
            onChange={e => {
              const newCombinations = [...combinations];
              newCombinations[index].stock = Number(e.target.value);
              setCombinations(newCombinations);
            }}
          />
        </Form.Item>
      )
    },
    {
      title: 'เลข SKU',
      dataIndex: 'sku',
      width: 200,
      align: 'center',
      render: (_, record, index) => (
        <Form.Item name={['skus', index]} >
          <TextArea
            autoSize={{ minRows: 1 }}
            maxLength={100}
            onChange={e => {
              const newCombinations = [...combinations];
              newCombinations[index].sku = e.target.value;
              setCombinations(newCombinations);
            }}
            style={{ resize: 'none' }}
          />
        </Form.Item>
      )
    }
  ];
};

// Component สำหรับแสดงในหน้าจอขนาดเล็ก
const MobileVariantCard = ({ record, index, variants, combinations, setCombinations }) => {
  return (
      <div className={`grid grid-cols-2 gap-2 ${index > 0 ?'mt-2 border-t border-gray-200': ''}`}>
        {/* ส่วนแสดงรูปและชื่อตัวเลือก */}
        <div className={`text-[18px] col-span-2 flex ${index > 0 ?'pt-[15px]': ''}`}>
          {record.image && (
            <img 
              src={record.image} 
              alt={record[variants[0].name]} 
              className="w-20 h-20 object-cover border rounded mr-3" 
            />
          )}
          {variants[0]?.name && (
            <div className="">
              {record[variants[0].name]}
            </div>
          )}
          {variants[1]?.name && (
            <div className="flex">
              <p> , </p> {record[variants[1].name]}
            </div>
          )}
        </div>

        {/* ราคา */}
        <div>
          <Form.Item name={['prices', index]} initialValue={0} label="ราคา" className="mb-0">
            <Input 
              type="number"
              min={0}
              maxLength={7}
              addonAfter="฿"
              className="hide-spin-buttons"
              onChange={e => {
                const newCombinations = [...combinations];
                newCombinations[index].price = Number(e.target.value);
                setCombinations(newCombinations);
              }}
            />
          </Form.Item>
        </div>
                
        {/* คลัง */}
        <div>
          <Form.Item 
            name={['stocks', index]} 
            initialValue={0} 
            label="คลัง"
            className="mb-0"
            tooltip={{ title: 'จำนวนคลัง หมายถึงจำนวนสินค้าทั้งหมดที่ผู้ขายมีอยู่ในคลังสินค้า', icon: <InfoCircleOutlined /> }}
          >
            <Input 
              type="number"
              min={0}
              maxLength={7}
              className="hide-spin-buttons"
              onChange={e => {
                const newCombinations = [...combinations];
                newCombinations[index].stock = Number(e.target.value);
                setCombinations(newCombinations);
              }}
            />
          </Form.Item>
        </div>

        {/* ส่วน SKU */}
        <div className="col-span-2">
          <Form.Item name={['skus', index]} label="เลข SKU" className="mb-0">
            <TextArea
              autoSize={{ minRows: 1 }}
              maxLength={100}
              onChange={e => {
                const newCombinations = [...combinations];
                newCombinations[index].sku = e.target.value;
                setCombinations(newCombinations);
              }}
              style={{ resize: 'none' }}
            />
          </Form.Item>
        </div>
      </div>
  );
};

const renderOptionsList = (variantIndex) => (
  <Form.List 
    name={['variants', variantIndex, 'options']}
  >
    {(fields, { add, remove }) => {
      // ถ้ายังไม่มี fields ให้เพิ่มตัวเลือกแรกเข้าไป
      if (fields.length === 0) {
        add({ value: '', image: null });
      }

      return (
      <div className="flex flex-col w-full gap-2">
        {fields.map((field, optionIndex) => (
          <div key={field.key} className="flex flex-row gap-2 items-center">
            {/* อัปโหลดรูปภาพ */}
            {variantIndex === 0 && (
              <ImgCrop rotationSlider>
                <Upload
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={(info) => handleVariantImageChange(info, variantIndex, optionIndex)}
                  onPreview={handlePreview}
                  fileList={
                    variants[variantIndex]?.options[optionIndex]?.image
                      ? [{
                          uid: `-${optionIndex}`,
                          name: `option-${optionIndex}`,
                          status: 'done',
                          url: variants[variantIndex].options[optionIndex].image
                        }]
                      : []
                  }
                >
                  {!variants[variantIndex]?.options[optionIndex]?.image && '+ รูป'}
                </Upload>
              </ImgCrop>
            )}

            {/* Input สำหรับค่าตัวเลือก */}
            <Form.Item
              {...field}
              name={[field.name, 'value']}
              validateTrigger={['onChange', 'onBlur']}
              rules={[{ required: true, whitespace: true, message: 'กรุณาใส่ตัวเลือก' }]}
              className="grow"
            >
              <Input
                placeholder="ตัวเลือก (เช่น แดง, น้ำเงิน)"
                onChange={(e) => {
                  const newVariants = [...variants];
                  if (!newVariants[variantIndex].options[optionIndex]) {
                    newVariants[variantIndex].options[optionIndex] = {};
                  }
                  newVariants[variantIndex].options[optionIndex].value = e.target.value;
                  setVariants(newVariants);
                  
                  // Update combinations
                  const newCombinations = generateCombinations(newVariants);
                  setCombinations(newCombinations);
                }}
              />
            </Form.Item>
            {/* แสดงปุ่มลบเฉพาะเมื่อมีตัวเลือกมากกว่า 1 ตัว */}
            {optionIndex === 0 ? null :
              <MinusCircleOutlined 
                onClick={() => {
                  remove(field.name);
                  handleRemoveOption(variantIndex, optionIndex);
                }}
                className="mb-2" 
              />
            }
          </div>
        ))}
        <Button type="dashed" onClick={() => add({ value: '', image: null })} block>
          เพิ่มตัวเลือกย่อย
        </Button>
      </div>
      );
    }}
  </Form.List>
);

  return (
    <>
      {user ? 
        <div className='md:inline hidden'>
          <Nav />
        </div>
        : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 flex-initial' style={{width: "48rem"}}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-product" className='text-sm text-gray-600' > <LeftOutlined /> จัดการผลิตภัณฑ์</Link>
            <h2 className='text-2xl mb-4 mt-2'>เพิ่มสินค้าใหม่</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-product" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">เพิ่มสินค้า</h2>
          </div>

          <Modal
            open={previewOpen}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img alt="ตัวอย่าง" style={{ width: '100%' }} src={previewImage} />
          </Modal>

          <Form 
            onFinish={onSubmit} form={form} layout="vertical" className='md:mt-0 mt-[62px]' 
            initialValues={{ 
              description:'',
              sku:'',
              weight:'',
              cost: 0,
              price: 0,
              status: true 
            }}
          >
            <div className='bg-white p-6 rounded-lg '>
              <Form.Item label="อัปโหลดภาพ" tooltip="อัปโหลดได้สูงสุด 9รูป">
                <ImgCrop rotationSlider>
                  <Upload
                    listType="picture-card"
                    maxCount={9}
                    beforeUpload={() => false} // ปิดการอัปโหลดอัตโนมัติ
                    onChange={handleProductImageChange} // จัดการการเปลี่ยนแปลงไฟล์
                    onPreview={handlePreview} // แสดงตัวอย่างภาพ
                  >
                    {imageProduct.length < 9 && <div><UploadOutlined /> อัปโหลดรูป</div>}
                  </Upload>
                </ImgCrop>
              </Form.Item>

              <Form.Item
                label="ร้านค้าที่ลงขาย"
                name="market"
                rules={[{ required: true, message: 'กรุณาเลือกร้านค้าที่ลงขาย' }]}
                className='pt-2'
              >
                <Select placeholder="เลือกร้านค้า">
                  {markets.map(market => (
                      <Select.Option key={market._id} value={market._id}>
                        {market.name}
                      </Select.Option>
                    ))}

                  <Select.Option key="add-market" value="add-market" >
                    <Link to="/add-market" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มร้านค้า</Link>
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="ชื่อสินค้า"
                name="name"
                rules={[{ required: true, message: 'กรุณาใส่ชื่อสินค้า' }]}
                className="mt-6"
              >
                <Input placeholder='กรุณาใส่ชื่อสินค้า' />
              </Form.Item>

              <Form.Item
                label="ประเภทสินค้า"
                name="category"
              >
                <Select placeholder="เลือกประเภทสินค้า">
                  {categories.map(category => (
                      <Select.Option key={category._id} value={category._id}>
                        <div className='flex justify-between'>
                          <div>{category.name}</div> 
                          <div className='flex'>

                            <div className='mr-1'><EditFilled  className='hover:bg-orange-400 rounded p-0.5 opacity-50 scale-110' onClick={() => handleEdit(category._id)} /></div>
                            
                            <Popconfirm
                                    title="ยืนยันการลบประเภทสินค้า"
                                    description="คุณต้องการลบประเภทสินค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => handleDelete(category._id)}
                                    okText="ยืนยัน"
                                    cancelText="ยกเลิก"
                                  >
                              <div className='mr-1'><DeleteFilled className='hover:bg-red-600 rounded p-0.5 opacity-50 scale-110'/></div>
                            </Popconfirm>

                          </div>
                          
                        </div>
                      </Select.Option>
                    ))}
                  <Select.Option key="add-category" value="add-category" >
                    <Link to="/add-category" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มประเภทสินค้า</Link>
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className='bg-white p-6 rounded-lg mt-4'>

              <Form.Item label="คำอธิบายสินค้า" name="description" >
                <TextArea 
                  placeholder='ป้อนคำอธิบายสินค้า'
                  style={{minHeight:'300px',maxHeight:'1200px' }}
                />
              </Form.Item>

              <Form.Item label="SKU" name="sku" className=''> 
                <Input />
              </Form.Item>

              <Form.Item 
                label="น้ำหนัก" 
                name="weight" 
                tooltip=""
                className='hidden'
              >
                <InputNumber min={0} placeholder='กรัม'/>
              </Form.Item>
            </div>

            {/* ตัวเลือกสินค้า */}
            <div className='bg-white p-6 rounded-lg mt-4'>
              <label>ตัวเลือกสินค้า</label>
              {variants.map((variant, index) => (
                <div key={index} className={`${index > 0 ? 'pt-4' : 'pb-4 border-b'}`}>
                  <div className='flex flex-row gap-2 w-full'>
                    <Form.Item
                      name={['variants', index, 'name']}
                      rules={[{ required: true, message: 'กรุณาใส่ชื่อตัวเลือก' }]}
                      className='grow'
                    >
                      <Input
                        placeholder="ชื่อ (เช่น สี, ขนาด)"
                        onChange={(e) => {
                          const newVariants = [...variants];
                          newVariants[index].name = e.target.value;
                          setVariants(newVariants);
                        }}
                      />
                    </Form.Item>
                    
                    <MinusCircleOutlined
                      style={{ fontSize: '16px', color: 'red' }}
                      onClick={() => handleRemoveVariant(index)}
                      className='mb-2'
                    />
                  </div>

                  
                  <div className="flex flex-row p-4 border rounded-lg bg-slate-50">
                    {renderOptionsList(index)}
                  </div>
                </div>
              ))}
              
              {variants.length < 2 && (
                <Button
                  type="dashed"
                  onClick={handleAddVariant}
                  className={`w-full flex justify-center items-center ${variants.length > 0 ? 'mt-4' : ''}`}
                >
                  <PlusOutlined /> เพิ่มตัวเลือกสินค้า
                </Button>
              )}
            </div>

            {/* ตารางสำหรับตั้งค่าราคา */}
            {variants.length > 0 && (
              <>
              <div className='bg-white rounded-lg mt-4 md:block hidden'>
                <Table
                  dataSource={combinations}
                  columns={getColumns()}
                  pagination={false}
                />
              </div>

              <div className='bg-white rounded-lg mt-4 p-4 md:hidden'>
                {combinations.map((record, index) => (
                  <MobileVariantCard
                    key={index}
                    record={record}
                    index={index}
                    variants={variants}
                    combinations={combinations}
                    setCombinations={setCombinations}
                  />
                ))}
              </div>
              </>
            )}

            {/* ราคาขายแบบตัวเลือกเดียว */}
            {variants.length < 1 &&
              <div className='bg-white p-6 rounded-lg mt-4'>
                <div className="flex">
                  <div className="flex-1 mr-8 hidden">
                    <Form.Item
                      label="ต้นทุน"
                      name="cost"
                    >
                      <InputNumber min={0} placeholder='0'/>
                    </Form.Item>
                  </div>

                  <div className="flex-1">
                    <Form.Item
                      label="ราคาขาย"
                      name="price"
                    >
                      <InputNumber min={0} placeholder='0'/>
                    </Form.Item>
                  </div>
                </div>
              </div>
            }

            <div className='bg-white p-6 pb-0.5 pt-2.5 rounded-lg mt-4 md:mb-0 flex justify-between'>
              <label className='text-lg'>การแสดงผล</label>
              <Form.Item
                name='status'
                valuePropName="checked"
                >
                  <Switch
                      style={{ width: '90px' }}
                      checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                      defaultChecked
                      className='md:mr-0 mr-4  md:scale-100 scale-150'
                  >
                  </Switch>
              </Form.Item>
            </div>

            <Form.Item>
              <div className='flex justify-end mt-4 mb-20'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
            >
              <div className=''>
                <button 
                  type="submit"
                  disabled={isSubmitting}
                  className= 
                    "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"
                  > 
                    {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>
            
          </Form>
        </div>

        <div></div>
      </div>
    </>
  );
}

export default AddProduct;