import React , { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLoginPage } from '../components/Nav';
import { UserLoginForm , EmailLoginForm } from '../components/login';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserContext';



function LoginPage() {
  const{user} =  useUserAuth();

  //ถ้าเป็นสมาชิกอยู่แล้ว จะไม่สามารถเข้าหน้านี้ได้ ต้องlogOut ก่อน
  if (user){
    return <Navigate to="/" />
  }
  
  const [isUsernameLoginForm, setIsUsernameLoginForm] = useState(true);

  // ฟังก์ชันสำหรับการสลับฟอร์มเข้าสู่ระบบ
  const toggleLoginForm = () => {
    setIsUsernameLoginForm(!isUsernameLoginForm);
  };

  return (
    <>
      <NavLoginPage />
      <div>
        {/* จะแสดง UserLoginForm หรือ EmailLoginForm ขึ้นอยู่กับค่าของ isUsernameLoginForm */}
        {isUsernameLoginForm ? (
          <EmailLoginForm toggleLoginForm={toggleLoginForm} />
        ) : (
          <UserLoginForm toggleLoginForm={toggleLoginForm} />
        )}
      </div>
    </>
  );
}

export default LoginPage