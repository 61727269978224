import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined, UploadOutlined, MailOutlined, PhoneOutlined, HomeOutlined, IdcardOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { FloatButton, Button, Form, Input, Upload, message, Spin, Switch, Select, Modal } from 'antd';
import ImgCrop from 'antd-img-crop';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { LoadingOutlined } from "@ant-design/icons";

function EditCustomer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [customer, setCustomer] = useState(null);
  const [markets, setMarkets] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [oldImageUrls, setOldImageUrls] = useState([]);
  const [remainingImageUrls, setRemainingImageUrls] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  useEffect(() => {
    if (!user) {
      navigate('../manage-customer');
    }

    const fetchMarkets = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, {
          params: { userId: user._id } // ส่ง userId เป็น parameter
        });
        setMarkets(response.data);
      } catch (err) {
        console.error('Error fetching markets:', err);
      }
    };

    const fetchCustomer = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/customer/from_id_customer/${id}`);
        const customerData = response.data;

        console.log(customerData)
        
        const sanitizedCustomer = {
          market: customerData.market?._id || "",
          name: customerData.name || "",
          nationalId: customerData.nationalId || "",
          address: customerData.address || "",
          phone: customerData.phone || "",
          email: customerData.email || "",
          description: customerData.description || "",
          status: customerData.status || false,
        };

        setCustomer(sanitizedCustomer);
        form.setFieldsValue(sanitizedCustomer);

        setOldImageUrls(customerData.imageUrls);
        setFileList((customerData.imageUrls).map((filename, index) => ({
          uid: index,
          name: `logo${index + 1}.png`,
          status: 'done',
          url: getImageCustomerUrl(filename),
        })));

      } catch (err) {
        console.error('Error fetching customer:', err);
      }
    };

    fetchMarkets();
    fetchCustomer();
  }, [id, form, navigate, user]);
  function getImageCustomerUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_CUSTOMER_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/customer`;
    return `${basePath}/${filename}`;
  }

  if (!customer) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin fullscreen size="large" /></div>
        </div>
      </>
    );
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const imageUrl = file.url || file.preview;
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
    setPreviewTitle(file.name || imageUrl.substring(imageUrl.lastIndexOf('/') + 1));
  };

  const handleImageChange = ({ fileList }) => {
    setFileList(fileList);
    const remainingUrls = fileList.filter(file => file.status === 'done').map(file => file.url);
    setRemainingImageUrls(remainingUrls);
  };

  const props = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error(`${file.name} ไม่รองรับไฟล์ประเภทนี้`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
    },
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

    const formData = new FormData();
    formData.append('market', values.market);
    formData.append('name', values.name);
    formData.append('nationalId', values.nationalId);
    formData.append('address', values.address);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('description', values.description);
    formData.append('status', values.status);
    formData.append('updatedAt', new Date().toISOString());

    fileList.length > 0 ? formData.append('oldImageUrls', JSON.stringify(oldImageUrls)) : '';
    formData.append('remainingImageUrls', JSON.stringify(remainingImageUrls));

    fileList.forEach(file => {
      if (file.originFileObj) {
        formData.append('logo', file.originFileObj);
      }
    });

    try {
      await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/customer/${id}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      message.success("แก้ไขข้อมูลลูกค้าสำเร็จ");
      navigate('../manage-customer');
    } catch (err) {
      console.error('Error updating customer:', err);
      message.error("แก้ไขข้อมูลลูกค้าไม่สำเร็จ");
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };

  return (
    <>
      {user ? 
        <div className='md:block hidden'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 flex-initial  md:mb-28 mb-10' style={{ width: "48rem" }}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-customer" className='text-sm text-gray-600' > <LeftOutlined /> จัดการรายชื่อลูกค้า</Link>
            <h2 className='text-2xl mb-4 mt-2'>แก้ไขข้อมูลลูกค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-customer" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">แก้ไขข้อมูลลูกค้า</h2>
          </div>

          <Form form={form} onFinish={onSubmit} layout="vertical" className='md:mt-0 mt-[62px]' >
            <div className='bg-white p-6 rounded-lg'>
              <Form.Item
                label="อัปโหลดภาพ"
                tooltip="อัปโหลดได้สูงสุด 1รูป"
              >
                <ImgCrop rotationSlider>
                  <Upload
                    {...props}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleImageChange}
                    onPreview={handlePreview}
                  >
                    {fileList.length < 1 && (
                      <div>
                        <UploadOutlined /><div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              
              <Form.Item
                label="ร้านค้า"
                name="market"
                rules={[{ required: true, message: 'กรุณาเลือกร้านค้า' }]}
                className='mt-8'
                tooltip={{
                    icon:  <ShopOutlined />,
                  }}
              >
                <Select>
                  {markets.map(market => (
                    <Select.Option key={market._id} value={market._id}>{market.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="ชื่อ"
                name="name"
                rules={[{ required: true, message: 'กรุณาใส่ชื่อ' }]}
                className=''
                tooltip={{
                  icon:  <UserOutlined />,
                }}
              >
                <Input placeholder='กรุณาใส่ชื่อ' />
              </Form.Item>
            </div>
            <div className='bg-white p-6 rounded-lg mt-4'>
              <Form.Item
                label="ที่อยู่"
                name="address"
                rules={[{ required: true, message: 'กรุณาใส่ที่อยู่' }]}
                tooltip={{
                  icon:  <HomeOutlined />,
                }}
              >
                <TextArea placeholder='กรุณาใส่ที่อยู่' style={{ minHeight: '80px' }} />
              </Form.Item>

              <Form.Item
                label="เบอร์โทร"
                name="phone"
                rules={[{ required: true, message: 'กรุณาใส่หมายเลขโทรศัพท์' }]}
                tooltip={{
                  icon:  <PhoneOutlined />,
                }}
              >
                <Input placeholder='กรุณาใส่หมายเลขโทรศัพท์' />
              </Form.Item>

              <Form.Item
                label="อีเมล"
                name="email"
                tooltip={{
                  icon:  <MailOutlined />,
              }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="เลขผู้เสียภาษี"
                name="nationalId"
                tooltip={{
                  icon:  <IdcardOutlined />,
              }}
              >
                <Input/>
              </Form.Item>
            </div>

            <div className='bg-white p-6 rounded-lg mt-4'>
              <Form.Item label="คำอธิบายลูกค้า" name="description">
                <TextArea placeholder='ป้อนคำอธิบายลูกค้า' style={{ minHeight: '80px' }}/>
              </Form.Item>
            </div>

            <div className='bg-white p-6 pb-0.5 pt-2.5 rounded-lg mt-4 md:mb-0 mb-10 flex justify-between'>
              <label className='text-lg'>การแสดงผล</label>                       
              <Form.Item
                name='status'
                valuePropName="checked"
              >
                <Switch
                  style={{ width: '90px' }}
                  checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                  defaultChecked
                  className='md:mr-0 mr-4  md:scale-100 scale-150'
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div className='flex justify-end mt-4'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
            >
              <div className=''>
                <button 
                  type="submit"
                  disabled={isSubmitting}
                  className= 
                  "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"> 
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

          </Form>

          <Modal 
            open={previewOpen} 
            title={previewTitle} 
            footer={null} onCancel={() => setPreviewOpen(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>

        <div></div>
      </div>
    </>
  );
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default EditCustomer;
