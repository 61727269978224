import React, { useState, useEffect } from 'react';
import { useUserAuth } from '../../../context/UserContext.jsx';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {LoginAlert} from '../../../components/Alert.jsx';

import { DeleteFilled , PrinterFilled ,SaveOutlined ,ShopOutlined ,UserOutlined , PhoneFilled,HomeFilled,SearchOutlined} from '@ant-design/icons';
import { CheckOutlined , LeftOutlined} from '@ant-design/icons';
import { LoadingOutlined } from "@ant-design/icons";
import { Nav,NavNoUser,Sidebar } from '../../../components/Nav.jsx';
import { Tooltip,Form,Modal , DatePicker,Input , Image , message,Collapse ,Switch,InputNumber,Spin } from 'antd';
import en from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
dayjs.extend(buddhistEra);
import './add-receipt.css';



function AddBill() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [fetchedInvoices, setFetchedInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [fetchedCustomers, setFetchedCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [customerSearchTerm, setCustomerSearchTerm] = useState('');

  const [showMarketModal, setShowMarketModal] = useState(false);
  const [fetchedMarkets, setFetchedMarkets] = useState([]);
  const [filteredMarkets, setFilteredMarkets] = useState([]);
  const [marketSearchTerm, setMarketSearchTerm] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const [totalWithVat, setTotalWithVat] = useState(0);
  const [vat, setVat] = useState(0);



  const fetchInvoices = async () => {
    if (user && user._id) {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/invoice`, 
          { params: { userId: user._id } },  // Body ของ request
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
        );
  
        if (response.data.length === 0) {
          setData(false);
        }
  
        if (Array.isArray(response.data)) {
          const sortedInvoices = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          setFetchedInvoices(sortedInvoices);
          setFilteredInvoices(sortedInvoices);
        } else {
          console.error('Fetched invoices are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching invoices:', err);
      }
    }
  };

  const fetchCustomers = async () => {
    if (user && user._id) {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/customer`, {
          params: { userId: user._id }
        });

        if (Array.isArray(response.data)) {
          const sortedCustomers = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          setFetchedCustomers(sortedCustomers);
          setFilteredCustomers(sortedCustomers);
        } else {
          console.error('Fetched customers are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching customers:', err);
      }
    }
  };

  const fetchMarkets = async () => {
    if (user && user._id) {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, {
          params: { userId: user._id }
        });

        if (Array.isArray(response.data)) {
          const sortedMarkets = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          setFetchedMarkets(sortedMarkets);
          setFilteredMarkets(sortedMarkets);
        } else {
          console.error('Fetched markets are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching markets:', err);
      }
    }
  };

  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }
  function getImageCustomerUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_CUSTOMER_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/invoice`;
    return `${basePath}/${filename}`;
  }

  const openInvoiceModal = () => {
    setShowInvoiceModal(true);
    fetchInvoices();
  };

  const openCustomerModal = () => {
    setShowCustomerModal(true);
    fetchCustomers();
  };

  const openMarketModal = () => {
    setShowMarketModal(true);
    fetchMarkets();
  };

  const handleInvoiceSelect = (invoice) => {
    setInvoices([...invoices, {
      no: invoices.length + 1,
      id: invoice.document_number,
      issuedate: invoice.issue_date,
      duedate: invoice.valid_till_date,
      unitPrice: invoice.total_price,
      preTaxAmount: invoice.total_price,
    }]);
    setShowInvoiceModal(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = fetchedInvoices.filter(invoice => 
      invoice.id.toLowerCase().includes(value) || invoice.no.toLowerCase().includes(value)
    );
    setFilteredInvoices(filtered);
  };

  const handleCustomerSelect = (customer) => {
    const address = (customer.address === 'undefined' ? '' : customer.address);
    const nationalId = (customer.nationalId === 'undefined' ? '' : customer.nationalId);
    const email = (customer.email === 'undefined' ? '' : customer.email);
    const phone = (customer.phone === 'undefined' ? '' : customer.phone);

    setCustomerDetails({
      ...customerDetails,
      name: customer.name,
      address: address,
      taxNumber: nationalId,
      email: email,
      phone: phone,
    });
    setShowCustomerModal(false);
  };

  const handleCustomerSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setCustomerSearchTerm(value);
    const filtered = fetchedCustomers.filter(customer => 
      customer.name.toLowerCase().includes(value)
    );
    setFilteredCustomers(filtered);
  };

  const handleMarketSelect = (market) => {
    const TaxNumber = (market.TaxNumber === 'undefined' || !market.TaxNumber ? '' : market.TaxNumber);
    const email = (market.email === 'undefined' || !market.email ? '' : market.email);
    const phone = (market.phone === 'undefined' || !market.phone ? '' : market.phone);
    setLogoUrl(market.logoUrl);

    setIssuerDetails({
      ...issuerDetails,
      name: market.name,
      taxNumber: TaxNumber,
      email: email,
      phone: phone,
      image_url: market.logoUrl,
    });
    setShowMarketModal(false);
  };

  const handleMarketSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setMarketSearchTerm(value);
    const filtered = fetchedMarkets.filter(market => 
      market.name.toLowerCase().includes(value)
    );
    setFilteredMarkets(filtered);
  };

  const buddhistLocale = {
    ...en,
    lang: {
      ...en.lang,
      fieldDateFormat: 'DD/MM/BBBB',
      yearFormat: 'BBBB',
      cellYearFormat: 'BBBB',
    },
  };

  const [formState, setFormState] = useState({

    vat_check: false,
    document_number: "BN-"+dayjs().add(543, 'year').format('DDMMYYYYHHmmss'),

    issue_date: dayjs().format('DD/MM/YYYY'),

    customer_signature_date: dayjs().format('DD/MM/YYYY'),
    seller_signature_date: dayjs().format('DD/MM/YYYY'),

    total_price: 0,
    remark: '',

    payment_method_cash: false,
    payment_method_bank: false,
    payment_method_check: false,
    payment_method_credit: false,
    payment_details: '',

    withholding_tax: false,
    withholding_tax_details: '',
  });

  const [customerDetails, setCustomerDetails] = useState({
    name: '' , address: '', taxNumber: '', email: '', phone: '', customer_signature:'',
  });

  const [issuerDetails, setIssuerDetails] = useState({
    name: '', taxNumber: '', phone: '', email: '', image_url:'', seller_signature:'',
  });

  const [invoices, setInvoices] = useState([
    { no: 1, id: '', issuedate:'', duedate:'', unitPrice: 0, preTaxAmount: 0 },
  ]);

  const handleSwitchChange = (checked, name) => {
    setFormState((prevState) => ({...prevState,[name]: checked,}));
  };

  useEffect(() => {
    calculateTotal();
  }, [formState.vat_check, invoices]);
  
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setFieldErrors({ ...fieldErrors, [name]: !value.trim() });
  };
  const handleIssueChange = (date, dateString) => {
    if (date) {
      setFormState({ ...formState, issue_date: date.format('DD/MM/YYYY') });
      setFieldErrors({ ...fieldErrors, issue_date: !dateString.trim() });
    }
  };
  const handleCustomer_signature_date = (date, dateString) => {
    if (date) {
      setFormState({ ...formState, customer_signature_date: date.format('DD/MM/YYYY') });
      setFieldErrors({ ...fieldErrors, customer_signature_date: !dateString.trim() });
    }
  };
  const handleSeller_signature_date = (date, dateString) => {
    if (date) {
      setFormState({ ...formState, seller_signature_date: date.format('DD/MM/YYYY') });
      setFieldErrors({ ...fieldErrors, seller_signature_date: !dateString.trim() });
    }
  };
  const handleCustomerChange = (e) => {
    setCustomerDetails({ ...customerDetails, [e.target.name]: e.target.value });
    if (e.target.name === 'name') {
      setFieldErrors({ ...fieldErrors, customer_name: !e.target.value.trim() });
    }
  };
  const handleIssuerChange = (e) => {
    setIssuerDetails({ ...issuerDetails, [e.target.name]: e.target.value });
    if (e.target.name === 'name') {
      setFieldErrors({ ...fieldErrors, issuer_name: !e.target.value.trim() });
    }
    if (e.target.name === 'image_url') {
      setIssuerDetails({ ...issuerDetails, image_url: e.target.value });
    }
  };
  const handleInvoiceChange = (index, e) => {
    const newInvoices = [...invoices];
    const { name, value } = e.target;

    //Update
    newInvoices[index][name] = value;

    //preTaxAmount
    const unitPrice = parseFloat(newInvoices[index].unitPrice) || 0;
    newInvoices[index].preTaxAmount = unitPrice;

    setInvoices(newInvoices);
  };

  //ปรับความสูง textareas
  const adjustTextareaHeight = (el) => {
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach(adjustTextareaHeight);
  });

  const handleTextareaChange = (e) => {
    const textarea = e.target;
    if (textarea.scrollHeight > 22) {
      textarea.style.maxHeight = 'none';
    } else {
      textarea.style.maxHeight = '22px';
    }
  };

  const handleNumberChange = (index, name, value) => {
    handleInvoiceChange(index, { target: { name, value } });
    calculateTotal();
  };

  const addInvoiceRow = () => {
    setInvoices([...invoices, { no: invoices.length + 1, id: '', issuedate: '', duedate: '', unitPrice: 0, preTaxAmount: 0 }]);
  };

  const removeInvoiceRow = (index) => {
    const newInvoices = invoices.filter((_, i) => i !== index);
    // Update the numbers for all rows after deletion
    newInvoices.forEach((invoice, i) => {
      invoice.no = i + 1;
    });
    setInvoices(newInvoices);
  };

  const calculateTotal = () => {
    const total = invoices.reduce((total, invoice) => total + invoice.preTaxAmount, 0);
    const totalWithVat = formState.vat_check ? total * 1.07 : total;
    const vat = formState.vat_check ? totalWithVat - total : total;

    setTotalWithVat(formState.vat_check ? totalWithVat : total);
    setVat(formState.vat_check ? vat : total);
  };

  const [fieldErrors, setFieldErrors] = useState({
    customer_name: false,
    document_number: false,
    issuer_name: false,
  });

  const handleSave = async (e) => {
    e.preventDefault();
    const id = await onSubmit(e);
    if (id) {
      navigate('../manage-bill');
    }
    setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
  };

  const handlePrint = async () => {
    const id = await onSubmit(); // ให้ onSubmit() return ค่า _id
    return id;
  };
  
  const handleClick = async (e) => {
    e.preventDefault();
  
    const id = await handlePrint();
  
    if (id) {
      // เปิดหน้าต่างใหม่ก่อน
      const newWindow = window.open("", "_blank");
      // ตั้งค่า URL ของหน้าต่างใหม่เมื่อสำเร็จ
      newWindow.location.href = `../export-bill/${id}`;
      navigate(`../edit-bill/${id}`);
    } else {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };
  

  const onSubmit = async (e) => {
    setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

    // Validate fields
    const errors = {
      customer_name: !customerDetails.name.trim(),
      document_number: !formState.document_number.trim(),
      issuer_name: !issuerDetails.name.trim(),
    };
    setFieldErrors(errors);
    if (Object.values(errors).some(Boolean)) {
      message.error('กรุณากรอกข้อมูลที่จำเป็น');
      return false;
    }

    const formData = new FormData();
  
    // Add bill details
    formData.append('vat_check', formState.vat_check);
    formData.append('document_number', formState.document_number);
    formData.append('issue_date', formState.issue_date);
    formData.append('total_price', totalWithVat);
    formData.append('remark', formState.remark);
    
    formData.append('payment_method_cash', formState.payment_method_cash);
    formData.append('payment_method_bank', formState.payment_method_bank);
    formData.append('payment_method_check', formState.payment_method_check);
    formData.append('payment_method_credit', formState.payment_method_credit);
    formData.append('payment_details', formState.payment_details);

    formData.append('withholding_tax', formState.withholding_tax);
    formData.append('withholding_tax_details', formState.withholding_tax_details);

    // Add customer details
    formData.append('customer_name', customerDetails.name);
    formData.append('customer_address', customerDetails.address);
    formData.append('customer_tax_number', customerDetails.taxNumber);
    formData.append('customer_phone', customerDetails.phone);
    formData.append('customer_email', customerDetails.email);
    formData.append('customer_signature', customerDetails.customer_signature);
    formData.append('customer_signature_date', formState.customer_signature_date);

    // Add issuer details
    formData.append('issuer_name', issuerDetails.name);
    formData.append('issuer_tax_number', issuerDetails.taxNumber);
    formData.append('issuer_phone', issuerDetails.phone);
    formData.append('issuer_email', issuerDetails.email);
    formData.append('issuer_image_url', issuerDetails.image_url);
    formData.append('seller_signature', issuerDetails.seller_signature);
    formData.append('seller_signature_date', formState.seller_signature_date);

    // Add invoices
    formData.append('bill_invoice', JSON.stringify(invoices));

    // Add user ID
    formData.append('user', user._id);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
  
    try {
      const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/bill`, 
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );
      
      message.success('บันทึกเอกสารสำเร็จ');
      return response.data._id;
    } catch (err) {
      console.error('Error adding document:', err);
      message.error('เกิดข้อผิดพลาดในการบันทึกเอกสาร');
      return false;
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };

  const [form] = Form.useForm();

  const errorInputClass = 'input error';


  return (
    <>
      {user ? 
        <div className='md:block hidden sticky top-0 z-50'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }      

      <div className='flex justify-between '>
        <div>
          <Sidebar />
        </div>
        
        <div className='md:overflow-x-auto overflow-x-hidden'>
          <div className='flex flex-col mt-2 md:mx-2 md:w-[754px]'> 
            <div className='mt-3 ml-4 md:block hidden'>
              <Link to="../manage-bill" className='text-sm text-gray-600' > <LeftOutlined /> จัดการเอกสาร</Link>
              <h2 className='text-2xl mb-4 mt-2'>สร้างใบวางบิล</h2>
            </div>

            <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
              <Link to="../manage-bill" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
                <LeftOutlined />
              </Link>
              <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">สร้างใบวางบิล</h2>
            </div>
        
            <div className='flex md:flex-row flex-col justify-center mb-2 md:mx-0 mx-1 gap-1 md:mt-0 mt-[50px]'>
              <div className='min-w-80 mt-2'>
                <Collapse
                prefix={<UserOutlined />}
                items={[
                  {
                    key: '1',
                    label: (
                      <span>
                          <UserOutlined className='mr-2 scale-125' />
                          รายชื่อลูกค้า
                      </span>
                  ),
                    children: (
                      <>
                        <Input 
                          placeholder="ค้นหาชื่อลูกค้า"
                          prefix={<SearchOutlined className='mr-1 opacity-40' />}
                          value={customerSearchTerm}
                          onChange={handleCustomerSearch}
                          className='max-h-10 mb-2 cursor-text	'
                        />
                        <div className="customer-list max-h-[546px] overflow-hidden overflow-y-auto">
                          {filteredCustomers.map(customer => (
                            <div 
                              key={customer._id} 
                              className="flex customer-item hover:bg-gray-100 duration-150 active:duration-75 active:bg-gray-200 border rounded-lg my-0.5 cursor-pointer overflow-hidden"
                              onClick={() => handleCustomerSelect(customer)}
                            >
                              <div className='min-w-16 max-w-16 '>
                                <a>
                                  {customer.imageUrls  && customer.imageUrls[0]  ? (
                                    <img className="" src={getImageCustomerUrl(customer.imageUrls[0])} alt={customer.name} width="80" />
                                    ) : (
                                    <img className="" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`} width="80" />
                                  )}
                                </a>
                              </div>
                              <div className='flex flex-col text-xs max-h-16 max-w-52 '>
                                <label className='cursor-pointer ml-2 '>{customer.name}</label>
                                <label className='cursor-pointer ml-2 opacity-80 text-ellipsis overflow-hidden' style={{ fontSize: '10px' }}> <PhoneFilled className='opacity-80'/> {customer.phone}</label>
                                <label className='cursor-pointer ml-2 opacity-80 text-ellipsis overflow-hidden' style={{ fontSize: '10px' }}> <HomeFilled className='opacity-80'/> {customer.address}</label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ),
                  },
                ]}
                className='bg-gray-50 duration-300 hover:bg-gray-100'
                onChange={openCustomerModal}
                />

              </div>

              <div className='min-w-80 mt-2'>
                <Collapse
                  items={[
                    {
                      key: '2',
                      label: (
                        <span>
                            <ShopOutlined className='mr-2 scale-125' />
                            รายชื่อร้านค้า / ผู้ออก
                        </span>
                    ),
                      children: (
                        <>
                          <Input 
                            placeholder="ค้นหาชื่อร้านค้า" 
                            prefix={<SearchOutlined className='mr-1 opacity-40' />}
                            value={marketSearchTerm}
                            onChange={handleMarketSearch}
                            className='max-h-6 md:max-h-10 mb-2 cursor-text	'
                            />
                          <div className="market-list max-h-[546px] overflow-hidden overflow-y-auto">
                            {filteredMarkets.map(market => (
                              <div 
                                key={market._id} 
                                className="flex market-item hover:bg-gray-100 duration-150 active:duration-75 active:bg-gray-200 border rounded-lg my-0.5 cursor-pointer overflow-hidden"
                                onClick={() => handleMarketSelect(market)}
                              >
                                <div className='min-w-16 max-w-16 '>
                                  <a>
                                    {market.logoUrl  && market.logoUrl[0]  ? (
                                      <img className="" src={getImageMarketUrl(market.logoUrl[0])} alt={market.name} width="80" />
                                      ) : (
                                      <img className="" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`} width="80" />
                                    )}
                                  </a>
                                </div>
                                <div className='flex flex-col text-xs max-h-16 max-w-64 '>
                                  <label className='cursor-pointer ml-2 my-2'>{market.name}</label>
                                  <label className='cursor-pointer ml-2 opacity-80 text-ellipsis overflow-hidden' style={{ fontSize: '10px' }}>{market.description}</label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ),
                    },
                  ]}
                  className='bg-gray-50 duration-300 hover:bg-gray-100'
                  onChange={openMarketModal}
                />

              </div>
            </div>

            <div className='shrink-0 min-h-10 flex items-center md:my-4 my-2 md:mx-0 mx-1 bg-white rounded-full'>
              <Switch
                className='mx-2'
                name="vat_check"
                checked={formState.vat_check}
                onChange={(checked) => handleSwitchChange(checked, 'vat_check')}
                >
              </Switch>
              คำนวณภาษีมวลค่าเพิ่ม (Vat7%)
            </div>

            <Form form={form} onFinish={onSubmit} layout="vertical" className='md:block hidden' >
            <div id="print-section" className='bg-white shadow-lg rounded-lg p-4' name="ฟอร์มสำหรับปริ้น">

              <div className='flex justify-between' >
                <div className='grow'>
                  <h1 className='m-4 ml-0 mb-0 text-3xl grow'>ใบวางบิล</h1>
                  <h1 className='mb-4 text-2xl  grow'>Billing Note</h1>

                </div>
                <div className='flex justify-center min-w-60 max-w-full max-h-24 '>
                  <div className='max-w-20 scale-110'>
                    {logoUrl ? (
                        <Image
                            className=""
                            name="image_url"
                            src={getImageMarketUrl(logoUrl)}
                            alt={logoUrl}
                            width="120"
                            onChange={(e) => handleIssuerChange({ target: { name: 'image_url', value: logoUrl } })}
                        />
                    ) : ''}
                  </div>
                </div>
              </div>

{/* ลูกค้า และ เลขที่เอกสาร */}
              <section>
                <div className="flex flex-col gap-1">
                  <div className="flex justify-between w-full">
                    <div className="text-sm flex ">
                      <div>
                        ลูกค้า <label className="text-xs opacity-80 pr-4">/ Customer</label>
                      </div>                                
                      <Tooltip
                        title="กรุณากรอกชื่อลูกค้า"
                        open={fieldErrors.customer_name}
                        placement="topRight"
                      >
                        <textarea 
                          type="text"
                          name="name"
                          placeholder="ชื่อ"
                          value={customerDetails.name}
                          onChange={handleCustomerChange}
                          className={fieldErrors.customer_name ? 'ring-1 ring-red-600 input w-80 overflow-y-hidden' : 'input w-80 overflow-y-hidden'}
                          style={{whiteSpace: 'wrap', overflowWrap: 'break-word', resize: 'none' }}
                        />
                      </Tooltip>
                    </div>

                    <div className="text-sm">เลขที่เอกสาร 
                      <Tooltip
                        title="กรุณากรอกเลขที่เอกสาร"
                        open={fieldErrors.document_number}
                        placement="topRight"
                      >
                        <input
                          type="text"
                          name="document_number"
                          placeholder="ชื่อ"
                          value={formState.document_number}
                          onChange={handleFormChange}
                          className={fieldErrors.document_number ? 'ring-1 ring-red-600 input ml-2' : 'input ml-2'}
                        />
                      </Tooltip>

                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className='flex'>
                      <div className="pr-4 text-sm mt-0.5">ที่อยู่ <label className="text-xs opacity-80">/ Address</label></div>
                      <textarea 
                        type="text" name="address" placeholder="-" value={customerDetails.address} onChange={handleCustomerChange} 
                        className="input min-w-80 max-w-80 min-h-5 text-sm overflow-y-hidden"
                        style={{minHeight: '17px', whiteSpace: 'wrap', overflowWrap: 'break-word', resize: 'none' }}
                        />
                    </div>
                    <div className='ml-10'>
                      วันที่<label className="text-xs opacity-80"> / Issue</label>
                      <Tooltip
                        title="กรุณาเลือกวันที่"
                        open={fieldErrors.issue_date}
                        placement="topRight"
                      >
                        <DatePicker
                          locale={buddhistLocale}
                          type="date"
                          name="issue_date"
                          className={`text-sm  cursor-pointer max-h-6 input ${fieldErrors.issue_date ? 'ring-1 ring-red-600' : ''}`}
                          defaultValue={dayjs()} // Set default to current date
                          onChange={handleIssueChange}
                          required
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pr-4 text-sm mt-0.5">เลขผู้เสียภาษี <label className="text-xs opacity-80">/ Tax ID</label></div>
                    <input type="text" name="taxNumber" placeholder="-" value={customerDetails.taxNumber} onChange={handleCustomerChange} className="input mr-2 max-h-5"/>
                    
                    <div className="flex flex-col gap-1">
                      <div className="flex">
                        <div className="px-2 text-sm place-content-center">เบอร์โทร :</div><input type="tel" name="phone" placeholder="-" value={customerDetails.phone} onChange={handleCustomerChange} className="input"/>
                      </div>
                      <div className="flex">
                        <div className="px-2 text-sm place-content-center">อีเมล :</div><input type="text" name="email" placeholder="-" value={customerDetails.email} onChange={handleCustomerChange} className="input min-w-64 max-w-64"/>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div class="flex flex-row pb-1">
                <p class="line-through mt-1.5	w-full  border"></p>
              </div>

{/* ผู้ออก */}
              <section>
                <div className="flex justify-between">
                  <div className="flex">
                    <h2 className="pr-4 text-sm text-nowrap">ผู้ออก</h2>
                    <Tooltip
                        title="กรุณากรอกชื่อผู้ออก"
                        open={fieldErrors.issuer_name}
                        placement="topRight"
                      >
                        <input
                          type="text"
                          name="name"
                          placeholder="ชื่อ"
                          value={issuerDetails.name}
                          onChange={handleIssuerChange}
                          className={`input max-h-5 ${fieldErrors.issuer_name  ? 'ring-1 ring-red-600' : ''}`}
                        />
                    </Tooltip>
                  </div>

                  <div className="flex flex-col gap-1 ml-1">
                    <div className="flex ">
                      <div className="pr-4 text-sm mt-0.5">เลขผู้เสียภาษี <label className="text-xs opacity-80">/ Tax ID</label></div>
                      <input type="text" name="taxNumber" placeholder="-" value={issuerDetails.taxNumber} onChange={handleIssuerChange} className="input"/>
                    </div>
                    <div className="flex ">
                      <div className="pr-2 text-sm mt-0.5 text-nowrap">เบอร์โทร :</div>
                      <input type="tel" name="phone" placeholder="-" value={issuerDetails.phone} onChange={handleIssuerChange} className="input max-w-28"/>
                      <div className="pr-2 text-sm mt-0.5 ml-2 text-nowrap">อีเมล :</div>
                      <input type="text" name="email" placeholder="-" value={issuerDetails.email} onChange={handleIssuerChange} className="input min-w-64 max-w-64"/>
                    </div>
                  </div>
                </div>
                
              </section>

{/* เอกสาร */}
              <div className='text-center bg-gray-500 no-print mt-2' >
                <div class="flex flex-row ">
                  <p class="line-through mt-0.5	w-full  border"></p>
                </div>
                <label className='text-white'>รายการ</label>
                <div class="flex flex-row ">
                  <p class="line-through mt-0.5	w-full h-px border"></p>
                </div>
              </div>
              
              <section className='text-sm'>
                <table className='border-collapse border border-slate-600 mt-2 max-w-2	'>
                  <thead>
                    <tr className='text-xs'>
                      <td className=" border border-slate-600 min-w-14 max-w-14">
                        <div className=" ml-1 flex flex-col">
                          <label>ลำดับที่</label>
                          <label className="opacity-80">No.</label>
                        </div>
                      </td>
                      <td className="border border-slate-600 min-w-[160px] max-w-[160px]">
                        <div className="ml-1 flex flex-col">
                          <label>เลขที่เอกสาร</label>
                          <label className="opacity-80">ID no.</label>
                        </div>
                      </td>
                      <td className="border border-slate-600 min-w-[120px] max-w-[120px]">
                        <div className="ml-1 flex flex-col">
                          <label>วันที่เอกสาร</label>
                          <label className="opacity-80">Issue Date</label>
                        </div>
                      </td>
                      <td className="border border-slate-600 min-w-[120px] max-w-[120px]">
                        <div className="ml-1 flex flex-col">
                          <label>วันที่ครบกำหนด</label>
                          <label className="opacity-80">Due Date</label>
                        </div>
                      </td>
                      <td className="border border-slate-600 min-w-[120px] max-w-[120px]">
                        <div className="ml-1 flex flex-col">
                          <label>จำนวนเงินรวม</label>
                          <label className="opacity-80">Full Amount</label>
                        </div>
                      </td>
                      <td className="border border-slate-600 min-w-[121px] max-w-[121px]">
                        <div className="ml-1 flex flex-col">
                          <label>จำนวนเงินวางบิล</label>
                          <label className="opacity-80">Billing Amount</label>
                        </div>
                      </td>
                      <td className="border border-slate-600  no-print">
                        <div className="flex flex-col">
                          <label>ลบ</label>
                          <label className="opacity-80">Del.</label>
                        </div>
                      </td>
                    </tr>
                    {invoices.map((invoice, index) => (
                    <tr key={index} className="text-sm hover:bg-gray-300">
                      <td className='border border-slate-600 min-w-14 max-w-14 content-start'>
                        <input
                          type="text" name="no" placeholder="" 
                          value={invoice.no} 
                          className="input w-full break-words align-middle text-xxs overflow-y-hidden text-center"
                          style={{ maxHeight:'22px',minHeight: '22px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                          readOnly
                          />
                      </td>
                      <td className='border border-slate-600 min-w-[160px] max-w-[160px] content-start'>
                        <textarea 
                          type="text" name="id" placeholder="เลขที่เอกสาร" value={invoice.id}
                          onChange={(e) => {handleInvoiceChange(index, e); handleTextareaChange(e);}} 
                          className="input w-full break-words align-middle text-xxs overflow-y-hidden"
                          style={{ maxHeight:'22px',minHeight: '22px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                          />
                      </td>
                      <td className='border border-slate-600 min-w-[120px] max-w-[120px] content-start'>
                        <textarea 
                          type="text" name="issuedate" placeholder="วันที่ออก" value={invoice.issuedate}
                          onChange={(e) => {handleInvoiceChange(index, e); handleTextareaChange(e);}} 
                          className="input w-full break-words align-middle text-xxs overflow-y-hidden"
                          style={{ maxHeight:'22px',minHeight: '22px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                          />
                      </td>
                      <td className='border border-slate-600 min-w-[120px] max-w-[120px] content-start'>
                        <textarea 
                          type="text" name="duedate" placeholder="วันที่ครบกำหนด" value={invoice.duedate}
                          onChange={(e) => {handleInvoiceChange(index, e); handleTextareaChange(e);}} 
                          className="input w-full break-words align-middle text-xxs overflow-y-hidden"
                          style={{ maxHeight:'22px',minHeight: '22px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                          />
                      </td>
                      <td className='border border-slate-600 min-w-[120px] max-w-[120px] content-start'>
                        <InputNumber 
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          type="text"
                          name="unitPrice"
                          placeholder="จำนวนเงินรวม"
                          value={invoice.unitPrice}
                          onChange={(value) => handleNumberChange(index, 'unitPrice', value)}
                          className="custom-input-number font-right max-w-full text-xxs"
                        />
                      </td>
                      <td className='border border-slate-600 min-w-[121px] max-w-[121px] cursor-default content-start'>
                        <InputNumber 
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          type="text"
                          name="preTaxAmount"
                          placeholder="จำนวนเงินวางบิล"
                          value={invoice.preTaxAmount}
                          onChange={(value) => handleNumberChange(index, 'preTaxAmount', value)}
                          className="custom-input-number font-right  max-w-full cursor-default rounded-lg text-xxs"
                          readOnly
                        />   
                      </td>
                      <td className='border border-slate-600 no-print'>
                        <label onClick={() => removeInvoiceRow(index)}><DeleteFilled className="p-1 bg-red-300 hover:bg-red-400 hover:cursor-pointer hover:scale-110 active:scale-90 active:bg-red-500 rounded-md no-print transition duration-150 ease-in-out"/></label>
                      </td>
                    </tr>
                    ))}
                  </thead>
                </table>

                <div className='flex gap-2'>
                  <p onClick={openInvoiceModal} className="duration-150 ease-out mt-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:ring-2 p-0.5 px-1 rounded-lg no-print cursor-pointer">+เพิ่มจากรายการใบแจ้งหนี้</p>
                  <p onClick={addInvoiceRow}    className="duration-150 ease-out mt-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:ring-2 p-0.5 px-1 rounded-lg no-print cursor-pointer">+เพิ่ม</p>
                </div>

              </section>

{/* หมายเหตุ */}
              <section className='text-sm'>
                <div className='flex justify-between mt-2'>
                  <div className='min-w-64 max-w-64'>
                    <h2 className="text-sm mt-0.5 text-nowrap">หมายเหตุ <label className="text-xs opacity-80">/ Remark</label></h2>
                    <textarea 
                        placeholder="-"  
                        name="remark"
                        value={formState.remark}
                        onChange={handleFormChange}
                        className="input min-w-80 max-w-80 min-h-5 text-sm overflow-y-hidden"
                        style={{ minHeight: '0.5rem', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                        />
                  </div>

                  <div className='flex flex-col max-w-[400px]'>
                    <div>
                      {formState.vat_check ?
                      <div className='flex flex-nowrap grow justify-end'>
                        <h2 className='pr-4 text-nowrap'>ภาษีมูลค่าเพิ่ม (Vat) : </h2><p>{vat.toFixed(2)}</p><h2 className='pl-1'> บาท</h2>
                      </div>:''
                      }
                      <div className='flex flex-nowrap grow justify-end'>
                        <h2 className='pr-4'>จำนวนเงินรวมทั้งสิ้น : </h2><p>{totalWithVat.toFixed(2)}</p><h2 className='pl-1'> บาท</h2>
                      </div>
                      <input
                        type="hidden"
                        name="total_price"
                        value={totalWithVat}
                        onChange={(e) => handleFormChange({ target: { name: 'total_price', value: totalWithVat } })}
                      />
                    </div>
                  </div>

                </div>
              </section>

              <div class="flex flex-row pb-1">
                <p class="line-through mt-1.5	w-full h-px border"></p>
              </div>

              <section className=''>
                <div className='flex gap-2'>
                  <div className='flex flex-col'>

                    <h2 className=" mt-0.5 text-nowrap">การชำระเงิน <label className="text-xs opacity-80">/ Payment</label></h2>
                    <div className='flex opacity-80'>
                      <label className='flex items-center cursor-pointer'>
                        <input
                          type="checkbox"
                          name="payment_method_cash"
                          value={formState.payment_method_cash}
                          className="translate-y-0.5"
                          checked={formState.payment_method_cash}
                          onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_cash')}
                          style={{ display: 'none' }}
                        />
                        {formState.payment_method_cash ? (
                          <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" />
                        ) : (
                          <div className="mr-1 min-h-5 border-2 rounded " style={{ padding: '9px' }}></div>
                        )}
                        เงินสด
                      </label>
                      <label className='flex items-center ml-5 cursor-pointer'>
                        <input
                          type="checkbox"
                          name="payment_method_bank"
                          value={formState.payment_method_bank}
                          className="translate-y-0.5"
                          checked={formState.payment_method_bank}
                          onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_bank')}
                          style={{ display: 'none' }}
                        />
                        {formState.payment_method_bank ? (
                          <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" /> 
                        ) : ( 
                          <div className="mr-1 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>
                        )}
                        ธนาคาร
                      </label>
                      <label className='flex items-center ml-5 cursor-pointer'>
                        <input
                          type="checkbox"
                          name="payment_method_check"
                          value={formState.payment_method_check}
                          className="translate-y-0.5"
                          checked={formState.payment_method_check}
                          onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_check')}                        
                          style={{ display: 'none' }}
                        />
                        {formState.payment_method_check ? <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1  min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                        เช็ค
                      </label>
                      <label className='flex items-center ml-5 cursor-pointer text-nowrap'>
                        <input
                          type="checkbox"
                          name="payment_method_credit"
                          value={formState.payment_method_credit}
                          className="translate-y-0.5"
                          checked={formState.payment_method_credit}
                          onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_credit')}                        
                          style={{ display: 'none' }}
                        />
                        {formState.payment_method_credit ? <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                        บัตรเครดิต
                      </label>
                    </div>

                    <div className='flex mt-2 z-10'>
                      <label className='text-base opacity-80 text-nowrap'>ข้อมูลเพิ่มเติม </label>
                      <textarea
                        name="payment_details" 
                        value={formState.payment_details} 
                        placeholder=""
                        onChange={handleFormChange} 
                        className="input ml-2 min-w-[224px] min-h-5 overflow-y-hidden"
                        style={{minHeight: '17px', whiteSpace: 'wrap', overflowWrap: 'break-word', resize: 'none' }}
                      />
                    </div>
                    <label className='text-sm opacity-80 ml-[95px] -mt-3'>............................................................</label>

                  </div>

                  <div className='flex flex-row gap-3 mt-[80px] h-[99px]'>
                      <div className='flex flex-col w-[192px] overflow-x-hidden'>
                        <div className='z-10'>
                          <input type="name" name="customer_signature" placeholder="" value={customerDetails.customer_signature} onChange={handleCustomerChange} className="input w-full "/>
                        </div>
                        <label class="text-sm opacity-80 -mt-3">....................................................................</label>
                        <div className='flex flex-nowrap text-nowrap mb-2'>
                          วันที่ <label className="text-xs opacity-80 mx-1 mt-0.5">/ Date</label>
                          <Tooltip
                            title="กรุณาเลือกวันที่"
                            open={fieldErrors.customer_signature_date}
                            placement="topRight"
                          >
                            <DatePicker
                              locale={buddhistLocale}
                              type="date"
                              name="customer_signature_date"
                              className={`text-sm  cursor-pointer max-h-6 input`}
                              defaultValue={dayjs()} // Set default to current date
                              onChange={handleCustomer_signature_date}
                            />
                          </Tooltip>
                        </div>
                        <div className=' text-nowrap'>
                          ผู้อนุมัติสั่งซื้อ <label className="text-xs opacity-80">/ Purchase Approver</label>
                        </div>
                      </div>

                      <div className='flex flex-col w-[192px] overflow-x-hidden'>
                        <div className='z-10'>
                          <input type="name" name="seller_signature" placeholder="" value={issuerDetails.seller_signature} onChange={handleIssuerChange} className="input w-full"/>
                        </div>
                        <label class="text-sm opacity-80 -mt-3">....................................................................</label>
                        <div className='flex flex-nowrap text-nowrap mb-2'>
                          วันที่ <label className="text-xs opacity-80 mx-1 mt-0.5">/ Date</label>
                          <Tooltip
                            title="กรุณาเลือกวันที่"
                            open={fieldErrors.seller_signature_date}
                            placement="topRight"
                          >
                            <DatePicker
                              locale={buddhistLocale}
                              type="date"
                              name="seller_signature_date"
                              className={`text-sm  cursor-pointer max-h-6 input `}
                              defaultValue={dayjs()} // Set default to current date
                              onChange={handleSeller_signature_date}
                            />
                          </Tooltip>
                        </div>
                        <div className=' text-nowrap text-center'>
                          ผู้ออกใบวางบิล <label className="text-xs opacity-80">/ Billing Officer</label>
                        </div>
                      </div>
                  </div>
                </div>
              </section>

            </div>
            </Form>

            {/*mini*/}
            <Form form={form} onFinish={onSubmit} layout="vertical" className='md:hidden text-base' >
            <div id="print-section" className='bg-white shadow-lg rounded-lg pb-8 mb-12' name="ฟอร์มสำหรับปริ้น">

              <div className='flex justify-between' >
                <div className='grow'>
                  <h1 className='mt-4 ml-2 mb-0 text-3xl grow'>ใบวางบิล</h1>
                  <h1 className='mb-4 ml-2 text-2xl  grow'>Billing Note</h1>
                </div>

                <div className='max-w-full max-h-24'>
                  <div className='max-w-[100px] pr-4 pt-4'>
                    {logoUrl ? (
                        <Image
                            className=""
                            name="image_url"
                            src={getImageMarketUrl(logoUrl)}
                            alt={logoUrl}
                            width="120"
                            onChange={(e) => handleIssuerChange({ target: { name: 'image_url', value: logoUrl } })}
                        />
                    ) : ''}
                  </div>
                </div>
              </div>

{/* ลูกค้า และ เลขที่เอกสาร */}
              <section className=''>
                <div className="flex flex-col gap-1">
                  <div className=" text-nowrap w-full mx-4">เลขที่เอกสาร 
                    <Tooltip
                      title="กรุณากรอกเลขที่เอกสาร"
                      open={fieldErrors.document_number}
                      placement="topRight"
                    >
                      <input
                        type="text"
                        name="document_number"
                        placeholder="ชื่อ"
                        value={formState.document_number}
                        onChange={handleFormChange}
                        className={fieldErrors.document_number ? 'ring-1 ring-red-600 input-mini ml-2' : 'input-mini ml-2'}
                      />
                    </Tooltip>
                  </div>
                  
                  <div className='mx-4'>
                    <div className=''>
                      วันที่<label className="text-xs opacity-80"> / Issue</label>
                      <Tooltip
                        title="กรุณาเลือกวันที่"
                        open={fieldErrors.issue_date}
                        placement="topRight"
                      >
                        <DatePicker
                          locale={buddhistLocale}
                          type="date"
                          name="issue_date"
                          className={`text-sm  cursor-pointer max-h-6 input ${fieldErrors.issue_date ? 'ring-1 ring-red-600' : ''}`}
                          defaultValue={dayjs()} // Set default to current date
                          onChange={handleIssueChange}
                          required
                        />
                      </Tooltip>
                    </div>
                  </div>

                  <div className='bg-slate-100 p-2 rounded-md mt-2 mx-1'>
                    <div className="flex flex-col gap-1">
                      <div className="flex">
                        <div>
                          ลูกค้า <label className="text-xs opacity-80 pr-4">/ Customer</label>
                        </div>                      
                        <Tooltip
                          title="กรุณากรอกชื่อลูกค้า"
                          open={fieldErrors.customer_name}
                          placement="topRight"
                        >
                          <textarea 
                            type="text"
                            name="name"
                            placeholder="ชื่อ"
                            value={customerDetails.name}
                            onChange={handleCustomerChange}
                            className={fieldErrors.customer_name ? 'ring-1 ring-red-600 input-mini overflow-y-hidden w-[500px]' : 'input-mini w-[500px] overflow-y-hidden'}
                            style={{whiteSpace: 'wrap', overflowWrap: 'break-word', resize: 'none' }}                          
                          />
                        </Tooltip>
                      </div>

                      <div className='flex'>
                        <div className="pr-4 text-base mt-0.5">
                          ที่อยู่ <label className="text-xs opacity-80">/ Address</label>
                        </div>
                        <textarea 
                          type="text" name="address" placeholder="ที่อยู่" value={customerDetails.address} onChange={handleCustomerChange} 
                          className="input-mini text-base overflow-y-hidden w-[500px]"
                          style={{minHeight: '17px', whiteSpace: 'wrap', overflowWrap: 'break-word', resize: 'none' }}
                          />
                      </div>

                      <div className="flex">
                        <div className="pr-4 text-base mt-0.5"><label className='text-nowrap'>เลขผู้เสียภาษี</label> <label className="text-xs opacity-80">/ Tax ID</label></div>
                        <input type="text" name="taxNumber" placeholder="เลขผู้เสียภาษี" value={customerDetails.taxNumber} onChange={handleCustomerChange} className="input-mini w-[500px]"/>
                      </div>

                      <div className="flex">
                        <div className="text-base place-content-center pr-4 text-nowrap">เบอร์โทร</div><input type="tel" name="phone" placeholder="เบอร์โทร" value={customerDetails.phone} onChange={handleCustomerChange} className="input-mini w-[500px]"/>
                      </div>

                      <div className="flex">
                        <div className="text-base place-content-center pr-4">อีเมล </div><input type="text" name="email" placeholder="อีเมล" value={customerDetails.email} onChange={handleCustomerChange} className="input-mini w-[500px]"/>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


{/* ผู้ออก */}
              <section className='bg-gray-100 p-2 rounded-md mt-2 mx-1'>
                <div className="flex flex-col gap-1">
                  <div className="flex">
                    <h2 className="pr-4 text-base text-nowrap">ผู้ออก</h2>
                    <Tooltip
                        title="กรุณากรอกชื่อผู้ออก"
                        open={fieldErrors.issuer_name}
                        placement="topRight"
                      >
                        <input
                          type="text"
                          name="name"
                          placeholder="ชื่อ"
                          value={issuerDetails.name}
                          onChange={handleIssuerChange}
                          className={`input-mini ${fieldErrors.issuer_name  ? 'ring-1 ring-red-600 w-[500px]' : 'w-[500px]'}`}
                        />
                    </Tooltip>
                  </div>

                  <div className="flex ">
                    <div className="pr-4 text-base mt-0.5"><label className='text-nowrap'>เลขผู้เสียภาษี</label> <label className="text-xs opacity-80">/ Tax ID</label></div>
                    <input type="text" name="taxNumber" placeholder="เลขผู้เสียภาษี" value={issuerDetails.taxNumber} onChange={handleIssuerChange} className="input-mini w-[500px]"/>
                  </div>

                  <div className="flex ">
                    <div className="pr-2 text-base mt-0.5 text-nowrap">เบอร์โทร :</div>
                    <input type="tel" name="phone" placeholder="เบอร์โทร" value={issuerDetails.phone} onChange={handleIssuerChange} className="input-mini w-[500px]"/>
                  </div>

                  <div className="flex ">
                    <div className="pr-2 text-base mt-0.5 text-nowrap">อีเมล :</div>
                    <input type="text" name="email" placeholder="อีเมล" value={issuerDetails.email} onChange={handleIssuerChange} className="input-mini w-[500px]"/>
                  </div>
                </div>
                
              </section>

{/* เอกสาร */}
              <section className=' bg-slate-100 rounded-lg'>
                <div className='text-center bg-gray-500 py-2 rounded-lg no-print mt-4' >
                  <label className='text-white text-xl'>รายการ</label>
                </div>

                <div className='mt-2 w-full'>
                    {invoices.map((invoice, index) => (
                    <div key={index} className="flex flex-col bg-white hover:bg-gray-300 border rounded-md mb-2 p-1 gap-1">
                      <div className='grid grid-cols-6 gap-1'>
                        <div className='col-span-4 content-start'>
                          <input
                            type="text" name="id" placeholder="เลขที่เอกสาร" value={invoice.id}
                            onChange={(e) => {handleInvoiceChange(index, e); }} 
                            className="input-mini-invoice w-full break-words align-middle overflow-y-hidden"
                            />
                        </div>
                        <div className='col-span-2 content-start relative'>
                            <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none z-10">
                              จำนวนเงิน
                            </span>
                            <InputNumber 
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                              type="text"
                              name="unitPrice"
                              value={invoice.unitPrice}
                              onChange={(value) => handleNumberChange(index, 'unitPrice', value)}
                              className="custom-input-number-mini font-right max-w-full"
                            />
                          </div>
                      </div>

                      <div className='grid grid-cols-6 gap-1'>
                        <div className='col-span-2 content-start'>
                          <input
                            type="text" name="issuedate" placeholder="วันที่ออก" value={invoice.issuedate}
                            onChange={(e) => {handleInvoiceChange(index, e); }} 
                            className="input-mini-invoice w-full break-words align-middle overflow-y-hidden"
                            />
                        </div>
                        <div className='col-span-2 content-start'>
                          <input
                            type="text" name="duedate" placeholder="วันที่ครบกำหนด" value={invoice.duedate}
                            onChange={(e) => {handleInvoiceChange(index, e); }} 
                            className="input-mini-invoice w-full break-words align-middle overflow-y-hidden"
                            />
                        </div>

                        <div className='col-span-2 content-start'>
                          <div className='no-print text-end'>
                            <label onClick={() => removeInvoiceRow(index)}><DeleteFilled className="text-2xl p-2 bg-red-300 hover:bg-red-400 hover:cursor-pointer active:bg-red-500 rounded-md no-print transition duration-150 ease-in-out"/></label>
                          </div>
                        </div>
                      </div>

                      <div className='flex gap-1'>
                        <div className='grid grid-cols-11 gap-1'>

                          <div className='col-span-4 content-start relative hidden'>
                            <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none z-10">
                              จำนวนเงินรวม
                            </span>
                            <InputNumber 
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                              type="text"
                              name="unitPrice"
                              value={invoice.unitPrice}
                              onChange={(value) => handleNumberChange(index, 'unitPrice', value)}
                              className="custom-input-number-mini font-right max-w-full"
                            />
                          </div>

                          <div className='col-span-3 cursor-default'>
                            <InputNumber 
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                              type="text"
                              name="preTaxAmount"
                              placeholder="Pre-Tax Amount"
                              value={invoice.preTaxAmount}
                              onChange={(value) => handleNumberChange(index, 'preTaxAmount', value)}
                              className="hidden"
                              readOnly
                            />   

                          </div>

                        </div>
                      </div>
                    </div>
                    ))}
                </div>

                <div className='grid grid-cols-2 text-center gap-2 mx-2 pb-2'>
                  <p onClick={openInvoiceModal} className="py-2 duration-150 ease-out mt-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:ring-2 p-0.5 px-1 rounded-lg no-print cursor-pointer">+เพิ่มจากรายการใบแจ้งหนี้</p>
                  <p onClick={addInvoiceRow}    className="py-2 duration-150 ease-out mt-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:ring-2 p-0.5 px-1 rounded-lg no-print cursor-pointer">+เพิ่ม</p>
                </div>
              </section>

{/* การชำระเงิน */}
              <section className='mx-4'>
                <div className='flex flex-col mt-4 text-xl'>
                  {formState.vat_check ?
                  <div className='flex flex-nowrap grow justify-end mx-4'>
                    <h2 className='pr-4 text-nowrap'>ภาษีมูลค่าเพิ่ม (Vat) : </h2><p>{vat.toFixed(2)}</p><h2 className='pl-1'> บาท</h2>
                  </div>:''
                  }
                  <div className='flex flex-nowrap grow justify-end mx-4'>
                    <h2 className='pr-4'>จำนวนเงินรวมทั้งสิ้น : </h2><p>{totalWithVat.toFixed(2)}</p><h2 className='pl-1'> บาท</h2>
                  </div>
                  <input
                    type="hidden"
                    name="total_price"
                    value={totalWithVat}
                    onChange={(e) => handleFormChange({ target: { name: 'total_price', value: totalWithVat } })}
                  />
                </div>

                <div className='flex mt-8'>
                  <h2 className=" mt-0.5 text-nowrap">หมายเหตุ <label className="text-xs opacity-80">/ Remark</label></h2>
                  <textarea 
                    placeholder="-"  
                    name="remark"
                    value={formState.remark}
                    onChange={handleFormChange}
                    className="input-mini ml-2 min-h-5 overflow-y-hidden w-[500px]"
                    style={{ minHeight: '0.5rem', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', resize: 'none' }}
                  />
                </div>
              </section>

              <div class="flex flex-row pb-1">
                <p class="line-through mt-1.5	w-full h-px border"></p>
              </div>

              <section className='mx-4'>
                <div className='flex flex-col'>

                  <h2 className=" mt-0.5 text-nowrap">การชำระเงิน <label className="text-xs opacity-80">/ Payment</label></h2>
                  <div className='flex opacity-80'>
                    <label className='flex items-center cursor-pointer'>
                      <input
                        type="checkbox"
                        name="payment_method_cash"
                        value={formState.payment_method_cash}
                        className="translate-y-0.5"
                        checked={formState.payment_method_cash}
                        onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_cash')}
                        style={{ display: 'none' }}
                      />
                      {formState.payment_method_cash ? (
                        <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" />
                      ) : (
                        <div className="mr-1 min-h-5 border-2 rounded " style={{ padding: '9px' }}></div>
                      )}
                      เงินสด
                    </label>
                    <label className='flex items-center ml-5 cursor-pointer'>
                      <input
                        type="checkbox"
                        name="payment_method_bank"
                        value={formState.payment_method_bank}
                        className="translate-y-0.5"
                        checked={formState.payment_method_bank}
                        onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_bank')}
                        style={{ display: 'none' }}
                      />
                      {formState.payment_method_bank ? (
                        <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" /> 
                      ) : ( 
                        <div className="mr-1 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>
                      )}
                      ธนาคาร
                    </label>
                    <label className='flex items-center ml-5 cursor-pointer'>
                      <input
                        type="checkbox"
                        name="payment_method_check"
                        value={formState.payment_method_check}
                        className="translate-y-0.5"
                        checked={formState.payment_method_check}
                        onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_check')}                        
                        style={{ display: 'none' }}
                      />
                      {formState.payment_method_check ? <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1  min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                      เช็ค
                    </label>
                    <label className='flex items-center ml-5 cursor-pointer'>
                      <input
                        type="checkbox"
                        name="payment_method_credit"
                        value={formState.payment_method_credit}
                        className="translate-y-0.5"
                        checked={formState.payment_method_credit}
                        onChange={(e) => handleSwitchChange(e.target.checked, 'payment_method_credit')}                        
                        style={{ display: 'none' }}
                      />
                      {formState.payment_method_credit ? <CheckOutlined className="mr-1 min-h-5 border-2 rounded p-0.5" /> : <div className="mr-1 min-w-5 min-h-5 border-2 rounded " style={{padding: '9px'}} ></div>}
                      บัตรเครดิต
                    </label>
                  </div>

                  <div className='flex mt-2 z-10'>
                    <label className='text-base opacity-80 text-nowrap'>ข้อมูลเพิ่มเติม </label>
                    <textarea
                      name="payment_details" 
                      value={formState.payment_details} 
                      placeholder=""
                      onChange={handleFormChange} 
                      className="input-mini ml-2 min-h-5 overflow-y-hidden w-[500px]"
                      style={{minHeight: '17px', whiteSpace: 'wrap', overflowWrap: 'break-word', resize: 'none' }}
                    />
                  </div>

                </div>
              </section>

              <div className='flex gap-3 mt-[40px] mx-2'>
                <div className='flex flex-col w-[200px] overflow-x-hidden'>
                  <div className='z-10'>
                    <input type="name" name="customer_signature" placeholder="" value={customerDetails.customer_signature} onChange={handleCustomerChange} className="input-mini w-full"/>
                  </div>
                  <label class="text-sm opacity-80 -mt-2">....................................................................</label>
                  <div className='flex flex-nowrap text-nowrap mb-2'>
                    วันที่ <label className="text-xs opacity-80 mx-1 mt-0.5">/ Date</label>
                    <Tooltip
                      title="กรุณาเลือกวันที่"
                      open={fieldErrors.customer_signature_date}
                      placement="topRight"
                    >
                      <DatePicker
                        locale={buddhistLocale}
                        type="date"
                        name="customer_signature_date"
                        className={`text-sm  cursor-pointer max-h-6 input`}
                        defaultValue={dayjs()} // Set default to current date
                        onChange={handleCustomer_signature_date}
                      />
                    </Tooltip>
                  </div>
                  <div className=' text-nowrap'>
                    ผู้อนุมัติสั่งซื้อ <label className="text-xs opacity-80">/ Purchase Approver</label>
                  </div>
                </div>

                <div className='flex flex-col w-[200px] overflow-x-hidden'>
                  <div className='z-10'>
                    <input type="name" name="seller_signature" placeholder="" value={issuerDetails.seller_signature} onChange={handleIssuerChange} className="input-mini w-full"/>
                  </div>
                  <label class="text-sm opacity-80 -mt-2">....................................................................</label>
                  <div className='flex flex-nowrap text-nowrap mb-2'>
                    วันที่ <label className="text-xs opacity-80 mx-1 mt-0.5">/ Date</label>
                    <Tooltip
                      title="กรุณาเลือกวันที่"
                      open={fieldErrors.seller_signature_date}
                      placement="topRight"
                    >
                      <DatePicker
                        locale={buddhistLocale}
                        type="date"
                        name="seller_signature_date"
                        className={`text-sm  cursor-pointer max-h-6 input `}
                        defaultValue={dayjs()} // Set default to current date
                        onChange={handleSeller_signature_date}
                      />
                    </Tooltip>
                  </div>

                  <div className=' text-nowrap'>
                    ผู้ออกใบวางบิล <label className="text-xs opacity-80">/ Billing Officer</label>
                  </div>
                </div>
              </div>

              </div>
            </Form>

              <div className='flex justify-end mt-4 md:mb-24 mr-3'>
                <div className="hidden md:block">
                  <button 
                    onClick={handleSave} 
                    disabled={isSubmitting}
                    className="bg-emerald-400 duration-150 ease-out hover:bg-emerald-500 active:bg-emerald-800 active:ring-2 md:p-2 md:px-4 p-1 px-2  rounded-lg text-white w-[99px]">
                    <SaveOutlined  className="pr-2 md:scale-125 text-white"/> {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                  </button>
                </div>
                <div className="ml-2 hidden md:block">
                  <button 
                    onClick={handleClick} 
                    disabled={isSubmitting}
                    className="transition bg-blue-500 duration-150 ease-out hover:bg-blue-600 active:bg-blue-700 active:ring-2 md:p-2 md:px-4 p-1 px-2  rounded-lg text-white w-[175px]">
                    <PrinterFilled className="pr-2 md:scale-125 text-white"/>{isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก/ปริ้นเอกสาร"}
                  </button>
                </div>
              </div>

              {/* ปุ่ม mini */}
              <div className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3 z-50 text-xl' style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}>
                <div className="grid grid-cols-3 gap-2">
                  <button onClick={handleSave} 
                    disabled={isSubmitting}
                    className="py-2 bg-emerald-400 duration-150 ease-out hover:bg-emerald-500 active:bg-emerald-800 active:ring-2 md:p-2 md:px-4 p-1 px-2  rounded-lg text-white">
                    <SaveOutlined  className="pr-2 md:scale-125 text-white"/> {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                  </button>

                  <button
                    onClick={handleClick} 
                    disabled={isSubmitting}
                    className="py-2 col-span-2 justify-center transition bg-blue-500 duration-150 ease-out hover:bg-blue-600 active:bg-blue-700 active:ring-2 md:p-2 md:px-4 p-1 px-2 rounded-lg text-white flex items-center"
                  >
                    <PrinterFilled className="pr-2 md:scale-125 text-white" />{isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก/ปริ้นเอกสาร"}
                  </button>
                </div>
              </div>

              <Modal
                title="เลือกใบแจ้งหนี้"
                open={showInvoiceModal}
                onCancel={() => setShowInvoiceModal(false)}
                footer={null}
              >
                <Input 
                  placeholder="ค้นหาใบแจ้งหนี้" 
                  value={searchTerm}
                  onChange={handleSearch}
                  className='max-h-6 md:max-h-10'
                />
                <div className="invoice-list">
                  {filteredInvoices.map(invoice => (
                    <div key={invoice._id}  className="flex invoice-item hover:bg-gray-100 border rounded-lg my-2 cursor-pointer overflow-hidden">
                      <div className='flex flex-col grow content-center' onClick={() => handleInvoiceSelect(invoice)}>
                        <div className='flex justify-between'>
                          <label className='cursor-pointer ml-2' >{invoice.customer_name} </label>    
                          <label className='cursor-pointer mr-2' ><ShopOutlined className='pl-2'/> {invoice.issuer_name} </label>  
                        </div>
                        <div className='flex justify-between'>
                          <label className='cursor-pointer ml-2 text-blue-400' >{invoice.document_number} </label>    
                        </div>
                        <div className='flex justify-between'>
                          <div>
                            <label className='cursor-pointer ml-2 -mt-0.5 opacity-80' >(วันที่ออก : {invoice.issue_date} ,</label>
                            <label className='cursor-pointer ml-1 -mt-0.5 opacity-80' >ครบกำหนด : {invoice.valid_till_date}) </label>
                          </div>
                          <label className='cursor-pointer mr-2  text-emerald-600' >ราคารวม {invoice.price} </label>   
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Modal>

          </div>
          

        </div>

        <div></div>
      </div>
    </>

  );
}

export default AddBill;
