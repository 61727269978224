import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { LeftOutlined ,PlusOutlined ,TikTokOutlined ,UploadOutlined , MailOutlined,PhoneOutlined,LinkOutlined ,FacebookFilled} from '@ant-design/icons';
import { FloatButton,Button, Form, Input, Upload, message ,Spin,Modal} from 'antd';
import { Radio , List ,ListItem ,ListItemPrefix ,Typography  } from "@material-tailwind/react";
import ImgCrop from 'antd-img-crop';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { LoadingOutlined } from "@ant-design/icons";
import lineIcon from '../../assets/lineLogo1.png';
import facebookIcon from '../../assets/facebookLogo2.png';
import instagramIcon from '../../assets/igLogo2.png';
import tiktokIcon from '../../assets/Tiktok_logo2.png';


function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [category, setCategory] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  useEffect(() => {
    
    //ดึงข้อมูลร้านค้าขึ้นมาแสดง
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/category/from_id_category/${id}`);
        const category = response.data;
        setCategory(category);

        const sanitizedCategory = {
          ...category,
          description: category.description === "undefined" ? "" : category.description,

        };
        setCategory(sanitizedCategory);

        form.setFieldsValue({
          name: category.name,
          description: sanitizedCategory.description,
        });

  
      } catch (err) {
        console.error('Error fetching category:', err);
      }
    };
  
    fetchCategory();

    if (user === null) return; // รอข้อมูลuser

  }, [id, form ,navigate,user]);

  if (!user) {
    navigate('../manage-product');
  }

  const onSubmit = async (values) => {
    setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('updatedAt', new Date().toISOString());

    try {
      const response = await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/category/${id}`,
        formData,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      message.success("แก้ไขข้อมูลประเภทสินค้าสำเร็จ");
      navigate('/manage-product');
    } catch (err) {
      console.error('Error updating category:', err);
      message.error("แก้ไขข้อมูลประเภทสินค้าไม่สำเร็จ");
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };

  if (!category) {
    return (
      <>
      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }

  return (
    <>
      {user ? 
        <div className='md:block hidden sticky top-0 z-50'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }      

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 md:mb-28 mb-10' style={{width: "48rem"}}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-product" className='text-sm text-gray-600' > <LeftOutlined /> จัดการผลิตภัณฑ์</Link>
            <h2 className='text-2xl mb-4 mt-2'>แก้ไขประเภทสินค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-product" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">แก้ไขประเภทสินค้า</h2>
          </div>

          <Form form={form} onFinish={onSubmit} layout="vertical" className='md:mt-0 mt-[62px]'>
            <div className='bg-white p-6 rounded-lg'>

              <Form.Item
                label="ชื่อประเภทสินค้า"
                name="name"
                rules={[{ required: true, message: 'กรุณาใส่ชื่อประเภทสินค้า' }]}
              >
                <Input placeholder='กรุณาใส่ชื่อประเภทสินค้า' />
              </Form.Item>

              <Form.Item label="คำอธิบายประเภทสินค้า" name="description" className='mt-4'>
                <TextArea placeholder='ป้อนคำอธิบายประเภทสินค้า' />
              </Form.Item>

            </div>

            <Form.Item>
              <div className='flex justify-end mt-4'>
                <button 
                  htmlType="submit" 
                  disabled={isSubmitting}
                  className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
            >
              <div className=''>
                <button 
                  type="submit"
                  disabled={isSubmitting}
                  className= 
                    "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"> 
                    {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                  </button>
              </div>
            </Form.Item>

          </Form>


        </div>

        <div></div>
      </div>
    </>
  );
}


export default EditCategory;