import React from 'react';
import { Link } from 'react-router-dom';

function Error404() {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h1 className="text-6xl font-bold ">404</h1>
          <p className="text-2xl mt-4">Page Not Found</p>
          <p className="text-lg mt-4">โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต และลองใหม่อีกครั้ง</p>
          <Link to="/" className="mt-6 inline-block text-blue-500">
            Go back to home
          </Link>
        </div>
      </div>
    );
  }
  
  export default Error404;