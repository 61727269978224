import React, { useEffect , useState} from 'react';
import axios from 'axios';
import { Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { Link , useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { FloatButton , message, Form, Input, Button,Spin  } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { LoadingOutlined } from "@ant-design/icons";

import './styles/add-market.css'


const { TextArea } = Input;

function AddCategory() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // สถานะการโหลด
  const [isSubmitting, setIsSubmitting] = useState(false); // เพิ่ม state สำหรับปุ่มบันทึก

  useEffect(() => {
    if (user !== undefined) {
      setLoading(false); // หยุดการโหลดเมื่อ user ถูกกำหนดค่าแล้ว
    }
    if (!user && !loading) {
      navigate('../manage-product');
    }

  }, [user, loading, navigate]);

  const onSubmit = async (data) => {
    try {
        setIsSubmitting(true); // เปิดสถานะกำลังบันทึก

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('user', user._id);

        console.log('FormData entries:', Array.from(formData.entries()));

        await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/category`,
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
        );

        message.success('เพิ่มประเภทสินค้าสำเร็จ');
        navigate('../manage-product');
    } catch (err) {
        console.error('Error adding category:', err);
        if (err.response) {
            message.error(`Error: ${err.response.data.message || 'Network error'}`);
        } else {
            message.error('Failed to add category. Please check your network connection.');
        }
    } finally {
      setIsSubmitting(false); // ปิดสถานะกำลังบันทึก
    }
  };

  return (
    <>
      {user ? 
        <div className='md:block hidden sticky top-0 z-50'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink flex-initial mx-1 md:mb-28 mb-5' style={{width: "48rem"}}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-product" className='text-sm text-gray-600' > <LeftOutlined /> จัดการผลิตภัณฑ์</Link>
            <h2 className='text-2xl mb-4 mt-2'>เพิ่มประเภทสินค้า</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-product" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">เพิ่มประเภทสินค้า</h2>
          </div>
          
            <Form layout="vertical" onFinish={onSubmit} className='md:mt-0 mt-[62px]'>
            <div className='mt-4 bg-white p-6 rounded-lg'>
                <Form.Item label="ชื่อประเภทสินค้า" name="name" className='' rules={[{ required: true, message: 'กรุณาใส่ชื่อประเภทสินค้า' }]}>
                  <Input 
                    placeholder='ชื่อประเภทสินค้า'
                  />
                </Form.Item>

                <Form.Item label="คำอธิบายประเภทสินค้า" name="description" className='mt-5'>
                  <TextArea 
                    placeholder='คำอธิบายประเภทสินค้า'
                  />
                </Form.Item>
              </div>
              

              <Form.Item className='flex justify-end mt-5'>
                <button 
                htmlType="submit" 
                disabled={isSubmitting}
                className='px-8 py-2 text-white rounded-lg bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition hidden md:block w-40'>
                  {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                </button>
              </Form.Item>

              <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	 pt-3 pb-3 px-3'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
              >
                <div className=''>
                  <button 
                    type="submit"
                    disabled={isSubmitting}
                    className= 
                      "text-xl px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 active:bg-blue-700 w-full"
                    > 
                      {isSubmitting ? <Spin indicator={<LoadingOutlined />} /> : "บันทึก"}
                    </button>
                </div>
              </Form.Item>
              

            </Form>
          
        </div>

        <div className=''></div>
      </div>

    </>
  );


}

export default AddCategory;
