import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Nav,NavNoUser,Sidebar,BottomNav } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Link, useNavigate } from 'react-router-dom';
import {LoginAlert} from '../../components/Alert.jsx';
import { Pagination, message, Popconfirm, Input, Select , Modal, Tag, Dropdown,Image, Tooltip, Skeleton,Spin , FloatButton } from 'antd';

import { QuestionCircleOutlined , CaretDownOutlined , DownOutlined ,CaretUpOutlined , EyeOutlined  } from '@ant-design/icons';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

import "./styles/manage-product.css";

const { Option } = Select;
const { Search } = Input;

function ManageProduct() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const userId = user ? user._id : null;
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [market, setMarket] = useState('');
  const [category, setCategory] = useState('');
  const [markets, setMarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('default');
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(true); // สถานะการโหลด

  const fetchProducts = async () => {
    if (user && user._id) {
        setLoading(true); // เริ่มโหลด
        try {
          const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/product`, {
            params: { userId: user._id } // ส่ง userId เป็น parameter
          });

          if(response.data.length === 0){
            setData(false)
          }

          // ดึงข้อมูลร้านค้าสำหรับแต่ละสินค้า
          const productsWithMarket = await Promise.all(response.data.map(async (product) => {
            if (product.market) {
              try {
                const marketResponse = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market/from_id_market/${product.market}`);
                product.market = marketResponse.data; // อัพเดตข้อมูลตลาด
              } catch (error) {
                console.error('Error fetching market:', error);
              }
            }
            return product;
          }));

          const sortedProducts = productsWithMarket.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
          setProducts(sortedProducts);
          setFilteredProducts(sortedProducts);
        } catch (err) {
            console.error('Error fetching products:', err);
        } finally {
          setLoading(false); // โหลดเสร็จ
        }
    }
  };
  function getImageMarketUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }
  function getImageProductUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_PRODUCT_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/product`;
    return `${basePath}/${filename}`;
  }

  const fetchMarkets = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, {
        params: { userId: user._id }
      });
      if (Array.isArray(response.data)) {
        setMarkets(response.data);
      } else {
        console.error('Fetched markets are not an array:', response.data);
      }
    } catch (err) {
      console.error('Error fetching markets:', err);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/category`, {
        params: { userId: user._id }
      });
      if (Array.isArray(response.data)) {
        setCategories(response.data);
      } else {
        console.error('Fetched categories are not an array:', response.data);
      }
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  useEffect(() => {
      const fetchData = async () => {
        await fetchProducts();
        await fetchMarkets();
        await fetchCategories();
        setLoading(false); // ปิดสถานะโหลดเมื่อข้อมูลพร้อม
      };
      fetchData();
  }, [user]);

 
  const handleBulkDelete = async () => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/product/bulk-delete`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },
            data: { productIds: selectedProducts }
        }
      );

      setProducts(products.filter(product => !selectedProducts.includes(product._id)));
      setSelectedProducts([]);
      navigate(0)
      message.success("ลบสินค้าสำเร็จ");
    } catch (err) {
      console.error('Error deleting products:', err);
    }
  };

  const handleDelete = async (id, imageUrls) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/product/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            },
            data: { imageUrls }
        }
      );

      setProducts(products.filter(product => product._id !== id));
      navigate(0)
      message.success("ลบสินค้าสำเร็จ")
    } catch (err) {
      console.error('Error deleting product:', err);
    }
  };

  const handleDetail = (id_text) => {
    const url = `/product/${id_text}`;
    window.open(url, '_blank');
  };

  const handleEdit = (id) => {
    navigate(`/edit-product/${id}`);
  };

  const handleSelectProduct = (id) => {
    setSelectedProducts(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(productId => productId !== id)
        : [...prevSelected, id]
    );
  };
  const handleSelectAll = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map(product => product._id));
    }
  };

  const handleItemsPerPageChange = (current, size) => {
    setItemsPerPage(size);
    setCurrentPage(1); // Reset to the first page when items per page change
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    filterProducts(value, market, category);
  };
  const handleMarketChange = (value) => {
    setMarket(value);
    filterProducts(searchQuery, value, category);
  };
  const handleCategoryChange = (value) => {
    setCategory(value);
    filterProducts(searchQuery, market, value);
  };

  const handleSortByCost = () => {
    let newSortOrder;
    if (sortOrder === 'default') newSortOrder = 'asc';
    else if (sortOrder === 'asc') newSortOrder = 'desc';
    else newSortOrder = 'default';
    setSortOrder(newSortOrder);

    const sortedProducts = [...filteredProducts];
    if (newSortOrder === 'asc') {
      sortedProducts.sort((a, b) => a.cost - b.cost);
    } else if (newSortOrder === 'desc') {
      sortedProducts.sort((a, b) => b.cost - a.cost);
    } else {
      sortedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    setFilteredProducts(sortedProducts);
  };
  const handleSortByPrice = () => {
    let newSortOrder;
    if (sortOrder === 'default') newSortOrder = 'ascp';
    else if (sortOrder === 'ascp') newSortOrder = 'descp';
    else newSortOrder = 'default';
    setSortOrder(newSortOrder);
  
    const sortedProducts = [...filteredProducts];
    if (newSortOrder === 'ascp') {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (newSortOrder === 'descp') {
      sortedProducts.sort((a, b) => b.price - a.price);
    } else {
      sortedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    setFilteredProducts(sortedProducts);
  };
  const handleSortByTime = () => {
    let newSortOrder;
    if (sortOrder === 'default') newSortOrder = 'asct';
    else if (sortOrder === 'asct') newSortOrder = 'desct';
    else newSortOrder = 'default';
    setSortOrder(newSortOrder);
  
    const sortedProducts = [...filteredProducts];
    if (newSortOrder === 'asct') {
      sortedProducts.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    } else if (newSortOrder === 'desct') {
      sortedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    } else {
      sortedProducts.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    setFilteredProducts(sortedProducts);
  };


  const filterProducts = (search, market , category) => {
    let filtered = products;

    if (search) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(search.toLowerCase()) ||
        product.sku.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (market) {
      filtered = filtered.filter(product => product.market._id === market);
    }

    if (category) {
      filtered = filtered.filter(product => product.category === category);
    }

    setFilteredProducts(filtered);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedProductsPage = filteredProducts.slice(startIndex, startIndex + itemsPerPage);

  /*if (user && products.length === 0 && data) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }*/

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

      // ฟังก์ชันสำหรับเปิดใช้งาน
      const handleEnable = async (productId)  => {
        try {
          await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/product/status/${productId}`,
            { status: true },
              {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                  }
              }
          );

          fetchProducts();
          message.success("เปิดใช้งานสินค้าสำเร็จ")
        } catch (error) {
            console.error(`Error enabling product with ID: ${productId}`, error);
            message.error("เปิดใช้งานสินค้าไม่สำเร็จ")
        }
    };

    // ฟังก์ชันสำหรับปิดใช้งาน
    const handleDisable = async (productId) => {
        try {
          await axios.put(`${import.meta.env.VITE_BACKEND_URL}api/product/status/${productId}`,
            { status: false },
              {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                  }
              }
          );
          
          fetchProducts();
          message.success("ปิดใช้งานสินค้าสำเร็จ")
        } catch (error) {
            console.error(`Error enabling product with ID: ${productId}`, error);
            message.error("ปิดใช้งานสินค้าไม่สำเร็จ")
        }
    };

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}

      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>

        <div className='overflow-x-hidden overflow-y-hidden	custom-scrollbar hidden md:block'>
          <LoginAlert />

          <div className='hidden md:block '>

            <div className='grid grid-cols-4 gap-2 mt-2 mx-1 ml-6 text-sm'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2 px-2 hover:bg-gray-50 hover:ring-gray-400 hover:ring shadow	 hover:cursor-pointer select-none '
              onClick={showModal}
              >
                <div className='bg-blue-100 rounded-full p-3  aspect-square mr-3' style={{width:'44px' , height:'44px'}}><EyeOutlined  className='w-10 text-blue-500 mx-3 mt-1' style={{transform:"scale(1.7)"}}/></div>
                ดูหน้าแสดงสินค้า
              </div>
            </div>

            <div className='flex justify-between '>
              <div>
                <h2 className='text-2xl mt-6 mb-2 pl-4'>สินค้าของฉัน</h2>
              </div>

              <div className='flex mt-4 mb-3 mr-2'>
                <div>
                {user ? 
                <div className='flex'>
                  <Link to="/add-product" >
                    <button className='
                        py-2 px-4 rounded
                        bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition text-white text-sm'
                      type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มสินค้าใหม่
                    </button>
                  </Link>
                </div>
                : 
                <button 
                  className='
                    my-6 py-2 px-4 rounded
                    bg-blue-300 text-white text-sm cursor-not-allowed'
                  type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มสินค้าใหม่
                </button>
                }
                </div>
              </div>
            </div>

            <div className='flex px-4 mb-6 bg-white py-4 rounded-lg mx-1 border'>
                <Search
                  placeholder="ค้นหาชื่อสินค้า,SKU สินค้า"
                  allowClear
                  style={{ width: 300, marginRight: 10 }}
                  onSearch={handleSearch}
                  value={searchQuery}
                  onChange={e => handleSearch(e.target.value)}
                />

                <Select
                  placeholder="เลือกร้านค้า"
                  value={market}
                  onChange={handleMarketChange}
                  style={{ width: 200 }}
                  className='h-[31.8px]'
                >
                  <Option value="">ร้านค้าทั้งหมด</Option>
                  {markets.map(market => (
                    <Option key={market._id} value={market._id}>{market.name}</Option>
                  ))}
                  <Select.Option key="add-market" value="add-market" >
                      <Link to="/add-market" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มร้านค้า</Link>
                  </Select.Option>
                </Select>

                <Select
                  placeholder="เลือกประเภทสินค้า"
                  value={category}
                  onChange={handleCategoryChange}
                  style={{ width: 200 }}
                  className='h-[31.8px] ml-2'
                >
                  <Option value="">ประเภทสินค้าทั้งหมด</Option>
                    {categories.map(category => (
                      <Option key={category._id} value={category._id}>{category.name}</Option>
                    ))}
                    <Select.Option key="add-category" value="add-category" >
                        <Link to="/add-category" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มประเภทสินค้า</Link>
                    </Select.Option>
                </Select>

                <div>
                  <Popconfirm
                    title="ยืนยันการลบผลิตภัณฑ์"
                    description="คุณต้องการลบผลิตภัณฑ์ที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={handleBulkDelete}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                    disabled={selectedProducts.length === 0}
                  >
                    {selectedProducts.length === 0? (
                      <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                    ) : (
                      <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                    )}
                  </Popconfirm>
                  <label className='text-xs ml-2 text-nowrap opacity-50'>เลือกแล้ว {selectedProducts.length} รายการ</label>
                </div>
            </div>

            <div className="hidden md:block grid-cols-1 gap-2">
              <div className=''>
                <div>
                  <div className='flex flex-row  ml-4 text-sm text-gray-500'>
                    <div className='w-8'>
                      <input
                        type="checkbox"
                        checked={selectedProducts.length === products.length}
                        onChange={handleSelectAll}
                        className='cursor-pointer transform scale-125'
                      />
                      
                    </div>
                    <div className='w-72'>สินค้า</div>
                    <div className='w-44'>SKU</div>
                    <div className='w-28 cursor-pointer flex' onClick={handleSortByCost}>ต้นทุน
                      {sortOrder === 'asc' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  

                      : sortOrder === 'desc' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 

                      : <div className='flex flex-col pl-1 	opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                    </div>
                    <div className='w-28 cursor-pointer flex' onClick={handleSortByPrice}>ราคาขาย
                      {sortOrder === 'ascp' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  

                      : sortOrder === 'descp' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 

                      : <div className='flex flex-col pl-1 	opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                    </div>
                    <div className='w-32'>ร้านค้า</div>
                    <div className='w-28 cursor-pointer flex' onClick={handleSortByTime}>อัปเดตแล้ว
                      {sortOrder === 'asct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  

                      : sortOrder === 'desct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 

                      : <div className='flex flex-col pl-1 	opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                    
                    </div>
                    <div className='w-18'>ดำเนินการ</div>
                  </div>
                </div>

                <div className='text-sm'>
                  {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[102px]">
                          <Skeleton.Avatar active size="large" shape="square" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 1 }} />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : products.length === 0 ? (
                    <div className="bg-white p-4 mt-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                    // แสดงสินค้าจริงเมื่อโหลดเสร็จ
                    selectedProductsPage.map(product => (
                      <div key={product._id} className={`flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg hover:shadow ${selectedProducts.includes(product._id) ? 'bg-indigo-50' : 'bg-white'}`}>
                        <div className="w-[30px] min-w-1 max-w-8">
                          <input
                            type="checkbox"
                            checked={selectedProducts.includes(product._id)}
                            onChange={() => handleSelectProduct(product._id)}
                            className="cursor-pointer transform scale-125"
                          />
                        </div>
                        <div className="min-w-12 w-16 rounded-lg overflow-hidden">
                          <a>
                            {product.imageUrls[0] ? (
                              <Image
                                className=""
                                src={getImageProductUrl(product.imageUrls[0])}
                                alt={product.name}
                                width="60"
                                onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                              />
                            ) : (
                              <Image className="rounded-lg" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/product.png`} width="60" />
                            )}
                          </a>
                        </div>
                        <div className="h-16 w-48 max-w-48 grid content-between shrink">
                          <Tooltip title={product.name} placement="topLeft">
                            <div className="pl-2 px-1 max-h-10 break-words truncate-2-lines">{product.name}</div>
                          </Tooltip>
                          <div className="pl-2 px-1 max-h-10 cursor-default">
                            {product.status ? <Tag color="success">เปิดใช้งาน</Tag> : <Tag color="red">ปิดใช้งาน</Tag>}
                          </div>
                        </div>
                        <div className="w-48 max-w-48 px-4 pl-8 truncate">{product.sku ? product.sku : '-'}</div>
                        <div className="w-28 max-w-28 px-4 truncate">฿{product.cost}</div>
                        <div className="w-28 max-w-28 px-4 truncate">฿{product.price}</div>
                        <div className="w-36 max-w-36 px-4 truncate">
                          <Tooltip title={product.market.name} placement="topLeft">
                            {product.market ? product.market.name : 'ไม่มีร้านค้า'}
                          </Tooltip>
                        </div>
                        <div className="flex flex-wrap max-w-14 px-1">{new Date(product.updatedAt).toLocaleString()}</div>

                        <div className='flex-row w-24 min-w-20 max-w-24 pl-4'>

                          <div className='flex justify-end'>
                            <button onClick={() => handleEdit(product._id)}><p className="text-indigo-600">แก้ไข</p></button>
                          </div>

                          <div className='flex justify-end'>
                            {(product.status === true ) ?
                            <Popconfirm
                              title="ยืนยันการปิดสินค้า"
                              description="คุณต้องการปิดผลิตภัณฑ์นี้หรือไม่? จะไม่แสดงในหน้าร้านค้า"
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              onConfirm={() => handleDisable(product._id)}                                         
                              okText="ยืนยัน"
                              cancelText="ยกเลิก"
                            >
                              <button><p className="text-indigo-600">ปิดใช้งาน</p></button>
                            </Popconfirm>
                            :
                            <button onClick={() => handleEnable(product._id)}><p className="text-indigo-600">เปิดใช้งาน</p></button>
                            }
                          </div>

                          <div className='flex justify-end pt-1'>
                            <Dropdown 
                              placement="topRight"
                              overlay={
                                <div className='bg-white p-1 rounded-lg' style={{ boxShadow: '0 6px 15px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)' }}>
                                  <div className='hover:bg-gray-100 pl-2 pr-10 py-1 rounded cursor-pointer' onClick={() => handleDetail(product.id_text)}>
                                    <button>
                                      <p className="text-indigo-600">ดูสินค้า</p>
                                    </button>
                                  </div>

                                  <Popconfirm
                                      title="ยืนยันการลบผลิตภัณฑ์"
                                      description="คุณต้องการลบผลิตภัณฑ์นี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                      onConfirm={() => handleDelete(product._id, product.imageUrls)}
                                      okText="ยืนยัน"
                                      cancelText="ยกเลิก"
                                    >
                                    <div className='hover:bg-gray-100 pl-2 pr-10 py-1 rounded cursor-pointer'>
                                      <button>
                                        <p className="text-indigo-600">ลบ</p>
                                      </button>
                                    </div>
                                  </Popconfirm>
                                </div>
                              }
                            >
                              <p className="text-indigo-600 cursor-pointer ">เพิ่มเติม <DownOutlined className='scale-75'/></p>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  
                </div>
                
              </div>
            </div>

            <div>
              <div className="pagination mb-28">
                <Pagination
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={products.length}
                  onChange={handlePageChange}
                  onShowSizeChange={handleItemsPerPageChange}
                  showSizeChanger
                  pageSizeOptions={['10', '20', '50' , '100']}
                />
              </div>
            </div>

          </div>

        </div>

        {/* mini */}
          <div className='grow md:hidden mx-1'>

            <LoginAlert />

            <div className='grid grid-cols-2 gap-2 mt-2 mx-1 text-sm md:block'>
              <div className='flex items-center text-center bg-white rounded-md w-full py-2.5 px-2 hover:bg-gray-50 hover:ring-gray-400 hover:ring shadow	 hover:cursor-pointer select-none '
              onClick={showModal}
              >
                <div className='bg-blue-100 rounded-full p-3  aspect-square mr-3' style={{width:'44px' , height:'44px'}}><EyeOutlined  className='w-10 text-blue-500 mx-3 mt-1' style={{transform:"scale(1.7)"}}/></div>
                ดูหน้าแสดงสินค้า
              </div>
            </div>

            <div className='flex justify-between mx-2'>
              <div>
                <h2 className='text-2xl my-4'>สินค้าของฉัน</h2>
              </div>

              <div>
                {user?
                <div className='flex flex-col items-end	'>
                  <div>
                    <Modal 
                      open={isModalOpen} 
                      onCancel={handleCancel}
                      okButtonProps={{
                        hidden: true,
                      }}
                      cancelText="ยกเลิก"
                    >
                      <label className='text-xl'>ร้านค้า</label>
                      <div className='mt-2'>
                        {loading ? (
                            <div className='p-2 truncate text-center'>
                              กำลังโหลด....
                            </div>
                        ) : markets.length === 0 ? (
                          <Link to="/add-market" className="full-width-link">
                            <div className='p-2 border truncate hover:shadow-md rounded-md'>
                              + เพิ่มร้านค้า
                            </div>
                          </Link>
                        ) : (
                          markets.map(market => (
                            <a href={`/${market.id_text}`} className='cursor-pointer' key={market._id} target="_blank" rel="noopener noreferrer">
                            <div className='flex mt-1 p-2 border truncate hover:shadow-md' style={{borderRadius: '16px' }}>
                                {market.logoUrl && market.logoUrl[0] ? (
                                <img
                                  className="rounded-lg object-cover"
                                  src={getImageMarketUrl(market.logoUrl[0])}
                                  alt="Logo"
                                  width="60"
                                />
                                ) : (
                                <img
                                  className="rounded-lg object-cover"
                                  src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`}
                                  alt="Image not found"
                                  width="60"
                                />
                                )}
                                <label className='cursor-pointer ml-3 text-lg'>{market.name}</label>
                              </div>
                            </a>
                          )))
                        }
                      </div>
                    </Modal>
                    
                  </div>
              
                  <Link to="/add-product" >
                    <button className='
                        p-2 my-4 rounded
                        bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white text-sm'
                      type="button"><label className='text-md mr-1 font-black cursor-pointer'>+</label> เพิ่มสินค้าใหม่
                    </button>
                  </Link>
                </div>
                : 
                <button 
                  className='
                    my-4 py-2 px-4 rounded
                    bg-blue-300 text-white text-sm cursor-not-allowed'
                  type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มสินค้าใหม่
                </button>
                }
              </div>
            </div>
            
            <div className='mb-4 mx-2'>

              <div className='flex flex-col'>
                <Search
                  placeholder="ค้นหาชื่อสินค้า, SKU สินค้า"
                  allowClear
                  style={{  marginRight: 10 }}
                  onSearch={handleSearch}
                  value={searchQuery}
                  onChange={e => handleSearch(e.target.value)}
                />

                <div className='flex mt-2'>
                  <Select
                    placeholder="เลือกร้านค้า"
                    value={market}
                    onChange={handleMarketChange}
                    className='w-32 max-w-44 text-ellipsis overflow-hidden'

                  >
                    <Option value="">ทั้งหมด</Option>
                    {markets.map(market => (
                      <Option key={market._id} value={market._id}>{market.name}</Option>
                    ))}
                  </Select>

                  <Select
                    placeholder="เลือกประเภทสินค้า"
                    value={category}
                    onChange={handleCategoryChange}
                    className='w-40 max-w-44 mx-1 text-ellipsis overflow-hidden'
                  >
                    <Option value="">ประเภทสินค้าทั้งหมด</Option>
                    {categories.map(category => (
                      <Option key={category._id} value={category._id}>{category.name}</Option>
                    ))}
                    <Select.Option key="add-category" value="add-category" >
                        <Link to="/add-category" className="bg-gray-100 rounded-lg full-width-link" >+ เพิ่มประเภทสินค้า</Link>
                    </Select.Option>
                  </Select>

                </div>

                
              </div>

              <div>
                  <Popconfirm
                    title="ยืนยันการลบผลิตภัณฑ์"
                    description="คุณต้องการลบผลิตภัณฑ์ที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={handleBulkDelete}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                    className='mt-2'
                    disabled={selectedProducts.length === 0}
                  >
                      {selectedProducts.length === 0? (
                        <button className='mt-0.5 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                      ) : (
                        <button className='mt-0.5 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                      )}
                  </Popconfirm>
                  <label className='text-xs ml-2 text-nowrap opacity-50'>เลือกแล้ว {selectedProducts.length} รายการ</label>
              </div>

            </div>

            <div className="grid grid-cols-1 gap-2">
              <div>
                <div className='flex justify-between'>
                  {products.length === 0? "" :
                  <div className='flex flex-row mb-3 ml-4 text-sm text-gray-500'>
                    <div className='w-8'>
                      <input
                        type="checkbox"
                        checked={selectedProducts.length === filteredProducts.length}
                        onChange={handleSelectAll}
                        className='cursor-pointer transform scale-125'
                      />
                    </div>
                  </div>
                  }
                  <div className='opacity-50 pr-2 space-x-4 flex'>
                    <div className='cursor-pointer flex' onClick={handleSortByCost}>ต้นทุน
                        {sortOrder === 'asc' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  

                        : sortOrder === 'desc' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 

                        : <div className='flex flex-col pl-1 	opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                      </div>
                      <div className='cursor-pointer flex' onClick={handleSortByPrice}>ราคาขาย
                        {sortOrder === 'ascp' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  

                        : sortOrder === 'descp' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 

                        : <div className='flex flex-col pl-1 	opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                      </div>
                      <div className='cursor-pointer flex' onClick={handleSortByTime}>เวลา
                        {sortOrder === 'asct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>  

                        : sortOrder === 'desct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div> 

                        : <div className='flex flex-col pl-1 	opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>} 
                      
                      </div>
                    </div>
                </div>

                <div className='text-sm '>
                  {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="py-4">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg">
                          <Skeleton.Avatar active size="large" shape="square" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 2 }} />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : products.length === 0 ? (
                    <div className='bg-white p-4 my-4 rounded-md text-center text-gray-400'>
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                    // แสดงสินค้าจริงเมื่อโหลดเสร็จ
                    selectedProductsPage.map(product => (
                      <div key={product._id} className='bg-white px-4 py-2 rounded-lg  mb-3 overflow-hidden break-all whitespace-normal'>
                        <div className='flex'>
                          <div className='mb-1'>
                            <input
                              type="checkbox"
                              checked={selectedProducts.includes(product._id)}
                              onChange={() => handleSelectProduct(product._id)}
                              className='cursor-pointer  transform scale-125'
                            />
                          </div>
                          <div className='pl-2 px-1 truncate text-xs text-gray-500'>{product.market ? product.market.name : 'ไม่มีร้านค้า'}</div>
                          <div className='grow flex justify-end'>{(product.status === true ) ? <Tag color="success" bordered={false}>เปิดใช้งาน</Tag> : <Tag bordered={false} color='red'>ปิดใช้งาน</Tag>  }</div>
                        </div>
  
                        <div className='flex flex-row'>
                          <div className='w-20 max-w-20'>                        
                          <a>
                            {product.imageUrls[0]  ? (
                              <Image 
                                className="rounded-lg" src={getImageProductUrl(product.imageUrls[0])} alt={product.name} width="80" 
                                onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                              />
                            ) : (
                              <Image className="rounded-lg" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/product.png`} width="80" />
                            )}
                            </a>
                          </div>
                          <div className='flex flex-col text-wrap w-full truncate' >
                            <div className='px-2 pt-1 max-h-10 truncate-2-lines'>{product.name}</div>
                            <div className='px-2 pt-1 truncate text-indigo-800'>sku: {product.sku ? product.sku : '-'}</div>
                            <div className='flex justify-between pt-0.5'>
                              <div className='ml-2 truncate text-amber-600 max-w-28'>ต้นทุน ฿{product.cost}</div>
                              <div className='truncate text-emerald-600 max-w-24'>ขาย ฿{product.price}</div>
                            </div>
                          </div>
                        </div>
                        
                        <button onClick={() => handleEdit(product._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300">แก้ไข</p></button>
  
                        {(product.status === true ) ?
                            <Popconfirm
                              title="ยืนยันการปิดสินค้า"
                              description="คุณต้องการปิดผลิตภัณฑ์นี้หรือไม่? จะไม่แสดงในหน้าร้านค้า"
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              onConfirm={() => handleDisable(product._id)}                                         
                              okText="ยืนยัน"
                              cancelText="ยกเลิก"
                            >
                              <button><p className="bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300">ปิดใช้งาน</p></button>
                            </Popconfirm>
                            :
                            <button onClick={() => handleEnable(product._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300">เปิดใช้งาน</p></button>
                        }
                        
                        <Dropdown 
                          className='cursor-pointer'
                          placement="topLeft"
                          overlay={
                            <div className='bg-white p-1 rounded-lg ' style={{ boxShadow: '0 6px 15px 5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)' }}>
                              <div className='flex'>
                                <button className='bg-gray-200 rounded py-1 px-4 grow mb-1 active:bg-gray-300' onClick={() => handleDetail(product.id_text)}><p className="w-full text-center">ดูสินค้า</p></button>
                              </div>
  
                              <div className='flex'>
                                <Popconfirm
                                  title="ยืนยันการลบผลิตภัณฑ์"
                                  description="คุณต้องการลบผลิตภัณฑ์นี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => handleDelete(product._id, product.imageUrls)}                                         
                                  okText="ยืนยัน"
                                  cancelText="ยกเลิก"
                                >
                                  <button className='bg-gray-200 rounded py-1 px-4 grow active:bg-gray-300'><p className="w-full text-center">ลบ</p></button>
                                </Popconfirm>
                              </div>
                            </div>
                          }
                          
                        >
                          <button className="bg-gray-200 rounded py-1 px-4 mt-2 max-w-24 focus:bg-gray-300">เพิ่มเติม <DownOutlined className='scale-75'/></button>
                        </Dropdown>
                      </div>
                    ))
                  )}
                </div>
                
              </div>
            </div>

            <div>
              <div className="flex justify-center mb-24">
                <Pagination
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={products.length}
                  onChange={handlePageChange}
                  onShowSizeChange={handleItemsPerPageChange}
                  showSizeChanger
                  pageSizeOptions={['10', '20', '50' , '100']}
                />
              </div>
            </div>

          </div>

        <div></div>
      </div>
    </>
  );
}

export default ManageProduct;