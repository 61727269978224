export const getCurrentPosition = (callback) => {
    if (typeof callback !== 'function') {
      console.error('callback is not a function');
      return; // ออกจากฟังก์ชันหาก callback ไม่ถูกต้อง
    }
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const initialPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          //console.log('ตำแหน่งปัจจุบัน:', initialPosition);
          callback(initialPosition); // เรียก callback กับตำแหน่งที่ได้
        },
        (error) => {
          console.error('เกิดข้อผิดพลาดในการรับตำแหน่ง:', error);
          // จัดการกรณีผิดพลาดที่นี่
        }
      );
    } else {
      console.error('เบราว์เซอร์นี้ไม่รองรับ Geolocation');
    }
  };