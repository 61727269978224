/*
run backend
node index.js

frontend
npm run dev

build
npm run build


upload to git
git add . && git commit -m "first commit" && git push
or
git add . ; git commit -m "first commit" ; git push


update from git
git pull

*/



import React , {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from './context/UserContext';
import { Nav,NavNoUser } from './components/Nav';


import './App.css'


function App() {
  const{user} =  useUserAuth();

  const navigate = useNavigate();

  useEffect(() => {
    navigate('../about');
  }, [navigate,user]);

  return (
    <>
      <div>
      {user ? <Nav /> : (<NavNoUser />)}
          
      </div>

    </>
  );
}

export default App
