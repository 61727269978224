import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Nav, NavNoUser , Sidebar } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { LeftOutlined, DownOutlined, QuestionCircleOutlined, DeleteFilled } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, message, FloatButton, Switch, Modal, Tooltip, Radio } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import './style.css';

import best from "../../assets/carrier/best.jpg";
import dhl from "../../assets/carrier/dhl.jpg";
import flash from "../../assets/carrier/flash.jpg";
import jt from "../../assets/carrier/jt.jpg";
import kerry from "../../assets/carrier/kerry.jpg";
import nim from "../../assets/carrier/nim.jpg";
import ninjavan from "../../assets/carrier/ninjavan.jpg";
import scg from "../../assets/carrier/scg.jpg";
import thaipost from "../../assets/carrier/thaipost.jpg";
import other from "../../assets/carrier/other.jpg";


const { TextArea } = Input;

function AddCarrier() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  const [markets, setMarkets] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [imgCarrier, setImgCarrier] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shippingtype, setShippingtype] = useState('a');

  const [loading, setLoading] = useState(true); // สถานะการโหลด

  useEffect(() => {
    if (user !== undefined) {
      setLoading(false); // หยุดการโหลดเมื่อ user ถูกกำหนดค่าแล้ว
    }
    if (!user && !loading) {
      navigate('../manage-carrier');
    }

    form.setFieldsValue({ [`weight_from_0`]: 0.00 }); // ถ้าเป็นแถวแรกให้กำหนด weight_from เป็น 0.00

  }, [user, loading, navigate]);

  const onSubmit = async (values) => {
    const formData = new FormData();
  
    formData.append('carrier', selectedCarrier);
    formData.append('imageUrls', imgCarrier);
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('status', values.status);
    formData.append('cod', values.cod);
    formData.append('shippingtype', values.shippingtype);

    if (values.shippingtype === 'a') {
      formData.append('shippingRates', JSON.stringify([{ weight_from: 0.00, weight_to: 9999, rate: values.rate }]));
    } else {
      formData.append('shippingRates', JSON.stringify(shippingRates));
    }
    formData.append('user', user._id);
  
    try {
      await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/carrier`, formData, 
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
            }
        }
      );

      message.success('เพิ่มตัวเลือกการจัดส่งสำเร็จ');
      navigate('../manage-carrier');
    } catch (err) {
      console.error('Error adding carrier:', err);
      message.error('Failed to add carrier');
    }
  };
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCarrierSelect = (value) => {
    setSelectedCarrier(value[0]);
    setImgCarrier(value[2]);
    form.setFieldsValue({ carrier: value[0] });
    setIsModalOpen(false);
  };

  const [shippingRates, setShippingRates] = useState([
    { id: Date.now(), weight_from: 0.00, weight_to: 0.00, rate: 0.00 },
  ]);
  
  const addShippingRateRow = () => {
    const lastRate = shippingRates[shippingRates.length - 1];
    const lastWeightTo = parseFloat(lastRate.weight_to);
  
    const newWeightFrom = lastWeightTo && !isNaN(lastWeightTo)
      ? parseFloat((lastWeightTo + 0.01).toFixed(2))
      : undefined;
  
    setShippingRates([
      ...shippingRates,
      { id: Date.now(), weight_from: newWeightFrom, weight_to: 0.00, rate: 0.00 },
    ]);
  };
  
  const removeShippingRateRow = (id) => {
    setShippingRates((prevRates) => {
      const updatedRates = prevRates.filter((rate) => rate.id !== id);
  
      // คำนวณน้ำหนักเริ่มต้นใหม่ของแถวถัดไป
      for (let i = 1; i < updatedRates.length; i++) {
        updatedRates[i] = {
          ...updatedRates[i],
          weight_from: parseFloat((updatedRates[i - 1].weight_to + 0.01).toFixed(2)),
        };
      }
  
      return updatedRates;
    });
  };
  
  const handleShippingRateChange = (id, field, value) => {
    const newShippingRates = shippingRates.map((rate) =>
      rate.id === id ? { ...rate, [field]: value } : rate
    );
    setShippingRates(newShippingRates);
  };

  const handleWeightChange = (id, field, value) => {
    setShippingRates((prevRates) => {
      const updatedRates = prevRates.map((rate, index) => {
        if (rate.id === id) {
          // อัปเดตค่า weight_to ของแถวปัจจุบัน
          return { ...rate, [field]: value };
        }
        return rate;
      });
  
      // หา index ของแถวปัจจุบัน
      const currentIndex = updatedRates.findIndex((rate) => rate.id === id);
  
      // ถ้าแถวก่อนหน้ามีอยู่ (ไม่ใช่แถวแรก)

        if (currentIndex < updatedRates.length - 1) {
          const nextRate = updatedRates[currentIndex + 1];
    
          // อัปเดต weight_from ของแถวถัดไปให้เท่ากับ weight_to + 0.01 ของแถวปัจจุบัน
          updatedRates[currentIndex + 1] = {
            ...nextRate,
            weight_from: parseFloat(value) + 0.01,
          };
        }
  
      return updatedRates;
    });
  };
  
  const [form] = Form.useForm();

  const carrierOptions = [
    { value: ['Best Express',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/best.jpg`,'best.jpg'], label: 'Best Express', imgSrc: best },
    { value: ['DHL',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/dhl.jpg`,'dhl.jpg'], label: 'DHL', imgSrc: dhl },
    { value: ['Flash',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/flash.jpg`,'flash.jpg'], label: 'Flash', imgSrc: flash },
    { value: ['J&T',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/jt.jpg`,'jt.jpg'], label: 'J&T', imgSrc: jt },
    { value: ['Kerry',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/kerry.jpg`,'kerry.jpg'], label: 'Kerry', imgSrc: kerry },
    { value: ['NIM Express',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/nim.jpg`,'nim.jpg'], label: 'NIM Express', imgSrc: nim },
    { value: ['Ninja Van',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/ninjavan.jpg`,'ninjavan.jpg'], label: 'Ninja Van', imgSrc: ninjavan },
    { value: ['SCG Express',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/scg.jpg`,'scg.jpg'], label: 'SCG Express', imgSrc: scg },
    { value: ['Thaipost',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/thaipost.jpg`,'thaipost.jpg'], label: 'Thaipost', imgSrc: thaipost },
    { value: ['Others',`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/orther.jpg`,'orther.jpg'], label: 'Others', secondlabel: 'ผู้ให้บริการขนส่งอื่นๆ', imgSrc: other },
  ];

  if (shippingtype==='') {
    return (
      <>
        <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin fullscreen size="large" /></div>
        </div>
      </>
    );
  }

  return (
    <>
      {user ? 
        <div className='md:block hidden'>
          <Nav />
        </div>
      : (<NavNoUser />)
      }

      <div className='flex justify-between'>
        <div>
          <Sidebar />
        </div>

        <div className='shrink mx-1 flex-initial  md:mb-24 mb-10' style={{ width: "48rem" }}>
          <div className='mt-3 ml-4 md:block hidden'>
            <Link to="../manage-carrier" className='text-sm text-gray-600' > <LeftOutlined /> เพิ่มผู้ให้บริการจัดส่ง</Link>
            <h2 className='text-2xl mb-4 mt-2'>เพิ่มผู้ให้บริการจัดส่ง</h2>
          </div>

          <div className="flex md:hidden bg-gray-100 border-b-[6px] border-gray-300 fixed top-0 left-0 w-full z-50 items-center">
            <Link to="../manage-carrier" className="text-md pl-4 pr-4 pt-4 pb-2 text-gray-900">
              <LeftOutlined />
            </Link>
            <h2 className="text-xl m-0 flex-grow text-center mr-10 -mb-1">เพิ่มผู้ให้บริการจัดส่ง</h2>
          </div>

          <div>
            <Modal
              open={isModalOpen}
              onCancel={handleCancel}
              okText="ยืนยัน"
              onOk={() => {
                setIsModalOpen(false);
              }}
              cancelButtonProps={{ hidden: true }}
              okButtonProps={{ hidden: true }}
              className=''
            >
              <div className='overflow-x-hidden overflow-y-auto mt-6 mb-6' style={{ height: "60svh" }}>
                <div className='mb-2'>
                  <label className='text-lg '>เลือกบริษัทขนส่ง</label>
                </div>
                <div className=''>
                  {carrierOptions.map(option => (
                    <div
                      key={option.value}
                      className={`flex mt-2 p-2 mx-1 border hover:shadow-md hover:bg-gray-50 cursor-pointer rounded-lg items-center ${selectedCarrier === option.value ? 'border-green-500' : ''}`}
                      onClick={() => handleCarrierSelect(option.value)}
                    >
                      <div>
                        <img src={option.imgSrc} className="w-14 rounded-full" alt={option.label} />
                      </div>
                      {option.secondlabel ? (
                        <div className='flex flex-col'>
                          <div><label className='text-xl cursor-pointer ml-3'>{option.label}</label></div>
                          <div><label className='text-sm cursor-pointer ml-3 text-gray-600'>{option.secondlabel}</label></div>
                        </div>
                      ) : (
                        <div><label className='text-xl cursor-pointer ml-3'>{option.label}</label></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>

          <Form form={form} onFinish={onSubmit} layout="vertical" className='md:mt-0 mt-[62px]'
            initialValues={{ 
              status: true,
              description: '',
              cod: false, 
              shippingtype: 'a' 
            }}
          >
            <div className='bg-white p-6 rounded-lg '>
              <div className='mb-3'>
                <label className='text-lg '>ประเภทค่าจัดส่ง</label>
              </div>

              <div className='flex wrap'>
                <div className='flex-1' onClick={showModal}>
                  <Form.Item
                    label="บริการขนส่ง"
                    name="carrier"
                    rules={[{ required: true, message: 'กรุณาใส่ชื่อการจัดส่ง' }]}
                    className=""
                  >
                    <Input
                      placeholder='โปรดเลือก'
                      suffix={<DownOutlined />}
                      readOnly
                      value={selectedCarrier}
                    />
                  </Form.Item>
                </div>
                <div className='flex-1 ml-4'>
                  <Form.Item
                    label="ชื่อการจัดส่ง"
                    name="name"
                    rules={[{ required: true, message: 'กรุณาใส่ชื่อการจัดส่ง' }]}
                    className=""
                    tooltip="ชื่อที่แสดงในหน้ารายการสินค้า"
                  >
                    <Input
                      placeholder='กรุณาใส่ชื่อการจัดส่ง'
                    />
                  </Form.Item>
                </div>
              </div>

              <Form.Item label="รายละเอียด" name="description">
                <TextArea
                  placeholder='ป้อนรายละเอียด'
                  style={{ minHeight: '80px' }}
                />
              </Form.Item>
            </div>

            <div className='bg-white md:px-6 py-6 px-3 rounded-lg mt-4'>
              <div className='mb-3'>
                <label className='text-lg'>ประเภทค่าจัดส่ง</label>
              </div>
              <div>
                <Form.Item name="shippingtype" onChange={(e) => setShippingtype(e.target.value)}>
                  <Radio.Group defaultValue="a" size="large">
                    <Radio.Button value="a">ค่าจัดส่งคงที่</Radio.Button>
                    <Radio.Button value="b">ค่าจัดส่งตามน้ำหนัก</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>
              {shippingtype === "a" ?
                <>
                  <div className='mt-4'>
                    <label>ค่าจัดส่ง</label>
                  </div>
                  <div>
                    <Form.Item
                      name="rate"
                      rules={[{ required: true, message: 'กรุณาใส่ค่าจัดส่ง' }]}
                    >
                      <InputNumber addonBefore="฿" placeholder='0.00' />
                    </Form.Item>
                  </div>
                </>
                :
                <>
                  <div className='bg-gray-100 p-2 rounded-lg mt-4'>
                    <div className=''>
                      <div className='flex'>
                        <div className="basis-1/3">
                          <label>น้ำหนักต่ำสุด</label>
                        </div>
                        <div className="basis-1/3">
                          <label>น้ำหนักสูงสุด</label>
                        </div>
                        <div className="basis-1/3 -ml-2">
                          <label>ค่าจัดส่ง</label>
                        </div>
                      </div>
                    </div>

                    <div>
                      {shippingRates.map((rate, index) => (
                        <div key={rate.id} className="flex md:gap-3 gap-1">
                          <div className="flex-1 md:mt-0 mt-[3px]">
                            <InputNumber
                              addonBefore={<span>กก.</span>}
                              placeholder="0.00"
                              min={0.00}
                              step={0.01}
                              name={`weight_from_${rate.id}`}
                              value={rate.weight_from}
                              disabled="true"
                              onChange={(value) => handleWeightChange(rate.id, 'weight_from', value)}
                              className="custom-input-number-carrier-from"
                            />
                          </div>
                        
                        <div className="flex-1">
                          <Form.Item
                            name={`weight_to_${rate.id}`}
                            rules={[
                              { required: true, message: 'กรุณาใส่น้ำหนักสูงสุด' },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const currentWeightFrom = shippingRates.find((r) => r.id === rate.id)?.weight_from;
                                  if (value <= currentWeightFrom) {
                                    return Promise.reject(
                                      new Error('น้ำหนักต้องมากกว่าน้ำหนักต่ำสุดในแถวเดียวกัน')
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <InputNumber
                              addonBefore={<span>กก.</span>}
                              placeholder="0.00"
                              min={0.00}
                              step={0.01}
                              value={rate.weight_to}
                              onChange={(value) => handleWeightChange(rate.id, 'weight_to', value)}
                              className="custom-input-number-carrier-to"
                            />
                          </Form.Item>
                        </div>
                        <div className="flex-1">
                          <Form.Item
                            name={`rate_${rate.id}`}
                            rules={[{ required: true, message: 'กรุณาใส่ค่าจัดส่ง' }]}
                          >
                            <InputNumber
                              addonBefore={<span>฿</span>}
                              formatter={(value) => 
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // เพิ่ม comma
                                .replace(/^$/, '0.00') // จัดการค่าที่เป็น null หรือ undefined
                                .replace(/(\.\d{0,2})$/, '$1') // ควบคุมให้แสดงทศนิยมไม่เกิน 2 ตำแหน่ง
                              }
                              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')} // เอา comma ออกเพื่อส่งค่ากลับ
                              placeholder="0.00"
                              min={0.00}
                              step={0.01}
                              value={rate.rate}
                              onChange={(value) => handleShippingRateChange(rate.id, 'rate', value)}
                              className="custom-input-number-carrier-to"
                            />
                          </Form.Item>
                        </div>
                        <div className="min-w-6">
                          {index > 0 && (
                            <label onClick={() => removeShippingRateRow(rate.id)} onChange={(value) => handleWeightChange(rate.id, 'weight_to', value)}>
                              <DeleteFilled className="p-1 mt-1 bg-red-300 hover:bg-red-400 scale-110 hover:scale-125 active:scale-150 active:bg-red-500 rounded-md no-print transition duration-150 ease-in-out cursor-pointer" />
                            </label>
                          )}
                        </div>
                      </div>
                      ))}
                      <label onClick={addShippingRateRow} className="duration-150 ease-out ml-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:ring-2 p-0.5 px-1 rounded-lg no-print cursor-pointer">+ ช่วงค่าน้ำหนัก</label>
                    </div>

                  </div>
                </>
              }
            </div>

            <div className='bg-white md:p-6 md:pb-4 p-3 pb-1 rounded-lg mt-4 justify-between hidden'>
              <div>
                <label className='text-lg '>เก็บเงินปลายทาง</label>
                <Tooltip
                  title="ตั้งค่าให้การจัดส่งนี้เป็นการจัดส่งแบบเก็บเงินปลายทาง"
                  className='opacity-70 ml-2'
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
              <Form.Item
                name='cod'
                valuePropName="checked"
              >
                <Switch
                  style={{ width: '90px ' }}
                  checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                  className='mr-4  scale-150'
                />
              </Form.Item>
            </div>

            <div className='bg-white p-6 pb-0.5 pt-2.5 rounded-lg mt-4 flex justify-between'>
              <label className='text-lg'>การแสดงผล</label>
              <Form.Item
                name='status'
                valuePropName="checked"
              >
                <Switch
                  style={{ width: '90px ' }}
                  checkedChildren="เปิดใช้งาน" unCheckedChildren="ปิดใช้งาน"
                  defaultChecked
                  className=''
                />
              </Form.Item>
            </div>

            <Form.Item>
              <div className='flex justify-end mt-4'>
                <button htmlType="submit" className='px-8 py-2 text-white rounded-lg bg-blue-400 hover:bg-blue-500 active:bg-blue-600 transition hidden md:block w-40'>บันทึก</button>
              </div>
            </Form.Item>

            <Form.Item
                className='form-item fixed bottom-0 left-0 md:hidden bg-white w-screen	pt-3 pb-3 px-3 z-50'
                style={{ boxShadow: '3px 3px 15px 10px rgb(0 0 0 / 0.1)' }}
              >
                <div className=''>
                      <button 
                        type="submit"
                        className= 
                          "text-xl px-6 py-2 text-white rounded-md bg-blue-400 hover:bg-blue-500 active:bg-blue-600 w-full"> 
                          บันทึก
                      </button>
                </div>
            </Form.Item>
          </Form>
        </div>

        <div></div>
      </div>
    </>
  );
}

export default AddCarrier;
