import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Nav, NavNoUser,Sidebar ,BottomNav } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Link , useNavigate } from 'react-router-dom';
import {LoginAlert} from '../../components/Alert.jsx';

import { message , Popconfirm, Image ,Tag, Skeleton} from 'antd';
import { QuestionCircleOutlined , EyeOutlined } from '@ant-design/icons';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { FloatButton ,Spin } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";


function ManageMarket() {
  const { user } = useUserAuth();
  const token = localStorage.getItem('token');
  const userId = user ? user._id : null;
  const [markets, setMarkets] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState(true);

  const [loading, setLoading] = useState(true); // สถานะการโหลด

  const fetchMarkets = async () => {
    if (user && user._id) { // เพิ่มการตรวจสอบ user และ user._id
      setLoading(true); // เริ่มโหลด
      try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/market`, {
          params: { userId: user._id }
        });

        if(response.data.length === 0){
          setData(false)
        }

        if (Array.isArray(response.data)) {
          setMarkets(response.data);
        } else {
          console.error('Fetched markets are not an array:', response.data);
        }
      } catch (err) {
        console.error('Error fetching markets:', err);
      } finally {
        setLoading(false); // โหลดเสร็จ
      }
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchMarkets();
      setLoading(false); // ปิดสถานะโหลดเมื่อข้อมูลพร้อม
    };
    fetchData();
  }, [user]);
  
  function getImageUrl(filename) {
    const basePath = import.meta.env.VITE_IMAGE_MARKET_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/market`;
    return `${basePath}/${filename}`;
  }

  const handleDelete = async (id, locationId) => {
    try {
      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/location/${locationId}`,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
      );

      await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/market/${id}`,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
              }
          }
      );

      setMarkets(markets.filter(market => market._id !== id));
      message.success('ลบร้านค้าสำเร็จ')
    } catch (err) {
      console.error('Error deleting market:', err);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-market/${id}`);
  };

  const handleDetail = (id) => {
    navigate(`/detail-market/${id}`);
  };

  /*if (user && markets.length === 0 && data) {
    return (
      <>
      {user ? <Nav /> : (<NavNoUser />)}
      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>
        <div><Spin  fullscreen size="large" /></div>
      </div>
    </>
  );
  }*/

  return (
    <>
      {user ? <Nav /> : (<NavNoUser />)}

      <div className='flex justify-between'>
        <div>
          <Sidebar />
          <BottomNav />
        </div>

        <div className='overscroll-none overflow-x overflow-y-hidden custom-scrollbar hidden md:block'>
          <LoginAlert />

          <div className='hidden md:block '>
            <div className='flex justify-between'>
              <div>
                <h2 className='text-2xl my-6'>ร้านค้าของฉัน</h2>
              </div>

              <div>
                {user ?
                <Link to="/add-market" >
                  <button className='
                      my-6 py-2 px-4 rounded
                      bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition text-white text-sm'
                    type="button"> เพิ่มร้านค้าใหม่
                  </button>
                </Link>
                :
                <button className='
                  my-6 py-2 px-4 rounded
                  bg-blue-300 text-white text-sm cursor-not-allowed'
                  type="button"> เพิ่มร้านค้าใหม่
                </button>
                }
              </div>
            </div>
            <table className='mb-28'>
              <thead>
                <tr>
                  <div className='flex flex-row  mb-3 text-sm text-gray-500 '> 
                    <th className='w-56 flex justify-start'>ร้านค้า</th>
                    <th className='w-32 flex justify-start'>สถานะ</th>
                    <th className='w-32 flex justify-end'>ดำเนินการ</th>
                  </div>
                </tr>
              </thead>

              <tbody className=''>
                {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(2)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[137px]">
                          <Skeleton.Avatar active size="large" shape="default" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 2 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : markets.length === 0 ? (
                    <div className="bg-white p-4 mt-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                markets.map(market => (
                  <tr key={market._id} className='flex flex-row bg-white px-4 py-4 rounded-lg mb-3 hover:drop-shadow mx-1'>
                    <td className=''>
                      <div className='flex flex-col w-44 '>
                        <div className='w-16 max-w-16 rounded-lg overflow-hidden'>
                          <a className='cursor-pointer'>
                          {market.logoUrl[0] ? (
                            <Image
                              className="rounded-lg object-cover"
                              src={getImageUrl(market.logoUrl[0])}
                              alt="Logo" width="60"
                              onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                            />
                          ) : (
                          <Image
                            className="rounded-lg object-cover"
                            src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`}
                            alt="Image not found" width="60"
                          />
                          )}
                          </a>
                        </div>
                        <div className='truncate pt-2 cursor-pointer' onClick={() => handleDetail(market._id)}>
                          {market.name}
                        </div>
                      </div>
                    </td>
                    <td className='text-sm cursor-pointer' onClick={() => handleDetail(market._id)}>
                      {market.status ==="open"? 
                        <Tag bordered={false} color="green">
                          เปิดทำการปกติ
                        </Tag>:''}
                      {market.status ==="vacation"? 
                        <Tag bordered={false} color="orange">
                          หยุดพักร้อน
                        </Tag>:''}                      
                      {market.status ==="closed"? 
                        <Tag bordered={false} color="red">
                          ปิดใช้งาน
                        </Tag>:''}
                    </td>
                    <td className='grow'>
                      <div className='flex-row grow pl-4'>
                        <div className='flex justify-end'>
                          <a href={`/${market.id_text}`} className='flex justify-end' key={market._id} target="_blank" rel="noopener noreferrer">
                            <p className="text-indigo-600">ดูหน้าร้าน</p>
                          </a>
                        </div>
                        <div className='flex justify-end'>
                          <button onClick={() => handleDetail(market._id)}><p className="text-indigo-600">ดูรายละเอียด</p></button>
                        </div>
                        <div className='flex justify-end'>
                          <button onClick={() => handleEdit(market._id)}><p className="text-indigo-600">แก้ไข</p></button>
                        </div>
                        <div className='flex justify-end'>
                          <Popconfirm
                            title="ยืนยันการลบร้านค้า"
                            description="คุณต้องการลบร้านค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => handleDelete(market._id,market.location)}                                         
                            okText="ยืนยัน"
                            cancelText="ยกเลิก"
                          >
                          <button><p className="text-indigo-600">ลบ</p></button>
                          </Popconfirm>
                        </div>
                      </div>
                    </td>
                  </tr>
                    ))
                  )}
              </tbody>
            </table>
          </div>
        </div>

        {/* mini */}
        <div className='grow md:hidden mx-1'>
            <LoginAlert />

            <div className='flex justify-between mx-2'>
              <div>
                <h2 className='text-2xl my-4'>ร้านค้าของฉัน</h2>
              </div>

              <div>
                {user?
                <Link to="/add-market" >
                  <button className='
                      my-6 ml-3 p-2 rounded
                      bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white text-sm'
                    type="button"> เพิ่มร้านค้าใหม่
                  </button>
                </Link>
                :
                <button className='
                  my-6 py-2 px-4 rounded ml-1
                  bg-blue-300 text-white text-sm cursor-not-allowed'
                  type="button"> เพิ่มร้านค้าใหม่
                </button>
                }
              </div>
            </div>

            <div className='mb-40 w-full'>
                {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(2)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[149px]">
                          <Skeleton.Avatar active size="large" shape="default" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 2 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : markets.length === 0 ? (
                    <div className="bg-white p-4 mt-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (
                markets.map(market => (
                  <tr key={market._id} className='flex flex-row bg-white px-4 py-4 mb-3 rounded-lg' >

                    <div className='flex-grow w-64 '>
                      <div className='flex flex-col'>
                          <div className='flex flex-row'>
                            <div className='flex flex-row w-[68px] shrink-0'>
                              <a className='cursor-pointer'>
                              {market.logoUrl[0] ? (
                                <Image
                                  className="rounded-lg object-cover"
                                  src={getImageUrl(market.logoUrl[0])}
                                  alt="Logo" 
                                  width="60"
                                  onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                                />
                              ) : (
                              <Image
                                className="rounded-lg object-cover"
                                src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`}
                                alt="Image not found"
                                width="60"
                              />
                              )}
                              </a>

                            </div>

                            <div className='min-w-[92px] mx-2 w-full text-center'>
                              <a href={`/${market.id_text}`} 
                                className='hidden' 
                                key={market._id} target="_blank" rel="noopener noreferrer"
                              >
                                <p className="bg-gray-200 rounded py-1 px-4">ดูหน้าร้าน</p>
                              </a>
                            </div>


                            <div className='flex flex-row-reverse cursor-pointer text-right'>
                              <div className='text-sm'>
                                {market.status === "open" && <Tag color="green">เปิดทำการปกติ</Tag>}
                                {market.status === "vacation" && <Tag color="orange">หยุดพักร้อน</Tag>}
                                {market.status === "closed" && <Tag color="red">ปิดใช้งาน</Tag>}
                              </div>
                            </div>
                          </div>

                          <div className='truncate max-w-80 whitespace-nowrap mt-2 cursor-pointer'>
                            {market.name}
                          </div>

                          <div>
                              <div className='flex flex-row'>
                                <a href={`/${market.id_text}`} 
                                  className='mr-3' 
                                  key={market._id} target="_blank" rel="noopener noreferrer"
                                >
                                  <p className="bg-gray-200 rounded py-1 px-4 mt-2">ดูหน้าร้าน</p>
                                </a>                                
                                <button onClick={() => handleDetail(market._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2">รายละเอียด</p></button>
                                <button onClick={() => handleEdit(market._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2 ml-3 hidden">แก้ไข</p></button>
                                <Popconfirm
                                  title="ยืนยันการลบร้านค้า"
                                  description="คุณต้องการลบร้านค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => handleDelete(market._id)}                                         
                                  okText="ยืนยัน"
                                  cancelText="ยกเลิก"
                                >
                                  <button><p className="bg-gray-200 rounded py-1 px-4 ml-3 mt-2">ลบ</p></button>
                                </Popconfirm>
                              </div>
                          </div>

                        </div>
                    </div>
                  </tr>
                    ))
                  )}
            </div>
        </div>
        
        <div className=''></div>
      </div>
    </>
  );
}

export default ManageMarket;