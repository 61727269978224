import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserContext';



function ProtectedRoute({ children }) {
    const navigate = useNavigate();
    const{user} =  useUserAuth();

    if (!user || !user.admin ){
        navigate('/');
    }

    return children;

}

export default ProtectedRoute

