import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

function CookieBar() {
  const [isCookieSet, setCookie] = useState(Cookies.get("cookieConsent"));

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      Cookies.set("cookieConsent", true, { expires: 365 }); // กำหนดค่าเริ่มต้นเป็น true
      setCookie(true);
    } else {
      setCookie(consent === "true"); // อัปเดตค่า state ตามคุกกี้ที่มีอยู่
    }
  }, []);

  // Function to handle accepting cookies
  const handleAcceptCookies = () => {
    Cookies.set("cookieConsent", true, { expires: 365 });
    setCookie(true);
  };

  // Function to handle rejecting cookies
  const handleRejectCookies = () => {
    Cookies.remove("cookieConsent");
    setCookie(false);
  };

  return (
    <>
    {!isCookieSet ?
        <div className="bg-white rounded-lg md:shadow border z-50  p-4 md:px-10 px-4 md:w-[90%] w-[98%] max-w-[1200px] fixed left-1/2 md:bottom-4 bottom-20 transform -translate-x-1/2 ">
            <p className="font-bold">
                เว็บไซต์นี้ใช้คุกกี้
            </p>
            <p>
                เราใช้คุกกี้เพื่อเพิ่มประสบการณ์ที่ดีในการใช้เว็บไซต์ รวมถึงเพื่อวิเคราะห์การเข้าใช้งานเว็บไซต์และทำความเข้าใจว่าผู้ใช้งานมาจากที่ใด นโยบายความเป็นส่วนตัว
            </p>
            <div className="flex justify-end">
                <button onClick={handleAcceptCookies} className="mt-2 bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white text-xl px-6 rounded-lg transition">ยอมรับ</button>
            </div>
        </div>
        :''
    }
    </>
  );
}

export default CookieBar;