import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BottomNav ,Nav, NavNoUser } from '../../components/Nav.jsx';
import { useUserAuth } from '../../context/UserContext.jsx';
import { Sidebar } from '../../components/Nav.jsx';
import { Link, useNavigate } from 'react-router-dom';
import {LoginAlert} from '../../components/Alert.jsx';

import { Pagination, message, Popconfirm, Input, Select , Tag, Dropdown,Image, Skeleton } from 'antd';
import { QuestionCircleOutlined , CaretDownOutlined , DownOutlined ,CaretUpOutlined , LeftOutlined   } from '@ant-design/icons';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Spin , FloatButton } from 'antd';
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";

const { Option } = Select;
const { Search } = Input;

function ManageCarrier() {
    const { user } = useUserAuth();
    const token = localStorage.getItem('token');

    const userId = user ? user._id : null;
    const [carriers, setCarriers] = useState([]);
    const [filteredCarriers, setFilteredCarriers] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('default');
    const navigate = useNavigate();
    const [data, setData] = useState(true);
    
    const [loading, setLoading] = useState(true); // สถานะการโหลด
  
    const fetchCarriers = async () => {
        if (user && user._id) {
          setLoading(true); // เริ่มโหลด
          try {
            const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}api/carrier`, {
              params: { userId: user._id }
            });
    
            if (response.data.length === 0) {
              setData(false)
            }
    
            if (Array.isArray(response.data)) {
              const sortedCarriers = response.data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
              setCarriers(sortedCarriers);
              setFilteredCarriers(sortedCarriers);
            } else {
              console.error('Fetched carriers are not an array:', response.data);
            }
          } catch (err) {
            console.error('Error fetching carriers:', err);
          } finally {
            setLoading(false); // โหลดเสร็จ
          }
        }
      };
  
    useEffect(() => {
        const fetchData = async () => {
          await fetchCarriers();
          setLoading(false); // ปิดสถานะโหลดเมื่อข้อมูลพร้อม
        };
        fetchData();
    }, [user]);
    function getImageCarrierUrl(filename) {
      const basePath = import.meta.env.VITE_IMAGE_CARRIER_URL || `${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/`;
      return `${basePath}/${filename}`;
    }
  
    const handleBulkDelete = async () => {
      try {
          await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/carrier/bulk-delete`,
            { carrierIds: selectedCarriers },  // Body ของ request
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                }
            }
          );
          setCarriers(carriers.filter(carrier => !selectedCarriers.includes(carrier._id)));
          setSelectedCarriers([]);
          navigate(0);
          message.success("ลบตัวเลือกการจัดส่งสินค้าสำเร็จ");
      } catch (err) {
          console.error('Error deleting carriers:', err);
      }
  };
  
  const handleDelete = async (id) => {
      try {
          await axios.delete(`${import.meta.env.VITE_BACKEND_URL}api/carrier/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // ส่ง Token ไปด้วย
                }
            }
          );
          setCarriers(carriers.filter(carrier => carrier._id !== id));
          navigate(0);
          message.success("ลบตัวเลือกการจัดส่งสินค้าสำเร็จ");
      } catch (err) {
          console.error('Error deleting carrier:', err);
      }
  };
  
    const handleEdit = (id) => {
        navigate(`/edit-carrier/${id}`);
    };
  
    const handleSelectCarrier = (id) => {
        setSelectedCarriers(prevSelected =>
        prevSelected.includes(id)
            ? prevSelected.filter(carrierId => carrierId !== id)
            : [...prevSelected, id]
        );
    };
    const handleSelectAll = () => {
        if (selectedCarriers.length === carriers.length) {
        setSelectedCarriers([]);
        } else {
        setSelectedCarriers(carriers.map(carrier => carrier._id));
        }
    };
  
    const handleItemsPerPageChange = (current, size) => {
      setItemsPerPage(size);
      setCurrentPage(1);
    };
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    const handleSearch = (value) => {
      setSearchQuery(value);
      filterCarriers(value);
    };
  
    const handleSortByTime = () => {
        let newSortOrder;
        if (sortOrder === 'default') newSortOrder = 'asct';
        else if (sortOrder === 'asct') newSortOrder = 'desct';
        else newSortOrder = 'default';
        setSortOrder(newSortOrder);

        const sortedCarriers = [...filteredCarriers];
        if (newSortOrder === 'asct') {
        sortedCarriers.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
        } else if (newSortOrder === 'desct') {
        sortedCarriers.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        } else {
        sortedCarriers.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        }
        setFilteredCarriers(sortedCarriers);
    };
  
    const filterCarriers = (search) => {
        let filtered = carriers;

        if (search) {
        filtered = filtered.filter(carrier =>
            carrier.name.toLowerCase().includes(search.toLowerCase())
        );
        }

        setFilteredCarriers(filtered);
    };
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedCarriersPage = filteredCarriers.slice(startIndex, startIndex + itemsPerPage);
    
  
    /*if (user && carriers.length === 0 && data) {
      return (
        <>
        {user ? <Nav /> : (<NavNoUser />)}
        <div className='flex justify-between'>
          <div>
            <Sidebar />
          </div>
          <div><Spin  fullscreen size="large" /></div>
        </div>
      </>
    );
    }*/
  

    return (
      <>
        {user ? <Nav /> : (<NavNoUser />)}
        {user? 
        <div className='hidden'>
          <a href="/chat" target="_blank" rel="noopener noreferrer">
          <FloatButton 
            tooltip="เปิดหน้าแชท" 
            type="primary"
            icon={<ChatBubbleOvalLeftEllipsisIcon />} 
            className='mr-6 scale-150'
          />
          </a>
        </div>
        : ''}
  
        <div className='flex justify-between'>
          <div>
            <Sidebar />
            <BottomNav />
          </div>
  
          <div className='overscroll-none overflow-x overflow-y-hidden custom-scrollbar hidden md:block'>
            <LoginAlert />
          
            <div className='shrink hidden md:block'>
              <div className='flex justify-between '>
                <div>
                  <h2 className='text-2xl my-6 pl-4'>ตั้งค่าการจัดส่งสินค้า</h2>
                </div>
    
                <div className='flex my-6 mr-2'>
                  <div>
                    {user ? 
                      <div className='flex'>
                        <Link to="/add-carrier">
                          <button className='
                            py-2 px-4 rounded
                            bg-blue-500 hover:bg-blue-600 active:bg-blue-700 transition text-white text-sm'
                            type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มผู้ให้บริการจัดส่ง
                          </button>
                        </Link>
                      </div>
                      : 
                      <button 
                        className='
                          my-6 py-2 px-4 rounded
                          bg-blue-300  text-white text-sm cursor-not-allowed'
                        type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มผู้ให้บริการจัดส่ง
                      </button>
                    }
                  </div>
                </div>
              </div>
    
              <div className='flex justify-between mb-6 bg-white py-4 rounded-lg mx-1 border'>
                <div className='flex pl-4'>
                  <Search
                    placeholder="ค้นหาชื่อการจัดส่ง"
                    allowClear
                    style={{ width: 300, marginRight: 10 }}
                    onSearch={handleSearch}
                    value={searchQuery}
                    onChange={e => handleSearch(e.target.value)}
                  />
    
                  <div>
                    <Popconfirm
                      title="ยืนยันการลบการจัดส่ง"
                      description="คุณต้องการลบการจัดส่งที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={handleBulkDelete}
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                      disabled={selectedCarriers.length === 0}
                    >
                      {selectedCarriers.length === 0 ? (
                        <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                      ) : (
                        <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                      )}
                    </Popconfirm>
                  </div>
                </div>
              </div>
    
              <div className="hidden md:block grid-cols-1 gap-2 mx-2">
                <div className=''>
                  <div>
                    <div className='flex flex-row ml-4 mb-1 text-sm text-gray-500'>
                      <div className='min-w-8'>
                        <input
                          type="checkbox"
                          checked={selectedCarriers.length === carriers.length}
                          onChange={handleSelectAll}
                          className='transform scale-125 cursor-pointer'
                        />
                      </div>
                      <div className='w-40 font-bold'>ชื่อการจัดส่ง</div>
                      <div className='w-32 font-bold cursor-pointer flex' onClick={handleSortByTime}>
                        อัปเดตแล้ว
                        {sortOrder === 'asct' ? (
                          <div className='flex flex-col pl-1'>
                            <CaretUpOutlined className='opacity-50 '/> 
                            <CaretDownOutlined className='-translate-y-1.5 '/>
                          </div>  
                        ) : sortOrder === 'desct' ? (
                          <div className='flex flex-col pl-1'>
                            <CaretUpOutlined className=''/> 
                            <CaretDownOutlined className='-translate-y-1.5 opacity-50'/>
                          </div> 
                        ) : (
                          <div className='flex flex-col pl-1 opacity-50'>
                            <CaretUpOutlined className=''/> 
                            <CaretDownOutlined className='-translate-y-1.5'/>
                          </div>
                        )} 
                      </div>  
                      <div className='w-32 font-bold text-nowrap text-right'>ดำเนินการ</div>
                    </div>
                  </div>

                  {loading ? (
                    // แสดง Skeleton ระหว่างโหลด
                    <div className="">
                      {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex flex-row bg-white px-4 py-4 mb-3 mx-1 rounded-lg h-[158px]">
                          <Skeleton.Avatar active size="large" shape="default" />
                          <div className="ml-4 flex-1">
                            <Skeleton active paragraph={{ rows: 2 }}/>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : carriers.length === 0 ? (
                    <div className="bg-white p-4 mt-4 rounded-md text-center text-gray-400">
                      <label>ไม่พบข้อมูล</label>
                    </div>
                  ) : (

                  selectedCarriersPage.map(carrier => (
                  <tr key={carrier._id} className={`flex flex-row bg-white px-4 py-4 rounded-lg mb-3 hover:drop-shadow ${selectedCarriers.includes(carrier._id) ? 'bg-indigo-50' : 'bg-white'}`}>
                    <td className=''>
                      <div className='flex flex-col w-40 '>
                        <div className='w-8 -mt-3'>
                          <input
                            type="checkbox"
                            checked={selectedCarriers.includes(carrier._id)}
                            onChange={() => handleSelectCarrier(carrier._id)}
                            className='transform scale-125 cursor-pointer'
                            />
                        </div>

                        <div className='cursor-pointer'>
                          <a className='' onClick={() => handleEdit(carrier._id)}>
                          {carrier.imageUrls ? (
                            <img
                              className="rounded-lg object-cover ring-1 ring-gray-200"
                              src={getImageCarrierUrl(carrier.imageUrls[0])}
                              alt="LogoCarrier"
                              width="60"
                              onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/other.jpg`; }}
                            />
                          ) : (
                            <img
                              className="rounded-lg object-cover"
                              src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/other.jpg`}
                              alt="Image not found"
                              width="60"
                            />
                          )}
                          </a>
                        </div>
                        <div className='truncate pt-2 cursor-pointer' onClick={() => handleEdit(carrier._id)}>
                          {carrier.name}
                        </div>
                        <div className='text-sm text-gray-500 truncate cursor-pointer' onClick={() => handleEdit(carrier._id)}>
                          {carrier.description === "undefined" ? 'ไม่มีรายละเอียด' : carrier.description}
                        </div>
                      </div>
                    </td>

                    <td className='text-sm cursor-pointer' onClick={() => handleEdit(carrier._id)}>
                      {new Date(carrier.updatedAt).toLocaleString()}
                    </td>
                    <td className='grow'>
                      <div className='flex-row grow pl-4'>
                        <div className='flex justify-end'>
                          <button onClick={() => handleEdit(carrier._id)}><p className="text-indigo-600">แก้ไข</p></button>
                        </div>
                        <div className='flex justify-end'>
                          <Popconfirm
                            title="ยืนยันการลบร้านค้า"
                            description="คุณต้องการลบร้านค้านี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => handleDelete(carrier._id)}                                         
                            okText="ยืนยัน"
                            cancelText="ยกเลิก"
                          >
                          <button><p className="text-indigo-600">ลบ</p></button>
                          </Popconfirm>
                        </div>
                      </div>
                    </td>
                  </tr>
                    ))
                  )}
                </div>
              </div>
    
              <div>
                <div className="pagination mb-28">
                  <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={carriers.length}
                    onChange={handlePageChange}
                    onShowSizeChange={handleItemsPerPageChange}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              </div>
    
            </div>
          </div>

          {/* mini */}
  
          <div className='grow md:hidden mx-1 '>
            <LoginAlert />
  
            <div className='mt-3'>
              <Link to="../homepage" className='ml-1 text-sm text-gray-600'>
                <LeftOutlined /> หน้าแรก
              </Link>
            </div>

            <div className='flex justify-between mx-2'>

              <div>
                <h2 className='text-2xl my-4 '>ตั้งค่าการจัดส่งสินค้า</h2>
              </div>
  
              <div>
                {user?
                <div className='flex flex-col items-end	'>
                  <Link to="/add-carrier" >
                    <button className='
                        p-2 my-4 mr-2 rounded
                        bg-blue-500 hover:bg-blue-600 active:bg-blue-700  text-white text-sm'
                      type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มผู้ให้บริการจัดส่ง
                    </button>
                  </Link>
                </div>
                : 
                <button 
                  className='
                    my-4 py-2 px-4 rounded
                    bg-blue-300  text-white text-sm cursor-not-allowed'
                  type="button"> <label className='text-md mr-1 font-black cursor-pointer'>+</label>เพิ่มผู้ให้บริการจัดส่ง
                </button>
                }
              </div>
            </div>
            
            <div className='mb-4 mx-2'>
  
              <div className='flex'>
                  <Search
                      placeholder="ค้นหาชื่อการจัดส่ง"
                      allowClear
                      style={{ marginRight: 10 }}
                      onSearch={handleSearch}
                      value={searchQuery}
                      onChange={e => handleSearch(e.target.value)}
                  />
              </div>
  
              <div>
                  <Popconfirm
                      title="ยืนยันการลบตัวเลือกการจัดส่ง"
                      description="คุณต้องการลบตัวเลือกการจัดส่งที่เลือกหรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={handleBulkDelete}
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                      className='ml-8 mt-2'
                      disabled={selectedCarriers.length === 0}
                  >
                      {selectedCarriers.length === 0 ? (
                      <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 text-sm text-gray-400 cursor-no-drop' type="button">ลบ</button>
                      ) : (
                      <button className='mt-0.5 ml-3 py-1 px-4 rounded bg-gray-200 hover:bg-gray-300 text-sm' type="button">ลบ</button>
                      )}
                  </Popconfirm>
              </div>
  
            </div>
  
            <div className="grid grid-cols-1 gap-2">
              <div>
                <div className='flex justify-between'>
                  {carriers.length === 0 ? "" :
                  <div className='flex flex-row mb-3 ml-3 text-sm text-gray-500'>
                      <div className='min-w-8'>
                      <input
                          type="checkbox"
                          checked={selectedCarriers.length === filteredCarriers.length}
                          onChange={handleSelectAll}
                          className='transform scale-125'
                      />
                      </div>
                  </div>
                  }
                  <div className='opacity-50 pr-2 space-x-4 flex'>
                      <div className='cursor-pointer flex' onClick={handleSortByTime}>เวลา
                          {sortOrder === 'asct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className='opacity-50 '/> <CaretDownOutlined className='-translate-y-1.5 '/></div>
                          : sortOrder === 'desct' ? <div className='flex flex-col pl-1'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5 opacity-50'/></div>
                              : <div className='flex flex-col pl-1  opacity-50'><CaretUpOutlined className=''/> <CaretDownOutlined className='-translate-y-1.5'/></div>}
                      </div>
                    </div>
                </div>
  
                <div className='text-sm '>
                  {carriers.length === 0 ? (
                  <div className='bg-white p-4 my-4 rounded-md text-center text-gray-400'>
                      <label>ไม่พบข้อมูล</label>
                  </div>) : ''}
                  
                  {selectedCarriersPage.map(carrier => (
                  <div key={carrier._id} className='bg-white px-4 py-2 rounded-lg mx-1 mb-3 overflow-hidden break-all whitespace-normal cursor-pointer' >
                      <div className='flex justify-between'>
                        <div className='mb-1'>
                          <input
                              type="checkbox"
                              checked={selectedCarriers.includes(carrier._id)}
                              onChange={() => handleSelectCarrier(carrier._id)}
                              className='transform scale-125 cursor-pointer'
                          />
                        </div>
                        <div onClick={() => handleEdit(carrier._id)}>
                          {new Date(carrier.updatedAt).toLocaleString()}  
                        </div>
                      </div>
  
                      <div className='flex flex-row' onClick={() => handleEdit(carrier._id)}>
                      <div className='min-w-20 max-w-20 '>
                              <a>
                              {carrier.imageUrls  && carrier.imageUrls[0]  ? (
                              <img 
                                className="rounded-lg ring-1 ring-gray-200" 
                                src={getImageCarrierUrl(carrier.imageUrls[0])} alt={carrier.name} width="80"                             
                                onError={(e) => { e.target.onerror = null; e.target.src = `${import.meta.env.VITE_BACKEND_URL}public/uploads/server/image-notfound.png`; }}
                              />
                              ) : (
                              <img className="rounded-lg" src={`${import.meta.env.VITE_BACKEND_URL}public/uploads/carrier/other.jpg`} width="80" />
                              )}
                              </a>
                          </div>
                          <div className='flex flex-col text-wrap w-full  px-2 truncate cursor-pointer' >
                            <div className=' pt-1 max-h-12 truncate-2-lines text-lg'>{carrier.name}</div>
                            <div className='text-sm text-gray-500 truncate'>
                              {carrier.description === "undefined" ? 'ไม่มีรายละเอียด' : carrier.description}
                            </div>
                          </div>
                      </div>

                      <div className='flex text-base'>
  
                        <button onClick={() => handleEdit(carrier._id)}><p className="bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300">แก้ไข</p></button>
                      
                        <Popconfirm
                          title="ยืนยันการลบตัวเลือกการจัดส่ง"
                          description="คุณต้องการลบตัวเลือกการจัดส่งนี้หรือไม่? จะไม่สามารถกู้คืนข้อมูลที่ลบได้"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => handleDelete(carrier._id)}
                          okText="ยืนยัน"
                          cancelText="ยกเลิก"
                        >
                          <button className='bg-gray-200 rounded py-1 px-4 mt-2 mr-1.5 active:bg-gray-300'><p className="w-full text-center">ลบ</p></button>
                        </Popconfirm>

                      </div>

                    </div>
                  ))}
                </div>
                
              </div>
            </div>
  
            <div>
              <div className="flex justify-center mb-24">
                <Pagination
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={carriers.length}
                  onChange={handlePageChange}
                  onShowSizeChange={handleItemsPerPageChange}
                  showSizeChanger
                  pageSizeOptions={['10', '20', '50' , '100']}
                />
              </div>
            </div>
  
          </div>
  
          <div className=''></div>
        </div>
      </>
    );
  }

export default ManageCarrier;