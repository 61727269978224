import React from 'react';
import { Link ,useLocation} from "react-router-dom";

import './Calcu.css';


import TiktokLogo from "../../assets/TikTokLogo.png";
import ShopeeLogo from "../../assets/ShopeeLogo.png"
import LazadaLogo from "../../assets/LazadaLogo.png"
import LineshopLogo from "../../assets/lineshopLogo.png"
import NocnocLogo from "../../assets/nocnocLogo.jpg"
import basket1_all from "../../assets/basket1_all.png"

export function SelectCalcu() {
  const location = useLocation();

  return(
    <div className='flex flex-row justify-center place-items-center gap-4'>

      {location.pathname==='/checkfee/tiktok' ? //Tiktok
      <div className="rounded-lg p-0 max-w-fit cursor-pointer  mt-[20px]">
        <Link to="/checkfee/tiktok"><img src={TiktokLogo} className='md:w-20 w-16 rounded-lg	 duration-100 '/></Link>
      </div>
      :
      <div className="rounded-lg p-0 max-w-fit mt-[20px]">
        <Link to="/checkfee/tiktok"><img src={TiktokLogo} className='md:w-14 w-[50px] grayscale hover:grayscale-0 rounded-lg hover:h-16 hover:w-16	 duration-100 shadowimg'/></Link>
      </div>
      }

      {location.pathname==='/checkfee/shopee' ? //Shopee
      <div className="rounded-lg p-0 max-w-fit cursor-pointer  mt-[20px]">
        <Link to="/checkfee/shopee"><img src={ShopeeLogo} className='md:w-20 w-16 rounded-lg	 duration-100 '/></Link>
      </div>
      :
      <div className="rounded-lg p-0 max-w-fit mt-[20px]">
        <Link to="/checkfee/shopee"><img src={ShopeeLogo} className='md:w-14 w-[50px] grayscale hover:grayscale-0 rounded-lg hover:h-16 hover:w-16	 duration-100 shadowimg'/></Link>
      </div>
      }

      {location.pathname==='/checkfee/lazada' ? //Lazada
      <div className="rounded-lg p-0 max-w-fit cursor-pointer  mt-[20px]">
        <Link to="/checkfee/lazada"><img src={LazadaLogo} className='md:w-20 w-16 rounded-lg	 duration-100 '/></Link>
      </div>
      :
      <div className="rounded-lg p-0 max-w-fit mt-[20px]">
        <Link to="/checkfee/lazada"><img src={LazadaLogo} className='md:w-14 w-[50px] grayscale hover:grayscale-0 rounded-lg hover:h-16 hover:w-16	 duration-100 shadowimg'/></Link>
      </div>
      }

      {location.pathname==='/checkfee/lineshop' ? //LineShop
      <div className="rounded-lg p-0 max-w-fit cursor-pointer  mt-[20px]">
        <Link to="/checkfee/lineshop"><img src={LineshopLogo} className='md:w-20 w-16 rounded-lg	 duration-100 '/></Link>
      </div>
      :
      <div className="rounded-lg p-0 max-w-fit mt-[20px]">
        <Link to="/checkfee/lineshop"><img src={LineshopLogo} className='md:w-14 w-[50px] grayscale hover:grayscale-0 rounded-lg hover:h-16 hover:w-16 duration-100 shadowimg'/></Link>
      </div>
      }

      {location.pathname==='/checkfee/nocnoc' ? //Nocnoc
      <div className="rounded-lg p-0 max-w-fit cursor-pointer  mt-[20px]">
        <Link to="/checkfee/nocnoc"><img src={NocnocLogo} className='md:w-20 w-16 rounded-lg	 duration-100 '/></Link>
      </div>
      :
      <div className="rounded-lg p-0 max-w-fit mt-[20px]">
        <Link to="/checkfee/nocnoc"><img src={NocnocLogo} className='md:w-14 w-[50px] grayscale hover:grayscale-0 rounded-lg hover:h-16 hover:w-16 duration-100 shadowimg'/></Link>
      </div>
      }

      {location.pathname==='/checkfee/b2c' ? //B2C
      <div className="rounded-lg p-0 max-w-fit cursor-pointer  mt-[20px]">
        <Link to="/checkfee/b2c"><img src={basket1_all} className='md:w-20 w-16 rounded-lg	 duration-100 '/></Link>
      </div>
      :
      <div className="rounded-lg p-0 max-w-fit mt-[20px]">
        <Link to="/checkfee/b2c"><img src={basket1_all} className='md:w-14 w-[50px] grayscale hover:grayscale-0 rounded-lg hover:h-16 hover:w-16 duration-100 shadowimg'/></Link>
      </div>
      }

    </div>
  )
}